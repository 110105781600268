export const types = {
  members: "[GET] Members",
  addMembers: "[POST] Members",
  removeMembers: "[DELETE] Members",
  refreshMembers: "[POST] Members",
  teams: "[GET] Teams",
  addTeams: "[POST] Teams",
  removeTeams: "[DELETE] Teams",
  refreshTeams: "[POST] Teams",
};
