import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Grid, Paper, Divider } from "@mui/material";
import Overview from "../overview/overview";

import { useSelector } from "react-redux";
import { MdPerson } from "react-icons/md";

const SummaryCauses = () => {
  const { causesCoins } = useSelector((state) => state.causes);
  const individualDonationAmount = causesCoins.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue;
    },
    0
  );

  const pending = {
    title: "Pendientes",
    totalNumber: 3,
    statistics: "10%",
    color: true,
    reference: "esta semana",
  };

  const individualDonation = {
    title: "Donación individual",
    totalNumber: individualDonationAmount,
    statistics: "5%",
    color: true,
    reference: "esta semana",
  };

  const donatedEquipment = {
    title: "Donación equipo",
    totalNumber: 2152080,
    statistics: "5%",
    color: true,
    reference: "esta semana",
  };

  const contributors = {
    title: "Donadores",
    totalNumber: "1250",
    statistics: "5631",
    color: false,
    reference: "usuarios",
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Overview data={individualDonation}></Overview>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            display: "flex",
            order: 3,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Overview data={pending}>
            <MdPerson
              style={{ width: "25px", height: "25px", color: "white" }}
            />
          </Overview>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Overview
            data={contributors}
            icon={
              <MdPerson
                style={{
                  width: "25px",
                  height: "25px",

                  color: "white",
                }}
              />
            }
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Overview
            data={donatedEquipment}
            icon={
              <MdPerson
                style={{
                  width: "25px",
                  height: "25px",

                  color: "white",
                }}
              />
            }
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SummaryCauses;
