import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import {
  createBlog,
  createBlogImage,
  updateBlog,
} from "../../redux/banners/actions/actions";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { Close } from "@mui/icons-material";
import { styles } from "../../styles/styles";
import { FormControl } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { Checkbox } from "@mui/material";

import UploadBlogImage from "./UploadBlogImage";

const MySwal = withReactContent(Swal);

const BlogModal = ({ open, onClose, data, handleRefresh, mode }) => {
  // useEffect(() => {
  //   console.log("Data prop:", data);
  // }, [data]);
  const theme = useTheme();

  const validationSchema = Yup.object().shape({
    businessName: Yup.string().required("Business Name is required"),
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
    // Add validation for other fields as needed
  });

  const [imageUpload, setImageUpload] = useState("");
  const [image, setImage] = useState("");
  const [fileUrl, setFileUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isRoute, setIsRoute] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: data?.name ?? "",
      imageURL: data?.imageURL ?? "",
      showOnWeb: data?.showOnWeb ?? false,
      orderNumber: data?.orderNumber ?? 0,
      showOnApp: data?.showOnApp ?? false,
    },
    onSubmit: async (values, { resetForm }) => {
      const updatedData = {
        showOnWeb: values.showOnWeb,
        name: values.name,
        imageURL: values.imageURL,
        orderNumber: values.orderNumber,
        showOnApp: values.showOnApp, // Corrected typo in key name
      };

      // Show the confirmation prompt using Swal
      const confirmResult = await MySwal.fire({
        text: ` ¿Estás seguro de que quieres editar este blog?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, editar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });

      if (confirmResult.isConfirmed) {
        setLoading(true);
        const loadingSwal = MySwal.fire({
          title: "Guardando...",

          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            MySwal.showLoading();
          },
        });

        try {
          if (mode === "add") {
            const id = await createBlog(updatedData);
            if (imageUpload !== "") {
              createBlogImage(imageUpload, id);
            }

            onClose();
            handleRefresh();
          } else {
            if (imageUpload !== "") {
              createBlogImage(imageUpload, data.id);
            }
            await updateBlog(data.id, updatedData);

            onClose();
            handleRefresh();
          }

          // Close the success Swal after a delay
          setLoading(false);
          setImageUpload("");
        } catch (error) {
          // Handle error
          console.error(error);
          MySwal.fire("Error", "Hubo un error al editar el blog", "error");
        } finally {
          setTimeout(() => {
            loadingSwal.close();
          }, 1000);
        }
      }
    },
  });

  useEffect(() => {
    // Update formik initial values and the image state when data prop changes
    formik.setValues({
      name: data?.name ?? "",
      imageURL: data?.imageURL ?? "",
      showOnWeb: data?.showOnWeb ?? false,
      orderNumber: data?.orderNumber ?? 0,
      showOnApp: data?.showOnApp ?? false,
    });

    // Update the image state with the initial data.imageURL
    setImage(data?.imageURL || "");
  }, [data]);

  //   useEffect(() => {
  //     if (formik.values.isRoute === true) {
  //       setIsRoute(true);
  //     } else {
  //       setIsRoute(false);
  //     }
  //     console.log(isRoute);
  //   }, [formik.values]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          // backgroundColor: theme.palette.secondary.secondary,
          width: "100%",
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle>
        {mode === "edit" ? "Editar Blog" : "Crear Categoría"}
        <Box sx={styles.closeButton} onClick={onClose}>
          <Close />
        </Box>
      </DialogTitle>
      <DialogContent>
        <form>
          <Box mb={3} mt={3}>
            <Typography>General</Typography>
          </Box>

          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item md={5}>
              <TextField
                variant="standard"
                fullWidth
                id="name"
                name="name"
                label="Nombre"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                variant="standard"
                fullWidth
                type="number"
                id="item"
                name="orderNumber"
                label="Numero Orden"
                value={formik.values.orderNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.orderNumber &&
                  Boolean(formik.errors.orderNumber)
                }
                helperText={
                  formik.touched.orderNumber && formik.errors.orderNumber
                }
              />
            </Grid>
          </Grid>

          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
            mt={2}
          >
            <Grid item md={5}>
              <FormControl
                component="fieldset"
                sx={{
                  marginLeft: "0px !important",
                  flexDirection: "row",
                  marginBottom: "15px",
                }}
              >
                <FormControlLabel
                  sx={{ marginLeft: "0px !important", flexDirection: "row" }}
                  value="showOnWeb"
                  label="Mostrar en Web"
                  labelPlacement="start"
                  control={
                    <Checkbox
                      name="showOnWeb"
                      checked={formik.values.showOnWeb}
                      onChange={formik.handleChange}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                />
              </FormControl>
            </Grid>
            <Grid item md={5}>
              <FormControl
                component="fieldset"
                sx={{
                  marginLeft: "0px !important",
                  flexDirection: "row",
                  marginBottom: "15px",
                }}
              >
                <FormControlLabel
                  sx={{ marginLeft: "0px !important", flexDirection: "row" }}
                  value="showOnApp"
                  label="Mostrar en App"
                  labelPlacement="start"
                  control={
                    <Checkbox
                      name="showOnApp"
                      checked={formik.values.showOnApp}
                      onChange={formik.handleChange}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                />
              </FormControl>
            </Grid>
          </Grid>

          <UploadBlogImage
            title={"Imagen Blog"}
            loadUrl={setFileUrl}
            imageUpload={imageUpload}
            setImageUpload={setImageUpload}
            setImage={setImage}
            image={image}
          />

          <DialogActions>
            <Button variant="contained" color="error" onClick={onClose}>
              Cancelar
            </Button>
            <Button
              onClick={formik.handleSubmit}
              variant="contained"
              disabled={loading}
            >
              {loading ? "Guardando..." : "Guardar"}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default BlogModal;
