import { useCallback, useEffect, useMemo, useState } from "react";

import SummaryCauses from "../../components/summary-causes/summary-causes";
import CausesTable from "../../components/causes-table/causesTable";
import CustomModal from "./../../components/modal/customModal";
import CauseAddition from "../../components/cause-addition/cause-addition";

import { Button, Stack } from "react-bootstrap";
import { Box, Container, useMediaQuery } from "@mui/material";
import { ASbutton } from "../../components/buttons/ASbutton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addCauses, addCoins } from "../../redux/causes/actions/actions";
import {
  getAllCausesByOrganization,
  getAllDonors,
} from "../../firebase/firebase.utils";
import { sortCausesTable } from "../../custom hooks/sort-table/sort-causes-table";
import { searchTable } from "../../custom hooks/search-table/search-table";
import TableFilters from "../../components/table/table-filters";

const Causes = () => {
  const [count, setCount] = useState(0);

  const matchesUp900 = useMediaQuery("(min-width:900px)");

  const [showCauseAdditionModal, setShowCauseAdditionModal] = useState(false);

  const handleRefresh = () => {
    setCount((current) => current + 1);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxCauses = useSelector((state) => state.causes);

  const [causes, setCauses] = useState([]);
  const [coinsByCause, setCoinsByCause] = useState([]);
  const [filteredCauses, setFilteredCauses] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  //const [filteringBy, setfilteringBy] = useState("Name");
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const [loading, setLoading] = useState(false);

  const handleClick = (url) => {
    navigate(url);
  };

  const headers = [
    { name: "Nombre de la causa", accessor: "causeName" },
    { name: "Solicitante", accessor: "requestor" },
    { name: "Registro", accessor: "register" },
    { name: "Coins meta", accessor: "goalCoins" },
    { name: "Coins obtenidos", accessor: "gottenCoins" },
    { name: "Categoria", accessor: "category" },
    { name: "Status", accessor: "status" },
    //{ name: "", accessor: "options" },
  ];

  const getCauses = useCallback(async () => {
    setLoading(true);
    //const causesList = await getAllCauses();
    const causesList = await getAllCausesByOrganization();
    let causesAmount = [];

    for (const cause of causesList) {
      let total = 0;
      const donorsByCause = await getAllDonors(cause.organizationId, cause.id);

      for (const donor of donorsByCause) {
        total = total + donor.coinsDonated;
      }

      causesAmount.push(total);
    }

    setCauses(causesList);
    setCoinsByCause(causesAmount);

    //setRequestors(requestorsList)
    setFilteredCauses(causesList);

    dispatch(addCauses(causesList));
    dispatch(addCoins(causesAmount));
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    if (reduxCauses.causes) {
      const { causes, causesCoins } = reduxCauses;
      setCauses(causes);
      setFilteredCauses(causes);
      setCoinsByCause(causesCoins);
      setLoading(false);
    } else {
      getCauses();
    }
  }, [reduxCauses]);

  useEffect(() => {
    if (count !== 0) {
      getCauses();
    }
  }, [count]);

  const newCauses = useMemo(() => {
    return filteredCauses.map((cause, index) => {
      return {
        id: cause.id,
        organizationId: cause.organizationId,
        causeName: cause.name,
        requestor: cause.requestor,
        register: cause.cdate,
        goalCoins: cause.coinsMeta,
        //gottenCoins: cause.coins ? cause.coins : "0",
        gottenCoins: coinsByCause[0] ? coinsByCause[index] : "0",
        category: cause.category,
        status: cause.isActiveCause ? "Activa" : "Cerrada",
        options: (
          <Button
            variant="secondary"
            size="sm"
            className="text-light"
            onClick={() =>
              handleClick(
                `/cause/${cause.id}/organization/${cause.organizationId}`
              )
            }
          >
            Ver
          </Button>
        ),
      };
    });
  }, [filteredCauses]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + Number(itemsPerPage);
    //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(newCauses.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(newCauses.length / Number(itemsPerPage)));
  }, [itemOffset, newCauses, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % newCauses.length;
    //console.log(
    //  `User requested page number ${event.selected}, which is offset ${newOffset}`
    //);
    setItemOffset(newOffset);
  };

  const sortTableElements = (sortingMethod) => {
    const sortedItems = sortCausesTable(currentItems, sortingMethod);
    setCurrentItems(sortedItems);
  };

  const showActiveCauses = (list) => {
    const filterElements = list.filter((cause) => cause.isActiveCause === true);

    setFilteredCauses(filterElements);
  };

  const showCloseCauses = (list) => {
    console.log(filteredCauses);
    const filterElements = list.filter(
      (cause) => cause.isActiveCause === false
    );

    setFilteredCauses(filterElements);
  };

  const showAllCauses = () => {
    setFilteredCauses(causes);
  };

  const dropdownList = [
    {
      valuesList: [
        { name: "Más recientes" },
        { name: "Más antiguos" },
        { name: "Orden alfabetico" },
        { name: "Metas + a -" },
        { name: "Metas - a +" },
      ],
      label: "",
      filterMethod: sortTableElements,
    },
  ];

  const options = [
    { text: "Todos", filterMethod: () => showAllCauses(causes) },
    { text: "Activas", filterMethod: () => showActiveCauses(causes) },
    { text: "Cerradas", filterMethod: () => showCloseCauses(causes) },
  ];

  const [searchField, setSearchField] = useState("");
  const rows = searchTable(currentItems, headers, searchField);

  return (
    <Container maxWidth={"xl"}>
      <Stack direction="horizontal" gap={2} className="mb-4">
        <Box className="ms-auto rounded-pill">
          <ASbutton
            buttonText="Agregar causa"
            onClick={() => {
              setShowCauseAdditionModal(true);
            }}
            style={{ color: "white !important" }}
          />
        </Box>
      </Stack>
      <SummaryCauses />

      <Box mb={-4}>
        <TableFilters
          dropdowns={matchesUp900 ? null : dropdownList}
          searchRows={matchesUp900 ? null : setSearchField}
          handleRefresh={matchesUp900 ? null : () => getCauses()}
        />
      </Box>

      <CausesTable
        options={options}
        dropdownList={dropdownList}
        headers={headers}
        rows={rows}
        loading={loading}
        pageCount={pageCount}
        setSearchField={setSearchField}
        getCauses={getCauses}
        searchField={searchField}
        setItemsPerPage={setItemsPerPage}
        handlePageClick={handlePageClick}
      />
      <CustomModal
        show={showCauseAdditionModal}
        handleClose={setShowCauseAdditionModal}
        modalTitle={() => <HeaderButtons type={"promo"} />}
      >
        <CauseAddition
          handleRefresh={handleRefresh}
          onClose={setShowCauseAdditionModal}
        />
      </CustomModal>
    </Container>
  );
};

const HeaderButtons = ({ type }) => (
  <Stack direction="horizontal" gap={2} className="">
    <Button
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#9A9A9A" }}
    >
      Registro
    </Button>
    <Button
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#AAAAAA" }}
    >
      Causa
    </Button>
  </Stack>
);

export default Causes;
