import { useState } from "react";
import { useEffect } from "react";

import { Card, Col, Row, Stack } from "react-bootstrap";

import { getAllDonors } from "../../firebase/firebase.utils";

const CauseDonorUsers = ({ causeId, organizationId }) => {
  const [donors, setDonors] = useState([]);

  useEffect(() => {
    const getDonors = async () => {
      const donorByCause = await getAllDonors(organizationId, causeId);

      if (donorByCause[0]) {
        setDonors(donorByCause);
      }
    };

    getDonors();
  }, [causeId, organizationId]);

  return (
    <Card className="border-0 rounded mt-3">
      <Card.Header className="bg-light border-bottom-0 px-0">
        <Stack direction="horizontal" className="px-3 py-3">
          <h3 className="h5">Donaciones</h3>{" "}
          <span className="ms-auto text-primary">Todas</span>
        </Stack>
        <Row className="m-0 bg-success px-2 py-3">
          <Col lg="2">#</Col>
          <Col>Usuario</Col>
          <Col className="text-end">Donación</Col>
        </Row>
      </Card.Header>
      <Card.Body className="bg-light border-bottom-0 rounded-bottom px-0">
        {donors.map((donor, index) => {
          return (
            <Row className="m-0 px-2 py-3">
              <Col lg="2">
                <p className="text-primary h5">{index + 1}</p>
              </Col>
              <Col lg="6">
                <small className="fw-bold">
                  {donor.name + " " + (donor.lastname ? donor.lastname : "")}
                </small>
                <small className="text-muted d-block">
                  {donor.email ? donor.email : "--"}
                </small>
              </Col>
              <Col className="text-end">
                <small className="fw-bold">{donor.coinsDonated}</small>
                <small className="d-block">
                  {donor.cdate
                    ? donor.cdate.toDate().toLocaleDateString("es-CR")
                    : "--"}
                </small>
              </Col>
            </Row>
          );
        })}

        {/* <Row className="m-0 px-2 py-3">
          <Col lg="2">
            <p className="text-primary h5">2</p>
          </Col>
          <Col lg="6">
            <small className="fw-bold">Alexys Alvarado</small>
            <small className="text-muted d-block">Ultima donacion</small>
          </Col>
          <Col className="text-end">
            <small className="fw-bold">14.520</small>
            <small className="d-block">14/10/23</small>
          </Col>
        </Row>
        <Row className="m-0 px-2 py-3">
          <Col lg="2">
            <p className="text-primary h5">3</p>
          </Col>
          <Col lg="6">
            <small className="fw-bold">Jorge Soler</small>
            <small className="text-muted d-block">Ultima donacion</small>
          </Col>
          <Col className="text-end">
            <small className="fw-bold">14.520</small>
            <small className="d-block">14/10/23</small>
          </Col>
        </Row>
        <Row className="m-0 px-2 py-3">
          <Col lg="2">
            <p className="text-primary h5">4</p>
          </Col>
          <Col lg="6">
            <small className="fw-bold">Marco Cersocimo</small>
            <small className="text-muted d-block">Ultima donacion</small>
          </Col>
          <Col className="text-end">
            <small className="fw-bold">14.520</small>
            <small className="d-block">14/10/23</small>
          </Col>
        </Row> */}
      </Card.Body>
    </Card>
  );
};

export default CauseDonorUsers;
