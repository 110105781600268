import React from "react";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../redux/user/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  IoPeopleOutline,
  IoStarOutline,
  IoCashOutline,
  IoCartOutline,
  IoBusinessOutline,
  IoTrophyOutline,
  IoGridOutline,
} from "react-icons/io5";
import { GoHome, GoVersions, GoCodeOfConduct } from "react-icons/go";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { Box, styled, useMediaQuery, useTheme } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MenuButton from "./MenuButton";
import logo from "../../assests/logo.png";
import { appVersion } from "../../utils/Environment";
import ResponsiveDrawer from "./ResponsiveDrawer";

const drawerWidth = 275;

const Navigation = ({ handleDrawerToggle, open }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const navigateTo = (link) => {
    navigate(link);
  };

  const matches1200 = useMediaQuery("(min-width:1200px)");

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/auth/login", { replace: true });
  };
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(0, 1),

    ...theme.mixins.toolbar,
  }));

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    overflowX: "hidden",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const CustomDrawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",

    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const customDrawer = (
    <CustomDrawer
      anchor="left"
      open={open}
      onClose={handleDrawerToggle}
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
      }}
    >
      <DrawerHeader>
        <Box
          component={"a"}
          id="sidebar"
          sx={{
            display: open ? "flex" : "none",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "175px",
          }}
          onClick={() => navigateTo("/")}
        >
          {open && (
            <img
              src={logo}
              alt="Home"
              style={{ width: "75%", height: "auto" }}
            />
          )}
        </Box>
        <IconButton
          onClick={handleDrawerToggle}
          sx={{ color: theme.palette.primary.secondary }}
        >
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>

      <List>
        <MenuButton
          item={{
            title: "Inicio",
            icon: <GoHome size={25} className="inactive-icon" />,
            route: "/home",
          }}
          sx={{ fontWeight: "normal", fontSize: 56 }}
          open={true}
        />
        <MenuButton
          item={{
            title: "Usuarios",
            icon: <IoPeopleOutline size={25} className="inactive-icon" />,
            route: "/user",
          }}
          open={true}
        />
        <MenuButton
          item={{
            title: "Asociaciones",
            icon: <IoBusinessOutline size={25} className="inactive-icon" />,
            route: "/b2b",
          }}
          open={true}
        />
        <MenuButton
          item={{
            title: "Sponsor",
            icon: <GoVersions size={25} className="inactive-icon" />,
            route: "/sponsors",
          }}
          open={true}
        />
        <MenuButton
          item={{
            title: "Solicitudes",
            icon: <GoVersions size={25} className="inactive-icon" />,
            route: "/sponsorRequests",
          }}
          open={true}
        />
        <MenuButton
          item={{
            title: "Causas",
            icon: <GoCodeOfConduct size={25} className="inactive-icon" />,
            route: "/causes",
          }}
          open={true}
        />
        <MenuButton
          item={{
            title: "Negocio",
            icon: <IoStarOutline size={25} className="inactive-icon" />,
            route: "/commerce",
          }}
          open={true}
        />
        <MenuButton
          item={{
            title: "Productos",
            icon: <IoCartOutline size={25} className="inactive-icon" />,
            route: "/products",
          }}
          open={true}
        />
        <MenuButton
          item={{
            title: "Publicidad",
            icon: <IoCashOutline size={25} className="inactive-icon" />,
            route: "/advertising",
          }}
          open={true}
        />
        <MenuButton
          item={{
            title: "Achievements",
            icon: <IoTrophyOutline size={25} className="inactive-icon" />,
            route: "/achievements",
          }}
          open={true}
        />
        <MenuButton
          item={{
            title: "Blog",
            icon: <IoTrophyOutline size={25} className="inactive-icon" />,
            route: "/blogs",
          }}
          open={true}
        />
        <MenuButton
          item={{
            title: "Equipo",
            icon: <IoGridOutline size={25} className="inactive-icon" />,
            route: "/roles",
          }}
          open={true}
        />
      </List>

      <Box style={{ flexGrow: 1 }} />
      <Box
        sx={{ display: "flex", justifyContent: "space-between" }}
        pl="20px"
        pr="25px"
        mb={2}
      >
        <Avatar alt="User Avatar" src="https://picsum.photos/200" />
        <div className="ms-4 w-100">
          <Typography variant="body1" sx={{ fontSize: "14px !important" }}>
            {user?.email ? user.email : "N/A"}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Project Manager
          </Typography>
        </div>
      </Box>

      <List>
        <MenuButton
          item={{
            title: "Cerrar sesión",
            icon: <ExitToAppIcon size={25} className="inactive-icon" />,
          }}
          open={true}
          onClickAction={handleLogout}
        />
      </List>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <ListItem>
          <ListItemText
            primary={`v${appVersion}`}
            sx={{ color: theme.palette.primary.main }}
          />
        </ListItem>
      </Box>
    </CustomDrawer>
  );

  return (
    <div className="d-flex">
      <IconButton
        onClick={handleDrawerToggle}
        sx={{ color: theme.palette.primary.main }}
      >
        <ChevronRightIcon />
      </IconButton>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {matches1200 ? (
          customDrawer
        ) : (
          <ResponsiveDrawer
            open={open}
            handleDrawerToggle={handleDrawerToggle}
            user={user}
            theme={theme}
          />
        )}
      </Box>
    </div>
  );
};

export default Navigation;
