export const searchTable = (data, headers, searchTerm) => {
  const results = data.filter((datum) => {
    return headers.some((header) => {
      let element = "";

      if (typeof datum[header.accessor].props?.children === "object") {
        element = datum[header.accessor].props.children[2].props.children;
      } else {
        element = datum[header.accessor].toString();
      }
      return element.toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  return results;
};
