import { useEffect, useState } from "react";
import { Box, CircularProgress, useTheme } from "@mui/material";
import fallback from "./../../assests/fallback2.png";
import { MdAdd, MdEdit } from "react-icons/md";
import "../../components/upload-logo/upload-logo.scss";

const UploadBannerImage = ({
  setImageUpload,
  imageUpload,
  setImage,
  image,
  inputId,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const uploadBanner = async () => {
      setImageUpload(imageUpload);
    };

    if (imageUpload === null) {
      return;
    } else {
      uploadBanner();
    }
  }, [imageUpload]);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "350px",
        width: "100%",
        maxHeight: "210px",
        height: "210px",
        marginRight: "auto",
        marginLeft: "auto",
      }}
      mt={1}
      mb={2}
    >
      {image === "" || image === null ? ( // Check if there's no image set
        <Box
          sx={{
            maxWidth: "350px",
            width: "100%",
            height: "210px",
            borderRadius: "18px",
            backgroundColor: "#f1f1f1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <label
            htmlFor={inputId ? inputId : "file-input"}
            style={{ cursor: "pointer" }}
          >
            <span
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                color: "#777",
              }}
            >
              Agregar imagen
            </span>
          </label>
        </Box>
      ) : !loading ? (
        <img
          src={image}
          alt="Banner Preview"
          style={{
            maxWidth: "350px",
            width: "100%",
            maxHeight: "210px",
            borderRadius: "18px",
            objectFit: "cover",
          }}
        />
      ) : (
        <CircularProgress color="secondary" />
      )}
      {/* <label
        htmlFor={inputId ? inputId : "file-input"}
        style={{ position: "absolute", right: "10px", top: "10px" }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            borderRadius: 50,
            display: "flex",
            width: "25px",
            height: "25px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {image === "" || image === null ? (
            <MdAdd color="white" />
          ) : (
            <MdEdit color="white" />
          )}
        </Box>
      </label> */}
      <input
        type="file"
        name="sponsor"
        id={inputId ? inputId : "file-input"}
        className="d-none"
        onChange={(event) => {
          setImageUpload(event.target.files[0]);
          setImage(URL.createObjectURL(event.target.files[0]));
        }}
      />
    </Box>
  );
};

export default UploadBannerImage;
