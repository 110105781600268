import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../redux/user/actions/auth";

import {
  Avatar,
  Box,
  Drawer,
  List,
  ListItem,
  styled,
  Typography,
} from "@mui/material";
import { ListItemText } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useMediaQuery } from "@mui/material";

import logo from "../../assests/logo.png";

import MenuButton from "./MenuButton";
import IconButton from "@mui/material/IconButton";
import { appVersion } from "../../utils/Environment";

import {
  IoPeopleOutline,
  IoStarOutline,
  IoCashOutline,
  IoCartOutline,
  IoBusinessOutline,
  IoTrophyOutline,
  IoGridOutline,
} from "react-icons/io5";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { GoHome, GoVersions, GoCodeOfConduct } from "react-icons/go";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const drawerWidth = 275;

const ResponsiveDrawer = ({ open, handleDrawerToggle, user, theme }) => {
  const navigate = useNavigate();
  const navigateTo = (link) => {
    navigate(link);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/auth/login", { replace: true });
  };

  const dispatch = useDispatch();

  const matches1200 = useMediaQuery("(min-width:1200px)");

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(0, 1),

    ...theme.mixins.toolbar,
  }));

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={handleDrawerToggle}
      variant={matches1200 ? "persistent" : ""}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
      }}
    >
      <DrawerHeader>
        <Box
          component={"a"}
          id="sidebar"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "175px",
          }}
          onClick={() => navigateTo("/")}
        >
          {open && (
            <img
              src={logo}
              alt="Home"
              style={{ width: "75%", height: "auto" }}
            />
          )}
        </Box>
        <IconButton
          onClick={handleDrawerToggle}
          sx={{ color: theme.palette.primary.main }}
        >
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>

      <div className="d-flex flex-column bg-light vh-100 p-3">
        <List>
          <MenuButton
            item={{
              title: "Inicio",
              icon: <GoHome size={25} className="inactive-icon" />,
              route: "/home",
            }}
            sx={{ fontWeight: "normal", fontSize: 56 }}
            open={true}
          />
          <MenuButton
            item={{
              title: "Usuarios",
              icon: <IoPeopleOutline size={25} className="inactive-icon" />,
              route: "/user",
            }}
            open={true}
          />
          <MenuButton
            item={{
              title: "Asociaciones",
              icon: <IoBusinessOutline size={25} className="inactive-icon" />,
              route: "/b2b",
            }}
            open={true}
          />
          <MenuButton
            item={{
              title: "Sponsor",
              icon: <GoVersions size={25} className="inactive-icon" />,
              route: "/sponsors",
            }}
            open={true}
          />
          <MenuButton
            item={{
              title: "Solicitudes",
              icon: <GoVersions size={25} className="inactive-icon" />,
              route: "/sponsorRequests",
            }}
            open={true}
          />
          <MenuButton
            item={{
              title: "Causas",
              icon: <GoCodeOfConduct size={25} className="inactive-icon" />,
              route: "/causes",
            }}
            open={true}
          />
          <MenuButton
            item={{
              title: "Negocio",
              icon: <IoStarOutline size={25} className="inactive-icon" />,
              route: "/commerce",
            }}
            open={true}
          />
          <MenuButton
            item={{
              title: "Productos",
              icon: <IoCartOutline size={25} className="inactive-icon" />,
              route: "/products",
            }}
            open={true}
          />
          <MenuButton
            item={{
              title: "Publicidad",
              icon: <IoCashOutline size={25} className="inactive-icon" />,
              route: "/advertising",
            }}
            open={true}
          />
          <MenuButton
            item={{
              title: "Achievements",
              icon: <IoTrophyOutline size={25} className="inactive-icon" />,
              route: "/achievements",
            }}
            open={true}
          />
          <MenuButton
            item={{
              title: "Blog",
              icon: <IoTrophyOutline size={25} className="inactive-icon" />,
              route: "/blogs",
            }}
            open={true}
          />
          <MenuButton
            item={{
              title: "Equipo",
              icon: <IoGridOutline size={25} className="inactive-icon" />,
              route: "/roles",
            }}
            open={true}
          />
        </List>
      </div>
      <Box style={{ flexGrow: 1 }} />
      <Box
        sx={{ display: "flex", justifyContent: "space-around" }}
        pr={2}
        pl={2}
        mb={2}
      >
        <Avatar alt="User Avatar" src="https://picsum.photos/200" />
        <div className="ms-2">
          <Typography variant="body1" sx={{ fontSize: "14px !important" }}>
            {user?.email ? user.email : "N/A"}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Project Manager
          </Typography>
        </div>
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "space-between" }}
        pr={2}
        pl={2}
        mb={2}
      >
        <ListItem onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Cerrar sesión" />
        </ListItem>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <ListItem>
          <ListItemText
            primary={`v${appVersion}`}
            sx={{ color: theme.palette.primary.main }}
          />
        </ListItem>
      </Box>
    </Drawer>
  );
};

export default ResponsiveDrawer;
