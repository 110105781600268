import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MUIDataTable from "mui-datatables";
import { Box, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomBodyWithSpinner from "../general/CustomBodyWithSpinner";

import { useNavigate } from "react-router-dom";
import { IoRefreshOutline } from "react-icons/io5";
import { IconButton } from "@mui/material";
import { PostModal } from "./PostModal";
import { ASbutton } from "../buttons/ASbutton";

export const PostsTable = ({
  items,
  setItems,
  handleRefresh,
  loading,
  id,
  noDataAvailable,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const BodyComponent = useMemo(
    () => (tableProps) => {
      return <CustomBodyWithSpinner loading={loading} {...tableProps} />;
    },
    [loading, noDataAvailable, items]
  );
  const onClose = () => {
    setEditModalOpen(false);

    setSelectedItem();
  };
  const handleEdit = (selectedItem) => {
    setSelectedItem(selectedItem);
    setEditModalOpen(true);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "name",
      label: "Nombre",
      options: {
        customBodyRender: (value) => {
          return value ? value : "N/A";
        },
        filter: false,
      },
    },

    {
      name: "sponsorName",
      label: "Sponsor",
      options: {
        customBodyRender: (value) => {
          return value ? value : 0;
        },
        filter: false,
      },
    },

    {
      name: "cdate",
      label: "Fecha de creación",
      options: {
        customBodyRender: (value) => {
          if (typeof value === "object" && value.seconds && value.nanoseconds) {
            const date = new Date(
              value.seconds * 1000 + value.nanoseconds / 1000000
            );
            const formattedDate = date.toLocaleDateString();
            return formattedDate;
          } else {
            return "-";
          }
        },

        filter: false,
      },
    },

    {
      name: "edit",
      label: "Revisar",
      options: {
        empty: true,
        customBodyRender: (_, tableMeta) => {
          const { rowIndex, rowData } = tableMeta;
          const selectedBlog = items.find((item) => item.id === rowData[0]);

          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <ASbutton
                buttonText={"Editar"}
                onClick={() => handleEdit(selectedBlog)}
              ></ASbutton>
            </Box>
          );
        },
        filter: false,
        sort: false,
      },
    },
  ];
  const CustomToolbar = ({ onRefresh }) => {
    return (
      <IconButton className="refresh-button" onClick={onRefresh}>
        <IoRefreshOutline size={25} />
      </IconButton>
    );
  };
  const options = {
    filter: true,
    responsive: "standard",
    selectableRows: "none",
    rowsPerPage: 15,
    // onTableChange: () => setItems(blogs),
    sortOrder: {
      name: "name",
      direction: "asc",
    },
    textLabels: {
      body: {
        noMatch: "No hay datos disponibles",
      },
    },
    sortCompare: (order) => (a, b) => {
      const isAsc = order === "asc";

      const column = "name";

      const aValue =
        a.data[column] !== undefined &&
        a.data[column] !== null &&
        a.data[column].trim() !== ""
          ? a.data[column]
          : "N/A";
      const bValue =
        b.data[column] !== undefined &&
        b.data[column] !== null &&
        b.data[column].trim() !== ""
          ? b.data[column]
          : "N/A";

      // Use default comparison for other cases
      if (isAsc) {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    },

    // onRowClick: (rowData) => {
    //   const clickedOwnerId = rowData[0];

    //   handleSponsorSelect(clickedOwnerId);
    // },
    customToolbar: () => {
      return <CustomToolbar onRefresh={handleRefresh} />;
    },
  };

  return (
    <Box mt={6} sx={{ width: "100%" }}>
      <MUIDataTable
        title={id}
        data={items}
        columns={columns}
        options={options}
        components={{ TableBody: BodyComponent }}
      />
      {selectedItem && (
        <PostModal
          open={editModalOpen}
          onClose={onClose}
          onRefresh={handleRefresh}
          mode={"edit"}
          data={selectedItem}
        />
      )}
    </Box>
  );
};
