import { types } from "../types/types";

export const getAchievements = () => ({
  type: types.achievements,
});

export const addAchievements = (achievements) => ({
  type: types.addAchievements,
  payload: achievements,
});
