import { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { Stack } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";

import { useFormik } from "formik";
import * as Yup from "yup";

import "react-circular-progressbar/dist/styles.css";

import { EditText } from "react-edit-text";

import darkPlus from "./../../assests/darkPlus.png";
import fallback from "./../../assests/fallback.jpg";

import {
  getActivityHistory,
  getUserWallet,
  getUserMetaData,
  getUserPrivateData,
  updateActivityHistory,
  updateUserData,
  updateUserMetaData,
  updateUserPrivateData,
  uploadFile,
  addAdminHistoryEntry,
} from "../../firebase/firebase.utils";
import { fsTimestampCreador } from "../../firebase/firebase-config";

import "./user-overview.scss";
import { Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { getChangedFields } from "../../utils/getChangeFields";
import { userInitialData } from "../../utils/userInnitialData";
import CustomModal from "../modal/customModal";
import EditUserDataForm from "./editUserDataForm/editUserDataForm";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { translations } from "./spanishFieldNames";

const MySwal = withReactContent(Swal);

const UserOverview = ({ id, user: userPublicData, editable }) => {
  const [userPrivateData, setUserPrivateData] = useState([]);
  const [userMetaData, setUserMetaData] = useState([]);
  const [imageUpload, setImageUpload] = useState(null);
  const [actityHistory, setActivityHistory] = useState([]);
  const [userWalletData, setUserWalletData] = useState([]);
  const [initialData, setInitialData] = useState(userInitialData);
  const [showEditModal, setShowEditModal] = useState(false);

  const { user: currentAdmin } = useSelector((state) => state.auth);

  const [active, setActive] = useState(null);
  const [inactive, setInactive] = useState(null);
  const [trigger, setTrigger] = useState(true);

  useEffect(() => {
    const getData = async () => {
      const privateData = await getUserPrivateData(userPublicData.id);
      const metaData = await getUserMetaData(userPublicData.id);
      const history = await getActivityHistory(userPublicData.id);
      const wallet = await getUserWallet(userPublicData.id);

      let total = history[0]?.totalkcal;
      let progress = (total * 100) / history[0]?.modality.transformedCalories;
      if (progress > 100) {
        progress = 100;
      }

      setUserPrivateData(privateData);
      setUserMetaData(metaData);
      setActivityHistory(history);
      setUserWalletData(wallet);
    };

    if (userPublicData.isActiveMember) {
      setActive(true);
      setInactive(false);
    } else {
      setActive(false);
      setInactive(true);
    }

    getData();
  }, [userPublicData.id, trigger]);

  useEffect(() => {
    const uploadUserImage = async () => {
      const path = `users/${id}/profile`;
      const fileUrl = await uploadFile(path, imageUpload);

      const userUpdates = { photoURL: fileUrl };

      updateUserData(id, userUpdates);
    };

    if (imageUpload === null) {
      return;
    } else {
      uploadUserImage();
    }
  }, [imageUpload, id]);

  const validate = Yup.object({
    country: Yup.string(),
    province: Yup.string(),
    canton: Yup.string(),
    district: Yup.string(),
    observations: Yup.string().required("Requerido"),
  });

  const createFullName = (name, lastname, secondLastname) => {
    if (!name) {
      name = "";
    }

    if (!lastname) {
      lastname = "";
    }

    if (!secondLastname) {
      secondLastname = "";
    }

    const fullName = name + " " + lastname + " " + secondLastname;

    return fullName;
  };

  useEffect(() => {
    if (userPublicData) {
      setInitialData({
        typeId: "Cedula",
        idNumber: userPrivateData?.personalId
          ? userPrivateData.personalId
          : "----",

        userName: userPublicData.name
          ? createFullName(
              userPublicData.name,
              userPublicData.lastname,
              userPublicData.secondLastname
            )
          : "----",
        birthday: userPublicData?.birthday ? userPublicData.birthday : "----",
        cdate: userPublicData?.cdate ? userPublicData.cdate : "----",
        email: userPublicData?.email ? userPublicData.email : "----",
        observations: "",
        phone: userPrivateData?.phone ? userPrivateData.phone : "----",
        country: userPublicData?.country ? userPublicData.country : "",
        province: userPublicData?.province ? userPublicData.province : "",
        totalCoins: userWalletData?.overallSportCoins
          ? userWalletData?.overallSportCoins
          : 0,
        canton: userPublicData.canton ? userPublicData.canton : "",
        district: userPublicData.district ? userPublicData.district : "",
        favoriteSport: userMetaData?.favoriteSport
          ? userMetaData.favoriteSport
          : "----",
        sportsOfInterest: userMetaData?.sportsOfInterest
          ? userMetaData.sportsOfInterest.join(" ")
          : "----",

        multiplier: actityHistory[0]?.modality.multiplier
          ? actityHistory[0].modality.multiplier
          : "3,7",
        activityTime: actityHistory[0]?.modality.activeTime
          ? actityHistory[0].modality.activeTime
          : "40min",
        status: userPublicData.isActiveMember,
      });
    }
  }, [userPublicData, userMetaData, userWalletData, actityHistory]);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: initialData,
    validationSchema: validate,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      const confirmResult = await MySwal.fire({
        title: "Confirmar edición",
        text: "¿Estás seguro de que deseas editar este usuario?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, continuar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });

      if (confirmResult.isConfirmed) {
        //setLoading(true);
        const loadingSwal = MySwal.fire({
          title: "Guardando...",
          html: '<div class="swal2-spinner"></div>',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            MySwal.showLoading();
          },
        });
        try {
          let inputCreationDate = new Date(values.cdate);
          let birthdayDate = new Date(values.birthday);

          inputCreationDate.setDate(inputCreationDate.getDate() + 1);
          birthdayDate.setDate(birthdayDate.getDate() + 1);

          let formatCreationDate =
            fsTimestampCreador.fromDate(inputCreationDate);
          let formatBirthdayDate = fsTimestampCreador.fromDate(birthdayDate);

          if (isNaN(formatCreationDate.nanoseconds)) {
            formatCreationDate = userPublicData.cdate;
          }

          if (isNaN(formatBirthdayDate.nanoseconds)) {
            formatBirthdayDate = userPublicData.cdate;
          }

          const fullNameArr = values.userName.split(" ");

          const fieldsChanged = getChangedFields(
            values,
            initialData,
            translations
          );

          const updatesUserPublicData = {
            typeId: values.typeId,
            name: fullNameArr[0],
            lastname: fullNameArr[1] ?? "",
            secondLastname: fullNameArr[2] ?? "",
            cdate: formatCreationDate,
            birthday: formatBirthdayDate,
            level: { totalCoins: values.totalCoins },
            totalCoins: values.totalCoins,
            country: values.country,
            province: values.province,
            canton: values.canton,
            district: values.district,
          };

          const updatesUserPrivateData = {
            personalId: values.idNumber,
            phone: values.phone,
          };

          const updatesUserMetaData = {
            favoriteSport: values.favoriteSport,
            sportsOfInterest: values.sportsOfInterest.split(" "),
          };

          const updatesActivityHistory = {
            modality: {
              multiplier: values.multiplier,
              activeTime: values.activityTime,
            },
          };

          const updatesAdminHistory = {
            modificationDate: new Date(),
            admin: {
              id: currentAdmin.uid,
              name: currentAdmin.displayName,
              email: currentAdmin.email,
            },
            modifications: fieldsChanged,
            observations: values.observations,
          };

          updateUserPrivateData(id, updatesUserPrivateData);
          updateUserMetaData(id, updatesUserMetaData);
          updateUserData(id, updatesUserPublicData);
          await addAdminHistoryEntry(
            updatesAdminHistory,
            `users/${id}/adminHistory`
          );
          //setShowEditModal(false);
          if (actityHistory.length) {
            updateActivityHistory(
              id,
              actityHistory[0].id,
              updatesActivityHistory
            );
          }

          setTimeout(() => {
            setTrigger((prevValue) => !prevValue);
            setShowEditModal(false);
          }, 2000);

          loadingSwal.close();
          MySwal.fire("Guardado!", "", "success");
        } catch (error) {
          console.log(error);
          MySwal.fire("Error", "Hubo un error al editar", "error");
        }

        setShowEditModal(false);
        resetForm();
        setTimeout(() => {
          setTrigger((prevValue) => !prevValue);
          setShowEditModal(false);
          loadingSwal.close();
        }, 1000);
      }
    },
  });

  return (
    <Card body border="light">
      <Form noValidate>
        <Row>
          <Col
            lg={12}
            xxl={6}
            className="d-flex align-items-center justify-content-center"
          >
            <Stack
              direction="vertical"
              className="flex-md-row justify-content-center align-items-center"
              gap={4}
              style={{ maxWidth: "450px" }}
            >
              <div>
                <div className="position-relative sponsor-container">
                  <Image
                    style={{
                      width: "120px",
                      height: "120px",
                      // width: "100%",
                      //height: "auto",
                      objectFit: "cover",
                      maxWidth: "120px",
                      //maxHeight: "120px",
                    }}
                    src={
                      userPublicData.photoURL
                        ? userPublicData.photoURL
                        : fallback
                    }
                    roundedCircle
                    // width="120px"
                    // height="120px"
                    onError={() => {}}
                  />
                  <label htmlFor="file-input">
                    <Image
                      src={darkPlus}
                      width="15"
                      height="15"
                      style={{ top: "85%" }}
                      className="position-absolute end-0 plus-sign"
                    />
                  </label>
                  <input
                    type="file"
                    name="sponsor"
                    id="file-input"
                    className="d-none"
                    onChange={(event) => {
                      setImageUpload(event.target.files[0]);
                    }}
                  />
                </div>

                <Button
                  sx={{ display: "block", mx: "auto" }}
                  onClick={() => setShowEditModal(true)}
                >
                  Editar
                </Button>
              </div>

              <Stack
                gap={2}
                className="justify-content-center align-items-center align-items-md-baseline"
              >
                <h2 className="h5 mb-0">
                  {userPublicData.userName ? userPublicData.userName : "----"}
                </h2>

                <p className="mb-0">
                  {userPublicData.email ? userPublicData.email : "----"}
                  {/* <EditText
                    name="email"
                    readonly={!editable}
                    onChange={handleChange}
                    onSave={({ value, previousValue }) => {
                      if (value === "") {
                        setFieldValue("email", previousValue);
                      } else {
                        handleSubmit();
                      }
                    }}
                    style={{
                      outline: 0,
                      width: "300px",
                      height: "30px",
                    }}
                    defaultValue={
                      userPublicData.email ? userPublicData.email : "----"
                    }
                    inputClassName="bg-info border-0 w-50 shadow-none is-invalid"
                    value={values.email}
                  /> */}
                </p>

                <small className="text-muted d-flex">
                  <span className="me-2">Usuario desde:</span>
                  <p>
                    {userPublicData.cdate.toDate().toLocaleDateString("es-CR", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </p>
                </small>
              </Stack>
            </Stack>
          </Col>
          <Col className="bl-over-1400 border-end mt-4 d-flex justify-content-center">
            <Row>
              <Col className="d-flex flex-column">
                <div className="w-auto">
                  <small className="text-muted">Código del plan</small>
                  <Typography variant="h5">
                    {userPublicData.planType.code}
                  </Typography>
                  <small className="text-muted">Nombre del plan</small>
                  <Typography variant="h5">
                    {userPublicData.planType.subTier}
                  </Typography>
                </div>
              </Col>
            </Row>
          </Col>
          <Col className="mt-4 ms-1">
            <Row>
              <Col className="d-flex flex-column align-items-md-center">
                <div className="w-auto">
                  <small className="text-muted">Coins acomulados</small>
                  <div className="fw-bold fs-4">
                    <EditText
                      name="totalCoins"
                      type="number"
                      className="mb-0"
                      readonly={!editable}
                      onChange={handleChange}
                      onSave={({ value, previousValue }) => {
                        if (value === "") {
                          setFieldValue("totalCoins", previousValue);
                        } else {
                          handleSubmit();
                        }
                      }}
                      style={{
                        outline: 0,
                        width: "100%",
                        height: "30px",
                      }}
                      defaultValue={
                        userPublicData.level?.totalCoins
                          ? userPublicData.level.totalCoins
                          : "----"
                      }
                      inputClassName="bg-info border-0 d-block shadow-none is-valid"
                      value={values.totalCoins.toFixed(2)}
                    />
                  </div>
                  <small className="text-muted">Aplicaciones integradas</small>
                  <p className="fw-bold fs-4">
                    {userPublicData?.integrationType
                      ? userPublicData.integrationType
                      : "--"}
                  </p>
                  <small className="text-muted">Registro deportivo</small>
                  <p className="fw-bold fs-4">
                    <EditText
                      name="multiplier"
                      type="number"
                      className="mb-0"
                      readonly={!editable}
                      onChange={handleChange}
                      onSave={({ value, previousValue }) => {
                        if (value === "") {
                          setFieldValue("multiplier", previousValue);
                        } else {
                          handleSubmit();
                        }
                      }}
                      style={{
                        outline: 0,
                        width: "100%",
                        height: "30px",
                      }}
                      defaultValue={
                        actityHistory[0]?.multiplier
                          ? actityHistory.multiplier
                          : "3,7"
                      }
                      inputClassName="bg-info border-0 d-block shadow-none is-valid"
                      value={values.multiplier}
                    />
                  </p>
                  <small className="text-muted">Tiempo de actividad</small>
                  <p className="fw-bold fs-4">
                    <EditText
                      name="activityTime"
                      type="number"
                      className="mb-0"
                      readonly={!editable}
                      onChange={handleChange}
                      onSave={({ value, previousValue }) => {
                        if (value === "") {
                          setFieldValue("activityTime", previousValue);
                        } else {
                          handleSubmit();
                        }
                      }}
                      style={{
                        outline: 0,
                        width: "100%",
                        height: "30px",
                      }}
                      defaultValue={
                        actityHistory[0]?.modality.activeTime
                          ? actityHistory[0].modality.activeTime
                          : "40min"
                      }
                      inputClassName="bg-info border-0 d-block shadow-none is-valid"
                      value={values.activityTime}
                    />
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <CustomModal
        show={showEditModal}
        handleClose={setShowEditModal}
        modalTitle={() => "Editar"}
      >
        <EditUserDataForm
          onClose={() => setShowEditModal(false)}
          formikData={{
            values,
            handleChange,
            errors,
            setFieldValue,
            handleSubmit,
            handleBlur,
            touched,
          }}
          handleRefresh={() => {}}
        />
      </CustomModal>
    </Card>
  );
};

export default UserOverview;
