import { types } from "../types/types";

export const getCauses = () => ({
  type: types.causes,
});

export const addCauses = (causes) => ({
  type: types.addCauses,
  payload: causes,
});

export const addCoins = (coins) => ({
  type: types.addCoins,
  payload: coins,
});

export const refreshCauses = (shouldRefresh) => ({
  type: types.refreshCauses,
  payload: shouldRefresh,
});
