import React, { useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";

import { ASbutton } from "../../buttons/ASbutton";
import { styles } from "../../../styles/styles";
import FilesUpload from "../../general/FilesUpload";

const B2bDocumentation = ({ handleNext, handleBack, files, setFiles }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [loading, setloading] = useState(false);

  // Remove the useRef for isFirstUse, it's not used in this code snippet
  // const isFirstUse = useRef(true);

  const next = () => {
    const dataToAdd = {
      files: files,
    };

    handleNext(dataToAdd);
  };

  const renderGrid = () => {
    // Render the FileUploadComponent here
    return <FilesUpload selectedFiles={files} setSelectedFiles={setFiles} />;
  };

  return (
    <Box sx={styles.plansCard}>
      <Box sx={styles.formTitle} mb={4}>
        <Typography
          variant="h7"
          sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
        >
          Selecciona la documentación (opcional)
        </Typography>
      </Box>
      {renderGrid()}
      <Grid
        container
        sx={styles.buttonsContainer}
        justifyContent={"flex-end"}
        direction="row"
      >
        <Grid item>
          <ASbutton
            onClick={handleBack}
            type="submit"
            backgroundColor={"grey"}
            color={"white"}
            buttonText={"Atrás"}
          />
        </Grid>
        <Grid item>
          <Box ml={2}>
            <ASbutton
              onClick={() => next()}
              backgroundColor={theme.palette.primary.main}
              color={"white"}
              buttonText={"Siguiente"}
            >
              Submit
            </ASbutton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default B2bDocumentation;
