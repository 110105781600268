import { Form } from "react-bootstrap";
import { Stack } from "react-bootstrap";

const ItemsControl = ({ setNumItemPerPage }) => (
  <Stack direction="horizontal" gap={2}>
    <Form.Label className="mb-0 text-muted">
      <small>Mostrar</small>
    </Form.Label>
    <Form.Select
      size="small"
      className="fw-bold border-0"
      style={{ width: "30%" }}
      onChange={(e) => setNumItemPerPage(e.target.value)}
    >
      <option>10</option>
      <option>20</option>
    </Form.Select>
    <Form.Label className="mb-0 text-muted">
      <small>por página</small>
    </Form.Label>
  </Stack>
);

export default ItemsControl;
