import { useState } from "react";

import AudienceTable from "../audience-table/audience-table";
import Publicity from "../commerce-table/publicity";

import { Card, Container, Stack } from "react-bootstrap";
import { Button } from "react-bootstrap";

const MarketingContainer = ({ promos, stepNumber, nextStep }) => {
  const [isBannerScreen, setIsBannerScreen] = useState(true);

  const pageDisplay = () => {
    if (isBannerScreen) {
      return <Publicity />;
    } else {
      return <AudienceTable />;
    }
  };

  return (
    <Container className="pb-3">
      {/* <Card className="border-light p-2 w-100"> */}
      <Stack
        direction="horizontal"
        gap={2}
        style={{ marginTop: "25px", paddingTop: "30px" }}
      >
        <Button
          style={{ backgroundColor: "#8A9BC4" }}
          size="sm"
          className="rounded-pill text-light border-0"
          onClick={() => setIsBannerScreen(true)}
        >
          Novedades
        </Button>
        <Button
          style={{ backgroundColor: "#AFAFAF" }}
          size="sm"
          className="rounded-pill text-light border-0"
          onClick={() => setIsBannerScreen(false)}
        >
          Audiencias
        </Button>
      </Stack>
      {pageDisplay()}
      {/* </Card> */}
    </Container>
  );
};

export default MarketingContainer;
