import theme from "./asTheme";

export const styles = {
  closeButton: {
    position: "absolute",
    right: 10,
    top: 15,
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  title: {
    fontFamily: `'Lexend', sans-serif`,
    color: "#3e3188 !important",
  },

  rowB: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },

  title: {
    fontFamily: `'Lexent', sans-serif`,
    color: "#3e3188 !important",
  },
  infoSection: {
    minHeight: "50vh",
    padding: "0px important",

    borderRadius: "18px",
    borderWidth: "2px",
    border: "solid",
    borderColor: theme.palette.secondary.terciary,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "50vh",
    paddingRight: "20px",
    marginTop: 4,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: "auto",
      marginLeft: "auto",
      height: "auto",
    },
    paddingBottom: "20px",
  },
  formCard: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "18px",
    borderWidth: "2px",
    border: "solid",
    borderColor: "#ECECEE",
    minHeight: "50vh",
    paddingRight: "20px",
    paddingLeft: "20px",
    marginTop: 4,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginRight: "auto",
      marginLeft: "auto",
      height: "auto",
    },
    paddingBottom: "20px",
  },

  inclusionCard: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "18px",
    borderWidth: "2px",
    border: "solid",
    borderColor: "#ECECEE",
    backgroundColor: "white",
    mt: 5.6,

    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginRight: "auto",
      marginLeft: "auto",
      height: "auto",
    },
  },

  plansCard: {
    display: "flex",
    flexDirection: "column",
    minHeight: "70vh",

    alignItems: "center",

    paddingRight: "20px",
    paddingLeft: "20px",
    marginTop: 4,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginRight: "auto",
      marginLeft: "auto",
      height: "auto",
    },
  },
  planSelection: {
    borderRadius: "18px",
    minHeight: "350px",
    borderWidth: "2px",
    border: "solid",
    borderColor: "#ECECEE",
    width: "100%",
    margin: "0 auto",
  },
  planSelectionLoading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "18px",
    minHeight: "350px",
    borderWidth: "2px",
    border: "solid",
    width: "100%",
    borderColor: "#ECECEE",
    margin: "0 auto",
  },
  formTitle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  buttonsContainer: {
    // marginTop: "4em",
    marginTop: "auto",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2em",
    },
    marginBottom: 2,
    // paddingRight: 2,
  },
  summaryCard: {
    width: "100%",
    borderRadius: "18px",
    borderWidth: "2px !important",
    border: "solid",
    borderColor: "#ECECEE",
    p: 2,
  },
  qrCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: "18px",
    borderWidth: "2px !important",
    border: "solid",
    borderColor: "#ECECEE",
    p: 2,
  },
  b2bTable: {
    width: "100%",
    minHeight: "60vh",
  },
  requestCard: {
    display: "flex",
    flexDirection: "column",

    alignItems: "center",

    marginTop: 2,
    [theme.breakpoints.down("md")]: {
      marginRight: "auto",
      marginLeft: "auto",
      height: "auto",
    },
  },
  requestSummaryCard: {
    width: "100%",
    borderRadius: "18px",
    position: "relative",
    borderWidth: "2px !important",
    border: "solid",
    borderColor: "#ECECEE",
    pr: 2,
    pl: 2,
    py: 1,
  },
};
