import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import { Col, Row, Button, Spinner, Stack } from "react-bootstrap";
import { TagsInput } from "react-tag-input-component";
import FormInput from "../form-input/form-input";
// import UploadLogo from "../upload-logo/upload-logo";
// import InputGroup from "react-bootstrap/InputGroup";

// import { createMember, createMemberImage } from "../../firebase/firebase.utils";
// import { fsTimestampCreador } from "../../firebase/firebase-config";

const roles = [
  { name: "Super Admin", value: "superadmin" },
  { name: "Admin", value: "admin" },
  { name: "Moderador", value: "moderator" },
];

const typeIdMember = [
  { name: "Pasaporte", value: "pasaporte" },
  { name: "Cédula", value: "cedula" },
];

const countriesList = [
  { name: "Costa Rica", value: "costaRica" },
  { name: "Nicaragüa", value: "nicaragua" },
];

const MemberAddition = ({ onClose, handleRefresh }) => {
  const [teams, setTeams] = useState([]);
  // const [imageUpload, setImageUpload] = useState("");
  // const [image, setImage] = useState("");
  // const [fileUrl, setFileUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const { userGetToken } = useSelector((state) => state.auth);

  const reloadToken = async () => {
    let freshToken = await userGetToken.getIdToken(true);
    return freshToken;
  };

  const validate = Yup.object({
    memberName: Yup.string().required("Requerido"),
    memberLastName: Yup.string().required("Requerido"),
    memberEmail: Yup.string()
      .email("Formato no válido")
      .typeError("Formato no válido")
      .required("Requerido"),
    roles: Yup.string(),
    typeIdMember: Yup.string(),
    idMember: Yup.number()
      .typeError("Solo puede ingresar valores numericos")
      .required("No es un ID válido"),
    countries: Yup.string(),
    province: Yup.string().required("Requerido"),
    city: Yup.string().required("Requerido"),
    location: Yup.string().required("Requerido"),
    memberPhoneNumber: Yup.number()
      .typeError("Solo puede ingresar valores numericos")
      .required("No es un número válido"),
  });

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      memberName: "",
      memberLastName: "",
      memberEmail: "",
      memberPhoneNumber: "",
      role: roles[0].value,
      typeIdMember: typeIdMember[0].name,
      idMember: "",
      countries: countriesList[0].name,
      city: "",
      province: "",
      location: "",
    },
    validationSchema: validate,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const memberTeams = teams;

      const memberData = {
        typeId: values.idType,
        memberId: values.idMember,
        name: values.memberName,
        lastName: values.memberLastName,
        phoneNumber: values.memberPhoneNumber,
        country: values.country,
        city: values.city,
        province: values.province,
        location: values.location,
        teams: memberTeams,
        imageUrl: "",
        isActive: true,
      };
      const token = await reloadToken();

      const url =
        "https://us-central1-allsports-69231.cloudfunctions.net/admins/rootAdmin";
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: values.memberEmail,
          role: values.role,
          data: memberData,
        }),
      };

      try {
        const response = await fetch(url, options);
        const res = await response.json();

        //const imageUrl = createMemberImage(imageUpload, res.adminId);

        setTimeout(() => {
          setLoading(false);
          handleRefresh();
          resetForm();
          onClose(false);
        }, 2000);

        return res.ok;
      } catch (error) {
        console.log(error);
        throw new Error(error);
      }
    },
  });
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      noValidate
      className="p-3"
    >
      <Row>
        <Col>
          <Form.Group>
            <FormInput
              label={"Nombre"}
              onChange={handleChange}
              value={values.memberName}
              type="text"
              name="memberName"
              errorList={errors}
            />

            <Form.Control.Feedback type="invalid">
              {errors.memberName}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <FormInput
              label={"Apellido"}
              onChange={handleChange}
              value={values.memberLastName}
              type="text"
              name="memberLastName"
              errorList={errors}
            />

            <Form.Control.Feedback type="invalid">
              {errors.memberLastName}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group>
            <FormInput
              label={"Correo"}
              onChange={handleChange}
              value={values.memberEmail}
              type="text"
              name="memberEmail"
              placeholder={""}
              errorList={errors}
            />

            <Form.Control.Feedback type="invalid">
              {errors.adminEmail}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: "14px", marginBottom: "15px" }}>
              Roles
            </Form.Label>
            <Form.Select
              size="sm"
              onChange={handleChange}
              name="role"
              value={values.role}
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-3"
            >
              {roles.map((role) => {
                return (
                  <option value={role.value} name="role">
                    {role.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: "14px" }}>Equipos</Form.Label>
            <TagsInput
              value={teams}
              onChange={setTeams}
              name="tags"
              autocomplete="off"
              classNames={{
                input:
                  "border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none",
                tag: "bg-tags",
              }}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: "14px" }} className="mb-3">
              Pais
            </Form.Label>
            <Form.Select
              size="sm"
              onChange={handleChange}
              name="country"
              value={values.country}
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
            >
              {countriesList.map((country) => {
                return (
                  <option value={country.value} name="country">
                    {country.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <FormInput
              label={"Provincia/Departamento"}
              onChange={handleChange}
              value={values.province}
              type="text"
              name="province"
              placeholder={"San Jose"}
              errorList={errors}
            />

            <Form.Control.Feedback type="invalid">
              {errors.province}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group>
            <FormInput
              label={"Ciudad"}
              onChange={handleChange}
              value={values.city}
              type="text"
              name="city"
              placeholder={"San Jose"}
              errorList={errors}
            />

            <Form.Control.Feedback type="invalid">
              {errors.city}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <FormInput
              label={"Ubicacion"}
              onChange={handleChange}
              value={values.location}
              type="text"
              name="location"
              placeholder={"https://googlemaps"}
              errorList={errors}
            />

            <Form.Control.Feedback type="invalid">
              {errors.location}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <FormInput
            label={"Teléfono"}
            onChange={handleChange}
            value={values.memberPhoneNumber}
            type="text"
            name="memberPhoneNumber"
            placeholder={"85321457"}
            errorList={errors}
          />
          <Form.Control.Feedback type="invalid">
            {errors.memberPhoneNumber}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label style={{ fontSize: "14px" }} className="mb-3">
            Tipo de ID
          </Form.Label>
          <Form.Select
            size="sm"
            onChange={handleChange}
            name="idType"
            value={values.idType}
            className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
          >
            {typeIdMember.map((idType) => {
              return (
                <option value={idType.value} name="idType">
                  {idType.name}
                </option>
              );
            })}
          </Form.Select>
        </Col>
        <Col>
          <FormInput
            label={"Número ID"}
            onChange={handleChange}
            value={values.idMember}
            type="text"
            name="idMember"
            placeholder={"202029991"}
            errorList={errors}
          />

          <Form.Control.Feedback type="invalid">
            {errors.idMember}
          </Form.Control.Feedback>
        </Col>
      </Row>

      <Stack direction="horizontal" className="mt-3">
        <Button
          variant="light"
          size="sm"
          className="rounded-pill ms-auto px-3"
          onClick={() => onClose(false)}
        >
          Cancelar
        </Button>
        <Button
          variant="primary"
          size="md"
          className="rounded-pill text-light px-3"
          type="submit"
          disabled={loading}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {!loading && <span style={{ color: "white !important" }}>Crear</span>}
        </Button>
      </Stack>
    </Form>
  );
};

export default MemberAddition;
