import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./table.scss";

const TableBody = ({ rows, columns, clickGoTo }) => {
  const navigate = useNavigate();
  const [tableRows, setTableRows] = useState([]);

  const handleClick = (url) => {
    navigate(url);
  };

  useEffect(() => {
    setTableRows(rows);
  }, [rows]);

  return (
    <tbody className="p-4">
      {tableRows.map((row) => {
        return (
          <tr
            className="hover-rows"
            key={row.id}
            onClick={() => {
              if (row.organizationId) {
                handleClick(
                  `${clickGoTo}${row.id}/organization/${row.organizationId}`
                );
              } else {
                handleClick(`${clickGoTo}${row.id}`);
              }
            }}
          >
            {columns.map(({ accessor }) => {
              if (row[accessor]?.toDate) {
                const dateField = row[accessor]
                  .toDate()
                  .toLocaleDateString("es-CR");

                return (
                  <td key={accessor} className="fw-bold">
                    {dateField}
                  </td>
                );
              }
              const tableField = row[accessor] ? row[accessor] : "--";
              return (
                <td key={accessor} className="fw-bold truncate">
                  {tableField}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
