import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Stack } from "react-bootstrap";
import { Col, Row, Container } from "react-bootstrap";
import { Button } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { getRequests } from "../../redux/requests/actions/requests";

import CustomModal from "../modal/customModal";
import ExchangeEdit from "../exchange-edit/exchange-edit";
import PromotionEdit from "../promotion-edit/promotion-edit";

import {
  getPendingExchanges,
  getPendingPromotions,
  getRedeemsFromSponsor,
} from "../../firebase/firebase.utils";

import "./requests.scss";
import RedeemModal from "../redeems/RedeemModal";

const Requests = ({ id, business }) => {
  const dispatch = useDispatch();
  const [pendingExchanges, setPendingExchanges] = useState([]);
  const [pendingPromotions, setPendingPromotions] = useState([]);
  const [redeemsList, setRedeemsList] = useState([]);
  const [showExchangeModal, setShowExchangeModal] = useState(false);
  const [showPromoModal, setShowPromoModal] = useState(false);
  const [showRedeemModal, setShowRedeemModal] = useState(false);
  const [selectedElement, setSelectedElement] = useState(0);
  const totalRequests = pendingExchanges.length + pendingPromotions.length;
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    dispatch(getRequests(totalRequests));
    const queryExchanges = getPendingExchanges(id);
    const queryPromotios = getPendingPromotions(id);
    const redeemsFromFB = getRedeemsFromSponsor(id);

    queryExchanges.onSnapshot((querySnapshot) => {
      const exchangesArr = [];
      querySnapshot.forEach((doc) => {
        exchangesArr.push({ id: doc.id, ...doc.data(), type: "exchange" });
        setPendingExchanges(exchangesArr);
      });
    });

    queryPromotios.onSnapshot((querySnapshot) => {
      const promosArr = [];
      querySnapshot.forEach((doc) => {
        promosArr.push({ id: doc.id, ...doc.data(), type: "promo" });
      });
      setPendingPromotions(promosArr);
    });

    setRedeemsList(redeemsFromFB);
  }, [id]);

  const renderRequests = () => {
    const allItems = [
      ...pendingExchanges,
      ...pendingPromotions,
      ...redeemsList,
    ];

    if (!allItems.length) {
      return <h5 className="text-center my-3">No hay solicitudes</h5>;
    }

    return allItems.map((element) => (
      <Row className="py-3">
        <Col xxl="1" lg={0}>
          <small className="d-block rounded-circle bg-primary outer-circle"></small>
        </Col>
        <Col className="p-0" lg="9">
          <Stack direction="horizontal" className="px-3">
            <small className="text-muted">
              {element.type === "redeem"
                ? "Reembolso"
                : element.type === "exchange"
                ? "Canje"
                : "Promo"}{" "}
              -{" "}
              {/* {element.cdate.toDate().toLocaleDateString("es-CR", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })} */}
            </small>
            <small className="text-muted ms-auto">Coins</small>
          </Stack>

          <Stack direction="horizontal" className="px-3">
            <small className="fw-bold">
              {element.type === "redeem" ? element.exchange.name : element.name}
            </small>
            <small className="ms-auto fw-bold">
              {element.coinPrice ? element.coinPrice : "--"}
            </small>
          </Stack>
        </Col>
        <Col className="text-center">
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              setSelectedElement(element);
              handleReview(element.type);
            }}
          >
            Revisar
          </Button>
        </Col>
      </Row>
    ));
  };

  const handleReview = (type) => {
    switch (type) {
      case "promo":
        setShowPromoModal(true);

        return;
      case "exchange":
        setShowExchangeModal(true);
        return;
      case "redeem":
        setShowRedeemModal(true);
      default:
        return;
    }
  };

  return (
    <Card className="border-light">
      <Card.Body className="p-0">
        <Stack
          direction="horizontal"
          className="py-3 px-4 border-bottom"
          gap={2}
        >
          <span
            className="bg-primary d-block rounded-circle text-light text-center"
            style={{ width: "25px", height: "25px" }}
          >
            {pendingExchanges.length + pendingPromotions.length}
          </span>
          <h2 className="h5 fw-bold mb-0">Solicitudes</h2>
        </Stack>
        <Container>
          {renderRequests()}

          {/* <div className="p-4"></div> */}
        </Container>
        <CustomModal
          show={showPromoModal}
          handleClose={setShowPromoModal}
          modalTitle={() => <HeaderButtons type={"promo"} />}
        >
          <PromotionEdit
            business={business}
            promotionId={selectedElement.id}
            sponsorId={id}
            onClose={setShowPromoModal}
            placeholderValues={selectedElement}
            buttonText={{ cancel: "Rechazar", continue: "Aprobar" }}
          />
        </CustomModal>
        <CustomModal
          show={showExchangeModal}
          handleClose={setShowExchangeModal}
          modalTitle={() => <HeaderButtons type={"exchange"} />}
        >
          <ExchangeEdit
            business={business}
            exchangeId={selectedElement.id}
            sponsorId={id}
            onClose={setShowExchangeModal}
            placeholderValues={selectedElement}
            buttonText={{ cancel: "Rechazar", continue: "Aprobar" }}
          />
        </CustomModal>
        <CustomModal
          show={showRedeemModal}
          handleClose={setShowRedeemModal}
          modalTitle={() => <HeaderButtons type={"redeem"} />}
          dialogClassName="redeemModal"
        >
          <RedeemModal
            business={business}
            redeem={selectedElement}
            sponsorId={id}
            onClose={setShowRedeemModal}
            onHide={handleShowModal}
          />
        </CustomModal>
      </Card.Body>
    </Card>
  );
};

const HeaderButtons = ({ type }) => (
  <Stack direction="horizontal" gap={2} className="">
    <Button
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#9A9A9A" }}
    >
      Aprobar
    </Button>
    <Button
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#AAAAAA" }}
    >
      {type === "promo"
        ? "Promo"
        : type === "exchange"
        ? "Canje"
        : type === "redeem"
        ? "Reembolso"
        : null}
    </Button>
  </Stack>
);

export default Requests;
