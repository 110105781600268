const translateField = (fieldName, translations) => {
  console.log(translations[fieldName]);
  return translations[fieldName] || fieldName;
};

export const getChangedFields = (currentData, originalData, translations) => {
  const changedFields = [];

  // Compare each field and add to changedFields if different
  for (const key in currentData) {
    if (!(key === "observations")) {
      if (currentData.hasOwnProperty(key)) {
        if (
          JSON.stringify(currentData[key]) !== JSON.stringify(originalData[key])
        ) {
          changedFields.push({
            field: key,
            fieldEs: translateField(key, translations),
            newValue: currentData[key],
            oldValue: originalData[key],
          });
        }
      }
    }
  }

  return changedFields;
};

export const getDifferencesInFieldsData = (data) => {
  return data.map((item) => {
    if (!Array.isArray(item.newValue)) {
      return {
        field: item.field,
        fieldEs: item.fieldEs,
        newValue: item.newValue,
        oldValue: item.oldValue,
      };
    }

    const newValueSet = new Set(item.newValue.map(JSON.stringify));
    const oldValueSet = new Set(item.oldValue.map(JSON.stringify));

    const added = item.newValue.filter(
      (obj) => !oldValueSet.has(JSON.stringify(obj))
    );
    const removed = item.oldValue.filter(
      (obj) => !newValueSet.has(JSON.stringify(obj))
    );
    // const changed = item.newValue.filter(
    //   (obj) =>
    //     oldValueSet.has(JSON.stringify(obj)) &&
    //     !isEqual(
    //       obj,
    //       item.oldValue.find((o) => isEqual(o, obj))
    //     )
    // );

    return {
      field: item.field,
      fieldEs: item.fieldEs,
      newValue: added,
      oldValue: removed,
    };
  });
};

// const isEqual = (obj1, obj2) => {
//   return JSON.stringify(obj1) === JSON.stringify(obj2);
// };
