import { useCallback, useEffect, useMemo, useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import CustomersTable from "../commerce-table/customers-table";
import ProductsTable from "../../components/commerce-table/products-table";
//import Publicity from "../commerce-table/publicity";
import MarketingContainer from "../marketing-container/marketing-container";

import "./commerce-table-tabs.scss";
import { useDispatch, useSelector } from "react-redux";
import { sortCustomersTable } from "../../custom hooks/sort-table/sort-customers-table";
import { addCustomers } from "../../redux/customers/actions/actions";
import { getClientsPreparedData } from "../../firebase/firebase.utils";
import { searchTable } from "../../custom hooks/search-table/search-table";
import TableFilters from "../table/table-filters";
import { useMediaQuery } from "@mui/material";

const CommerceTableTabs = () => {
  const [tabCustomersClass, setTabCustomersClass] = useState("selected-tab");
  const [tabProductsClass, setTabProductsClass] = useState("inactive-tab");
  const [tabPublicityClass, setTabPublicityClass] = useState("inactive-tab");

  const matchesUp900 = useMediaQuery("(min-width:900px)");

  const modifyTab = (tabName) => {
    if (tabName === "customers") {
      setTabCustomersClass("selected-tab");
      setTabProductsClass("inactive-tab");
      setTabPublicityClass("inactive-tab");
    } else {
      setTabPublicityClass("selected-tab");
      setTabCustomersClass("inactive-tab");
      setTabProductsClass("inactive-tab");
    }
  };

  const dispatch = useDispatch();
  const reduxCustomers = useSelector((state) => state.customers);

  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);

  const [loading, setLoading] = useState(false);

  const headers = [
    { name: "Nombre", accessor: "name" },
    { name: "Plan", accessor: "planName" },
    { name: "Tipo", accessor: "type" },
    { name: "Compra", accessor: "memberSince" },
    { name: "Ultima renovacion", accessor: "lastRenovation" },
    { name: "Donado", accessor: "donated" },
    { name: "Registro", accessor: "cdate" },
    { name: "Status", accessor: "status" },
    // { name: "Acciones", accessor: "actions" },
  ];
  const headersSearch = [
    { name: "Nombre", accessor: "name" },
    { name: "Plan", accessor: "planName" },
    { name: "Tipo", accessor: "type" },
    { name: "Compra", accessor: "memberSince" },
    // { name: "Ultima renovacion", accessor: "lastRenovation" },
    // { name: "Donado", accessor: "donated" },
    { name: "Registro", accessor: "cdate" },
    { name: "Status", accessor: "status" },
  ];

  const newMemberList = useMemo(
    () =>
      filteredCustomers.map((customer) => {
        return {
          id: customer.id,
          name: customer.name,
          planName: customer.planType?.subTier
            ? customer.planType?.subTier
            : customer.planType,
          type: (
            <span
              className={
                customer.planStatus === "Nuevo" ? "text-active" : "text-primary"
              }
            >
              {customer.planStatus}
            </span>
          ),
          memberSince: customer.memberSince,
          lastRenovation: customer.lastRenovation,
          donated: customer.donated,
          cdate: customer.cdate,
          status: (
            <span
              className={
                customer.status === "Activo" ? "text-active" : "text-danger"
              }
            >
              {customer.status}
            </span>
          ),
        };
      }),
    [filteredCustomers]
  );

  const [itemsPerPage, setItemsPerPage] = useState(5);
  //const [filteringBy, setfilteringBy] = useState("Name");

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + Number(itemsPerPage);
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(newMemberList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(newMemberList.length / Number(itemsPerPage)));
  }, [itemOffset, newMemberList, itemsPerPage]);

  const getCustomers = useCallback(async () => {
    setLoading(true);

    const data = await getClientsPreparedData();

    // const userList = await getAllClients();
    // const donationsByUser = [];

    //let wallets = [];
    // for (const user of userList) {
    //   const userWallet = await getUserWallet(user.id);
    //   const donations = await getAllDonationsByUser(user.id);
    //   wallets.push(userWallet);
    //   donationsByUser.push(donations);
    // }

    setCustomers(data);
    setFilteredCustomers(data);
    //setCustomerWallets(wallets);
    //setCustomerDonations(donationsByUser);

    dispatch(addCustomers(data));
    //dispatch(addCustomerWallets(wallets));
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    if (reduxCustomers.customers) {
      console.log("No nuevos clientes");
      const { customers, customerWallets } = reduxCustomers;
      setCustomers(customers);
      setFilteredCustomers(customers);
      //setCustomerWallets(customerWallets);
      setLoading(false);
    } else {
      getCustomers();
    }
  }, [reduxCustomers]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % newMemberList.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const sortTableElements = (sortingMethod) => {
    const sortedItems = sortCustomersTable(currentItems, sortingMethod);
    setCurrentItems(sortedItems);
  };

  const dropdownList = [
    {
      valuesList: [
        { name: "Compras recientes" },
        { name: "Registro recientes" },
        { name: "Donaciones + a -" },
      ],
      label: "",
      filterMethod: sortTableElements,
    },
  ];

  const showNewCustomers = (list) => {
    const newCustomers = list.filter(
      (customer) => customer.planStatus === "Nuevo"
    );

    setFilteredCustomers(newCustomers);
  };

  const showRenovations = (list) => {
    const renovations = list.filter(
      (customer) => customer.planStatus === "Renovacion"
    );

    setFilteredCustomers(renovations);
  };

  const showDuePlans = (list) => {
    const duePlans = list.filter((customer) => customer.status === "Due");

    setFilteredCustomers(duePlans);
  };

  const showAllCustomers = () => {
    setFilteredCustomers(customers);
  };

  const options = [
    { text: "Todos", filterMethod: () => showAllCustomers() },
    { text: "Nuevos", filterMethod: () => showNewCustomers(customers) },
    {
      text: "Renovaciones",
      filterMethod: () => showRenovations(customers),
    },
    { text: "Due", filterMethod: () => showDuePlans(customers) },
  ];

  const [searchField, setSearchField] = useState("");
  const rows = searchTable(currentItems, headersSearch, searchField);

  return (
    <Tabs
      defaultActiveKey={"customers"}
      onSelect={(tabName) => modifyTab(tabName)}
      className="table-tabs mt-4"
    >
      <Tab
        tabClassName={tabCustomersClass}
        title="Clientes"
        eventKey={"customers"}
        className="bg-light"
      >
        {matchesUp900 ? null : (
          <TableFilters
            dropdowns={dropdownList}
            searchRows={setSearchField}
            handleRefresh={() => getCustomers()}
          />
        )}

        <CustomersTable
          options={options}
          dropdownList={dropdownList}
          headers={headers}
          rows={rows}
          loading={loading}
          pageCount={pageCount}
          setSearchField={setSearchField}
          getCustomers={getCustomers}
          searchField={searchField}
          setItemsPerPage={setItemsPerPage}
          handlePageClick={handlePageClick}
        />
      </Tab>

      <Tab
        tabClassName={tabPublicityClass}
        title="Publicidad"
        eventKey={"publicity"}
        className="bg-light"
      >
        <MarketingContainer />
        {/* <Publicity /> */}
      </Tab>
    </Tabs>
  );
};

export default CommerceTableTabs;
