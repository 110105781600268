import { Outlet, Navigate, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";

export const useAuth = () => {
  const { user } = useSelector((state) => state.auth);
  return user;
};

/*const ProtectedRoute = () => {
  const isUser = useAuth();
  const location = useLocation();
  return isUser ? (
    <Outlet />
  ) : (
    <Navigate to={"/"} replace state={{ from: location }} />
  );
};

<Navigate replace to="auth/login" />

export default ProtectedRoute;*/

export const ProtectedRoute = ({ isLoggedIn, children }) => {
  return isLoggedIn ? children : <Navigate replace to="/auth/login" />;
};
