import { Form, Button, Spinner } from "react-bootstrap";

import { useFormik } from "formik";
import * as Yup from "yup";

import FormInput from "../form-input/form-input";

import { translations } from "./spanishFieldName";

import InputGroup from "react-bootstrap/InputGroup";

import { ReactComponent as WhatsApp } from "./../../assests/whatsapp.svg";
import { ReactComponent as Instagram } from "./../../assests/instagram.svg";
import { ReactComponent as Facebook } from "./../../assests/facebook.svg";
import { ReactComponent as Tiktok } from "./../../assests/tiktok.svg";
import {
  addAdminHistoryEntry,
  updateSponsor,
} from "../../firebase/firebase.utils";

import "./social-media-edition.scss";
import { useEffect, useState } from "react";
import { getChangedFields } from "../../utils/getChangeFields";
import { useSelector } from "react-redux";

const SocialMediaEdition = ({ sponsorId, onClose, business }) => {
  const [loading, setLoading] = useState(false);

  const [initialData, setInitialData] = useState({
    facebook: "",
    instagram: "",
    whatsApp: "",
    tiktok: "",
  });

  const pattern =
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
  const validate = Yup.object({
    facebook: Yup.string().matches(pattern, "URL no valida"),
    instagram: Yup.string().matches(pattern, "URL no valida"),
    whatsApp: Yup.string().matches(pattern, "URL no valida"),
    tiktok: Yup.string().matches(pattern, "URL no valida"),
  });

  const { user: currentAdmin } = useSelector((state) => state.auth); //https://www.facebook.com/pequenomundocr

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: initialData,
    validationSchema: validate,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const socialMediaData = {
        facebook: values.facebook,
        instagram: values.instagram,
        whatsApp: values.whatsApp,
        tiktok: values.tiktok,
      };

      const fieldsChanged = getChangedFields(values, initialData, translations);

      const adminHistory = {
        modificationDate: new Date(),
        admin: {
          id: currentAdmin.uid,
          name: currentAdmin.displayName,
          email: currentAdmin.email,
        },
        modifications: fieldsChanged,
      };

      updateSponsor(sponsorId, socialMediaData);
      addAdminHistoryEntry(adminHistory, `sponsors/${sponsorId}/adminHistory`);

      setTimeout(() => {
        setLoading(false);
        resetForm();
        onClose(false);
      }, 2000);
    },
  });

  useEffect(() => {
    if (business) {
      setInitialData({
        facebook: business.facebook ? business.facebook : "",
        instagram: business.instagram ? business.instagram : "",
        whatsApp: business.whatsApp ? business.whatsApp : "",
        tiktok: business.tiktok ? business.tiktok : "",
      });
    }
  }, [business]);

  return (
    <Form
      className="mt-3 p-3"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      noValidate
    >
      <Form.Group>
        <Form.Label style={{ fontSize: "14px" }}>Redes sociales</Form.Label>

        <InputGroup className="mb-3">
          <InputGroup.Text
            id="basic-addon1"
            className="p-0 bg-transparent border-0"
            style={{ zIndex: 10, bottom: "15px", borderBottom: "1px" }}
          >
            <WhatsApp style={{ width: "20px", height: "20px" }} />
          </InputGroup.Text>
          <FormInput
            type="text"
            name="whatsApp"
            errorList={errors}
            //style={{ paddingLeft: "25px" }}
            value={values.whatsApp}
            onChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">
            {errors.whatsApp}
          </Form.Control.Feedback>
          <div className="input-help-text w-100">
            Ejemplo de URL valida: https://wa.me/50634567879
          </div>
        </InputGroup>

        <InputGroup className="mb-3">
          <InputGroup.Text
            id="basic-addon1"
            className="p-0 bg-transparent border-0"
            style={{ zIndex: 10, bottom: "15px", borderBottom: "1px" }}
          >
            <Instagram style={{ width: "20px", height: "20px" }} />
          </InputGroup.Text>
          <FormInput
            type="text"
            name="instagram"
            errorList={errors}
            //style={{ paddingLeft: "25px" }}
            value={values.instagram}
            onChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">
            {errors.instagram}
          </Form.Control.Feedback>
          <div className="input-help-text w-100">
            Ejemplo de URL valida: https://www.instagram.com/cavemandg
          </div>
        </InputGroup>

        <InputGroup className="mb-3">
          <InputGroup.Text
            id="basic-addon1"
            className="p-0 bg-transparent border-0"
            style={{ zIndex: 10, bottom: "15px", borderBottom: "1px" }}
          >
            <Facebook style={{ width: "20px", height: "20px" }} />
          </InputGroup.Text>
          <FormInput
            type="text"
            name="facebook"
            errorList={errors}
            //style={{ paddingLeft: "25px" }}
            value={values.facebook}
            onChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">
            {errors.facebook}
          </Form.Control.Feedback>
          <div className="input-help-text w-100">
            Ejemplo de URL valida: https://www.facebook.com/CavemanBand/
          </div>
        </InputGroup>

        <InputGroup className="mb-3">
          <InputGroup.Text
            id="basic-addon1"
            className="p-0 bg-transparent border-0"
            style={{ zIndex: 10, bottom: "15px", borderBottom: "1px" }}
          >
            <Tiktok style={{ width: "20px", height: "20px" }} />
          </InputGroup.Text>
          <FormInput
            type="text"
            name="tiktok"
            errorList={errors}
            //style={{ paddingLeft: "25px" }}
            value={values.tiktok}
            onChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">
            {errors.tiktok}
          </Form.Control.Feedback>
          <div className="input-help-text w-100">
            Ejemplo de URL valida: https://www.tiktok.com/@donnydust
          </div>
        </InputGroup>
      </Form.Group>

      <Button
        type="submit"
        className="mt-3 d-block text-light"
        style={{ margin: "auto" }}
        disabled={loading}
      >
        {loading && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
        {!loading && <span style={{ color: "white !important" }}>Guardar</span>}
      </Button>
    </Form>
  );
};

export default SocialMediaEdition;
