import { useState } from "react";

import loginCover from "../../assests/logincover.png";
import logo from "../../assests/logo.png";

import Form from "react-bootstrap/Form";

import FormInput from "../form-input/form-input";
import LoginButton from "../login-button/login-button";

import { useDispatch, useSelector } from "react-redux";
import authErrors from "../../firebase/auth-error-codes";

import { useFormik } from "formik";
import * as Yup from "yup";

import { loginUser } from "../../redux/user/actions/auth";
import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
  Checkbox,
  useTheme,
} from "@mui/material";

import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const defaultFormFields = {
  email: "",
  password: "",
};

const LogInForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);

  const theme = useTheme();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { error: authenticationError } = useSelector((state) => state.auth);

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const validate = Yup.object({
    email: Yup.string().email("Email no valido").required("Email es requerido"),
    password: Yup.string()
      .min(6, "Contraseña debe contener al menos 6 caracteres")
      .required("Contraseña requerida"),
  });

  const navigateTo = (route) => {
    navigate(route);
  };

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    handleBlur,
    setFieldError,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validate,
    onSubmit: (values, { validateForm, setFieldTouched }) => {
      // if (authenticationError) {
      //   if (authenticationError.code.includes("user")) {
      //     console.log("user error");
      //     setFieldError("email", authErrors[authenticationError.code].msg);
      //     validateForm().then(() => setFieldTouched("email", true));
      //   } else {
      //     setFieldError("password", authErrors[authenticationError.code].msg);
      //     validateForm().then(() => setFieldTouched("password", true));
      //   }

      //   setSubmitting(false);
      // // } else {
      // if (!authenticationError) {
      console.log("inside", authenticationError);
      customHandleSubmit(values.email, values.password);
      //resetForm();
      //}
      //}
    },
  });

  const customHandleSubmit = async (email, password) => {
    //event.preventDefault();
    //resetFormFields();
    dispatch(loginUser(email, password, setFieldError));
  };

  // useEffect(() => {
  //   if (authenticationError) {
  //     if (authenticationError.code.includes("user")) {
  //       console.log("user error", errors);
  //       setFieldError("email", authErrors[authenticationError.code].msg);
  //       setFieldTouched();
  //       validateForm();
  //       console.log("user error", errors);
  //     } else {
  //       setFieldError("password", authErrors[authenticationError.code].msg);
  //       setFieldTouched();
  //       validateForm();
  //     }

  //     setSubmitting(false);
  //   }
  // }, [authenticationError, setErrors, setSubmitting]);

  return (
    <>
      <Container>
        <Grid container>
          <Grid item md={6}>
            <Box
              sx={{
                width: "100%",
                height: "auto",
                [theme.breakpoints.down("md")]: {
                  margin: "1em auto 2em auto",
                  width: "50%",
                  height: "auto",
                },
              }}
            >
              <img
                src={loginCover}
                alt="Home"
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "65%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "100%",
                }}
                mb={2}
              >
                <img
                  src={logo}
                  alt="Home"
                  style={{ width: "75%", height: "auto" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "100%",
                }}
                mb={2}
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Hola 👋
                </Typography>
                <Typography variant="h7">
                  Por favor ingresas tus datos
                </Typography>
              </Box>
              <FormControl sx={{ mb: 2 }} fullWidth>
                <TextField
                  label="Email"
                  type="email"
                  id="email"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  label="Password"
                  type="password"
                  id="password"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                />
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  mt: 2,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      id="rememberMe"
                      onChange={handleChange}
                      checked={values.rememberMe}
                    />
                  }
                  label="Recordar Contraseña"
                />
                {/* <Typography
                  variant="body1"
                  color={theme.palette.primary.main}
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigateTo("/auth/forgotpassword")}
                >
                  Olvidaste la contraseña?
                </Typography> */}
              </Box>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mt: 4 }}
              >
                Iniciar Sesión
              </Button>
            </form>

            <p className="text-danger mt-3">
              {
                authErrors[
                  authenticationError?.code
                    ? authenticationError?.code
                    : "no-error"
                ].msg
              }
            </p>
          </Grid>
        </Grid>
      </Container>
      {/* <Form
        style={{ width: "100%" }}
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Form.Group className="mb-3" controlId="formBasicLogin">
          <FormInput
            label="Email"
            type="email"
            onChange={handleChange}
            name="email"
            value={values.email}
            className="mb-2 bg-light"
            errorList={errors}
            onBlur={handleBlur}
            touched={touched}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <FormInput
            label="Password"
            type="password"
            onChange={handleChange}
            name="password"
            value={values.password}
            className="mb-2 bg-light"
            errorList={errors}
            onBlur={handleBlur}
            touched={touched}
          />
          <Form.Control.Feedback type="invalid">
            {errors.password}
          </Form.Control.Feedback>
        </Form.Group>
        <LoginButton text={"Iniciar Sesión"} />
      </Form>

      <p className="text-danger mt-3">
        {
          authErrors[
            authenticationError?.code ? authenticationError?.code : "no-error"
          ].msg
        }
      </p> */}
    </>
  );
};

export default LogInForm;
