import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MUIDataTable from "mui-datatables";
import { Box, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../redux/users/actions/actions";
import CustomBodyWithSpinner from "../general/CustomBodyWithSpinner";
import { useNavigate } from "react-router-dom";
import { IoRefreshOutline } from "react-icons/io5";
import { IconButton } from "@mui/material";
import { MdArrowOutward } from "react-icons/md";

export const UsersTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const { users, isLoading } = useSelector((state) => state.user);
  const [usersFromDb, setUsersFromDB] = useState([]);
  const isFirstUse = useRef(true);
  const [loading, setloading] = useState(false);

  const getUsersFromFB = useCallback(() => {
    try {
      dispatch(getAllUsers());
    } catch (error) {
      console.log(error);
    }
  }, [users]);

  useEffect(() => {
    if (isFirstUse.current) {
      setloading(true);
      getUsersFromFB();
      isFirstUse.current = false;
    } else if (users.length > 0) {
      setloading(false);
      setUsersFromDB(users);
    }
  }, [getUsersFromFB, users]);

  const handleUserSelect = (url) => {
    navigate(`/user/${url}`);
  };

  const handleRefresh = () => {
    setloading(true);
    getUsersFromFB();
    setTimeout(() => {
      setloading(false);
    }, 1000);
  };

  const BodyComponent = useMemo(
    () => (tableProps) =>
      (
        <CustomBodyWithSpinner
          loading={loading || users.length === 0}
          {...tableProps}
        />
      ),
    [users, loading]
  );

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "name",
      label: "Nombre",
      options: {
        customBodyRender: (value) => {
          return value ? value : "N/A";
        },
        filter: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
      },
    },
    {
      name: "lastname",
      label: "Apellido",
      options: {
        customBodyRender: (value) => {
          return value ? value : "N/A";
        },
        filter: false,
      },
    },
    {
      name: "secondLastname",
      label: "Segundo Apellido",
      options: {
        customBodyRender: (value) => {
          return value ? value : "N/A";
        },

        filter: false,
      },
    },
    {
      name: "phone",
      label: "Teléfono",
      options: {
        customBodyRender: (value) => {
          return value ? value : "N/A";
        },

        filter: false,
      },
    },
    {
      name: "cdate",
      label: "Fecha de registro",
      options: {
        customBodyRender: (value) => {
          if (typeof value === "object" && value.seconds && value.nanoseconds) {
            const date = new Date(
              value.seconds * 1000 + value.nanoseconds / 1000000
            );
            const formattedDate = date.toLocaleDateString();
            return formattedDate;
          } else {
            return "-";
          }
        },

        filter: false,
      },
    },

    {
      name: "plan",
      label: "Nivel",
      options: {
        customBodyRender: (value) => {
          return value ? value : "-";
        },
        filter: false,
      },
    },

    {
      name: "personalId",
      label: "Identificación",
      options: {
        customBodyRender: (value) => {
          return value ? value : "N/A";
        },
        filter: false,
      },
    },
    {
      name: "integrationType",
      label: "Integración",
      options: {
        customBodyRender: (value) => {
          return value ? value : "N/A";
        },
        filter: false,
      },
    },
    {
      name: "overallSportCoins",
      label: "Coins Totales",
      options: {
        customBodyRender: (value) => {
          return value !== undefined ? value.toFixed(2) : "-";
        },
        filter: false,
      },
    },
    {
      name: "sportCoins",
      label: "Coins",
      options: {
        customBodyRender: (value) => {
          return value !== undefined ? value.toFixed(2) : "-";
        },
        filter: false,
      },
    },
    {
      name: "isActiveMember",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return value ? "Activo" : "Inactivo";
        },
      },
    },
    {
      name: "viewMore",
      label: "Ver Más",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const userId = tableMeta.rowData[0];
          return (
            <IconButton
              onClick={() => handleUserSelect(userId)}
              size="small"
              sx={{
                backgroundColor: theme.palette.primary.main,
                width: "32px",
                height: "32px",
                color: "white",
              }}
            >
              <MdArrowOutward />
            </IconButton>
          );
        },
        filter: false,
        sort: false,
      },
    },
  ];
  const CustomToolbar = ({ onRefresh }) => {
    return (
      <IconButton className="refresh-button" onClick={onRefresh}>
        <IoRefreshOutline size={25} />
      </IconButton>
    );
  };
  const options = {
    filter: true,
    responsive: "standard",
    selectableRows: "none",
    rowsPerPage: 15,
    onTableChange: () => setUsersFromDB(users),
    sortOrder: {
      name: "name", // Replace "name" with the column name you want to sort by initially
      direction: "asc", // Change this to "desc" if you want the initial sorting direction to be descending
    },
    sortCompare: (order) => (a, b) => {
      const isAsc = order === "asc";
      // Replace "lastname" with the column name you want to handle
      const column = "lastname";

      // Handle null, undefined, and empty string values
      const aValue =
        a.data[column] !== undefined &&
        a.data[column] !== null &&
        a.data[column].trim() !== ""
          ? a.data[column]
          : "N/A";
      const bValue =
        b.data[column] !== undefined &&
        b.data[column] !== null &&
        b.data[column].trim() !== ""
          ? b.data[column]
          : "N/A";

      // Use default comparison for other cases
      if (isAsc) {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    },
    customToolbar: () => {
      return <CustomToolbar onRefresh={() => handleRefresh()} />;
    },

    // onRowClick: (rowData) => {
    //   const clickedOwnerId = rowData[0];

    //   handleUserSelect(clickedOwnerId);
    // },
  };
  // if (loading) {
  //   return <div>loading</div>;
  // }
  return (
    <Box mt={6} sx={{ width: "100%" }}>
      {/* <div className="refresh-container">
          <Button className="refresh-button">
            <IoRefreshOutline size={25} onClick={handleRefresh} />
          </Button>
        </div> */}
      <MUIDataTable
        title="Usuarios"
        data={usersFromDb}
        columns={columns}
        options={options}
        components={{ TableBody: BodyComponent }}
      />
    </Box>
  );
};
