import { useEffect, useState } from "react";

import { Card, Form } from "react-bootstrap";

import { EditText, EditTextarea } from "react-edit-text";

import { Formik } from "formik";
import * as Yup from "yup";

import {
  getUserMetaData,
  updateUserMetaData,
} from "../../firebase/firebase.utils";

const UserProfile = ({ id, user: userPublicData, editable }) => {
  const [userMetaData, setUserMetaData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const metaData = await getUserMetaData(userPublicData.id);

      setUserMetaData(metaData);
    };

    getData();
  }, [userPublicData.id]);

  if (!userMetaData) return;

  return (
    <Card
      className="border-light mb-n1 rounded-top"
      style={{ borderRadius: 0 }}
    >
      <Card.Body className="p-0">
        <Formik
          //validationSchema={validate}
          enableReinitialize={true}
          initialValues={{
            attitude: userMetaData.attitude?.title
              ? userMetaData.attitude.title
              : "----",
            weeklyHours: userMetaData.weeklyHours?.title
              ? userMetaData.weeklyHours.title
              : "----",
            sportEvents: userMetaData.sportEvents?.title
              ? userMetaData.sportEvents.title
              : "----",
            description: userMetaData.description?.title
              ? userMetaData.description.title
              : "----",
          }}
          onSubmit={(values) => {
            const updatesUserMetaData = {
              attitude: values.attitude,
              weeklyHours: values.weeklyHours,
              sportEvents: values.sportEvents,
              description: values.description,
            };

            updateUserMetaData(id, updatesUserMetaData);
          }}
        >
          {({ handleChange, handleSubmit, values, errors, setFieldValue }) => (
            <Form noValidate>
              <h2 className="h5 py-3 px-4 fw-bold">Perfil</h2>
              <div className="border-bottom border-top py-2 px-4">
                <small className="text-primary d-block">Perfil laboral</small>
                <small>
                  <EditText
                    name="attitude"
                    readonly={!editable}
                    style={{
                      outline: 0,
                      width: "270px",
                      height: "30px",
                    }}
                    className="d-flex"
                    inputClassName="bg-info border-0 shadow-none form-control is-invalid"
                    onChange={handleChange}
                    onSave={({ value, previousValue }) => {
                      if (value === "") {
                        setFieldValue("attitude", previousValue);
                      } else {
                        handleSubmit();
                      }
                    }}
                    value={values.attitude}
                  />
                </small>
                {/* <small>{userMetaData.attitude.title}</small> */}
              </div>
              <div className="border-bottom py-2 px-4">
                <small className="text-primary d-block">
                  Horas dedicadas al deporte
                </small>
                <small>
                  <EditText
                    name="weeklyHours"
                    readonly={!editable}
                    style={{
                      outline: 0,
                      width: "270px",
                      height: "30px",
                    }}
                    className="d-flex"
                    inputClassName="bg-info border-0 shadow-none form-control is-invalid"
                    onChange={handleChange}
                    onSave={({ value, previousValue }) => {
                      if (value === "") {
                        setFieldValue("weeklyHours", previousValue);
                      } else {
                        handleSubmit();
                      }
                    }}
                    value={values.weeklyHours}
                  />
                </small>
                {/* <small>{userMetaData.weeklyHours.title}</small> */}
              </div>
              <div className="border-bottom py-2 px-4">
                <small className="text-primary d-block">
                  Eventos deportivos anuales
                </small>
                <small>
                  <EditText
                    name="sportEvents"
                    readonly={!editable}
                    style={{
                      outline: 0,
                      width: "270px",
                      height: "30px",
                    }}
                    className="d-flex"
                    inputClassName="bg-info border-0 shadow-none form-control is-invalid"
                    onChange={handleChange}
                    onSave={({ value, previousValue }) => {
                      if (value === "") {
                        setFieldValue("sportEvents", previousValue);
                      } else {
                        handleSubmit();
                      }
                    }}
                    value={values.sportEvents}
                  />
                </small>
                {/* <small>{userMetaData.sportEvents.title}</small> */}
              </div>
              <div className="border-bottom py-2 px-4">
                <small className="text-primary d-block">
                  Objectivo deportivo
                </small>
                <small>
                  <EditTextarea
                    name="description"
                    rows={3}
                    readonly={!editable}
                    style={{
                      outline: 0,
                      width: "270px",
                      height: "70px",
                    }}
                    className="d-flex"
                    inputClassName="bg-info border-0 shadow-none form-control is-invalid"
                    onChange={handleChange}
                    onSave={({ value, previousValue }) => {
                      if (value === "") {
                        setFieldValue("description", previousValue);
                      } else {
                        handleSubmit();
                      }
                    }}
                    value={values.description}
                  />
                </small>
                {/* <small>{userMetaData.description.title}</small> */}
              </div>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default UserProfile;
