import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  Box,
  IconButton,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { MdCloudUpload, MdDelete, MdUploadFile } from "react-icons/md";

const FilesUpload = ({ selectedFiles, setSelectedFiles }) => {
  const theme = useTheme();

  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  useEffect(() => {
    console.log(selectedFiles);
  }, []);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files).map((file) => ({
      id: Date.now() + file.name, // Assign a unique identifier
      file,
    }));
    setSelectedFiles((prevSelectedFiles) => [
      ...prevSelectedFiles,
      ...newFiles,
    ]);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsDraggingOver(false);

    const droppedFiles = Array.from(event.dataTransfer.files);
    setSelectedFiles(droppedFiles);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsDraggingOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsDraggingOver(false);
  };

  const handleTouchStart = (event) => {
    // Handle touch start event for iOS drag-and-drop
    event.preventDefault();
    event.stopPropagation();

    // Retrieve the files from the touch event
    const touchFiles = Array.from(event.changedTouches).map(
      (touch) => touch.file
    );
    setSelectedFiles(touchFiles);
  };

  const handleTouchMove = (event) => {
    // Prevent scrolling while performing the touch move
    event.preventDefault();
    event.stopPropagation();
  };

  const handleIconButtonClick = () => {
    // Trigger the click event on the file input when icon button is clicked
    fileInputRef.current.click();
  };

  const handleRemoveFile = (id) => {
    setSelectedFiles((prevSelectedFiles) =>
      prevSelectedFiles.filter((item) => item.id !== id)
    );
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 16,
          borderRadius: "18px",
          borderWidth: "2px !important",
          border: "solid",
          borderStyle: "dashed !important",
          borderRadius: "18px",

          borderColor: isDraggingOver ? theme.palette.primary.main : "#ECECEE",
          width: "450px",
          minHeight: "150px",
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <label
          htmlFor="file-input"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 8,
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              mb: 2,
            }}
          >
            <IconButton
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: "white",
                width: 36,
                height: 36,
                margin: "0 auto",
              }}
              onClick={handleIconButtonClick}
            >
              <MdCloudUpload />
            </IconButton>
          </Box>

          <Typography variant="body1">
            Arrastra y suelta o selecciona tus archivos
          </Typography>
          <Typography
            sx={{
              fontSize: "12px !important",
              fontWeight: "400 !important",
              color: "grey",
            }}
            textAlign={"center "}
          >
            Formatos permitidos : Jpeg, pdf
          </Typography>
        </label>
        <input
          ref={fileInputRef}
          id="file-input"
          type="file"
          onChange={handleFileChange}
          multiple // Allow multiple file selection
          style={{ display: "none" }}
        />
      </Box>
      {uploading && (
        <Box mt={2} textAlign="center">
          <CircularProgress />
          <Typography variant="body2" mt={1}>
            Uploading...
          </Typography>
        </Box>
      )}
      {selectedFiles.length > 0 && (
        <Box mt={2} textAlign="center">
          {selectedFiles.map((item) => (
            <div
              key={item.id}
              style={{ display: "flex", alignItems: "center" }}
            >
              {item.file.type.startsWith("image/") ? (
                <img
                  src={URL.createObjectURL(item.file)}
                  alt={`Preview ${item.id}`}
                  style={{ maxWidth: "100px" }}
                />
              ) : item.file.type === "application/pdf" ? (
                <div>
                  <MdUploadFile /> {item.file.name} (
                  {Math.round(item.file.size / 1024)} KB){" "}
                  <IconButton
                    color="error"
                    onClick={() => handleRemoveFile(item.id)}
                    size="small"
                    style={{ marginLeft: "8px" }}
                  >
                    <MdDelete />
                  </IconButton>
                </div>
              ) : (
                <div>
                  {item.file.name} - File type not supported{" "}
                  <IconButton
                    color="error"
                    onClick={() => handleRemoveFile(item.id)}
                    size="small"
                    style={{ marginLeft: "8px" }}
                  >
                    <MdDelete />
                  </IconButton>
                </div>
              )}
            </div>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default FilesUpload;
