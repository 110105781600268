import { useState, useEffect, useMemo, useCallback } from "react";
import { Table, Stack, Image, Spinner, Button } from "react-bootstrap";
import { ReactComponent as DarkPencilIcon } from "./../../assests/pencil.svg";
import { ReactComponent as DarkTrashCanIcon } from "./../../assests/trashcan.svg";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { getAllAchievements } from "../../firebase/firebase.utils";
import { sortAchievementsTable } from "../../custom hooks/sort-table/sort-achievements-table";
import { searchTable } from "../../custom hooks/search-table/search-table";
import { useDispatch, useSelector } from "react-redux";
import { addAchievements } from "../../redux/achievements/actions/actions";

import ReactPaginate from "react-paginate";
import TableHeader from "../table/table-header";
import TableBodyHover from "../table/table-body-hover";
import ItemsControl from "../items-control/items-control";
import TableFilters from "../table/table-filters";
import CustomModal from "../modal/customModal";
import CoinLoader from "./../coin-loader/coin-loader";

import AchievementDeletion from "../achievement-deletion/achievement-deletion";
import AchievementEdition from "../achievement-edit/achievement-edit";

import fallback from "./../../assests/fallback.jpg";
import "./achievements-table.scss";

const AchievementsTable = ({ count }) => {
  const dispatch = useDispatch();
  const reduxAchievements = useSelector((state) => state.achievement);

  const [achievements, setAchievements] = useState([]);
  const [filteredAchievements, setFilteredAchievements] = useState([]);
  const [selectedAchievement, setSelectedAchievement] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [showEditionAchievementModal, setShowEditionAchievementModal] =
    useState(false);
  const [showDeleteAchievementModal, setShowDeleteAchievementModal] =
    useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [searchField, setSearchField] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchItems, setSearchItems] = useState([]);

  const handleDelete = (achievement) => {
    setShowDeleteAchievementModal(true);
    setSelectedAchievement(achievement.id);
  };

  const handleEdit = (achievement) => {
    setShowEditionAchievementModal(true);
    setSelectedAchievement(achievement);
  };

  const headers = [
    { name: "", accessor: "imageUrl" },
    { name: "Achievements", accessor: "name" },
    { name: "Award", accessor: "award" },
    { name: "Categoria", accessor: "categorie" },
    { name: "Recompensa", accessor: "hasReward" },
    { name: "Coins", accessor: "coins" },
    { name: "Descripción", accessor: "description" },
    { name: "Módulo", accessor: "module" },
    { name: "Status", accessor: "status" },
    { name: "Acción", accessor: "action" },
  ];
  const headersSearch = [
    { name: "", accessor: "imageUrl" },
    { name: "Achievements", accessor: "name" },
    { name: "Award", accessor: "award" },
    { name: "Categoria", accessor: "categorie" },
    { name: "Recompensa", accessor: "hasReward" },
    { name: "Coins", accessor: "coins" },
    { name: "Descripción", accessor: "description" },
    { name: "Módulo", accessor: "module" },
    { name: "Status", accessor: "status" },
  ];
  const newAchievementsList = useMemo(
    () =>
      filteredAchievements.map((achievement) => {
        return {
          id: achievement.id,
          imageUrl: (
            <Image
              src={achievement.imageUrl ? achievement.imageUrl : fallback}
              width="50px"
              height="50px"
              roundedCircle
            />
          ),
          name: achievement.name ? achievement.name : "--",
          award: achievement.title ? achievement.title : "--",
          categorie: achievement.tag ? achievement.tag : "--",
          hasReward: achievement.hasReward ? (
            <span size="sm" className="w-100">
              Si
            </span>
          ) : (
            <span size="sm" className="w-100">
              No
            </span>
          ),
          coins: achievement.reward ? achievement.reward : "--",
          description: achievement.description ? achievement.description : "--",
          module: achievement.module ? achievement.module : "--",
          status: achievement.isActive ? (
            <span size="sm" className="activeColor">
              Activo
            </span>
          ) : (
            <span size="sm" className="inactiveColor">
              Inactivo
            </span>
          ),
          action: (
            <Stack direction="horizontal" gap={2}>
              <button
                variant="secondary"
                size="sm"
                className="rounded-circle hover-button"
              >
                <DarkPencilIcon onClick={() => handleEdit(achievement)} />
              </button>
              <button
                variant="secondary"
                size="sm"
                className="rounded-circle hover-button"
              >
                <DarkTrashCanIcon
                  onClick={() => {
                    handleDelete(achievement);
                  }}
                />
              </button>
            </Stack>
          ),
        };
      }),
    [filteredAchievements]
  );

  useEffect(() => {
    // MAIN
    const endOffset = itemOffset + Number(itemsPerPage);
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(newAchievementsList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(newAchievementsList.length / Number(itemsPerPage)));
  }, [itemOffset, newAchievementsList, itemsPerPage]);

  // useEffect(() => {
  //   try {

  //       const myArray = currentItems.forEach(a=> a);

  //       delete myArray[4];

  //       console.log(`myArray values: `, myArray);

  //   } catch (error) {
  //     console.log(error);
  //   }

  // }, [])

  const getAchievements = useCallback(async () => {
    setLoading(true);
    const achievementList = await getAllAchievements();
    setAchievements(achievementList);
    setFilteredAchievements(achievementList);

    dispatch(addAchievements(achievementList));
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);

    if (reduxAchievements.achievements) {
      const { achievements } = reduxAchievements;
      console.log("No new achievements", reduxAchievements);
      setAchievements(achievements);
      setFilteredAchievements(achievements);
      setLoading(false);
    } else {
      getAchievements();
    }
  }, [reduxAchievements]);

  useEffect(() => {
    if (count !== 0) {
      getAchievements();
    }
  }, [count]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % newAchievementsList.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const sortTableElements = (sortingMethod) => {
    const sortedItems = sortAchievementsTable(currentItems, sortingMethod);
    setCurrentItems(sortedItems);
  };

  const dropdownList = [
    {
      valuesList: [
        { name: "Más recientes" },
        { name: "Más antiguos" },
        { name: "Orden alfabetico" },
      ],
      label: "",
      filterMethod: sortTableElements,
    },
  ];

  const showActiveAchievements = (list) => {
    const achievements = list.filter(
      (achievement) => achievement.isActive === true
    );

    setFilteredAchievements(achievements);
  };

  const showInactiveAchievements = (list) => {
    const achievements = list.filter(
      (achievement) => achievement.isActive === false
    );
    setFilteredAchievements(achievements);
  };

  const showAllAchievements = () => {
    setFilteredAchievements(achievements);
  };

  const options = [
    { text: "Todos", filterMethod: () => showAllAchievements(achievements) },
    {
      text: "Activos",
      filterMethod: () => showActiveAchievements(achievements),
    },
    {
      text: "Inactivos",
      filterMethod: () => showInactiveAchievements(achievements),
    },
  ];

  const rows = searchTable(currentItems, headersSearch, searchField);

  return (
    <>
      <TableFilters
        options={options}
        dropdowns={dropdownList}
        searchRows={setSearchField}
        handleRefresh={() => getAchievements()}
      />
      <div className="p-4 bg-light">
        {loading ? (
          <div className="loading-container">
            {/* <Spinner
              className="spinner-style"
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            /> */}

            <CoinLoader size={{ width: 150, height: 150 }} />
          </div>
        ) : (
          <Table className="bg-light mb-0 p-4">
            <TableHeader headers={headers} />
            <TableBodyHover
              rows={rows}
              columns={headers}
              searchField={searchField}
            />
          </Table>
        )}
      </div>

      <div className="bg-light p-4 rounded-bottom">
        <Stack direction="horizontal">
          <ItemsControl setNumItemPerPage={setItemsPerPage} />

          <div className="ms-auto">
            <ReactPaginate
              breakLabel="..."
              nextLabel={<IoIosArrowForward />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel={<IoIosArrowBack />}
              renderOnZeroPageCount={null}
              containerClassName="pagination pagination-modifier"
              pageClassName="page-item"
              previousClassName="previus-button"
              nextClassName="next-button"
              pageLinkClassName="page-link page-link-modifier"
              activeClassName="active"
            />
          </div>
        </Stack>
      </div>
      <CustomModal
        show={showDeleteAchievementModal}
        handleClose={setShowDeleteAchievementModal}
        modalTitle={() => "Eliminar Achievement"}
      >
        <AchievementDeletion
          onClose={setShowDeleteAchievementModal}
          achievementId={selectedAchievement}
          handleRefresh={() => getAchievements()}
        />
      </CustomModal>
      <CustomModal
        show={showEditionAchievementModal}
        handleClose={setShowEditionAchievementModal}
        modalTitle={() => <HeaderButtons type={"promo"} />}
      >
        <AchievementEdition
          onClose={setShowEditionAchievementModal}
          achievement={selectedAchievement}
          handleRefresh={() => getAchievements()}
        />
      </CustomModal>
    </>
  );
};

export default AchievementsTable;

const HeaderButtons = ({ type }) => (
  <Stack direction="horizontal" gap={2} className="">
    <Button
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#9A9A9A" }}
    >
      Editar
    </Button>
    <Button
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#AAAAAA" }}
    >
      Achievement
    </Button>
  </Stack>
);
