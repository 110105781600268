import { Box, Container, useTheme } from "@mui/material";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";

import Navigation from "./navigation";
import { useEffect } from "react";

const drawerWidth = 275;

const SidebarLayout = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [firstUse, setFirstUse] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [marginLeft, setMarginLeft] = useState(0);
  const [mobileVerticalDrawerOpen, setMobileVerticalDrawerOpen] =
    useState(false);

  useEffect(() => {
    // Calculate the margin left based on the screen width and mobileVerticalDrawerOpen state
    const calculatedMarginLeft = mobileVerticalDrawerOpen
      ? window.innerWidth * 0.18
      : 0;
    setMarginLeft(calculatedMarginLeft);
  }, [mobileVerticalDrawerOpen]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const contentStyle = {
    transition: "margin 0.2s ease",

    [theme.breakpoints.up("lg")]: {
      //marginLeft: open ? "225px" : "10px",
      marginLeft: open ? "275px" : "90px",
    },
  };

  const mobileContent = {
    width: drawerWidth,
    heigt: "100vh",
    //marginRight: `${marginLeft}px`,
    transition: "margin 0.2s ease",
  };

  return (
    <>
      <Box sx={mobileContent}></Box>
      <Navigation
        open={open}
        handleDrawerToggle={handleDrawerToggle}
        setOpen={setOpen}
        mobileVerticalDrawerOpen={mobileVerticalDrawerOpen}
        setMobileVerticalDrawerOpen={setMobileVerticalDrawerOpen}
      />
      <Container maxWidth="xxl" sx={{ mb: 4, px: { xs: 0, sm: "20px" } }}>
        <Box sx={contentStyle}>
          <Outlet />
        </Box>
      </Container>
    </>
  );
};

export default SidebarLayout;
