export const sortSelectPromoTable = (cells, sortingMethod) => {
  let sortedItems = [];

  const getValues = (element) => {
    const value = element.businessName.props.children[2].props.children;

    return value;
  };

  switch (sortingMethod) {
    case "Más recientes":
      sortedItems = [...cells].sort((firstElement, secondElement) => {
        return secondElement.dueDate.toDate() - firstElement.dueDate.toDate();
      });

      return sortedItems;
    case "Más antiguos":
      sortedItems = [...cells].sort((firstElement, secondElement) => {
        return firstElement.dueDate.toDate() - secondElement.dueDate.toDate();
      });

      return sortedItems;
    case "Orden alfabetico":
      sortedItems = [...cells].sort((firstElement, secondElement) => {
        return getValues(firstElement).localeCompare(getValues(secondElement));
      });
      return sortedItems;
    default:
      return;
  }
};
