import { useState, useEffect, useMemo } from "react";

import { Table } from "react-bootstrap";
import { Stack } from "react-bootstrap";

import ReactPaginate from "react-paginate";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

import TableHeader from "../table/table-header";
import TableBody from "../table/table-body";
import ItemsControl from "../items-control/items-control";
//import TableFilters from "../table/table-filters";
import AudienceFilters from "./audience-filters";
import CoinLoader from "../coin-loader/coin-loader";

import { searchTable } from "../../custom hooks/search-table/search-table";

const AudienceTable = () => {
  const [audiences, setAudiences] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchField, setSearchField] = useState("");

  const headers = [
    { name: "Audiencia", accessor: "audienceName" },
    { name: "Deportes", accessor: "sports" },
    { name: "Intereses", accessor: "interests" },
    { name: "Team", accessor: "team" },
    { name: "Comportamientos", accessor: "behavior" },
  ];

  const newAudienceList = useMemo(
    () =>
      audiences.map((audience) => ({
        id: audience.id,
        audienceName: audience.audienceName,
        sports: audience.sports,
        interests: audience.interests,
        team: audience.team,
        behavior: audience.behavior,
      })),
    [audiences]
  );

  const dropdownList = [
    {
      valuesList: [],
      label: "",
      filterMethod: () => {},
    },
  ];

  const options = [{ text: "" }];

  useEffect(() => {
    // MAIN
    const endOffset = itemOffset + Number(itemsPerPage);
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(newAudienceList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(newAudienceList.length / Number(itemsPerPage)));
  }, [itemOffset, newAudienceList, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % newAudienceList.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const data = [
      {
        id: 1,
        audienceName: "General Costa Rica",
        sports: "Todos",
        interests: "Todos",
        team: "Todos",
        behavior: "Emprendedor, empleado, empresarios",
      },
      {
        id: 2,
        audienceName: "Triatletas +35",
        sports: "Triatlon",
        interests: "Comida, pasatiempos",
        team: "Fiebres",
        behavior: "Empresario",
      },
    ];

    setAudiences(data);
  }, []);

  const rows = searchTable(currentItems, headers, searchField);

  return (
    <>
      <AudienceFilters
        searchRows={setSearchField}
        dropdowns={dropdownList}
        options={options}
        //handleRefresh={() => getUsers()}
      />
      <div className="p-4 bg-light">
        {loading ? (
          <div className="loading-container">
            {/* <Spinner
              className="spinner-style"
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            /> */}
            <CoinLoader size={{ width: 150, height: 150 }} />
          </div>
        ) : (
          <Table
            className="bg-light mb-0 p-4"
            responsive
            style={{ width: "400px !important" }}
          >
            <TableHeader headers={headers} />
            <TableBody rows={rows} columns={headers} clickGoTo="/user/" />
          </Table>
        )}
      </div>

      <div className="bg-light p-4 rounded-bottom">
        <Stack direction="horizontal">
          <ItemsControl setNumItemPerPage={setItemsPerPage} />

          <div className="ms-auto">
            <ReactPaginate
              breakLabel="..."
              nextLabel={<IoIosArrowForward />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel={<IoIosArrowBack />}
              renderOnZeroPageCount={null}
              containerClassName="pagination pagination-modifier"
              pageClassName="page-item"
              previousClassName="previus-button"
              nextClassName="next-button"
              pageLinkClassName="page-link page-link-modifier"
              activeClassName="active"
            />
          </div>
        </Stack>
      </div>
    </>
  );
};

export default AudienceTable;
