export const branchInitialValues = {
  city: "",
  location: "",
  province: "San José",
  district: "",
  countries: "CR",
  latitude: "",
  longitude: "",
  canton: "",
  district: "",
  geopoint: "",
};
