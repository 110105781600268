import {
  Container,
  Row,
  Col,
  Button,
  Form as BootstrapForm,
  Stack,
} from "react-bootstrap";
import FormInput from "../form-input/form-input";

const idTypeList = [{ key: 1, name: "Cedula Juridica" }];
const tierList = ["Basic", "Premium", "Elite"];

const categoriesList = [
  "Bar/Vida Nocturna",
  "Restaurante/Cafetería",
  "Tienda deportiva",
  "Outfit",
  "Servicios de salud",
  "Recreación al aire libre",
  "Instalación deportiva",
  "Entretenimiento Indoor",
  "Hospedaje",
  "Supermercado/Abarrotes",
  "Tienda de tecnología",
  "Servicios educativos",
  "Estetica/Spa/Cuidado personal",
  "Productos especializados",
  "Eventos deportivos",
  "Otro",
];

const EditSponsorDataForm = ({ onClose, formikData }) => {
  const {
    values,
    handleChange,
    errors,
    handleSubmit,
    handleBlur,
    touched,
    submitCount,
  } = formikData;

  console.log(errors);

  return (
    <>
      <Container
        as={"form"}
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
      >
        <Row>
          <Col md={12}>
            <BootstrapForm.Group>
              <BootstrapForm.Label>Nombre</BootstrapForm.Label>

              <FormInput
                type="text"
                name="name"
                className="form-control"
                value={values.name}
                as={BootstrapForm.Control}
                onChange={handleChange}
                errorList={errors}
              />
            </BootstrapForm.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <BootstrapForm.Group>
              <BootstrapForm.Label>Tipo de ID</BootstrapForm.Label>

              <BootstrapForm.Select
                name="typeId"
                value={values.typeId}
                onChange={handleChange}
                className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none"
              >
                <option value="">Seleccione una opcion</option>
                {idTypeList.map((item) => {
                  return (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  );
                })}
              </BootstrapForm.Select>

              <BootstrapForm.Control.Feedback type="invalid">
                {/* {errors.description} */}
              </BootstrapForm.Control.Feedback>
            </BootstrapForm.Group>
          </Col>

          <Col md={6}>
            <BootstrapForm.Group>
              <BootstrapForm.Label>Número de ID</BootstrapForm.Label>

              <FormInput
                type="number"
                name="idNumber"
                className="form-control mb-2"
                value={values.idNumber}
                as={BootstrapForm.Control}
                onChange={handleChange}
                errorList={errors}
              />

              <BootstrapForm.Control.Feedback type="invalid">
                {/* {errors.description} */}
              </BootstrapForm.Control.Feedback>
            </BootstrapForm.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <BootstrapForm.Group>
              <BootstrapForm.Label>Correo</BootstrapForm.Label>

              <FormInput
                type="email"
                name="email"
                className="form-control"
                value={values.email}
                onChange={handleChange}
                as={BootstrapForm.Control}
                errorList={errors}
              />

              <BootstrapForm.Control.Feedback type="invalid">
                {/* {errors.description} */}
              </BootstrapForm.Control.Feedback>
            </BootstrapForm.Group>
          </Col>

          <Col md={6}>
            <BootstrapForm.Group>
              <BootstrapForm.Label>Teléfono</BootstrapForm.Label>

              <FormInput
                type="text"
                name="phone"
                className="form-control"
                value={values.phone}
                as={BootstrapForm.Control}
                onChange={handleChange}
                errorList={errors}
              />

              <BootstrapForm.Control.Feedback type="invalid">
                {/* {errors.description} */}
              </BootstrapForm.Control.Feedback>
            </BootstrapForm.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <BootstrapForm.Group>
              <BootstrapForm.Label>Categoria</BootstrapForm.Label>

              <BootstrapForm.Select
                name="category"
                value={values.category}
                onChange={handleChange}
                className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
              >
                <option value="">Seleccione una opcion</option>
                {categoriesList.map((category) => {
                  return (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  );
                })}
              </BootstrapForm.Select>

              <BootstrapForm.Control.Feedback type="invalid">
                {/* {errors.description} */}
              </BootstrapForm.Control.Feedback>
            </BootstrapForm.Group>
          </Col>

          <Col md={6}>
            <BootstrapForm.Group>
              <BootstrapForm.Label>Tier</BootstrapForm.Label>

              <BootstrapForm.Select
                name="tier"
                value={values.tier}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.tier}
                className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
              >
                <option value="">Seleccione una opcion</option>
                {tierList.map((tierItem) => (
                  <option key={tierItem} value={tierItem}>
                    {tierItem}
                  </option>
                ))}
              </BootstrapForm.Select>

              <BootstrapForm.Control.Feedback type="invalid">
                {errors.tier}
              </BootstrapForm.Control.Feedback>
            </BootstrapForm.Group>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <BootstrapForm.Group>
              <BootstrapForm.Label>Productos que ofrece</BootstrapForm.Label>

              <FormInput
                type="text"
                name="products"
                className="form-control"
                value={values.products}
                as={BootstrapForm.Control}
                onChange={handleChange}
                errorList={errors}
              />

              <BootstrapForm.Control.Feedback type="invalid">
                {/* {errors.description} */}
              </BootstrapForm.Control.Feedback>
            </BootstrapForm.Group>
          </Col>
        </Row>

        <Stack direction="horizontal" gap={2} className="mt-2">
          <Button variant="light" className="ms-auto" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Finalizar
          </Button>
        </Stack>
      </Container>
    </>
  );
};

export default EditSponsorDataForm;
