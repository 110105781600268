import { types } from "../types/types";

export const getProducts = () => ({
  type: types.products,
});

export const deleteProducts = () => ({
  type: types.removeProducts,
});

export const addProducts = (products) => ({
  type: types.addProducts,
  payload: products,
});
