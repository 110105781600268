import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import Overview from "./../../components/overview/overview";
import { Grid, Paper, useMediaQuery, useTheme } from "@mui/material";

import { MdBusiness } from "react-icons/md";

const CommerceSummary = () => {
  const theme = useTheme();
  const downSM = useMediaQuery(theme.breakpoints.down("md"));
  const customers = {
    title: "Clientes",
    totalNumber: 123,
    statistics: "10%",
    color: true,
    reference: "en octubre",
  };

  const newCustomers = {
    title: "Nuevos",
    totalNumber: 321,
    statistics: "10%",
    color: true,
    reference: "esta semana",
  };

  const renovation = {
    title: "Renovacion",
    totalNumber: 1282852,
    statistics: "5%",
    color: true,
    reference: "esta semana",
  };

  const planBestSeller = {
    title: "Plan mas vendido",
    totalNumber: 2152080,
    statistics: "5%",
    color: true,
    reference: "esta semana",
  };

  const retention = {
    title: "Retencion",
    totalNumber: "1250",
    statistics: "5631",
    color: false,
    reference: "usuarios",
  };
  const divider = () => {
    if (!downSM) {
      return (
        <div
          className="bg-secondary bg-opacity-25"
          style={{ width: "1px", height: "100px" }}
        ></div>
      );
    } else {
      return null;
    }
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            display: "flex",
            //justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Overview
            data={customers}
            icon={
              <MdBusiness
                style={{
                  width: "25px",
                  height: "25px",

                  color: "white",
                }}
              />
            }
          ></Overview>
          {divider()}
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Overview
            data={newCustomers}
            icon={
              <MdBusiness
                style={{
                  width: "25px",
                  height: "25px",

                  color: "white",
                }}
              />
            }
          />

          {divider()}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            display: "flex",
            //justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Overview
            data={planBestSeller}
            icon={
              <MdBusiness
                style={{
                  width: "25px",
                  height: "25px",

                  color: "white",
                }}
              />
            }
          />
          {divider()}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            display: "flex",
            //justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Overview
            data={retention}
            icon={
              <MdBusiness
                style={{
                  width: "25px",
                  height: "25px",

                  color: "white",
                }}
              />
            }
          />
          {/* <div style={{ width: "70px" }}>
            <CircularProgressbar
              value={28}
              text={`${28}%`}
              strokeWidth={12}
              styles={buildStyles({
                pathColor: theme.palette.accent.main,
                trailColor: "#FCCF7D",
                textColor: "#FAA60F",
              })}
            />
          </div> */}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CommerceSummary;
