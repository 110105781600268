import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MUIDataTable from "mui-datatables";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllB2bUsers, getAllUsers } from "../../redux/users/actions/actions";
import CustomBodyWithSpinner from "../general/CustomBodyWithSpinner";
import { useNavigate } from "react-router-dom";
import { IoRefreshOutline } from "react-icons/io5";
import { IconButton } from "@mui/material";
import { MdEdit, MdArrowOutward } from "react-icons/md";
import { getAllAssociations } from "../../redux/b2b/actions/actions";
import EditB2b from "./EditB2b";
import { Typography } from "@mui/material";

export const B2bTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  // const { b2bUsers } = useSelector((state) => state.user);
  const { associations } = useSelector((state) => state.b2b);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [associationsFromDB, setAssociationsFromDB] = useState([]);
  const isFirstUse = useRef(true);
  const [loading, setloading] = useState(false);

  const matchesUp752 = useMediaQuery("(min-width:752px)");

  const getUsersFromFB = useCallback(() => {
    try {
      dispatch(getAllAssociations());
    } catch (error) {
      console.log(error);
    }
  }, [associations]);

  const handleOpenEditModal = (rowData) => {
    const id = rowData[0];
    const b2bData = associationsFromDB.find((item) => item.id === id);
    setSelectedData(b2bData);
    setIsEditModalOpen(true);
  };
  const handleCloseEditModal = () => {
    setSelectedData([]);
    setIsEditModalOpen(false);
  };

  useEffect(() => {
    if (isFirstUse.current) {
      setloading(true);
      getUsersFromFB();
      isFirstUse.current = false;
    } else if (associations.length > 0) {
      setloading(false);
      setAssociationsFromDB(associations);
    }
  }, [getUsersFromFB, associations]);

  const handleB2bSelect = (url) => {
    navigate(`/b2b/${url}`);
  };

  const handleRefresh = () => {
    setloading(true);
    getUsersFromFB();
    setTimeout(() => {
      setloading(false);
    }, 1000);
  };

  const BodyComponent = useMemo(
    () => (tableProps) =>
      (
        <CustomBodyWithSpinner
          loading={loading || associations.length === 0}
          {...tableProps}
        />
      ),
    [associations, loading]
  );

  const columns = useMemo(
    () => [
      {
        name: "id",
        label: "ID",
        options: {
          display: false,
          filter: false,
        },
      },
      {
        name: "businessName",
        label: "Nombre Empresa",
        options: {
          customBodyRender: (value) => {
            return value ? value : "N/A";
          },
          filter: false,
        },
      },
      {
        name: "name",
        label: "Nombre Asociación",
        options: {
          customBodyRender: (value) => {
            return value ? value : "N/A";
          },
          filter: false,
        },
      },
      {
        name: "codeName",
        label: "Codigo Asociación",
        options: {
          customBodyRender: (value) => {
            return value ? value : "N/A";
          },
          filter: false,
        },
      },

      {
        name: "phone",
        label: "Teléfono",
        options: {
          customBodyRender: (value) => {
            return value ? value : "N/A";
          },

          filter: false,
        },
      },
      {
        name: "email",
        label: "Correo Electrónico",
        options: {
          customBodyRender: (value) => {
            return value ? value : "N/A";
          },

          filter: false,
        },
      },
      {
        name: "numUsers",
        label: "Usuarios",
        options: {
          customBodyRender: (value) => {
            // console.log(value);
            return value ? value : 0;
          },

          filter: false,
        },
      },
      {
        name: "cdate",
        label: "Fecha",
        options: {
          customBodyRender: (value) => {
            if (
              typeof value === "object" &&
              value.seconds &&
              value.nanoseconds
            ) {
              const date = new Date(
                value.seconds * 1000 + value.nanoseconds / 1000000
              );
              const formattedDate = date.toLocaleDateString();
              return formattedDate;
            } else {
              return "-";
            }
          },

          filter: false,
        },
      },

      // {
      //   name: "isActive",
      //   label: "Mostrados en el app",
      //   options: {
      //     customBodyRender: (value) => {
      //       return value ? "Activo" : "Inactivo";
      //     },
      //   },
      // },
      // {
      //   name: "edit",
      //   label: "Editar",
      //   options: {
      //     customBodyRender: (value, tableMeta) => {
      //       const rowData = tableMeta.rowData;
      //       // console.log("Row Data:", rowData); /

      //       return (
      //         <IconButton
      //           onClick={() => handleOpenEditModal(rowData)} // Pass the rowData
      //           size="small"
      //           color="primary"
      //         >
      //           <MdEdit />
      //         </IconButton>
      //       );
      //     },
      //     filter: false,
      //     sort: false,
      //   },
      // },
      {
        name: "viewMore",
        label: "Ver Más",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const b2bId = tableMeta.rowData[0];
            return (
              <IconButton
                onClick={() => handleB2bSelect(b2bId)}
                size="small"
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  width: "32px",
                  height: "32px",
                  color: "white",
                }}
              >
                <MdArrowOutward />
              </IconButton>
            );
          },
          filter: false,
          sort: false,
        },
      },
    ],
    []
  );
  const CustomToolbar = ({ onRefresh }) => {
    return (
      <IconButton className="refresh-button" onClick={onRefresh}>
        <IoRefreshOutline size={25} />
      </IconButton>
    );
  };
  const options = {
    filter: false,
    responsive: "standard",
    selectableRows: "none",
    rowsPerPage: 15,
    onTableChange: () => setAssociationsFromDB(associations),
    sortOrder: {
      name: "name", // Replace "name" with the column name you want to sort by initially
      direction: "asc", // Change this to "desc" if you want the initial sorting direction to be descending
    },
    sortCompare: (order) => (a, b) => {
      const isAsc = order === "asc";
      // Replace "lastname" with the column name you want to handle
      const column = "lastname";

      // Handle null, undefined, and empty string values
      const aValue =
        a.data[column] !== undefined &&
        a.data[column] !== null &&
        a.data[column].trim() !== ""
          ? a.data[column]
          : "N/A";
      const bValue =
        b.data[column] !== undefined &&
        b.data[column] !== null &&
        b.data[column].trim() !== ""
          ? b.data[column]
          : "N/A";

      // Use default comparison for other cases
      if (isAsc) {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    },
    customToolbar: () => {
      return <CustomToolbar onRefresh={() => handleRefresh()} />;
    },

    // onRowClick: (rowData) => {
    //   const clickedOwnerId = rowData[0];

    //   handleUserSelect(clickedOwnerId);
    // },
  };
  // if (loading) {
  //   return <div>loading</div>;
  // }
  return (
    <Box mt={6} sx={{ width: "100%" }}>
      <Typography
        variant="h6"
        textAlign="center"
        sx={{
          mt: 6,
          display: {
            xs: "block",
            [theme.breakpoints.up(752)]: {
              display: "none",
            },
          },
          mb: -2,
          zIndex: 110,
          position: "relative",
          background: "#fff",
          border: "3px solid #ECECEE",
          borderBottom: 0,
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
        }}
      >
        Asociaciones
      </Typography>
      <MUIDataTable
        title={matchesUp752 ? "Asociaciones" : ""}
        data={associationsFromDB}
        columns={columns}
        options={options}
        components={{ TableBody: BodyComponent }}
      />
      <EditB2b
        open={isEditModalOpen}
        onClose={handleCloseEditModal}
        data={selectedData}
        handleRefresh={handleRefresh}
      />
    </Box>
  );
};
