import { useState, useEffect, useMemo, useCallback } from "react";

import { ReactComponent as DarkPencilIcon } from "./../../assests/pencil.svg";
import { ReactComponent as DarkTrashCanIcon } from "./../../assests/trashcan.svg";
import { ReactComponent as DarkRoundedMenuIcon } from "./../../assests/rounded-hamburger-menu.svg";

import { Table, Spinner } from "react-bootstrap";
import { Stack } from "react-bootstrap";

import ReactPaginate from "react-paginate";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

import TableHeader from "../table/table-header";
import TableBodyHover from "../table/table-body-hover";
import ItemsControl from "../items-control/items-control";
import TableFilters from "../table/table-filters";
import CoinLoader from "./../coin-loader/coin-loader";

import { getClientsPreparedData } from "../../firebase/firebase.utils";

import { sortCustomersTable } from "../../custom hooks/sort-table/sort-customers-table";
import { searchTable } from "../../custom hooks/search-table/search-table";

import "./customers-table.scss";
import { useDispatch, useSelector } from "react-redux";
import { addCustomers } from "../../redux/customers/actions/actions";
import { useMediaQuery } from "@mui/material";

const CustomersTable = ({
  setSearchField,
  dropdownList,
  options,
  getCustomers,
  loading,
  headers,
  rows,
  setItemsPerPage,
  handlePageClick,
  pageCount,
}) => {
  const matchesUp900 = useMediaQuery("(min-width:900px)");
  const matchesDown620 = useMediaQuery("(max-width:620px)");
  // const dispatch = useDispatch();
  // const reduxCustomers = useSelector((state) => state.customers);

  // const [customers, setCustomers] = useState([]);
  // const [filteredCustomers, setFilteredCustomers] = useState([]);

  // const [loading, setLoading] = useState(false);

  // const headers = [
  //   { name: "Nombre", accessor: "name" },
  //   { name: "Plan", accessor: "planName" },
  //   { name: "Tipo", accessor: "type" },
  //   { name: "Compra", accessor: "memberSince" },
  //   { name: "Ultima renovacion", accessor: "lastRenovation" },
  //   { name: "Donado", accessor: "donated" },
  //   { name: "Registro", accessor: "cdate" },
  //   { name: "Status", accessor: "status" },
  //   // { name: "Acciones", accessor: "actions" },
  // ];
  // const headersSearch = [
  //   { name: "Nombre", accessor: "name" },
  //   { name: "Plan", accessor: "planName" },
  //   { name: "Tipo", accessor: "type" },
  //   { name: "Compra", accessor: "memberSince" },
  //   // { name: "Ultima renovacion", accessor: "lastRenovation" },
  //   // { name: "Donado", accessor: "donated" },
  //   { name: "Registro", accessor: "cdate" },
  //   { name: "Status", accessor: "status" },
  // ];

  // const newMemberList = useMemo(
  //   () =>
  //     filteredCustomers.map((customer) => {
  //       return {
  //         id: customer.id,
  //         name: customer.name,
  //         planName: customer.planType?.subTier
  //           ? customer.planType?.subTier
  //           : customer.planType,
  //         type: (
  //           <span
  //             className={
  //               customer.planStatus === "Nuevo" ? "text-active" : "text-primary"
  //             }
  //           >
  //             {customer.planStatus}
  //           </span>
  //         ),
  //         memberSince: customer.memberSince,
  //         lastRenovation: customer.lastRenovation,
  //         donated: customer.donated,
  //         cdate: customer.cdate,
  //         status: (
  //           <span
  //             className={
  //               customer.status === "Activo" ? "text-active" : "text-danger"
  //             }
  //           >
  //             {customer.status}
  //           </span>
  //         ),
  //       };
  //     }),
  //   [filteredCustomers]
  // );

  // const [itemsPerPage, setItemsPerPage] = useState(5);
  // //const [filteringBy, setfilteringBy] = useState("Name");

  // // We start with an empty list of items.
  // const [currentItems, setCurrentItems] = useState([]);
  // const [pageCount, setPageCount] = useState(0);
  // // Here we use item offsets; we could also use page offsets
  // // following the API or data you're working with.
  // const [itemOffset, setItemOffset] = useState(0);

  // useEffect(() => {
  //   // Fetch items from another resources.
  //   const endOffset = itemOffset + Number(itemsPerPage);
  //   console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  //   setCurrentItems(newMemberList.slice(itemOffset, endOffset));
  //   setPageCount(Math.ceil(newMemberList.length / Number(itemsPerPage)));
  // }, [itemOffset, newMemberList, itemsPerPage]);

  // const getCustomers = useCallback(async () => {
  //   setLoading(true);

  //   const data = await getClientsPreparedData();

  //   // const userList = await getAllClients();
  //   // const donationsByUser = [];

  //   //let wallets = [];
  //   // for (const user of userList) {
  //   //   const userWallet = await getUserWallet(user.id);
  //   //   const donations = await getAllDonationsByUser(user.id);
  //   //   wallets.push(userWallet);
  //   //   donationsByUser.push(donations);
  //   // }

  //   setCustomers(data);
  //   setFilteredCustomers(data);
  //   //setCustomerWallets(wallets);
  //   //setCustomerDonations(donationsByUser);

  //   dispatch(addCustomers(data));
  //   //dispatch(addCustomerWallets(wallets));
  // }, [dispatch]);

  // useEffect(() => {
  //   setLoading(true);
  //   if (reduxCustomers.customers) {
  //     console.log("No nuevos clientes");
  //     const { customers, customerWallets } = reduxCustomers;
  //     setCustomers(customers);
  //     setFilteredCustomers(customers);
  //     //setCustomerWallets(customerWallets);
  //     setLoading(false);
  //   } else {
  //     getCustomers();
  //   }
  // }, [reduxCustomers]);

  // // Invoke when user click to request another page.
  // const handlePageClick = (event) => {
  //   const newOffset = (event.selected * itemsPerPage) % newMemberList.length;
  //   console.log(
  //     `User requested page number ${event.selected}, which is offset ${newOffset}`
  //   );
  //   setItemOffset(newOffset);
  // };

  // const sortTableElements = (sortingMethod) => {
  //   const sortedItems = sortCustomersTable(currentItems, sortingMethod);
  //   setCurrentItems(sortedItems);
  // };

  // const dropdownList = [
  //   {
  //     valuesList: [
  //       { name: "Compras recientes" },
  //       { name: "Registro recientes" },
  //       { name: "Donaciones + a -" },
  //     ],
  //     label: "",
  //     filterMethod: sortTableElements,
  //   },
  // ];

  // const showNewCustomers = (list) => {
  //   const newCustomers = list.filter(
  //     (customer) => customer.planStatus === "Nuevo"
  //   );

  //   setFilteredCustomers(newCustomers);
  // };

  // const showRenovations = (list) => {
  //   const renovations = list.filter(
  //     (customer) => customer.planStatus === "Renovacion"
  //   );

  //   setFilteredCustomers(renovations);
  // };

  // const showDuePlans = (list) => {
  //   const duePlans = list.filter((customer) => customer.status === "Due");

  //   setFilteredCustomers(duePlans);
  // };

  // const showAllCustomers = () => {
  //   setFilteredCustomers(customers);
  // };

  // const options = [
  //   { text: "Todos", filterMethod: () => showAllCustomers() },
  //   { text: "Nuevos", filterMethod: () => showNewCustomers(customers) },
  //   {
  //     text: "Renovaciones",
  //     filterMethod: () => showRenovations(customers),
  //   },
  //   { text: "Due", filterMethod: () => showDuePlans(customers) },
  // ];

  // const [searchField, setSearchField] = useState("");
  // const rows = searchTable(currentItems, headersSearch, searchField);

  return (
    <>
      <TableFilters
        options={options}
        dropdowns={matchesUp900 ? dropdownList : null}
        searchRows={matchesUp900 ? setSearchField : null}
        handleRefresh={matchesUp900 ? () => getCustomers() : null}
        showArrows={matchesDown620}
      />
      <div className="p-4 bg-light">
        {loading ? (
          <div className="loading-container">
            {/* <Spinner
              className="spinner-style"
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            /> */}
            <CoinLoader size={{ width: 150, height: 150 }} />
          </div>
        ) : (
          <Table
            className="bg-light mb-0 p-4"
            responsive
            style={{ width: "400px !important" }}
          >
            <TableHeader headers={headers} />
            <TableBodyHover
              //rows={currentItems}
              rows={rows}
              columns={headers}
              clickGoTo="/user/"
            />
          </Table>
        )}
      </div>

      <div className="bg-light p-4 rounded-bottom">
        <Stack direction="horizontal">
          <ItemsControl setNumItemPerPage={setItemsPerPage} />

          <div className="ms-auto">
            <ReactPaginate
              breakLabel="..."
              nextLabel={<IoIosArrowForward />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel={<IoIosArrowBack />}
              renderOnZeroPageCount={null}
              containerClassName="pagination pagination-modifier"
              pageClassName="page-item"
              previousClassName="previus-button"
              nextClassName="next-button"
              pageLinkClassName="page-link page-link-modifier"
              activeClassName="active"
            />
          </div>
        </Stack>
      </div>
    </>
  );
};

export default CustomersTable;
