import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MUIDataTable from "mui-datatables";
import { Box, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAssociationCodes } from "../../redux/b2b/actions/actions";
import { refreshToken } from "../../redux/user/actions/auth";
import CustomBodyWithSpinner from "../general/CustomBodyWithSpinner";
import { useNavigate } from "react-router-dom";
import { IoRefreshOutline } from "react-icons/io5";
import { IconButton } from "@mui/material";
import { MdArrowOutward, MdEdit } from "react-icons/md";
import { B2bUserModal } from "./B2bUserModal";

export const B2bUsersTable = ({
  isDataAvailable,
  activeUsers,
  handleRefresh,
  loading,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const isFirstUse = useRef(true);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleOpenEditModal = async (item) => {
    setEditModalOpen(true);

    setSelectedItem(item);
    console.log(item);
  };

  // useEffect(() => {
  //   if (isFirstUse.current) {
  //     setloading(true);
  //     getUsersFromFB();
  //     isFirstUse.current = false;
  //   } else if (users.length > 0) {
  //     setloading(false);
  //     setUsersFromDB(users);
  //   } else {
  //     const timeout = setTimeout(() => {
  //       setNoDataAvailable(true);
  //     }, 4200);

  //     return () => clearTimeout(timeout);
  //   }
  //   if (noDataAvailable) {
  //     setloading(false);
  //   }
  // }, [getUsersFromFB, users]);

  const handleUserSelect = (url) => {
    navigate(`/user/${url}`);
  };
  const onClose = () => {
    setEditModalOpen(false);

    setSelectedItem();
  };

  const BodyComponent = useMemo(
    () => (tableProps) => {
      if (isDataAvailable) {
        return (
          <CustomBodyWithSpinner loading={!isDataAvailable} {...tableProps} />
        );
      }

      return (
        <CustomBodyWithSpinner
          loading={loading && activeUsers.length === 0}
          {...tableProps}
        />
      );
    },
    [activeUsers, loading, isDataAvailable]
  );

  const columns = [
    {
      name: "name",
      label: "Nombre",
      options: {
        display: true,
        filter: false,
      },
    },
    {
      name: "lastname",
      label: "Apellido",
      options: {
        display: true,
        filter: false,
      },
    },
    {
      name: "codeId",
      label: "Código",
      options: {
        display: true,
        filter: false,
      },
    },

    {
      name: "duration",
      label: "Duración",
      options: {
        customBodyRender: (value) => {
          return value ? value : "N/A";
        },
        filter: false,
      },
    },
    {
      name: "activationDate",
      label: "Fecha de activación",
      options: {
        customBodyRender: (value) => {
          if (typeof value === "object" && value.seconds && value.nanoseconds) {
            const date = new Date(
              value.seconds * 1000 + value.nanoseconds / 1000000
            );
            const formattedDate = date.toLocaleDateString();
            return formattedDate;
          } else {
            return "-";
          }
        },

        filter: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return value === "active" ? "Activo" : " ";
        },
        filter: false,
      },
    },
    {
      name: "userRef",
      label: "Referencia",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "edit",
      label: "Editar",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const data = activeUsers[dataIndex];

          // console.log("schedules:", schedules);
          // console.log("dataIndex:", dataIndex);
          // console.log("schedule:", schedule);

          const handleEdit = () => {
            console.log(" edit button");
            handleOpenEditModal(data);
          };

          return (
            <IconButton
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: "white",
              }}
              onClick={handleEdit}
            >
              <MdEdit size={14} />
            </IconButton>
          );
        },
      },
      filter: false,
    },
    // {
    //   name: "viewMore",
    //   label: "Ver Más",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       const userId = tableMeta.rowData[0];
    //       return (
    //         <IconButton
    //           onClick={() => handleUserSelect(userId)}
    //           size="small"
    //           sx={{
    //             backgroundColor: theme.palette.primary.main,
    //             width: "32px",
    //             height: "32px",
    //             color: "white",
    //           }}
    //         >
    //           <MdArrowOutward />
    //         </IconButton>
    //       );
    //     },
    //     filter: false,
    //     sort: false,
    //   },
    // },
  ];
  const CustomToolbar = ({ onRefresh }) => {
    return (
      <IconButton className="refresh-button" onClick={onRefresh}>
        <IoRefreshOutline size={25} />
      </IconButton>
    );
  };
  const options = {
    filter: true,
    responsive: "standard",
    selectableRows: "none",
    rowsPerPage: 15,
    textLabels: {
      body: {
        noMatch: "No hay datos disponibles",
      },
    },

    sortOrder: {
      name: "name",
      direction: "asc",
    },
    sortCompare: (order) => (a, b) => {
      const isAsc = order === "asc";
      // Replace "lastname" with the column name you want to handle
      const column = "codeId";

      const aValue =
        a.data[column] !== undefined &&
        a.data[column] !== null &&
        a.data[column].trim() !== ""
          ? a.data[column]
          : "N/A";
      const bValue =
        b.data[column] !== undefined &&
        b.data[column] !== null &&
        b.data[column].trim() !== ""
          ? b.data[column]
          : "N/A";

      // Use default comparison for other cases
      if (isAsc) {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    },
    customToolbar: () => {
      return <CustomToolbar onRefresh={() => handleRefresh()} />;
    },

    // onRowClick: (rowData) => {
    //   const clickedOwnerId = rowData[0];

    //   handleUserSelect(clickedOwnerId);
    // },
  };
  // if (loading) {
  //   return <div>loading</div>;
  // }
  return (
    <Box mt={6} sx={{ width: "100%" }} id="B2bTable">
      {/* <div className="refresh-container">
            <Button className="refresh-button">
              <IoRefreshOutline size={25} onClick={handleRefresh} />
            </Button>
          </div> */}

      <MUIDataTable
        title="Usuarios Activos"
        data={activeUsers}
        columns={columns}
        options={options}
        components={{ TableBody: BodyComponent }}
      />
      {selectedItem && (
        <B2bUserModal
          user={selectedItem}
          open={editModalOpen}
          onClose={onClose}
          onRefresh={handleRefresh}
        />
      )}
    </Box>
  );
};
