import { useState } from "react";
import { Stack } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Filters from "../filters/filters";
import Searchbar from "../searchbar/searchbar";
import { IoRefreshOutline } from "react-icons/io5";

// import "./table.scss";
// import "./table-filters.scss";

import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const SelectPromotionFilters = ({
  searchRows,
  dropdowns,
  options = [],
  handleRefresh,
  product,
}) => {
  const [selectedButton, setSelectedButton] = useState(options[0]?.text);

  const handleClick = (event, filterMethod) => {
    const selectedElementId = event.target.textContent;
    setSelectedButton(selectedElementId);

    filterMethod();
  };

  return (
    <div className="py-4 bg-light mt-4 rounded-top" id="table">
      <Stack direction="horizontal" gap={2}>
        {options.map((option) => (
          <Button
            key={Math.random() * (100 - 1) + 1}
            variant="link"
            className={`${
              selectedButton === option.text ? "text-priamry" : "text-secondary"
            } text-decoration-none`}
            onClick={(event) => handleClick(event, option.filterMethod)}
          >
            {option.text}
          </Button>
        ))}

        <Searchbar
          onSearch={searchRows}
          barWidth="100%"
          containerClass="w-100"
        />
        {dropdowns.map((dropdown) => {
          return (
            <Filters
              label={dropdown.label}
              values={dropdown.valuesList}
              sortingMethod={dropdown.filterMethod}
            />
          );
        })}

        {/* {product && (
          <Link
            to={{
              pathname: "add-product",
              state: { prevPath: location.pathname },
            }}
          >
            <Button size="sm" className="rounded-pill add-product">
              Agregar
            </Button>
          </Link>
        )} */}
        {handleRefresh ? (
          <div className="refresh-container">
            <a className="refresh-button">
              <IoRefreshOutline size={25} onClick={handleRefresh} />
            </a>
          </div>
        ) : null}
      </Stack>
    </div>
  );
};

export default SelectPromotionFilters;
