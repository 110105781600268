import { db } from "../../../firebase/firebase-config";
import { types } from "../types/types";

export const getAllSponsors = () => {
  return (dispatch) => {
    db.collection("sponsors")
      .get()
      .then(async (itemsFromFs) => {
        const sponsorPromises = itemsFromFs.docs.map(async (item) => {
          const sponsorData = item.data();
          const length = await getExchangesLength(item.id);

          return {
            id: item.id,
            exchanges: length,
            ...sponsorData, // Include the rest of the user data
          };
        });
        const sponsorsData = await Promise.all(sponsorPromises);
        dispatch(getBusinesses(sponsorsData));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getAllRequests = () => {
  return (dispatch) => {
    db.collection("contacts")
      .doc("sponsorRequests")
      .collection("requests")
      .get()
      .then(async (itemsFromFs) => {
        const sponsorPromises = itemsFromFs.docs.map(async (item) => {
          const sponsorData = item.data();

          return {
            id: item.id,
            ...sponsorData,
          };
        });
        const requestData = await Promise.all(sponsorPromises);
        dispatch(getRequests(requestData));
        console.log("requestData:", requestData);
      })
      .catch((err) => {
        console.error("Error fetching requests:", err);
        // Dispatch an action or handle the error as needed
      });
  };
};

export const getExchangesLength = async (id) => {
  try {
    const snapshot = await db
      .collection("sponsors")
      .doc(id)
      .collection("exchanges")
      .get();
    return snapshot.size;
  } catch (error) {
    console.log("Error getting exchanges length", error);
    return 0;
  }
};

export const getBusinesses = (businesses) => ({
  type: types.businesses,
  payload: {
    businesses,
  },
});
export const getRequests = (requests) => ({
  type: types.requests,
  payload: {
    requests,
  },
});

export const addBusinesses = (businesses) => ({
  type: types.addBusinesses,
  payload: businesses,
});

export const refreshUsers = (shouldRefresh) => ({
  type: types.refreshBusinesses,
  payload: shouldRefresh,
});
