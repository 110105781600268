import { types } from "../types/types";

const INITIAL_STATE = {
  achievements: null,
};

const achievementsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.addAchievements:
      return {
        ...state,
        achievements: action.payload,
      };
    case types.removeAchievements:
      return {
        ...state,
        achievements: null,
      };
    default:
      return state;
  }
};

export default achievementsReducer;
