import * as Yup from "yup";

const categories = [
  "Bar/Vida Nocturna",
  "Restaurante/Cafetería",
  "Tienda deportiva",
  "Outfit",
  "Servicios de salud",
  "Recreación al aire libre",
  "Instalación deportiva",
  "Entretenimiento Indoor",
  "Hospedaje",
  "Supermercado/Abarrotes",
  "Tienda de tecnología",
  "Servicios educativos",
  "Estetica/Spa/Cuidado personal",
  "Productos especializados",
  "Eventos deportivos",
  "Otro",
];

export const createPromoSchema = Yup.object().shape({
  promoName: Yup.string()
    .required("Requerido")
    .max(15, "Largo máximo: 15 carácteres"),
  categories: Yup.string().oneOf(categories, "Categoria no valida"),
  promoSummary: Yup.string()
    .required("Requerido")
    .max(30, "Largo máximo: 30 carácteres"),
  description: Yup.string()
    .required("Requerido")
    .max(225, "Largo máximo: 225 carácteres"),
});
