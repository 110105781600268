import { useState, useEffect, useCallback, useRef } from "react";

import { Card } from "react-bootstrap";
import { Stack } from "react-bootstrap";
import { Button } from "react-bootstrap";
import CustomModal from "../modal/customModal";

import { getUserPaymentHistory } from "../../firebase/firebase.utils";
import UserRefund from "../user-refund/user-refund";

const elementsLimit = 3;
let isProduction = process.env.NODE_ENV === "production";

const UserPurchases = ({ user: userPublicData }) => {
  const [userPaymentsData, setUserPaymentsData] = useState([]);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [lastElement, setLastElement] = useState(null);
  const [paymentDataSize, setPaymentDataSize] = useState(10); //Set always bigger than limit
  const [triggerBtn, setTriggerBtn] = useState(false);

  const isMounted = useRef(process.env.NODE_ENV === "production");
  console.log(isMounted.current);

  const getData = async () => {
    const { paymentHistory, lastVisible } = await getUserPaymentHistory(
      userPublicData.id,
      lastElement,
      elementsLimit
    );

    if (!isProduction) {
      setUserPaymentsData((prevValues) => {
        const temp = [...paymentHistory, ...prevValues];

        const uniqueArr = temp.filter(
          (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
        );

        return uniqueArr;
      });
    } else {
      setUserPaymentsData((prevValues) => [...paymentHistory, ...prevValues]);
    }

    setLastElement(lastVisible);
    setPaymentDataSize(paymentHistory.length);
  };

  // useEffect(() => {
  //   if (paymentDataSize >= elementsLimit) {
  //     if (isMounted) {
  //       console.log("Me ejecute");
  //       getData();
  //     } else {
  //       isMounted = true;
  //     }
  //   }
  // }, [triggerBtn]);

  useEffect(() => {
    if (paymentDataSize >= elementsLimit) {
      getData();
    }
  }, [triggerBtn]);

  const cleanSelectedPayment = (transactionId) => {
    const cleanArray = userPaymentsData.map((payment) => ({
      ...payment,
      selected: false,
    }));

    const index = cleanArray.findIndex(
      (payment) => payment.transactionId === transactionId
    );

    cleanArray[index].selected = !cleanArray[index].selected;

    setUserPaymentsData(cleanArray);

    // Set the selectedPayment state to the selected payment, or empty string if none selected
    setSelectedPayment(cleanArray[index].selected ? cleanArray[index] : "");
  };
  const handleRefund = () => {
    const payment = userPaymentsData.find((payment) => payment.selected);
    setShowRefundModal(true);
    setSelectedPayment(payment);
  };

  return (
    <div>
      <Card
        body
        border="light"
        className="bg-success mb-n1 rounded-top"
        style={{ borderRadius: 0 }}
      >
        <Stack direction="horizontal" className="mb-3">
          <h2 className="h5 fw-bold">Compras</h2>
          <h3 className="h6 text-primary ms-auto">Pagos</h3>
        </Stack>

        {userPaymentsData?.map((payment) => {
          return (
            <div
              key={Math.random() * (100 - 1) + 1}
              className="bg-effect hover-effect"
              onClick={(e) => {
                cleanSelectedPayment(payment.transactionId);
              }}
            >
              <Stack direction="horizontal">
                <small
                  className={`${
                    payment.selected ? "bg-primary" : "bg-secondary"
                  } d-inline-block rounded-circle me-3`}
                  //className="bg-secondary rounded-circle me-3"
                  style={{ width: "12px", height: "12px" }}
                ></small>
                <p className="mb-0 fw-bold fs-6">Plan {payment.plan.subTier}</p>
                <p className="mb-0 fw-bold fs-6 ms-auto">
                  {payment?.transactionAmount ?? 0}
                </p>
              </Stack>

              <Stack direction="horizontal" className="text-muted mb-5">
                <small
                  className="bg-transparent rounded-circle me-3"
                  style={{ width: "12px", height: "12px" }}
                ></small>
                <small className="fw-bold">Renovación</small>
                <small className="fw-bold ms-auto">
                  {payment.transactionDate
                    .toDate()
                    .toLocaleDateString("es-CR", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                </small>
              </Stack>
            </div>
          );
        })}

        <Stack
          direction="horizontal"
          gap={2}
          className="justify-content-center "
        >
          <Button
            variant="link"
            size="small"
            onClick={() => setTriggerBtn((prevValue) => !prevValue)}
            disabled={paymentDataSize < elementsLimit}
          >
            Ver más
          </Button>
        </Stack>
      </Card>
      <div className="bg-light p-3 rounded-bottom">
        <Stack
          direction="horizontal"
          gap={2}
          className="justify-content-center "
        >
          <Button
            variant="primary"
            size="small"
            onClick={() => handleRefund()}
            disabled={selectedPayment === ""}
          >
            <strong>Reintegro</strong>
          </Button>
        </Stack>
      </div>
      <CustomModal
        show={showRefundModal}
        handleClose={setShowRefundModal}
        modalTitle={() => "Reintegro"}
      >
        <UserRefund
          onClose={setShowRefundModal}
          payment={selectedPayment}
          //handleRefresh={() => getData()}
        />
      </CustomModal>
    </div>
  );
};

export default UserPurchases;
