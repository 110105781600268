import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MUIDataTable from "mui-datatables";
import { Box, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomBodyWithSpinner from "../general/CustomBodyWithSpinner";
import { getAllRequests } from "../../redux/sponsors/actions/actions";
import { useNavigate } from "react-router-dom";
import { IoRefreshOutline } from "react-icons/io5";
import { IconButton } from "@mui/material";
import { MdArrowOutward } from "react-icons/md";
import RequestModal from "./RequestModal";

export const RequestsTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { requests } = useSelector((state) => state.businesses);
  const [sponsorsFromDb, setSponsorsFromDB] = useState([]);
  const isFirstUse = useRef(true);
  const [loading, setloading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const getSponsorsFromFB = useCallback(() => {
    try {
      dispatch(getAllRequests());
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  }, [requests]);
  useEffect(() => {
    if (isFirstUse.current) {
      setloading(true);
      getSponsorsFromFB();
      setSponsorsFromDB(requests);
      isFirstUse.current = false;
    } else if (requests.length > 0) {
      setloading(false);
      setSponsorsFromDB(requests);
    }
  }, [getSponsorsFromFB, requests]);

  const BodyComponent = useMemo(
    () => (tableProps) =>
      (
        <CustomBodyWithSpinner
          loading={loading || requests.length === 0}
          {...tableProps}
        />
      ),
    [requests, loading]
  );
  const handleRefresh = () => {
    setloading(true);
    getSponsorsFromFB();
    setTimeout(() => {
      setloading(false);
    }, 1000);
  };
  const handleSponsorSelect = (url) => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const handleOpenRequest = (item) => {
    console.log(item);
    setSelected(item);
    setOpen(true);
  };
  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "name",
      label: "Comercio",
      options: {
        customBodyRender: (value) => {
          return value ? value : "N/A";
        },
        filter: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
      },
    },

    {
      name: "phone",
      label: "Teléfono",
      options: {
        customBodyRender: (value) => {
          return value ? value : "N/A";
        },

        filter: false,
      },
    },

    {
      name: "exchanges",
      label: "Canjes",
      options: {
        customBodyRender: (value) => {
          return value ? value : "N/A";
        },

        filter: false,
      },
    },
    {
      name: "cdate",
      label: "Fecha de registro",
      options: {
        customBodyRender: (value) => {
          if (typeof value === "object" && value.seconds && value.nanoseconds) {
            const date = new Date(
              value.seconds * 1000 + value.nanoseconds / 1000000
            );
            const formattedDate = date.toLocaleDateString();
            return formattedDate;
          } else {
            return "-";
          }
        },

        filter: false,
      },
    },

    {
      name: "isActive",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return value ? "Activo" : "Inactivo";
        },
      },
    },
    {
      name: "viewMore",
      label: "Ver Más",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = tableMeta.rowData;
          const selectItem = requests.find((item) => item.id === rowData[0]);
          return (
            <IconButton
              onClick={() => handleOpenRequest(selectItem)}
              size="small"
              sx={{
                backgroundColor: theme.palette.primary.main,
                width: "32px",
                height: "32px",
                color: "white",
              }}
            >
              <MdArrowOutward />
            </IconButton>
          );
        },
        filter: false,
        sort: false,
      },
    },
  ];
  const CustomToolbar = ({ onRefresh }) => {
    return (
      <IconButton className="refresh-button" onClick={onRefresh}>
        <IoRefreshOutline size={25} />
      </IconButton>
    );
  };
  const options = {
    filter: true,
    responsive: "standard",
    selectableRows: "none",
    rowsPerPage: 15,
    onTableChange: () => setSponsorsFromDB(requests),
    sortOrder: {
      name: "name",
      direction: "asc",
    },
    sortCompare: (order) => (a, b) => {
      const isAsc = order === "asc";

      const column = "name";

      const aValue =
        a.data[column] !== undefined &&
        a.data[column] !== null &&
        a.data[column].trim() !== ""
          ? a.data[column]
          : "N/A";
      const bValue =
        b.data[column] !== undefined &&
        b.data[column] !== null &&
        b.data[column].trim() !== ""
          ? b.data[column]
          : "N/A";

      // Use default comparison for other cases
      if (isAsc) {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    },

    // onRowClick: (rowData) => {
    //   const clickedOwnerId = rowData[0];

    //   handleSponsorSelect(clickedOwnerId);
    // },
    customToolbar: () => {
      return <CustomToolbar onRefresh={handleRefresh} />;
    },
  };

  return (
    <Box mt={6} sx={{ width: "100%" }}>
      <MUIDataTable
        title="Solicitudes para sponsor"
        data={sponsorsFromDb}
        columns={columns}
        options={options}
        components={{ TableBody: BodyComponent }}
      />
      {selected && (
        <RequestModal
          open={open}
          onClose={onClose}
          item={selected}
          theme={theme}
          handleRefresh={handleRefresh}
        />
      )}
    </Box>
  );
};
