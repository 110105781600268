import Form from "react-bootstrap/Form";
import { Button, Spinner, Stack } from "react-bootstrap";

import { useFormik } from "formik";
import * as Yup from "yup";

import FormInput from "../form-input/form-input";
import { deleteAchievement } from "../../firebase/firebase.utils";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const AchievementDeletion = ({ onClose, achievementId, handleRefresh }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // const validate = Yup.object({
  //   message: Yup.string().required("Requerido"),
  // });

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      message: "",
    },
    // validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      deleteAchievement(achievementId);

      setTimeout(() => {
        setLoading(false);
        handleRefresh();
        navigate("/achievements");
        resetForm();
        onClose(false);
      }, 2000);
    },
  });

  return (
    <Form
      className="p-2"
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <p>La eliminación es permanente ¿Seguro que desea continuar?</p>
      <Form.Check
        type={"checkbox"}
        id={`sponsorNotification`}
        label={`Notificar al usuario`}
      />

      <FormInput
        as="textarea"
        rows={5}
        cols={25}
        placeholder={"Escribe el motivo de la eliminación"}
        id="sponsorMessage"
        name="message"
        className="bg-success border-0 p-2 my-3"
        onChange={handleChange}
        errorList={errors}
        value={values.message}
      />

      <Form.Control.Feedback type="invalid">
        {errors.message}
      </Form.Control.Feedback>

      <Stack direction="horizontal">
        <Button
          variant="light"
          className="rounded-pill ms-auto"
          onClick={() => onClose(false)}
        >
          Cancelar
        </Button>
        <Button
          variant="primary"
          size="sm"
          className="rounded-pill text-light px-3"
          type="submit"
          disabled={loading}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {!loading && (
            <span style={{ color: "white !important" }}>Sí, eliminar...</span>
          )}
        </Button>
      </Stack>
    </Form>
  );
};

export default AchievementDeletion;
