export const sortProductsTable = (cells, sortingMethod) => {
  console.log(cells);
  let sortedItems = [];
  switch (sortingMethod) {
    case "IVA + a -":
      sortedItems = [...cells].sort((firstElement, secondElement) => {
        return firstElement.tax - secondElement.tax;
      });
      return sortedItems;

    case "IVA - a +":
      sortedItems = [...cells].sort((firstElement, secondElement) => {
        return secondElement.tax - firstElement.tax;
      });
      return sortedItems;

    case "Precio + a -":
      sortedItems = [...cells].sort((firstElement, secondElement) => {
        return secondElement.price - firstElement.price;
      });
      return sortedItems;

    case "Precio - a +":
      sortedItems = [...cells].sort((firstElement, secondElement) => {
        return firstElement.price - secondElement.price;
      });

      return sortedItems;

    default:
      return;
  }
};
