import { Box, Divider, Typography, useTheme } from "@mui/material";
import { Image } from "react-bootstrap";
import { Stack } from "react-bootstrap";

const Overview = ({ children, data, number, icon }) => {
  const total = data.totalNumber.toLocaleString("en-US");
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        // ml: 2,
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 1,
          mr: 1.5,
          borderRadius: "50%",
        }}
      >
        {icon}
        {number ? (
          <Box
            sx={{
              width: "25px",
              height: "25px",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Typography variant="body1" textAlign={"center"} color={"white"}>
              {number}
            </Typography>
          </Box>
        ) : children ? (
          <div className="rounded-circle p-3 bg-info">{children}</div>
        ) : (
          //<Image src={icon} alt="" className="rounded-circle p-3 bg-info" />
          ""
        )}
      </Box>
      <Stack className="justify-content-center me-1">
        <small className="mb-0 text-muted">{data.title}</small>
        <p className="h5 mb-0">{total}</p>
        <small>
          <span className={!data.color ? "fw-bold" : "text-primary"}>
            {data.statistics}
          </span>{" "}
          {data.reference}
        </small>
      </Stack>
      {/* <Divider
        orientation="vertical"
        flexItem
        sx={{
          borderColor: theme.palette.primary.main,
          borderWidth: "2px",
          borderRadius: 2,
        }}
      /> */}
    </Box>
  );
};

export default Overview;
