import { Tab, Tabs } from "@mui/material";

const CustomTabs = ({ value, handleChange, tabList }) => {
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      centered
      variant="scrollable"
      scrollButtons="auto"
      allowScrollButtonsMobile
    >
      {tabList.map((tabItem) => {
        return (
          <Tab
            key={tabItem.id}
            label={tabItem.label}
            sx={{ fontSize: 12, py: 0, alignItems: "center" }}
          />
        );
      })}
    </Tabs>
  );
};

export default CustomTabs;
