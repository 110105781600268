import { useEffect } from "react";

import { Card } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { Form } from "react-bootstrap";

import fallback from "./../../assests/fallback.jpg";
import darkPlus from "./../../assests/darkPlus.png";

import "./upload-logo.scss";

const UploadLogo = ({
  title,
  setImageUpload,
  imageUpload,
  setImage,
  image,
  inputId,
}) => {
  useEffect(() => {
    const uploadSponsorImage = async () => {
      setImageUpload(imageUpload);
    };

    if (imageUpload === null) {
      return;
    } else {
      uploadSponsorImage();
    }
  }, [imageUpload]);

  return (
    <div className="mt-3">
      <Form.Group>
        <Form.Label style={{ fontSize: "14px" }}>{title}</Form.Label>
        <Card className="border-0 shadow-sm">
          <Card.Body className="d-flex align-items-center justify-content-center sponsor-container">
            <Image
              src={image === "" ? fallback : image}
              //width="50%"
              //height="100%"
              roundedCircle
              style={{ objectFit: "cover", width: "100px", height: "100px" }}
            />
            <div>
              <label htmlFor={inputId ? inputId : "file-input"}>
                <div
                  className="position-absolute plus-sign bg-primary px-2 rounded-circle"
                  style={{ top: "95%", left: "50%" }}
                >
                  <Image src={darkPlus} width="11" height="11" />
                </div>
              </label>
              <input
                type="file"
                name="sponsor"
                id={inputId ? inputId : "file-input"}
                className="d-none"
                onChange={(event) => {
                  setImageUpload(event.target.files[0]);
                  setImage(URL.createObjectURL(event.target.files[0]));
                }}
              />
            </div>
          </Card.Body>
        </Card>
      </Form.Group>
    </div>
  );
};

export default UploadLogo;
