import { useEffect, useState } from "react";

import "./table.scss";

const TableBody = ({
  rows,
  columns,
  clickMethod,
  fixedColumnHeight = false,
}) => {
  const [tableRows, setTableRows] = useState([]);

  useEffect(() => {
    setTableRows(rows);
  }, [rows]);

  return (
    <tbody className="p-4">
      {tableRows.map((row, index) => {
        return (
          <tr
            className="hover-rows"
            key={index}
            onClick={clickMethod ? () => clickMethod(row.id) : () => {}}
          >
            {columns.map(({ accessor }) => {
              if (row[accessor]?.toDate) {
                const dateField = row[accessor]
                  .toDate()
                  .toLocaleDateString("es-CR");

                return (
                  <td
                    key={accessor}
                    className="fw-bold text-center"
                    style={{
                      width: fixedColumnHeight + "px",
                      lineHeight: fixedColumnHeight + "px",
                    }}
                  >
                    {dateField}
                  </td>
                );
              }
              const tableField = row[accessor] ? row[accessor] : "--";
              return (
                <td
                  key={accessor}
                  className="fw-bold"
                  style={{
                    height: fixedColumnHeight + "px",
                    lineHeight: fixedColumnHeight + "px",
                  }}
                >
                  {tableField}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
