import React, { useEffect, useMemo, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Box, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomBodyWithSpinner from "../general/CustomBodyWithSpinner";
import { useNavigate } from "react-router-dom";
import { IoRefreshOutline } from "react-icons/io5";
import { IconButton } from "@mui/material";
import { MdEdit, MdArrowOutward } from "react-icons/md";
import { deleteBanner } from "../../redux/banners/actions/actions";
import EditPromoBanner from "./EditPromoBanner";
import Swal from "sweetalert2";
import CustomTabs from "./CustomTabs";

const labelList = [
  { id: 1, label: "Publicidad" },
  { id: 2, label: "Banners Home" },
  { id: 3, label: "Banners Carrusel" },
  { id: 4, label: "Banners Actividad" },
];

export const AdvertisingTable = ({
  promoBanners,
  loading,
  handleRefresh,
  value,
  handleChange,
}) => {
  const navigate = useNavigate();

  const matchesUp1000 = useMediaQuery("(min-width:1000px)");

  const [bannersFromDB, setBannersFromDB] = useState([]);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  useEffect(() => {
    // Update bannersFromDB when promoBanners change
    setBannersFromDB(promoBanners);
  }, [promoBanners]);
  // useEffect(() => {
  //   if (isFirstUse.current || promoBanners.length === 0) {
  //     setloading(true);
  //     getPromoBannersFromFB();
  //     isFirstUse.current = false;
  //     setTimeout(() => {
  //       setloading(false);
  //     }, 1000);
  //   }
  // }, [getPromoBannersFromFB]);

  const handleUserSelect = (url) => {
    navigate(`/user/${url}`);
  };
  const handleOpenEditModal = (rowData) => {
    const id = rowData[0];
    const bannerData = bannersFromDB.find((item) => item.id === id);
    setSelectedData(bannerData);
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedData([]);
    setIsEditModalOpen(false);
  };

  const BodyComponent = useMemo(
    () => (tableProps) =>
      <CustomBodyWithSpinner loading={loading} {...tableProps} />,
    [loading]
  );

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "businessName",
      label: "Sponsor",
      options: {
        customBodyRender: (value) => {
          return value ? value : "N/A";
        },
        filter: false,
      },
    },
    {
      name: "name",
      label: "Nombre",
      options: {
        customBodyRender: (value) => {
          return value ? value : "N/A";
        },
        filter: false,
      },
    },
    {
      name: "description",
      label: "Descripción",
      options: {
        customBodyRender: (value) => {
          return value ? value : "N/A";
        },
        filter: false,
      },
    },
    {
      name: "imageURL",
      label: "Imagen",
      options: {
        customBodyRender: (value) => {
          return value ? (
            <img
              src={value}
              alt="Banner"
              style={{
                width: "100px",
                height: "50px",
                borderRadius: "4px",
                objectFit: "contain",
              }}
            />
          ) : (
            "N/A"
          );
        },
        filter: false,
      },
    },

    {
      name: "cdate",
      label: "Fecha de registro",
      options: {
        customBodyRender: (value) => {
          if (typeof value === "object" && value.seconds && value.nanoseconds) {
            const date = new Date(
              value.seconds * 1000 + value.nanoseconds / 1000000
            );
            const formattedDate = date.toLocaleDateString();
            return formattedDate;
          } else {
            return "-";
          }
        },

        filter: false,
      },
    },
    {
      name: "dueDate",
      label: "Fecha válida",
      options: {
        customBodyRender: (value) => {
          if (typeof value === "object" && value.seconds && value.nanoseconds) {
            const date = new Date(
              value.seconds * 1000 + value.nanoseconds / 1000000
            );
            const formattedDate = date.toLocaleDateString();
            return formattedDate;
          } else {
            return "-";
          }
        },

        filter: false,
      },
    },

    {
      name: "isActive",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return value ? "Activo" : "Inactivo";
        },
      },
    },
    {
      name: "itemNumber",
      label: "Orden",
      options: {
        // customBodyRender: (value) => {
        //   return value ? value : "N/A";
        // },
        filter: false,
      },
    },

    {
      name: "edit",
      label: "Editar",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowData = tableMeta.rowData;
          // console.log("Row Data:", rowData); /

          return (
            <IconButton
              onClick={() => handleOpenEditModal(rowData)} // Pass the rowData
              size="small"
              color="primary"
            >
              <MdEdit />
            </IconButton>
          );
        },
        filter: false,
        sort: false,
      },
    },
  ];
  const CustomToolbar = ({ onRefresh }) => {
    return (
      <IconButton className="refresh-button" onClick={onRefresh}>
        <IoRefreshOutline size={25} />
      </IconButton>
    );
  };
  const CustomTitle = () => {
    return (
      <CustomTabs
        value={value}
        handleChange={handleChange}
        tabList={labelList}
      />
    );
  };
  const options = {
    filter: true,
    responsive: "standard",
    selectableRows: "single",

    rowsPerPage: 15,

    sortOrder: {
      name: "name",
      direction: "asc",
    },
    sortCompare: (order) => (a, b) => {
      const isAsc = order === "asc";

      const column = "lastname";

      const aValue =
        a.data[column] !== undefined &&
        a.data[column] !== null &&
        a.data[column].trim() !== ""
          ? a.data[column]
          : "N/A";
      const bValue =
        b.data[column] !== undefined &&
        b.data[column] !== null &&
        b.data[column].trim() !== ""
          ? b.data[column]
          : "N/A";

      // Use default comparison for other cases
      if (isAsc) {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    },
    customToolbar: () => {
      return <CustomToolbar onRefresh={handleRefresh} />;
    },

    onRowsDelete: (rowsToDelete) => {
      Swal.fire({
        title: "Alerta",
        text: "¿Está seguro que desea eliminar este banner?",

        icon: "warning",
        confirmButtonText: "Eliminar",
        showDenyButton: true,
        denyButtonText: "Volver",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            for (const row of rowsToDelete.data) {
              const dataIndex = row.index;
              const data = promoBanners[dataIndex];

              await deleteBanner(data.id, dataIndex);
            }
            handleRefresh();

            Swal.fire("Banner eliminado", "", "success");
          } catch (error) {
            console.error("Error deleting item:", error);
            Swal.fire("Error al eliminar el banner", "", "error");
          }
        }
      });

      return false;
    },
  };

  return (
    <Box sx={{ width: "100%" }}>
      {/* <div className="refresh-container">
            <Button className="refresh-button">
              <IoRefreshOutline size={25} onClick={handleRefresh} />
            </Button>
          </div> */}

      <MUIDataTable
        title={matchesUp1000 ? CustomTitle() : ""}
        data={bannersFromDB}
        columns={columns}
        options={options}
        components={{ TableBody: BodyComponent }}
      />

      <EditPromoBanner
        open={isEditModalOpen}
        onClose={handleCloseEditModal}
        data={selectedData}
        handleRefresh={handleRefresh}
      />
    </Box>
  );
};
