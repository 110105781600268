import * as Yup from "yup";

const categories = [
  "Bar/Vida Nocturna",
  "Restaurante/Cafetería",
  "Tienda deportiva",
  "Outfit",
  "Servicios de salud",
  "Recreación al aire libre",
  "Instalación deportiva",
  "Entretenimiento Indoor",
  "Hospedaje",
  "Supermercado/Abarrotes",
  "Tienda de tecnología",
  "Servicios educativos",
  "Estetica/Spa/Cuidado personal",
  "Productos especializados",
  "Eventos deportivos",
  "Otro",
];

export const createExchangeSchema = Yup.object().shape({
  exchangeable: Yup.string()
    .required("Requerido")
    .max(15, "Largo máximo: 15 carácteres"),
  categories: Yup.string().oneOf(categories, "Categoria no valida"),
  sportCoinsValue: Yup.number()
    .typeError()
    .required("No es un número")
    .min(4500, "El valor minimo es de 4500"),
  specification: Yup.string()
    .required("Requerido")
    .max(30, "Largo máximo: 30 carácteres"),
  restrictions: Yup.string()
    .required("Requerido")
    .max(225, "Largo máximo: 225 carácteres"),
});
