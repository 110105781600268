import { Box, Typography } from "@mui/material";
import LogInForm from "../../components/login-form/login-form";

const LogIn = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    minHeight="100vh"
    //sx={{ margin: "auto", maxWidth: { xs: "65%", sm: "50%", md: "30%" } }}
  >
    <Typography variant="h3" component="h1" noWrap>
      Iniciar Sesión
    </Typography>
    <LogInForm />
  </Box>
  //   <div className="d-flex flex-column justify-content-center align-items-center vh-100">

  //   </div>
);

export default LogIn;
