import { useMemo } from "react";
import { useState } from "react";

import { EditText } from "react-edit-text";

import { v4 as uuid } from "uuid";
import { FieldArray, useFormikContext } from "formik";

import { Card, Table, Form } from "react-bootstrap";
import { Stack } from "react-bootstrap";

import { ReactComponent as DarkPencilIcon } from "./../../assests/pencil.svg";

import TableHeader from "../table/table-header";

import "./product-values.scss";

const ProductValues = () => {
  const typeData = ["Moneda", "Porcentaje"];
  const { setFieldValue, errors, handleChange, values } = useFormikContext();

  const [dataVariables, setDataVariables] = useState([
    {
      id: uuid().slice(0, 8),
      onlyReadable: true,
      variable: "Gastos médicos por accidente",
      type: typeData,
      amount: 2500000,
    },
    {
      id: uuid().slice(0, 8),
      onlyReadable: true,
      variable: "Incapacidad permanente",
      type: typeData,
      amount: "Plus+",
    },
    {
      id: uuid().slice(0, 8),
      onlyReadable: true,
      variable: "Muerte accidental",
      type: typeData,
      amount: 2500000,
    },
    {
      id: uuid().slice(0, 8),
      onlyReadable: true,
      variable: "SM asistencias",
      type: typeData,
      amount: "Plus+",
    },
    {
      id: uuid().slice(0, 8),
      onlyReadable: true,
      variable: "Canjes y promociones",
      type: typeData,
      amount: "Plus+",
    },
  ]);

  const headers = [
    { name: "Variable", accessor: "variable" },
    //{ name: "Tipo", accessor: "type" },
    { name: "Valores", accessor: "values" },
    { name: "Acciones", accessor: "actions" },
  ];

  const editVariable = (elementId) => {
    let rowIndex = dataVariables.findIndex(
      (product) => product.id === elementId
    );
    const productsCopy = [...dataVariables];

    productsCopy[rowIndex].onlyReadable = !productsCopy[rowIndex].onlyReadable;

    setDataVariables(productsCopy);
  };

  const deleteVariable = (elementId) => {
    let rowIndex = dataVariables.findIndex(
      (product) => product.id === elementId
    );
    const productsCopy = [...dataVariables];

    productsCopy.splice(rowIndex, 1);

    setDataVariables(productsCopy);
  };

  const rows = useMemo(
    () =>
      dataVariables.map((product) => {
        return {
          productId: product.id,
          onlyReadable: product.onlyReadable,
          variable: product.variable,
          type: product.type,
          amount: product.amount,
          actions: (
            <Stack direction="horizontal" style={{ paddingRight: "60px" }}>
              <span
                className={`text-light ms-auto products-btn ${
                  !product.onlyReadable ? "products-active-edition" : ""
                }`}
              >
                <DarkPencilIcon onClick={() => editVariable(product.id)} />
              </span>
              {/* <span className="text-light products-btn">
                <DarkTrashCanIcon onClick={() => deleteVariable(product.id)} />
              </span> */}
            </Stack>
          ),
        };
      }),
    [dataVariables]
  );

  return (
    // <Form>
    <Card border="light" className="mt-4">
      <Card.Body>
        <Table
          className="bg-light mb-0 p-4 products-table"
          responsive
          style={{ width: "400px !important" }}
        >
          <TableHeader headers={headers} />
          <tbody className="p-4">
            <FieldArray
              name="data"
              render={(arrayHelpers) =>
                rows.map((row, index) => {
                  return (
                    <tr key={row.id}>
                      <td>
                        <Form.Group>
                          <EditText
                            readonly={row.onlyReadable}
                            //name={`variableData[${index}]`}
                            name={`data[${index}].description`}
                            onChange={handleChange}
                            defaultValue={row.variable}
                            // value={values.variableData[index]}
                            value={values.data[index]?.description}
                            className="products-container"
                            inputClassName="bg-info border-0 shadow-none form-control is-invalid products-input"
                            onSave={({ value, previousValue }) => {
                              if (value === "") {
                                setFieldValue(
                                  `data[${index}].description`,
                                  previousValue
                                );
                              }
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.data
                              ? errors.data[index]?.description
                              : null}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </td>
                      <td style={{ paddingLeft: "8px" }}>
                        <Form.Group>
                          <EditText
                            type="text"
                            readonly={row.onlyReadable}
                            // name={`valuesData[${index}]`}
                            name={`data[${index}].value`.toString()}
                            onChange={handleChange}
                            defaultValue={row.amount.toString()}
                            //value={values.valuesData[index]}
                            value={values.data[index]?.value.toString()}
                            className="products-container"
                            inputClassName="bg-info border-0 shadow-none form-control is-invalid products-input"
                            onSave={({ value, previousValue }) => {
                              if (value === "") {
                                setFieldValue(
                                  `data[${index}].value`,
                                  previousValue
                                );
                              }
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.data ? errors.data[index]?.value : null}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </td>
                      <td>{row.actions}</td>
                    </tr>
                  );
                })
              }
            ></FieldArray>
          </tbody>
        </Table>
      </Card.Body>
    </Card>

    //   {/* {id ? (
    //     ""
    //   ) : (
    //     <Stack direction="horizontal" gap={3} className="mb-2 mt-3">
    //       <Link to="/commerce" className="ms-auto">
    //         <Button variant="info" className="rounded-pill">
    //           Cancelar
    //         </Button>
    //       </Link>
    //       <Button variant="primary" className="rounded-pill" type="submit">
    //         Agregar
    //       </Button>
    //     </Stack>
    //   )}
    // </Form> */}
  );
};

export default ProductValues;
