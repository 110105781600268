import { TableBody, TableBodyCell, TableBodyRow } from "mui-datatables";
import { withStyles } from "tss-react/mui";
import {
  CircularProgress,
  Typography,
  TableBody as MuiTableBody,
} from "@mui/material";

const defaultStyles = {
  loading: {
    textAlign: "center",
  },
};

const CustomBodyWithSpinner = ({
  classes,
  loading,
  options,
  columns,
  ...othersProps
}) => {
  const visibleColCnt = columns.filter(
    (datum) => datum.display === "true"
  ).length;
  //const classes = useStyles();
  return loading ? (
    <MuiTableBody>
      <TableBodyRow options={options}>
        <TableBodyCell
          colSpan={
            options.selectableRows !== "none" || options.expandableRows
              ? visibleColCnt + 1
              : visibleColCnt
          }
          options={options}
          colIndex={0}
          rowIndex={0}
        >
          <Typography
            variant="body1"
            className={classes.loading} // Use classes.loading here
            component={"div"}
          >
            <CircularProgress />
          </Typography>
        </TableBodyCell>
      </TableBodyRow>
    </MuiTableBody>
  ) : (
    <TableBody options={options} columns={columns} {...othersProps} />
  );
};

export default withStyles(CustomBodyWithSpinner, defaultStyles, {
  name: "CustomBodyWithSpinner",
});
