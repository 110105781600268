import { Card, Stack } from "react-bootstrap";
import { Table, Image, Button } from "react-bootstrap";

import TableHeader from "../table/table-header";
import SelectAudienceFilters from "./select-audience-filters";
import TableBodyHover from "../table/table-body-hover";
import { useState, useMemo } from "react";
import { useEffect } from "react";

import ReactPaginate from "react-paginate";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import ItemsControl from "../items-control/items-control";

import { searchTable } from "./../../custom hooks/search-table/search-table";

import { sortSelectPromoTable } from "../../custom hooks/sort-table/sort-select-promotion-table";

const SelectAudience = ({ promos }) => {
  const [audiences, setAudiences] = useState([
    {
      id: 1,
      name: "Triatletas +35",
      sports: "Triatlon",
      interests: "Restaurante",
      team: "Fiebres",
    },
    {
      id: 2,
      name: "Cilismo hombres +22",
      sports: "Ciclismo de montaña, Ciclismo de ruta",
      interests: "Comidas, suplementos",
      team: "Wellness",
    },
    {
      id: 3,
      name: "Ciclismo mujeres +26",
      sports: "Ciclismo de montaña, Ciclismo de ruta",
      interests: "Marcas, comida, suplementos",
      team: "Wellness",
    },
  ]);
  const [searchField, setSearchField] = useState("");
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [filteredPromos, setFilteredPromos] = useState([]);

  const headers = [
    { name: "Nombre", accessor: "name" },
    { name: "Deportes", accessor: "sports" },
    { name: "Intereses", accessor: "interests" },
    { name: "Team", accessor: "team" },
  ];

  const newAudienceList = useMemo(
    () =>
      audiences.map((audience) => ({
        id: audience.id,
        name: audience.name ? (
          <Stack direction="horizontal" gap={2}>
            <small
              className={`${
                audience.selected ? "bg-primary" : "bg-info"
              } d-inline-block rounded-circle me-3`}
              style={{ width: "15px", height: "15px" }}
              id={audience.id}
            ></small>
            <Image src={""} width="0px" height="0px" roundedCircle />
            <span>{audience.name}</span>{" "}
          </Stack>
        ) : (
          ""
        ),
        sports: audience.sports,
        interests: audience.interests,
        team: audience.team,
      })),
    [audiences]
  );

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + Number(itemsPerPage);
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(newAudienceList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(newAudienceList.length / Number(itemsPerPage)));
  }, [itemOffset, newAudienceList, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % newAudienceList.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  // const sortTableElements = (sortingMethod) => {
  //   const sortedItems = sortSelectPromoTable(currentItems, sortingMethod);
  //   setCurrentItems(sortedItems);
  // };

  const dropdownList = [
    {
      valuesList: [
        { name: "Más recientes" },
        { name: "Más antiguos" },
        { name: "Orden alfabetico" },
      ],
      label: "",
      filterMethod: console.log(),
      //filterMethod: sortTableElements,
    },
  ];

  //const options = [{ text: "" }];

  // useEffect(() => {
  //   setAudiences(promos);
  //   setFilteredPromos(promos);
  // }, [promos]);

  const cleanSelectedAudience = (id) => {
    const cleanArray = audiences.map((audience) => ({
      ...audience,
      selected: false,
    }));

    const index = cleanArray.findIndex((audience) => audience.id === id);

    cleanArray[index].selected = !cleanArray[index].selected;

    setAudiences(cleanArray);
  };

  const rows = searchTable(currentItems, headers, searchField);

  return (
    // <Card className="border-light mx-auto p-2 mt-n4">
    <>
      <SelectAudienceFilters
        //options={options}
        dropdowns={dropdownList}
        searchRows={setSearchField}
        barWidth="100%"
        //handleRefresh={() => getMembers()}
      />
      <Table className="bg-light mb-0 p-4">
        <TableHeader headers={headers} />
        <TableBodyHover
          rows={rows}
          columns={headers}
          searchField={searchField}
          clickMethod={cleanSelectedAudience}
          //fixedColumnHeight={50}
        />
      </Table>

      <Stack direction="horizontal" className="mt-4">
        <ReactPaginate
          breakLabel="..."
          nextLabel={<IoIosArrowForward />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={<IoIosArrowBack />}
          renderOnZeroPageCount={null}
          containerClassName="pagination pagination-modifier"
          pageClassName="page-item"
          previousClassName="previus-button"
          nextClassName="next-button"
          pageLinkClassName="page-link page-link-modifier"
          activeClassName="active"
        />

        <Button variant="primary" className="fw-bold ms-auto rounded-pills">
          Siguiente <IoIosArrowForward />
        </Button>
      </Stack>
    </>
    // </Card>
  );
};

export default SelectAudience;
