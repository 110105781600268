import { Grid, Paper, Divider } from "@mui/material";
import { ReactComponent as UserGroup } from "./../../assests/userGroup.svg";
import { ReactComponent as Phone } from "./../../assests/phone.svg";
import { ReactComponent as CheckedUser } from "./../../assests/checkedUser.svg";
import { countActiveUsers, countUsers } from "../../firebase/firebase.utils";
import { useEffect, useState } from "react";
import Overview from "../overview/overview";
import { useSelector } from "react-redux";
import { MdPerson, MdPerson2, MdBusiness } from "react-icons/md";

const SummaryCard = () => {
  const [usersNumber, setUsersNumber] = useState(0);
  const [activeUsersNum, setActiveUsersNum] = useState(0);

  const { users } = useSelector((state) => state.user);
  useEffect(() => {
    const count = async () => {
      await countUsers(setUsersNumber);
      await countActiveUsers(setActiveUsersNum);
    };

    count();
  }, []);

  const totalUsers = {
    title: "Total de usuarios",
    totalNumber: usersNumber,
    statistics: "16%",
    reference: "este mes",
    color: true,
  };

  const totalCustomers = {
    title: "Clientes",
    totalNumber: activeUsersNum,
    statistics: "10%",
    reference: "este mes",
    color: true,
  };

  const connectedUsers = {
    title: "Conectados",
    totalNumber: 189,
    statistics: "5%",
    reference: "de usuarios",
    color: true,
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Overview
            data={totalUsers}
            icon={
              <MdPerson
                style={{
                  width: "25px",
                  height: "25px",

                  color: "white",
                }}
              />
            }
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Overview
            data={totalCustomers}
            icon={
              <MdPerson2
                style={{
                  width: "25px",
                  height: "25px",

                  color: "white",
                }}
              />
            }
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Overview
            data={connectedUsers}
            icon={
              <MdPerson
                style={{
                  width: "25px",
                  height: "25px",

                  color: "white",
                }}
              />
            }
          />
        </Grid>

        {/* <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Overview
            data={totalCustomers}
            icon={
              <MdPerson
                style={{
                  width: "25px",
                  height: "25px",

                  color: "white",
                }}
              />
            }
          />
        </Grid> */}
      </Grid>
    </Paper>
  );
};

export default SummaryCard;
