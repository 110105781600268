import React, { useEffect, useRef } from "react";
import {
  CardContent,
  CardMedia,
  IconButton,
  useTheme,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import fallback from "../../assests/imgDrop.png";

const UploadImage = ({
  setImageUpload,
  imageUpload,
  setImage,
  image,
  inputId,
}) => {
  const theme = useTheme();
  const fileInputRef = useRef(null);

  // useEffect(() => {
  //   const uploadImage = async () => {
  //     setImageUpload(imageUpload);
  //   };

  //   if (imageUpload === null) {
  //     return;
  //   } else {
  //     uploadImage();
  //   }
  // }, [imageUpload]);

  const removeImage = () => {
    setImageUpload([]);
    setImage([]);
  };

  console.log(image);

  const handleClickImage = (event) => {
    // Prevent triggering the file input's click event
    event.preventDefault();

    // Trigger the click event on the hidden file input
    fileInputRef.current.click();
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        elevation: 1,
        // width: "100px",
        // height: "100px",
      }}
    >
      <CardContent>
        {image?.map((file, index) => (
          // {imageUpload.map((file, index) => (
          <Box
            key={index}
            sx={{ position: "relative", display: "inline-block" }}
          >
            <IconButton
              size="small"
              onClick={() => removeImage(index)}
              aria-label="Remove Image"
              sx={{
                position: "absolute",
                right: -8,
                top: -8,
                backgroundColor: theme.palette.primary.main,
                zIndex: 1,
              }}
            >
              <CloseIcon fontSize="small" sx={{ color: "white" }} />
            </IconButton>
            <label htmlFor={`file-input-${index}`}>
              <CardMedia
                component="img"
                alt="Uploaded Image"
                //image={URL.createObjectURL(file)}
                image={file}
                sx={{
                  objectFit: "cover",
                  width: "100px",
                  height: "100px",
                  cursor: "pointer",
                  borderRadius: "18px",
                  marginRight: "10px",
                }}
                onClick={handleClickImage}
              />
            </label>
          </Box>
        ))}
        <label htmlFor="file-input">
          <CardMedia
            component="img"
            alt="Uploaded Image"
            image={fallback}
            sx={{
              objectFit: "cover",
              width: "100px",
              height: "100px",
              cursor: "pointer",
              borderRadius: "18px",
            }}
            onClick={handleClickImage}
          />
        </label>
        <input
          ref={fileInputRef}
          type="file"
          name="sponsor"
          id="file-input"
          style={{ display: "none" }}
          multiple
          onChange={(event) => {
            //setImageUpload(event.target.files[0]);
            //setImage(URL.createObjectURL(event.target.files[0]));

            const files = Array.from(event.target.files);
            setImageUpload(files);
            setImage(files.map((file) => URL.createObjectURL(file)));
          }}
        />
      </CardContent>
    </Box>
  );
};

export default UploadImage;
