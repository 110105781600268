export const types = {
  getUsers: "[Users] GetUsers",
  getB2BUsers: "[B2bUsers] GetB2bUsers",
  isLoadingUsers: "[Users] IsLoadingUsers",
  addUsers: "[POST] Users",
  removeUsers: "[DELETE] Users",
  refreshUsers: "[POST] Users",
  addWallets: "[POST] Wallets",
  removeWallets: "[DELETE] Wallets",
  addPrivateData: "[DELETE] PrivateData",
  removePrivateData: "[DELETE] PrivateData",
};
