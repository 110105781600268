import { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";

import {
  addAdminHistoryEntry,
  deactivateSponsor,
  deleteSponsor,
  getSponsor,
  getSponsorAdminHistory,
  updateSponsor,
} from "../../firebase/firebase.utils";

import darkLeftArrow from "./../../assests/darkLeftArrow.png";

import AdminHistory from "../../components/adminHistory/AdminHistory";

import { Container, Col, Row } from "react-bootstrap";
import { Stack, Button } from "react-bootstrap";

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import Swal from "sweetalert2";

import BusinessOverview from "../../components/business-overview/business-overview";
import SponsorExchange from "../../components/sponsor-exchange/sponsor-exchange";
import SponsorPromo from "../../components/sponsor-promo/sponsor-promo";
import Requests from "../../components/requests/requests";
import CustomModal from "../../components/modal/customModal";
import SocialMediaEdition from "../../components/social-media-edition/social-media-edition";
import { useSelector } from "react-redux";

const Sponsor = () => {
  const { id } = useParams();
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [showSocialMediaEdition, setShowSocialMediaEdition] = useState(false);

  const { user: currentAdmin } = useSelector((state) => state.auth);

  useEffect(() => {
    const getSingleSponsor = async () => {
      getSponsor(id).onSnapshot((business) =>
        setSelectedBusiness(business.data())
      );
    };

    getSingleSponsor();
  }, [id]);

  if (!selectedBusiness) {
    return;
  }

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-primary me-2",
      cancelButton: "btn btn-secondary",
      denyButton: "btn btn-danger me-2",
    },
    buttonsStyling: false,
  });

  const callOmitSponsor = () => {
    swalWithBootstrapButtons
      .fire({
        title: "Confirme su elección",
        showDenyButton: true,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Desactivar",
        confirmButtonColor: "#FAA60F",
        denyButtonText: "Eliminar",
      })
      .then((result) => {
        if (result.isConfirmed) {
          const adminHistory = {
            modificationDate: new Date(),
            admin: {
              id: currentAdmin.uid,
              name: currentAdmin.displayName,
              email: currentAdmin.email,
            },
            modifications: [
              { field: "isActive", newValue: false, oldValue: true },
            ],
          };

          deactivateSponsor(id);
          updateSponsor(id, { isActive: false });
          addAdminHistoryEntry(adminHistory, `sponsor/${id}/adminHistory`);
          Swal.fire("Sponsor desactivado", "", "success");
        } else if (result.isDenied) {
          deleteSponsor(id);
          Swal.fire("Sponsor eliminado", "", "success");
        }
      });
  };

  const callIncludeSponsor = () => {
    Swal.fire({
      title: "Confirme su elección",
      showDenyButton: true,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Activar",
      confirmButtonColor: "#FAA60F",
      denyButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        const adminHistory = {
          modificationDate: new Date(),
          admin: {
            id: currentAdmin.uid,
            name: currentAdmin.displayName,
            email: currentAdmin.email,
          },
          modifications: [
            { field: "isActive", newValue: true, oldValue: false },
          ],
        };

        updateSponsor(id, { isActive: true });
        addAdminHistoryEntry(adminHistory, `sponsor/${id}/adminHistory`);
        Swal.fire("Sponsor activado", "", "success");
      } else if (result.isDenied) {
        deleteSponsor(id);
        Swal.fire("Sponsor eliminado", "", "success");
      }
    });
  };

  return (
    <Container>
      <Stack direction="horizontal" className="w-100 mb-4">
        <Link
          to="/sponsors"
          className="w-25 d-block link-dark text-decoration-none"
        >
          <h1 className="h5 mb-0">
            <span className="d-inline-block" style={{ width: "14px" }}>
              <img
                src={darkLeftArrow}
                alt="Black arrow icon"
                className="w-100"
              />
            </span>
            Atrás
          </h1>
        </Link>
        {selectedBusiness.isActive ? (
          <Button
            variant="link"
            className="text-dark ms-auto"
            onClick={callOmitSponsor}
          >
            Eliminar/Desactivar
          </Button>
        ) : (
          <Button
            variant="link"
            className="text-dark ms-auto"
            onClick={callIncludeSponsor}
          >
            Eliminar/Activar
          </Button>
        )}
      </Stack>
      <BusinessOverview
        business={selectedBusiness}
        id={id}
        openModal={setShowSocialMediaEdition}
      />
      <div style={{ marginTop: "30px" }}>
        <ResponsiveMasonry columnsCountBreakPoints={{ 800: 1, 900: 2 }}>
          <Masonry gutter="30px">
            <SponsorExchange business={selectedBusiness} id={id} />
            <SponsorPromo business={selectedBusiness} id={id} />
            <Requests business={selectedBusiness} id={id} />
            <AdminHistory id={id} getAdminHistory={getSponsorAdminHistory} />
          </Masonry>
        </ResponsiveMasonry>
      </div>
      {/* <Row>
        <Col>
          <SponsorExchange business={selectedBusiness} id={id} />
        </Col>
        <Col>
          <SponsorPromo business={selectedBusiness} id={id} />
        </Col>
        <Col lg="6">
          <Requests business={selectedBusiness} id={id} />
        </Col>
        <Col>
          <AdminHistory id={id} getAdminHistory={getSponsorAdminHistory} />
        </Col>
      </Row> */}
      <CustomModal
        show={showSocialMediaEdition}
        handleClose={setShowSocialMediaEdition}
        modalTitle={() => "Editar Redes Sociales"}
      >
        <SocialMediaEdition
          sponsorId={id}
          business={selectedBusiness}
          onClose={setShowSocialMediaEdition}
        />
      </CustomModal>
    </Container>
  );
};

export default Sponsor;
