import { types } from "../types/types";

export const getInsurers = () => ({
  type: types.insurers,
});

export const deleteInsurers = () => ({
  type: types.removeInsurers,
});

export const addInsurers = (insurers) => ({
  type: types.addInsurers,
  payload: insurers,
});
