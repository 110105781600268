import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import UploadBannerImage from "./UploadBannerImage";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Close } from "@mui/icons-material";
import { styles } from "../../styles/styles";
import {
  createHomeCarouselBanner,
  createHomeCarouselImage,
} from "../../redux/banners/actions/actions";

const MySwal = withReactContent(Swal);

const AddHomeCarouselBanner = ({ open, onClose, handleRefresh }) => {
  const theme = useTheme();

  const validationSchema = Yup.object().shape({
    // Define validation rules for your form fields
  });

  const handleClose = () => {
    setImageUpload("");
    setImage("");

    onClose();
  };

  const [imageUpload, setImageUpload] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const routes = [
    { id: 1, value: "Sponsor", name: "Sponsor" },
    { id: 1, value: "Commerce", name: "Comercio" },
    { id: 1, value: "Exchange", name: "Canjes" },
  ];

  const formik = useFormik({
    initialValues: {
      link: "",
      imageURL: "",
      isWebView: false,
      orderNumber: 0,
      isWebOnly: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const newData = {
        link: values.link,
        isWebView: values.isWebView,
        imageURL: values.imageURL,
        orderNumber: values.orderNumber,
        isWebOnly: values.isWebOnly,
      };

      const confirmResult = await MySwal.fire({
        title: "Confirmar adición",
        text: "¿Estás seguro de que deseas agregar este banner?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, agregar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });
      console.log(imageUpload);
      if (confirmResult.isConfirmed) {
        setLoading(true);
        const loadingSwal = MySwal.fire({
          title: "Guardando...",
          html: '<div class="swal2-spinner"></div>',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            MySwal.showLoading();
          },
        });
        try {
          const id = await createHomeCarouselBanner(newData);
          await createHomeCarouselImage(imageUpload, id);

          setTimeout(() => {
            setLoading(false);
            resetForm();
            handleRefresh();
            onClose();
          }, 2000);

          loadingSwal.close();
          MySwal.fire("Guardado!", "", "success");
        } catch (error) {
          MySwal.fire("Error", "Hubo un error al agregar el banner", "error");
        }

        onClose();
        setTimeout(() => {
          setLoading(false);
          setImageUpload("");
          setImage("");
          handleRefresh();
          onClose();
          loadingSwal.close();
        }, 1000);
      }
    },
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          width: "100%",
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle>
        Agregar Banner de Carrusel
        <Box sx={styles.closeButton} onClick={onClose}>
          <Close />
        </Box>
      </DialogTitle>
      <DialogContent>
        <form>
          <Box mt={2}>
            <Typography>General</Typography>
          </Box>
          <Grid container>
            <Grid item md={12}>
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="link"
                name="link"
                label="Enlace"
                value={formik.values.link}
                onChange={formik.handleChange}
                error={formik.touched.link && Boolean(formik.errors.link)}
                helperText={formik.touched.link && formik.errors.link}
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
            mt={2}
          >
            <Grid item md={5}>
              <TextField
                variant="standard"
                fullWidth
                type="number"
                id="item"
                name="orderNumber"
                label="Numero Orden"
                value={formik.values.orderNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.orderNumber &&
                  Boolean(formik.errors.orderNumber)
                }
                helperText={
                  formik.touched.orderNumber && formik.errors.orderNumber
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
            mt={2}
          >
            <Grid item md={5}>
              <FormControl
                component="fieldset"
                sx={{
                  marginLeft: "0px !important",
                  flexDirection: "row",
                  marginBottom: "15px",
                }}
              >
                <FormControlLabel
                  sx={{ marginLeft: "0px !important", flexDirection: "row" }}
                  value="isWebView"
                  label="WebView"
                  labelPlacement="start"
                  control={
                    <Checkbox
                      name="isWebView"
                      checked={formik.values.isWebOnly}
                      onChange={formik.handleChange}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                />
              </FormControl>
            </Grid>
            <Grid item md={5}>
              <FormControl
                component="fieldset"
                sx={{
                  marginLeft: "0px !important",
                  flexDirection: "row",
                  marginBottom: "15px",
                }}
              >
                <FormControlLabel
                  sx={{ marginLeft: "0px !important", flexDirection: "row" }}
                  value="isWebOnly"
                  label="Solo Website"
                  labelPlacement="start"
                  control={
                    <Checkbox
                      name="isWebOnly"
                      checked={formik.values.isWebOnly}
                      onChange={formik.handleChange}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                />
              </FormControl>
            </Grid>
          </Grid>

          <UploadBannerImage
            title={"Banner"}
            loadUrl={setFileUrl}
            imageUpload={imageUpload}
            setImageUpload={setImageUpload}
            setImage={setImage}
            image={image}
          />

          <DialogActions>
            <Button variant="contained" color="error" onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              onClick={formik.handleSubmit}
              variant="contained"
              disabled={loading}
            >
              {loading ? "Guardando..." : "Agregar"}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddHomeCarouselBanner;
