import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import {
  createBannerImage,
  updatePromoBanner,
} from "../../redux/banners/actions/actions";

import UploadBannerImage from "./UploadBannerImage";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FormInput from "../form-input/form-input";
import { Close } from "@mui/icons-material";
import { styles } from "../../styles/styles";
import { FormControl } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { Checkbox } from "@mui/material";
import { useSelector } from "react-redux";
import { addAdminHistoryEntry } from "../../firebase/firebase.utils";
import { getChangedFields } from "../../utils/getChangeFields";

const MySwal = withReactContent(Swal);
const EditPromoBanner = ({ open, onClose, data, handleRefresh }) => {
  const validationSchema = Yup.object().shape({
    businessName: Yup.string().required("Business Name is required"),
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
    // Add validation for other fields as needed
  });

  const [imageUpload, setImageUpload] = useState("");
  const [image, setImage] = useState(data.imageURL);
  const [fileUrl, setFileUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const { user: currentAdmin } = useSelector((state) => state.auth);

  useEffect(() => {
    // Update formik initial values and the image state when data prop changes
    formik.setValues({
      businessName: data.businessName || "",
      name: data.name || "",
      description: data.description || "",
      restrictions: data.restrictions || "",
      imageURL: data.imageURL || "",
      dueDate: data.dueDate
        ? new Date(data.dueDate.toDate()).toISOString().slice(0, 10)
        : new Date().toISOString().slice(0, 10),

      itemNumber: data.itemNumber || 0,
      isActive: data.isActive ?? false,
    });

    // Update the image state with the initial data.imageURL
    setImage(data.imageURL || "");
  }, [data]);

  const initialData = {
    businessName: data.businessName || "",
    name: data.name ?? "",
    description: data.description ?? "",
    restrictions: data.restrictions ?? "",
    imageURL: data.imageURL ?? "",
    dueDate: data.dueDate ?? new Date(),
    itemNumber: data.itemNumber ?? 0,
    isActive: data.isActive ?? false,
  };

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const dueDate = new Date(values.dueDate);

      const fieldsChanged = getChangedFields(values, initialData);

      const updatedData = {
        businessName: values.businessName,
        name: values.name,
        description: values.description,
        restrictions: values.restrictions,
        imageURL: values.imageURL,
        dueDate: dueDate,
        cdate: data.cdate,
        itemNumber: values.itemNumber,
        isActive: values.isActive,
      };

      const adminHistory = {
        modificationDate: new Date(),
        admin: {
          id: currentAdmin.uid,
          name: currentAdmin.displayName,
          email: currentAdmin.email,
        },
        modifications: fieldsChanged,
      };

      // Show the confirmation prompt using Swal
      const confirmResult = await MySwal.fire({
        title: "Confirmar edición",
        text: "¿Estás seguro de que deseas editar este banner?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, editar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });

      if (confirmResult.isConfirmed) {
        const loadingSwal = MySwal.fire({
          title: "Guardando...",

          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            MySwal.showLoading();
          },
        });
        try {
          if (imageUpload !== "") {
            createBannerImage(imageUpload, data.id);
          }

          await updatePromoBanner(data.id, updatedData);
          await addAdminHistoryEntry(
            adminHistory,
            `banners/exchange/promotionCarousel/${data.id}/adminHistory`
          );

          console.log(values.imageURL);
          // console.log(values);
          loadingSwal.close();
          MySwal.fire("Guardado!", "", "success");
        } catch (error) {
          MySwal.fire("Error", "Hubo un error al editar el banner", "error");
        }

        onClose();
        setTimeout(() => {
          setLoading(false);
          setImageUpload("");

          handleRefresh();
          onClose();
          loadingSwal.close();
        }, 1000);
      }
    },
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          // backgroundColor: theme.palette.secondary.secondary,
          width: "100%",
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle>
        Editar Banner de Publicidad{" "}
        <Box sx={styles.closeButton} onClick={onClose}>
          <Close />
        </Box>
      </DialogTitle>
      <DialogContent>
        <form>
          <TextField
            margin="dense"
            variant="standard"
            fullWidth
            id="businessName"
            name="businessName"
            label="Nombre Negocio"
            value={formik.values.businessName}
            onChange={formik.handleChange}
            error={
              formik.touched.businessName && Boolean(formik.errors.businessName)
            }
            helperText={
              formik.touched.businessName && formik.errors.businessName
            }
          />

          <TextField
            margin="dense"
            variant="standard"
            fullWidth
            id="name"
            name="name"
            label="Nombre"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            margin="dense"
            variant="standard"
            fullWidth
            id="description"
            name="description"
            label="Descripción"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />
          <TextField
            margin="dense"
            variant="standard"
            fullWidth
            id="restrictions"
            name="restrictions"
            label="Restricciones"
            value={formik.values.restrictions}
            onChange={formik.handleChange}
            error={
              formik.touched.restrictions && Boolean(formik.errors.restrictions)
            }
            helperText={
              formik.touched.restrictions && formik.errors.restrictions
            }
          />
          <TextField
            margin="dense"
            variant="standard"
            fullWidth
            type="number"
            id="item"
            name="itemNumber"
            label="Numero"
            value={formik.values.itemNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.itemNumber && Boolean(formik.errors.itemNumber)
            }
            helperText={formik.touched.itemNumber && formik.errors.itemNumber}
          />
          <FormInput
            label={"Válido hasta"}
            onChange={formik.handleChange}
            value={formik.values.dueDate}
            type="date"
            name="dueDate"
            placeholder={formik.values.dueDate}
            errorList={formik.errors} // Pass the formik.errors as the errorList prop if you are using it in FormInput
          />
          <FormControl
            component="fieldset"
            sx={{
              marginLeft: "0px !important",
              flexDirection: "row",
              marginBottom: "15px",
            }}
          >
            <FormControlLabel
              sx={{ marginLeft: "0px !important", flexDirection: "row" }}
              value="isActive"
              label="Activo"
              labelPlacement="start"
              control={
                <Checkbox
                  name="isActive"
                  checked={formik.values.isActive}
                  onChange={formik.handleChange}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
            />
          </FormControl>

          <UploadBannerImage
            title={"Banner"}
            loadUrl={setFileUrl}
            imageUpload={imageUpload}
            setImageUpload={setImageUpload}
            setImage={setImage}
            image={image}
          />

          <DialogActions>
            <Button variant="contained" color="error" onClick={onClose}>
              Cancelar
            </Button>
            <Button
              onClick={formik.handleSubmit}
              variant="contained"
              disabled={loading}
            >
              {loading ? "Guardando..." : "Guardar"}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditPromoBanner;
