import React, { useMemo, useRef, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Box, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomBodyWithSpinner from "../general/CustomBodyWithSpinner";

import { useNavigate } from "react-router-dom";
import { IoRefreshOutline } from "react-icons/io5";
import { IconButton } from "@mui/material";
import { MdArrowOutward } from "react-icons/md";
import BlogModal from "./BlogModal";

export const BlogsTable = ({
  refresh,
  loading,
  setloading,
  blogsFromDb,
  setBlogsFromDB,
  noDataAvailable,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { blogs } = useSelector((state) => state.blogs);

  const isFirstUse = useRef(true);

  const [open, setOpen] = useState(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [selected, setSelected] = useState(null);

  const BodyComponent = useMemo(
    () => (tableProps) => {
      return <CustomBodyWithSpinner loading={loading} {...tableProps} />;
    },
    [loading, noDataAvailable, blogsFromDb]
  );
  const handleRefresh = () => {
    setloading(true);
    refresh();
    setTimeout(() => {
      setloading(false);
    }, 1000);
  };

  const handleOpenRequest = (itemId) => {
    navigate(`/blogs/${itemId}`);
  };

  const handleOpenEditModal = (selectedItem) => {
    const id = selectedItem.id;

    const bannerData = blogsFromDb.find((item) => item.id === id);
    setSelectedData(bannerData);
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedData([]);
    setIsEditModalOpen(false);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "name",
      label: "Nombre",
      options: {
        customBodyRender: (value) => {
          return value ? value : "N/A";
        },
        filter: false,
      },
    },
    {
      name: "imageURL",
      label: "Imagen",
      options: {
        customBodyRender: (value) => {
          return value ? (
            <img
              src={value}
              alt="Banner"
              style={{
                width: "100px",
                height: "50px",
                borderRadius: "4px",
                objectFit: "contain",
              }}
            />
          ) : (
            "N/A"
          );
        },
        filter: false,
      },
    },

    {
      name: "pendingPostsCount",
      label: "Pendientes",
      options: {
        customBodyRender: (value) => {
          return value ? value : 0;
        },
        filter: false,
      },
    },
    {
      name: "acceptedPostsCount",
      label: "Aceptados",
      options: {
        customBodyRender: (value) => {
          return value ? value : 0;
        },
        filter: false,
      },
    },
    {
      name: "rejectedPostsCount",
      label: "Rechazados",
      options: {
        customBodyRender: (value) => {
          return value ? value : 0;
        },
        filter: false,
      },
    },

    {
      name: "viewMore",
      label: "Ver Más",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = tableMeta.rowData;
          const selectItem = blogs.find((item) => item.id === rowData[0]);
          return (
            <IconButton
              onClick={() => handleOpenRequest(selectItem.name)}
              size="small"
              sx={{
                backgroundColor: theme.palette.primary.main,
                width: "32px",
                height: "32px",
                color: "white",
              }}
            >
              <MdArrowOutward />
            </IconButton>
          );
        },
        filter: false,
        sort: false,
      },
    },

    {
      name: "edit",
      label: "Editar",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = tableMeta.rowData;
          const selectItem = blogs.find((item) => item.id === rowData[0]);
          return (
            <IconButton
              onClick={() => handleOpenEditModal(selectItem)}
              size="small"
              sx={{
                backgroundColor: theme.palette.primary.main,
                width: "32px",
                height: "32px",
                color: "white",
              }}
            >
              <MdArrowOutward />
            </IconButton>
          );
        },
        filter: false,
        sort: false,
      },
    },
  ];
  const CustomToolbar = ({ onRefresh }) => {
    return (
      <IconButton className="refresh-button" onClick={onRefresh}>
        <IoRefreshOutline size={25} />
      </IconButton>
    );
  };
  const options = {
    filter: true,
    responsive: "standard",
    selectableRows: "none",
    rowsPerPage: 15,
    onTableChange: () => setBlogsFromDB(blogs),
    sortOrder: {
      name: "name",
      direction: "asc",
    },
    textLabels: {
      body: {
        noMatch: "No hay datos disponibles",
      },
    },
    sortCompare: (order) => (a, b) => {
      const isAsc = order === "asc";

      const column = "name";

      const aValue =
        a.data[column] !== undefined &&
        a.data[column] !== null &&
        a.data[column].trim() !== ""
          ? a.data[column]
          : "N/A";
      const bValue =
        b.data[column] !== undefined &&
        b.data[column] !== null &&
        b.data[column].trim() !== ""
          ? b.data[column]
          : "N/A";

      // Use default comparison for other cases
      if (isAsc) {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    },

    // onRowClick: (rowData) => {
    //   const clickedOwnerId = rowData[0];

    //   handleSponsorSelect(clickedOwnerId);
    // },
    customToolbar: () => {
      return <CustomToolbar onRefresh={handleRefresh} />;
    },
  };

  return (
    <Box mt={6} sx={{ width: "100%" }}>
      <MUIDataTable
        title="Blogs"
        data={blogsFromDb}
        columns={columns}
        options={options}
        components={{ TableBody: BodyComponent }}
      />
      {selectedData && (
        <BlogModal
          open={isEditModalOpen}
          onClose={handleCloseEditModal}
          data={selectedData}
          handleRefresh={handleRefresh}
          mode={"edit"}
        />
      )}
    </Box>
  );
};
