import { useEffect, useState } from "react";
import { Image, Col, Row, Card, Stack } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import ProgressBar from "react-bootstrap/ProgressBar";

import {
  getAllDonors,
  updateCause,
  uploadFile,
} from "../../firebase/firebase.utils";

import { Link } from "react-router-dom";

import causebg from "./../../assests/cause-bg.jpg";
import darkPlus from "./../../assests/darkPlus.png";

const CauseOverview = ({ selectedCause, causeId, organizationId }) => {
  const [donors, setDonors] = useState([]);
  const [progress, setProgress] = useState(0);
  const [totalCoins, setTotalCoins] = useState(0);
  const [imageUpload, setImageUpload] = useState(null);

  useEffect(() => {
    const getDonors = async () => {
      const donorByCause = await getAllDonors(organizationId, causeId);
      let total = 0;

      if (donorByCause[0]) {
        setDonors(donorByCause);

        for (const donor of donorByCause) {
          total = total + donor.coinsDonated;
        }
        let progress = (total * 100) / selectedCause.coinsMeta;
        if (progress > 100) {
          progress = 100;
        }
        setProgress(progress);
        setTotalCoins(total);
      }
    };

    getDonors();
  }, [causeId, organizationId, selectedCause.coinsMeta]);

  useEffect(() => {
    const uploadCauseImage = async () => {
      const path = `cause/${causeId}/banner`;
      const fileUrl = await uploadFile(path, imageUpload);

      const updatesCause = { imageURL: fileUrl };

      updateCause(organizationId, causeId, updatesCause);
    };

    if (imageUpload === null) {
      return;
    } else {
      uploadCauseImage();
    }
  }, [imageUpload, causeId, organizationId]);

  const calculateIndividualDonations = () => {
    let total = 0;

    donors.forEach((donation) => {
      total += donation.coinsDonated;
    });
    return total;
  };

  if (!selectedCause) return;

  return (
    <Card body border="light" className="position-relative">
      <Row className="mb-3">
        <Col lg="7" className="border-end">
          <Row>
            <Stack direction="horizontal" gap={3}>
              <div className="position-relative sponsor-container">
                <Image
                  src={
                    selectedCause.imageURL ? selectedCause.imageURL : causebg
                  }
                  width="120"
                  height="90"
                  className="rounded"
                />
                <label htmlFor="file-input">
                  <Image
                    src={darkPlus}
                    width="15"
                    height="15"
                    style={{ top: "85%" }}
                    className="position-absolute end-0 plus-sign"
                  />
                </label>
                <input
                  type="file"
                  name="cause"
                  id="file-input"
                  className="d-none"
                  onChange={(event) => {
                    setImageUpload(event.target.files[0]);
                  }}
                />
              </div>

              <div>
                <small className="text-muted">
                  <span>
                    {selectedCause.cdate.toDate().toLocaleDateString("es-CR", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                </small>

                <h1 className="h5">{selectedCause.name}</h1>
                <Stack direction="horizontal" gap={2}>
                  <strong>{selectedCause.category}</strong>
                  {selectedCause.generalTags.map((tag) => {
                    return (
                      <div>
                        <Badge bg="secondary">{tag}</Badge>
                      </div>
                    );
                  })}
                </Stack>
              </div>
            </Stack>
          </Row>

          <Row className="mt-3">
            <Col>
              <small className="text-muted d-block">Pais</small>
              <strong className="text-muted d-block">
                {selectedCause.country}
              </strong>
            </Col>
            <Col>
              <small className="text-muted d-block">Provincia/Dep</small>
              <strong className="text-muted d-block">
                {selectedCause.province}
              </strong>
            </Col>
            <Col>
              <small className="text-muted d-block">Ciudad</small>
              <strong className="text-muted d-block">
                {selectedCause.city}
              </strong>
            </Col>
            <Col>
              <small className="text-muted d-block">Ubicacion</small>
              <Link className="text-muted d-block fw-bold">
                {selectedCause.location}
              </Link>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col className="text-muted">
              <h2 className="h6">Detalles de la causa</h2>
              <small>{selectedCause.description}</small>
            </Col>
          </Row>
        </Col>
        <Col className="border-end">
          <small className="text-muted">Avance</small>
          <Stack direction="horizontal" gap={3}>
            <Stack direction="horizontal" gap={2}>
              <span
                style={{ width: "10px", height: "10px" }}
                className="bg-primary rounded-circle d-block"
              ></span>
              <small>Recibidos</small>
            </Stack>
            <Stack direction="horizontal" gap={2}>
              <span
                style={{ background: "#E9ECEF", width: "10px", height: "10px" }}
                className="rounded-circle d-block"
              ></span>
              <small>Meta</small>
            </Stack>
          </Stack>
          <ProgressBar now={progress} variant="primary" />
          <p className="text-primary mt-3">{progress.toFixed(2) + "%"}</p>

          <small className="text-muted">Recibidos</small>
          <p className="text-primary">{totalCoins}</p>
          <small className="text-muted">Meta</small>
          <p className="fw-bold">{selectedCause.coinsMeta}</p>
        </Col>
        <Col>
          <small className="text-muted">Estado</small>
          <p style={{ color: "#AAAAAA" }}>
            {selectedCause.isActiveCause ? "Activa" : "Cerrada"}
          </p>

          <div className="mb-3">
            <small className="text-muted">Donacion individual</small>
            <p className="fw-bold mb-0">{calculateIndividualDonations()}</p>
            <small>
              <span className="text-danger">5%</span> esta semana
            </small>
          </div>

          <div>
            <small className="text-muted">Donacion de equipo</small>
            <p className="fw-bold mb-0">52.080</p>
            <small>
              <span className="text-primary">15%</span> esta semana
            </small>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default CauseOverview;
