import Form from "react-bootstrap/Form";
import { Button, Spinner, Stack } from "react-bootstrap";
import { Image } from "react-bootstrap";

import { useFormik } from "formik";
import * as Yup from "yup";

import editStartbucks from "./../../assests/editStartbucks.jpg";

import FormInput from "../form-input/form-input";
import {
  addAdminHistoryEntry,
  updatePromos,
} from "../../firebase/firebase.utils";
import { useEffect, useState } from "react";
import { getChangedFields } from "../../utils/getChangeFields";
import { useSelector } from "react-redux";
import { promotionInitialValues } from "./promotionInitialValues";
import { translations } from "./spanishFieldName";

const PromotionsEdit = ({
  business,
  selectedPromo = { imageURL: editStartbucks },
  promotionId,
  sponsorId,
  onClose,
  placeholderValues,
  buttonText,
}) => {
  const categories = [
    "Bar/Vida Nocturna",
    "Restaurante/Cafetería",
    "Tienda deportiva",
    "Outfit",
    "Servicios de salud",
    "Recreación al aire libre",
    "Instalación deportiva",
    "Entretenimiento Indoor",
    "Hospedaje",
    "Supermercado/Abarrotes",
    "Tienda de tecnología",
    "Servicios educativos",
    "Estetica/Spa/Cuidado personal",
    "Productos especializados",
    "Eventos deportivos",
    "Otro",
  ];
  const [loading, setLoading] = useState(false);
  const [initialData, setInitialData] = useState(promotionInitialValues);

  const { user: currentAdmin } = useSelector((state) => state.auth);

  useEffect(() => {
    if (placeholderValues) {
      setInitialData({
        exchangable: placeholderValues.name,
        specification: placeholderValues.description,
        restrictionsAndDetails: placeholderValues.description ?? "",
        category: placeholderValues.category ?? "",
        categories: placeholderValues.category ?? "",
        dueDate: placeholderValues.dueDate.toDate().toISOString().slice(0, 10),
      });
    }
  }, [placeholderValues]);

  const validate = Yup.object({
    exchangable: Yup.string().required("requerido"),
    specification: Yup.string().required("requerido"),
    restrictionsAndDetails: Yup.string().required("requerido"),
    // category: Yup.string().oneOf(business.category, "No existe"),
    dueDate: Yup.string().required("requerido"),
  });

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: initialData,
    validationSchema: validate,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      try {
        const [newDateYear, newDateMonth, newDateDay] = values.dueDate
          .split("-")
          .map(Number);
        const newDate = new Date(newDateYear, newDateMonth - 1, newDateDay); // Months are 0-indexed in JavaScript

        const [initialDateYear, initialDateMonth, initialDateDay] =
          initialData.dueDate.split("-").map(Number);

        const initialDate = new Date(
          initialDateYear,
          initialDateMonth - 1,
          initialDateDay
        ); // Months are 0-indexed in JavaScript

        const updates = {
          name: values.exchangable,
          description: values.specification,
          restrictions: values.restrictionsAndDetails,
          dueDate: newDate,
          category: values.category,
        };

        //dueDate coming from values and initialDate is a string, let's turn it to a Date
        const fieldsChanged = getChangedFields(
          { ...values, dueDate: newDate },
          { ...initialData, dueDate: initialDate },
          translations
        );

        const adminHistory = {
          modificationDate: new Date(),
          admin: {
            id: currentAdmin.uid,
            name: currentAdmin.displayName,
            email: currentAdmin.email,
          },
          modifications: fieldsChanged,
          modificationContext: {
            collectionName: "promotions",
            docId: promotionId,
          },
        };

        updatePromos(sponsorId, promotionId, updates);
        addAdminHistoryEntry(
          adminHistory,
          `sponsors/${sponsorId}/adminHistory`
        );

        setTimeout(() => {
          setLoading(false);
          resetForm();
          onClose(false);
        }, 2000);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const updatePromoStatus = (newStatus) => {
    const adminHistory = {
      modificationDate: new Date(),
      admin: {
        id: currentAdmin.uid,
        name: currentAdmin.displayName,
        email: currentAdmin.email,
      },
      modifications: [
        { field: "status", newValue: newStatus, oldValue: "pending" },
      ],
      modificationContext: {
        collectionName: "promotions",
        docId: promotionId,
      },
    };

    updatePromos(sponsorId, promotionId, {
      status: newStatus,
    });

    addAdminHistoryEntry(adminHistory, `sponsors/${sponsorId}/adminHistory`);
  };

  if (!promotionId && categories[0]) return;

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      noValidate
      className="p-3"
    >
      <Stack direction="horizontal" className="mb-3">
        <Image
          src={business.logo}
          roundedCircle
          className="me-2"
          width="80"
          height="80"
        />

        <div>
          <h3 className="mb-0 fw-bol">{business.name}</h3>
          <p className="mb-0">
            {business.category.map((categ) => `${categ} `)}
          </p>
        </div>
      </Stack>

      <FormInput
        label={"Nombre de la promoción"}
        onChange={handleChange}
        value={values.exchangable}
        type="text"
        name="exchangable"
        placeholder={placeholderValues.name}
        errorList={errors}
      />

      <FormInput
        label={"Resumen de la promocion"}
        as="textarea"
        onChange={handleChange}
        value={values.specification}
        type="text"
        name="specification"
        placeholder={placeholderValues.description}
        errorList={errors}
      />

      <FormInput
        label={"Detalles y restricciones de la promoción"}
        as="textarea"
        onChange={handleChange}
        value={values.restrictionsAndDetails}
        type="text"
        name="restrictionsAndDetails"
        placeholder={placeholderValues.restrictions}
        errorList={errors}
      />

      <Stack direction="horizontal" gap={3}>
        <div>
          {console.log(values.dueDate)}
          <FormInput
            label={"Válido hasta"}
            onChange={handleChange}
            value={values.dueDate}
            type="date"
            name="dueDate"
            placeholder={placeholderValues.dueDate}
            errorList={errors}
          />

          <Form.Label style={{ fontSize: "14px" }} className="mt-3">
            Categoria
          </Form.Label>
          <Form.Select
            size="sm"
            onChange={handleChange}
            name="category"
            value={values.category}
            className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
          >
            {categories.map((categ) => {
              return (
                <option value={categ} name="category">
                  {categ}
                </option>
              );
            })}
          </Form.Select>
        </div>

        <Image
          src={placeholderValues.imageURL}
          rounded
          style={{ width: "180px", height: "180px", objectFit: "cover" }}
          className="mt-3 ms-auto"
        ></Image>
      </Stack>

      <Stack direction="horizontal" className="mt-3">
        <Form.Check
          type={"checkbox"}
          id={"sponsorNotification"}
          label={"Notificar al sponsor"}
          style={{ fontSize: "14px" }}
        />

        <Button
          variant="light"
          size="sm"
          className="rounded-pill ms-auto px-3"
          onClick={() => {
            if (buttonText.cancel === "Rechazar") {
              updatePromoStatus("rejected");
              // updatePromos(sponsorId, promotionId, { status: "rejected" });
            }
            onClose(false);
          }}
        >
          {buttonText.cancel}
        </Button>
        <Button
          variant="primary"
          size="sm"
          className="rounded-pill text-light px-3"
          type="submit"
          onClick={() => {
            if (buttonText.continue === "Aprobar") {
              updatePromoStatus("active");
              // updatePromos(sponsorId, promotionId, {
              //   status: "active",
              // });
              onClose(false);
            } else {
              handleSubmit();
            }
          }}
          disabled={loading}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {!loading && (
            <span style={{ color: "white !important" }}>
              {buttonText.continue}
            </span>
          )}
        </Button>
      </Stack>
    </Form>
  );
};

export default PromotionsEdit;
