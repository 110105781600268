import { types } from "../types/types";

export const getMembers = () => ({
  type: types.members,
});

export const addMembers = (members) => ({
  type: types.addMembers,
  payload: members,
});

export const refreshMembers = (shouldRefresh) => ({
  type: types.refreshMembers,
  payload: shouldRefresh,
});


export const getTeams = () => ({
  type: types.teams,
});

export const addTeams = (teams) => ({
  type: types.addTeams,
  payload: teams,
});

export const refreshTeams = (shouldRefresh) => ({
  type: types.refreshTeams,
  payload: shouldRefresh,
});
