export const translations = {
  typeId: "tipo identificación",
  idNumber: "Número identificación",
  userName: "Nombre de usuario",
  birthday: "Nacimiento",
  cdate: "Fecha de creación",
  email: "Email",
  phone: "Teléfono",
  country: "País",
  province: "Provincia",
  totalCoins: "Total de coins",
  canton: "Cantón",
  district: "Distrito",
  favoriteSport: "Deporte favorito",
  sportsOfInterest: "Deportes de interés",
  multiplier: "Multiplicador",
  activityTime: "Tiempo de actividad",
  status: "Estado",
};
