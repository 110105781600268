import { auth, db, storageRef } from "../../../firebase/firebase-config";
import { ADMIN_API_URL, API_URL } from "../../../utils/globals";
import { types } from "../types/types";

export const getAllAssociations = () => {
  return async (dispatch) => {
    try {
      const associationSnapshots = await db.collection("associations").get();

      const associationPromises = associationSnapshots.docs.map(
        async (associationDoc) => {
          const associationData = associationDoc.data();

          const b2bUsersSnapshot = await db
            .collection("associations")
            .doc(associationDoc.id)
            .collection("codes")
            .get();

          const numB2BUsers = b2bUsersSnapshot.size;

          return {
            id: associationDoc.id,
            ...associationData,
            numUsers: numB2BUsers,
          };
        }
      );

      const associationsWithUsersCount = await Promise.all(associationPromises);

      dispatch(getAssociations(associationsWithUsersCount));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAssociationProducts = async (associationId) => {
  try {
    const productsQuerySnapshot = await db
      .collection("products")
      .where("associationRef", "==", associationId)
      .get();

    const productsData = productsQuerySnapshot.docs.map((productDoc) => ({
      id: productDoc.id,
      ...productDoc.data(),
    }));

    return productsData;
  } catch (error) {
    console.error(error);
  }
};
export const addB2btoFb = async (data) => {
  const doc = await db.collection("associations").add(data);

  return doc.id;
};
export const updateB2b = async (id, data) => {
  try {
    db.collection("associations").doc(id).set(data, { merge: true });
    console.log("Documento actualizado:", data);
  } catch (error) {
    console.log(error);
  }
};
export const deactivateB2B = async (sponsorId) => {
  try {
    const products = await db
      .collection("products")
      .doc(sponsorId)
      .collection("exchanges")
      .get();

    products.docs.forEach((item) => {
      item.ref.update({ status: "pending" });
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAssociation = (id) => {
  try {
    const association = db.collection("associations").doc(id);

    return association;
  } catch (error) {
    console.log(error);
  }
};
export const getAssociationAsync = async (id) => {
  try {
    const association = await db.collection("associations").doc(id).get();

    return association.data();
  } catch (error) {
    console.log(error);
  }
};
export const editUser = async (userId, data) => {
  try {
    await db.collection("users").doc(userId).set(data, { merge: true });
  } catch (error) {
    console.log(error);
  }
};
export const createB2b = async (adminDataToSet, token) => {
  const url =
    "https://us-central1-allsports-69231.cloudfunctions.net/admins/associationAdmin";
  const body = {
    associationId: adminDataToSet.associationId,
    adminEmail: adminDataToSet.email,
    cdate: adminDataToSet.cdate,
    associationRef: adminDataToSet.associationRef,
  };
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      associationId: body.associationId,
      email: body.adminEmail,
    }),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    console.log("response", response, "res", res);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};
export const getAssociationCodes = async (associationId) => {
  try {
    const associationRef = db.collection("associations").doc(associationId);

    const codesSnapshot = await associationRef.collection("codes").get();

    const codesDataPromises = codesSnapshot.docs.map(async (codeDoc) => {
      const codeId = codeDoc.id;
      const userRef = codeDoc.data().userRef;
      const codeData = codeDoc.data();
      const userSnapshot = await db.collection("users").doc(userRef).get();
      const userData = userSnapshot.data();
      const { personalId, phone } = await getPrivateData(userRef);
      return {
        codeId: codeId,
        userId: userRef,
        personalId: personalId ?? "N/A",
        phone: phone ?? "N/A",
        ...userData,
        ...codeData,
      };
    });

    // Await all the promises using Promise.all
    const codesData = await Promise.all(codesDataPromises);

    return codesData;
  } catch (error) {
    console.error(error);
  }
};

export const getAllAssociationCodes = () => {
  return async (dispatch) => {
    try {
      const codesSnapshot = await db.collectionGroup("codes").get();

      const codesDataPromises = codesSnapshot.docs.map(async (codeDoc) => {
        const codeId = codeDoc.id;
        const userRef = codeDoc.data().userRef;
        const codeData = codeDoc.data();
        const userSnapshot = await db.collection("users").doc(userRef).get();
        const userData = userSnapshot.data();
        const { personalId, phone } = await getPrivateData(userRef);

        // GET ASSOCIATION
        const associationRef = codeDoc.ref.parent.parent;
        const associationId = associationRef.id;

        // ASSOCIATION DATA
        const associationSnapshot = await associationRef.get();
        const associationData = associationSnapshot.data();
        const codeName = associationData.codeName;

        return {
          codeId: codeId,
          userId: userRef,
          personalId: personalId ?? "N/A",
          phone: phone ?? "N/A",
          associationId: associationId,
          codeName: codeName,
          ...userData,
          ...codeData,
        };
      });

      const codesData = await Promise.all(codesDataPromises);

      dispatch(getCodes(codesData));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getUserByUserRef = async (userRef) => {
  try {
    const userSnapshot = await db.collection("users").doc(userRef).get();

    if (!userSnapshot.exists) {
      console.log("User does not exist for ID:", userRef);
      return null;
    }

    const userData = userSnapshot.data();
    const { personalId, phone } = await getPrivateData(userRef);

    return {
      userId: userRef,
      name: userData.name ?? "N/A",
      lastname: userData.lastname ?? "N/A",
      secondLastname: userData.secondLastname ?? "N/A",
      email: userData.email ?? "N/A",
      plan: userData.planType?.subTier ?? "N/A",
      personalId: personalId ?? "N/A",
      phone: phone ?? "N/A",
      ...userData,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const getPrivateData = async (userId) => {
  return await db
    .collection("users")
    .doc(userId)
    .collection("private")
    .doc("data")
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.log("Error getting private data:", error);
      return {};
    });
};

export const getWalletData = async (userId) => {
  return await db
    .collection("users")
    .doc(userId)
    .collection("private")
    .doc("wallet")
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.log("Error getting wallet data:", error);
      return {};
    });
};

export const createAssociationImage = async (imageUpload, id) => {
  const imageName = imageUpload.name;

  try {
    const path = `associations/${id}/${imageName}`;
    const fileUrl = await uploadFile(path, imageUpload);

    if (fileUrl) {
      const newImg = { imageURL: fileUrl };
      await updateB2b(id, newImg);
    }
  } catch (error) {
    console.log(error);
  }
};
export const uploadFile = async (path, file) => {
  if (file === null) return;
  // console.log(path, file);
  try {
    const upload = await storageRef.ref(path).put(file);
    const fileUrl = await upload.ref.getDownloadURL();

    return fileUrl;
  } catch (error) {
    console.log(file, path);
    console.log("No se puede subir el archivo seleccionado");
  }
};

export const uploadFiles = async (userId, associationId, files) => {
  // console.log("Association ID:", associationId);
  // console.log("User ID:", userId);
  // console.log("files:", files);
  try {
    const userRef = db.doc(`users/${userId}`);
    const userDoc = await userRef.get();

    const storagePath = `associations/${associationId}/${userId}/userFiles/`;
    const ref = storageRef.ref(storagePath);
    if (!files) {
      console.log("Files array is undefined or null");
      return;
    }
    const uploadPromises = files.map(async (fileData) => {
      const { id, file } = fileData; // Destructure id and file from the object
      const fileRef = ref.child(file.name); // Use original file name as the reference key

      await fileRef.put(file);

      const imageURL = await fileRef.getDownloadURL();

      const fileType = file.type.startsWith("image/") ? "image" : "pdf";

      return { link: imageURL, type: fileType };
    });

    const uploadedFilesData = await Promise.all(uploadPromises);

    const existingFiles = userDoc.data()?.associationFiles?.files || [];
    const updatedFiles = existingFiles.concat(uploadedFilesData);

    const associationDataRef = db.doc(`users/${userId}/private/documents`);
    await associationDataRef.set(
      {
        associationFiles: {
          files: updatedFiles,
        },
      },
      { merge: true }
    );
  } catch (err) {
    console.log(err);
  }
};

export const setSale = async (data, token) => {
  const url = API_URL + "/b2b/user";

  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  try {
    const response = await fetch(url, options);
    const res = await response.json();
    console.log("res", res, "response", response);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateAuthUserPhotoURL = (photoURL) => {
  const user = { photoURL };
  return auth.currentUser.updateProfile(user);
};

export const uploadPhoto = async (uid, img) => {
  try {
    const ref = storageRef(`/users/${uid}/profile`);
    const res = await ref.putFile(img);
    if (res.state === "success") {
      const imageURL = await ref.getDownloadURL();
      await updateAuthUserPhotoURL(imageURL);
      await db.doc(`users/${uid}`).set({ photoURL: imageURL }, { merge: true });
    }
  } catch (err) {
    console.log(err);
  }
};
export const removeUser = async (data, token) => {
  const url = API_URL + "/b2b/user";

  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    console.log("res", res, "response", response);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateCodeLink = async (associationId, codeId) => {
  const updateData = {
    linkAvailable: false,
  };
  await db
    .collection("associations")
    .doc(associationId)
    .collection("codes")
    .doc(codeId)
    .set(updateData, { merge: true });
};

export const getCodeFromFb = (codeId, associationId, onUpdate) => {
  const codeDocRef = db
    .collection("associations")
    .doc(associationId)
    .collection("codes")
    .doc(codeId);

  const unsubscribe = codeDocRef.onSnapshot(
    (codeSnapshot) => {
      if (codeSnapshot.exists) {
        const codeData = codeSnapshot.data();
        // Process the code data from Firestore as needed
        console.log("Code data:", codeData);
        onUpdate(codeData); // Call the provided callback with the updated data
      } else {
        console.log("No code data found for the given code ID.");
        onUpdate(null); // Call the callback with null when no data exists
      }
    },
    (error) => {
      console.error("Error fetching code data:", error);
    }
  );

  // Return a function that can be used to unsubscribe the listener
  return () => {
    unsubscribe();
  };
};
export const getCodeAsyncFromFb = async (codeId) => {
  try {
    const associationsSnapshot = await db.collection("associations").get();
    for (const associationDoc of associationsSnapshot.docs) {
      const codeDocRef = associationDoc.ref.collection("codes").doc(codeId);
      const codeSnapshot = await codeDocRef.get();

      if (codeSnapshot.exists) {
        const codeData = { ...codeSnapshot.data(), codeId: codeSnapshot.id };

        const associationId = associationDoc.id;

        console.log("Code data:", codeData);
        console.log("Association ID:", associationId);

        return { codeData, associationId };
      }
    }

    console.log("No code data found for the given code ID.");
    return null;
  } catch (error) {
    console.error("Error fetching code data:", error);
    return null;
  }
};

export const activateUser = async (data, token) => {
  const url = API_URL + "/b2b/user";

  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    console.log("res", res, "response", response);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export const checkCodeName = async (codeName) => {
  try {
    const codeNameExists = await db
      .collection("associations")
      .where("codeName", "==", codeName)
      .get();

    return !codeNameExists.empty;
  } catch (error) {
    console.log(error);
    throw new Error("Error checking codeName existence");
  }
};

export const subscribeToNotifications = (callback) => {
  const notificationsRef = db
    .collection("notifications")
    .doc("cms")
    .collection("main");

  const unsubscribe = notificationsRef.onSnapshot((snapshot) => {
    const notifications = snapshot.docs.map((doc) => doc.data());
    callback(notifications);
  });

  return unsubscribe;
};

export const getAssociations = (associations) => ({
  type: types.associations,
  payload: {
    associations,
  },
});
export const getCodes = (codes) => ({
  type: types.codes,
  payload: {
    codes,
  },
});
export const getB2bUsers = (b2bUsers) => ({
  type: types.b2bUsers,
  payload: {
    b2bUsers,
  },
});

export const getProducts = (products) => ({
  type: types.products,
  payload: {
    products,
  },
});
export const getNotifications = (notifications) => ({
  type: types.notifications,
  payload: {
    notifications,
  },
});

export const isLoading = (isLoadingProducts) => ({
  type: types.isLoadingProducts,
  payload: {
    isLoadingProducts,
  },
});
