import Modal from "react-bootstrap/Modal";

const CustomModal = ({
  children,
  modalTitle,
  handleClose,
  show,
  currentSize,
  animated = false,
  dialogClassName,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={animated}
      centered
      //size={currentSize ? currentSize : "md"}
      dialogClassName={dialogClassName ? dialogClassName : ""}
      backdropClassName="custom-modal-backdrop"
      style={{ zIndex: "1202" }}
    >
      <Modal.Header closeButton className="border-bottom-0 ps-0">
        <Modal.Title className="fs-5 fw-bold px-4">{modalTitle()}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="border-top-0 pb-4">{children}</Modal.Body>
    </Modal>
  );
};

export default CustomModal;
