export const translations = {
  country: "Pais",
  province: "Provincia",
  canton: "Cantón",
  district: "Distrito",
  location: "Ubicación",
  latitude: "Latitud",
  longitude: "Longitud",
  city: "Ciudad",
};
