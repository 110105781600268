import Form from "react-bootstrap/Form";
import { Button, Stack, Spinner, Image, Dropdown } from "react-bootstrap";

import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

import "../requests/requests.scss";

import { useCallback, useEffect, useState } from "react";
import {
  getUserFromRedeem,
  getUserWallet,
  handleRefund,
} from "../../firebase/firebase.utils";
import CoinLoader from "../coin-loader/coin-loader";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
const motivesList = [
  { label: "Errores en la gestión del Sponsor", value: "sponsorError" },
  { label: "Errores en la información", value: "informationError" },
  { label: "Errores de la Plataforma Digital", value: "platformError" },
];

const RedeemModal = ({ redeem, sponsorId, business, onClose, show }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [userPrivateData, setUserPrivateData] = useState([]);
  const [dropdownLabel, setDropdownLabel] = useState("");

  const getUser = useCallback(async () => {
    setLoading(true);
    try {
      const user = await getUserFromRedeem(redeem.user);
      const userWallet = await getUserWallet(redeem.user);
      console.log(userWallet.sportCoins);
      setUserPrivateData(userWallet);
      setUserData(user);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [redeem.user, userData, userPrivateData]);

  useEffect(() => {
    getUser();
  }, []);

  const handleReject = (values, state) => {
    setLoading(true);

    const refundHistory = {
      refundedCoins: values.refundedCoins,
      refundedNotes: values.notes ?? "",
      refundedCoins: values.refundedCoins,
      status: "rejected",
      notify: values.notification,
    };
    const refundData = {
      motive: values.motive ?? "",
      notes: values.details ?? "",
    };
    const refundedCoins = 0;
    handleRefund(
      sponsorId,
      redeem.id,
      refundHistory,
      refundData,
      refundedCoins
    );
    setTimeout(() => {
      setLoading(false);
      showSwalModal(state);
      onClose(false);
    }, 2000);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      redeem: redeem?.exchange.name ?? "",
      description: redeem.exchange.description ?? "",
      user: userData?.name ?? "",
      motive: redeem?.refundData?.motive ?? "",
      details: redeem?.refundData?.notes ?? "",
      code: "",
      refundedCoins: redeem.coinPrice ?? "",
      branch: "",
      redeemDate:
        redeem?.refundData?.requestDate.toDate().toISOString().slice(0, 10) ?? "",
      email: userData?.email ?? "",
      activePlan: userData?.planType?.subTier ?? "",
      notes: "",
      notification: false,
    },
    validationSchema: Yup.object({
      // redeem: Yup.string().required("Required"),
      // description: Yup.string().required("Required"),
      // user: Yup.string().required("Required"),
      // motive: Yup.string().required("Required"),
      // details: Yup.string().required("Required"),
      // code: Yup.string().required("Required"),
      // refundedCoins: Yup.number().min(0, "Must be a positive number"),
      // branch: Yup.string().required("Required"),
      // redeemDate: Yup.date().required("Required"),
      // name: Yup.string().required("Required"),
      // activePlan: Yup.string().required("Required"),
      // notes: Yup.string(),
      // notification: Yup.boolean(),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const refundHistory = {
        refundedNotes: values.notes ?? "",
        refundedDate: new Date(),
        refundedCoins: values.refundedCoins,
        status: "refunded",
        notify: values.notification,
      };
      const refundData = {
        motive: values.motive,
        notes: values.details,
      };
      const refundedCoins = values.refundedCoins;

      handleRefund(
        redeem.user,
        sponsorId,
        redeem.id,
        refundHistory,
        refundData,
        refundedCoins,
        userPrivateData
      );

      setTimeout(() => {
        setLoading(false);
        resetForm();
        showSwalModal("Reembolso Aprobado");
        onClose(false);
      }, 2000);
    },
  });
  const showSwalModal = (state) => {
    Swal.fire({
      title: `${state}`,
      html: `
      <div class="border-top-0 pb-4 summarySwal">
        <div class="mt-3 mb-4 p-2">
          <div class="row">
            <div class="col-sm-6 text-left">Producto:</div>
            <div class="col-sm-6 text-right">${redeem.exchange.name}</div>
          </div>
          <div class="row">
            <div class="col-sm-6 text-left">Detalles:</div>
            <div class="col-sm-6 text-right">${
              redeem.exchange.description
            }</div>
          </div>
          <div class="row"> 
            <div class="col-sm-6 text-left">Coins rembolsados:</div>
            <div class="col-sm-6 text-right">${redeem.refundedCoins}</div>
          </div>
          <div class="row">
            <div class="col-sm-6 text-left">Usuario solicitante:</div>
            <div class="col-sm-6 text-right">${userData?.name ?? ""}</div>
          </div>
          <div class="row">
            <div class="col-sm-6 text-left">Sucursal:</div>
            <div class="col-sm-6 text-right"> N/A</div>
          </div>
          <div class="row">
            <div class="col-sm-6 text-left">Fecha y hora de solicitud:</div>
            <div class="col-sm-6 text-right">${
              redeem.refundData.requestDate
                .toDate()
                .toISOString().replace(/T/, ' ').replace(/\..+/, '')}</div>
          </div>
          <div class="row">
            <div class="col-sm-6 text-left">Fecha y hora de ${
              state === "Reembolso Aprobado" ? "aprovación" : "denegación"
            }:</div>
            <div class="col-sm-6 text-right">${new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')}</div>

          </div>
          <div class="row">
          <div class="col-sm-6 text-left">Motivo:</div>
          <div class="col-sm-6 text-right">  ${redeem.refundData.motive}</div>
        </div>
        </div>
        <div class="mt-3 mb-4 p-2">
        <div class="row mt-3">
          <div class="col-sm-12 text-left">Detalles:</div>
          <div class="col-sm-12 text-left">${formik.values.details}</div>
        </div>
      </div>
        <div class="mt-3 mb-4 p-2">
          <div class="row mt-3">
            <div class="col-sm-12 text-left">Notas:</div>
            <div class="col-sm-12 text-left">${formik.values.notes}</div>
          </div>
        </div>
      </div>
    `,
      customClass: {
        content: "text-left",
        closeButton: "close-btn",
      },
      showConfirmButton: false,
      showCloseButton: true,
    });
  };
  // if (!redeemId) return;

  return (
    <FormikProvider value={formik}>
      {loading ? (
        <div className="loading-container">
          <CoinLoader size={{ width: 150, height: 150 }} />
        </div>
      ) : (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
          className="p-3 refundForm"
        >
          <Stack direction="horizontal" className="mb-4">
            <Image
              src={business.logo}
              roundedCircle
              className="me-2"
              width="80"
              height="80"
            />

            <div>
              <h3 className="mb-0 fw-bol">{business.name}</h3>
              <p className="mb-0">
                {business.category.map((categ) => `${categ} `)}
              </p>
            </div>
          </Stack>
          <Stack direction="horizontal" className="mb-3 " gap={3}>
            <Form.Group controlId="redeem" style={{ width: "100%" }}>
              <Form.Label>Producto</Form.Label>
              <Form.Control
                className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                type="text"
                name="redeem"
                value={formik.values.redeem}
                onChange={formik.handleChange}
                disabled
              />
            </Form.Group>

            <Form.Group controlId="description" style={{ width: "100% " }}>
              <Form.Label>Descripcion</Form.Label>
              <Form.Control
                className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                type="text"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                disabled
              />
            </Form.Group>
          </Stack>

          <Form.Group controlId="user" style={{ width: "100%" }}>
            <Form.Label>Usuario que solicita el reembolso</Form.Label>
            <Form.Control
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
              type="text"
              name="user"
              value={formik.values.user}
              onChange={formik.handleChange}
              disabled
            />
          </Form.Group>

          <Form.Group controlId="motives" className="mb-3">
            <Form.Label style={{ fontSize: "14px" }}>
              Motivo del Reembolso
            </Form.Label>
            <Form.Select
              size="sm"
              onChange={(e) => {
                const selectedValue = e.target.value;
                const selectedMotive = motivesList.find(
                  (motive) => motive.value === selectedValue
                );
                formik.setFieldValue("motive", selectedValue);
                setDropdownLabel(selectedMotive.label);
              }}
              name="motives"
              value={formik.values.motive}
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
            >
              <option value="" disabled>
                Selecciona un motivo
              </option>
              {motivesList.map((motive) => (
                <option key={motive.value} value={motive.value}>
                  {motive.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group controlId="details" style={{ width: "100%" }}>
            <Form.Label>Detalles</Form.Label>
            <Form.Control
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
              as="textarea"
              name="details"
              value={formik.values.details}
              onChange={formik.handleChange}
            />
          </Form.Group>
          <Stack
            direction="horizontal"
            className="mb-3"
            gap={3}
            style={{ width: "100%" }}
          >
            <Form.Group controlId="code" style={{ width: "100%" }}>
              <Form.Label>Codigo Verificador</Form.Label>
              <Form.Control
                className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                type="text"
                name="code"
                value={formik.values.code}
                onChange={formik.handleChange}
                disabled
              />
            </Form.Group>

            <Form.Group controlId="refundedCoins" style={{ width: "100%" }}>
              <Form.Label>Coins a Reembolsar</Form.Label>
              <Form.Control
                className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                type="number"
                name="refundedCoins"
                value={formik.values.refundedCoins}
                onChange={formik.handleChange}
              />
            </Form.Group>
          </Stack>
          <Stack
            direction="horizontal"
            className="mb-3"
            gap={3}
            style={{ width: "100%" }}
          >
            <Form.Group controlId="branch" style={{ width: "100%" }}>
              <Form.Label>Sucursal</Form.Label>
              <Form.Control
                className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                type="text"
                name="branch"
                value={formik.values.branch}
                onChange={formik.handleChange}
                disabled
              />
            </Form.Group>

            <Form.Group controlId="redeemDate" style={{ width: "100%" }}>
              <Form.Label>Redeem Date</Form.Label>
              <Form.Control
                className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                type="date"
                name="redeemDate"
                value={formik.values.redeemDate}
                onChange={formik.handleChange}
                disabled
              />
            </Form.Group>
          </Stack>
          <div className="mb-3">
            <h5>Informacion del cliente</h5>{" "}
          </div>
          <Stack
            direction="horizontal"
            className="mb-3"
            gap={3}
            style={{ width: "100%" }}
          >
            <Form.Group controlId="name" style={{ width: "100%" }}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                type="text"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                disabled
              />
            </Form.Group>
            <Form.Group controlId="activePlan" style={{ width: "100%" }}>
              <Form.Label>Plan Activo</Form.Label>
              <Form.Control
                className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                type="text"
                name="activePlan"
                value={formik.values.activePlan}
                onChange={formik.handleChange}
                disabled
              />
            </Form.Group>
          </Stack>

          <Form.Group controlId="notes" style={{ width: "100%" }}>
            <Form.Label>Notas</Form.Label>
            <Form.Control
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
              type="text"
              name="notes"
              value={formik.values.notes}
              onChange={formik.handleChange}
            />
          </Form.Group>

          <Stack
            direction="horizontal"
            className="mt-5 justify-content-between"
            gap={3}
          >
            <Form.Group controlId="notification">
              <Form.Check
                type="checkbox"
                label="Notificar al Sponsor"
                name="notification"
                checked={formik.values.notification}
                onChange={formik.handleChange}
              />
            </Form.Group>
            <div className="buttonContainer">
              <Button
                variant="danger"
                size="sm"
                style={{ marginRight: "1rem" }}
                className="rounded-pill text-light px-3"
                onClick={() =>
                  handleReject(formik.values, "Reembolso Cancelado")
                }
              >
                Rechazar
              </Button>
              <Button
                size="sm"
                className="rounded-pill text-light px-3 ml-3"
                variant="primary"
                type="submit"
              >
                Aprobar
              </Button>
            </div>
          </Stack>
        </Form>
      )}
    </FormikProvider>
  );
};

export default RedeemModal;
