import { types } from "../types/types";

const INITIAL_STATE = {
  businesses: [],
  requests: [],
};

const sponsorsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.businesses:
      return {
        ...state,
        businesses: action.payload.businesses,
      };
    case types.requests:
      return {
        ...state,
        requests: action.payload.requests,
      };

    case types.addBusinesses:
      return {
        ...state,
        businesses: action.payload,
      };
    case types.removeBusinesses:
      return {
        ...state,
        businesses: null,
      };
    case types.refreshBusinesses:
      return {
        ...state,
        refresh: action.payload,
      };
    default:
      return state;
  }
};

export default sponsorsReducer;
