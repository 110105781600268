import { Card, Col, Row, Stack } from "react-bootstrap";

const CauseDonorTeams = () => {
  return (
    <Card className="border-0 rounded mt-3">
      <Card.Header className="bg-light border-bottom-0 px-0">
        <Stack direction="horizontal" className="px-3 py-3">
          <h3 className="h5">Equipos donantes</h3>{" "}
          <span className="ms-auto text-primary">Todos</span>
        </Stack>
        <Row className="m-0 bg-success px-2 py-3">
          <Col lg="2">#</Col>
          <Col>Equipo</Col>
          <Col className="text-end">Donación</Col>
        </Row>
      </Card.Header>
      <Card.Body className="bg-light border-bottom-0 rounded-bottom px-0">
        {/* <Row className="m-0 px-2 py-3">
          <Col lg="2">
            <p className="text-primary h5">1</p>
          </Col>
          <Col lg="6">
            <small className="fw-bold">Equipo A</small>
            <small className="text-muted d-block">Ultima donacion</small>
          </Col>
          <Col className="text-end">
            <small className="fw-bold">14.520</small>
            <small className="d-block">14/10/23</small>
          </Col>
        </Row>
        <Row className="m-0 px-2 py-3">
          <Col lg="2">
            <p className="text-primary h5">2</p>
          </Col>
          <Col lg="6">
            <small className="fw-bold">Equipo C</small>
            <small className="text-muted d-block">Ultima donacion</small>
          </Col>
          <Col className="text-end">
            <small className="fw-bold">14.520</small>
            <small className="d-block">14/10/23</small>
          </Col>
        </Row>
        <Row className="m-0 px-2 py-3">
          <Col lg="2">
            <p className="text-primary h5">3</p>
          </Col>
          <Col lg="6">
            <small className="fw-bold">Equipo B</small>
            <small className="text-muted d-block">Ultima donacion</small>
          </Col>
          <Col className="text-end">
            <small className="fw-bold">14.520</small>
            <small className="d-block">14/10/23</small>
          </Col>
        </Row>
        <Row className="m-0 px-2 py-3">
          <Col lg="2">
            <p className="text-primary h5">4</p>
          </Col>
          <Col lg="6">
            <small className="fw-bold">Equipo J</small>
            <small className="text-muted d-block">Ultima donacion</small>
          </Col>
          <Col className="text-end">
            <small className="fw-bold">14.520</small>
            <small className="d-block">14/10/23</small>
          </Col>
        </Row> */}
      </Card.Body>
    </Card>
  );
};

export default CauseDonorTeams;
