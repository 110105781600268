import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { Button, Stack } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { AdvertisingTable } from "../../components/advertising/AdvertisingTable";
import { useSelector } from "react-redux";

import {
  getAllActivityBanners,
  getAllCarouselBanners,
  getAllHomeBanners,
  getAllPromoBanners,
} from "../../redux/banners/actions/actions";
import AddPromoBanner from "../../components/advertising/AddPromoBanner";
import { styles } from "../../styles/styles";
import { WellnessBannersTable } from "../../components/advertising/WellnessBannersTable";
import AddHomeBanner from "../../components/advertising/AddHomeBanner";
import { WellnessCarouselTable } from "../../components/advertising/WellnessCarouselTable";
import { ActivityBannersTable } from "../../components/advertising/ActivityBannersTable";
import AddHomeCarouselBanner from "../../components/advertising/AddHomeCarouselBanner";
import { ASbutton } from "../../components/buttons/ASbutton";
import CustomTabs from "../../components/advertising/CustomTabs";

const labelList = [
  { id: 1, label: "Publicidad" },
  { id: 2, label: "Banners Home" },
  { id: 3, label: "Banners Carrusel" },
  { id: 4, label: "Banners Actividad" },
];

const Advertising = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { promoBanners, homeBanners, carouselBanners, activityBanners } =
    useSelector((state) => state.banners);
  const isFirstUse = useRef(true);
  const [loading, setloading] = useState(false);
  const [openPromoModal, setOpenPromoModal] = useState(false);
  const [openHomeBannerModal, setOpenHomeBannerModal] = useState(false);
  const [openCarouselModal, setOpenCarouselModal] = useState(false);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getPromoBannersFromFB = useCallback(() => {
    try {
      dispatch(getAllPromoBanners());
      dispatch(getAllHomeBanners());
      dispatch(getAllCarouselBanners());
      dispatch(getAllActivityBanners());
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  }, [promoBanners]);

  useEffect(() => {
    if (isFirstUse.current) {
      setloading(true);
      getPromoBannersFromFB();
      isFirstUse.current = false;
      setTimeout(() => {
        setloading(false);
      }, 1000);
    }
  }, [getPromoBannersFromFB]);

  const handleRefresh = () => {
    setloading(true);
    getPromoBannersFromFB();
    setTimeout(() => {
      setloading(false);
    }, 1000);
  };
  const handleCloseEditModal = () => {
    setOpenPromoModal(false);
  };
  const handleCloseHomeModal = () => {
    setOpenHomeBannerModal(false);
  };
  const handleCloseCarouselModal = () => {
    setOpenCarouselModal(false);
  };
  const TabPanel = ({ children, value, index }) => {
    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
      >
        {value === index && (
          <Grid item md={12} sm={12} xs={12}>
            {children}
          </Grid>
        )}
      </Box>
    );
  };

  return (
    <Container maxWidth={"xl"}>
      <Stack direction="horizontal" gap={2} className="mb-4">
        <Typography variant="h5" sx={styles.title}>
          Publicidad
        </Typography>
        {/* <button onClick={handleCopy}>Copy JSON</button> */}

        {value === 0 && (
          <Box className="ms-auto rounded-pill">
            <ASbutton
              buttonText={"Agregar Publicidad"}
              onClick={() => setOpenPromoModal(true)}
              style={{ color: "white !important" }}
            />
          </Box>
        )}
        {value === 1 && (
          <Box className="ms-auto rounded-pill">
            <ASbutton
              buttonText={"Agregar Banner de Home"}
              onClick={() => setOpenHomeBannerModal(true)}
              style={{ color: "white !important" }}
            />
          </Box>
        )}
        {value === 2 && (
          <Box className="ms-auto rounded-pill">
            <ASbutton
              buttonText={"Agregar Banner de Carrusel"}
              onClick={() => setOpenCarouselModal(true)}
              style={{ color: "white !important" }}
            />
          </Box>
        )}
        {value === 3 && (
          <Box className="ms-auto rounded-pill">
            <ASbutton
              buttonText={"Agregar Banner de Actividad"}
              onClick={() => setOpenHomeBannerModal(true)}
              style={{ color: "white !important" }}
            />
          </Box>
        )}
        {/* <Button className="ms-auto rounded-pill" onClick={handleClick}>
          Recargar
        </Button> */}
      </Stack>
      {/* <Grid item> */}
      <Box mt={6}>
        <Box
          bgcolor="#ffffff"
          sx={{
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            border: "3px solid #ECECEE",
            borderBottom: "none",
            mb: -3,
            position: "relative",
            zIndex: 100,
            display: { xs: "block" },
            "@media (min-width:1000px)": {
              display: "none",
            },
          }}
        >
          <Box sx={{ maxWidth: "520px", margin: "5px auto" }}>
            <CustomTabs
              value={value}
              handleChange={handleChange}
              tabList={labelList}
            />
          </Box>
        </Box>
        <TabPanel value={value} index={0}>
          <AdvertisingTable
            promoBanners={promoBanners}
            loading={loading}
            setloading={setloading}
            isFirstUse={isFirstUse}
            getPromoBannersFromFB={getPromoBannersFromFB}
            handleRefresh={handleRefresh}
            value={value}
            handleChange={handleChange}
          />
        </TabPanel>
        {/* </Grid> */}
        <TabPanel value={value} index={1}>
          <WellnessBannersTable
            promoBanners={homeBanners}
            loading={loading}
            setloading={setloading}
            isFirstUse={isFirstUse}
            getPromoBannersFromFB={getPromoBannersFromFB}
            handleRefresh={handleRefresh}
            value={value}
            handleChange={handleChange}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <WellnessCarouselTable
            promoBanners={carouselBanners}
            loading={loading}
            setloading={setloading}
            isFirstUse={isFirstUse}
            getPromoBannersFromFB={getPromoBannersFromFB}
            handleRefresh={handleRefresh}
            value={value}
            handleChange={handleChange}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ActivityBannersTable
            promoBanners={activityBanners}
            loading={loading}
            setloading={setloading}
            isFirstUse={isFirstUse}
            getPromoBannersFromFB={getPromoBannersFromFB}
            handleRefresh={handleRefresh}
            value={value}
            handleChange={handleChange}
          />
        </TabPanel>
      </Box>

      <AddPromoBanner
        open={openPromoModal}
        onClose={handleCloseEditModal}
        handleRefresh={handleRefresh}
      />
      <AddHomeBanner
        open={openHomeBannerModal}
        onClose={handleCloseHomeModal}
        handleRefresh={handleRefresh}
        activity={value === 3 ? true : false}
      />
      <AddHomeCarouselBanner
        open={openCarouselModal}
        onClose={handleCloseCarouselModal}
        handleRefresh={handleRefresh}
      />
    </Container>
  );
};

export default Advertising;
