const TableHeader = ({ headers }) => (
  <thead>
    <tr>
      {headers.map((header, index) => {
        return (
          <th key={index} className="text-muted fw-normal">
            {header.name}
          </th>
        );
      })}
    </tr>
  </thead>
);

export default TableHeader;
