import { useState, useEffect, useMemo, useCallback } from "react";
import { Table, Stack, Button, Spinner, Image } from "react-bootstrap";
import { ReactComponent as DarkPencilIcon } from "./../../assests/pencil.svg";
import { ReactComponent as DarkTrashCanIcon } from "./../../assests/trashcan.svg";
//import { ReactComponent as DarkRoundedMenuIcon } from "./../../assests/rounded-hamburger-menu.svg";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { getAllMembers } from "../../firebase/firebase.utils";
import { sortMembersTable } from "../../custom hooks/sort-table/sort-members-table";
import { searchTable } from "../../custom hooks/search-table/search-table";
import { useDispatch, useSelector } from "react-redux";
import { addMembers } from "../../redux/members/actions/actions";

import ReactPaginate from "react-paginate";
import TableHeader from "../table/table-header";
import TableBodyHover from "../table/table-body-hover";
import ItemsControl from "../items-control/items-control";
import TableFilters from "../table/table-filters";
import CustomModal from "../modal/customModal";
import CoinLoader from "./../coin-loader/coin-loader";

import MemberDeletion from "../roles-deletion/member-deletion";
import MemberEdition from "../roles-edition/member-edition";

import fallback from "./../../assests/fallback.jpg";
import "./roles-table.scss";

const MembersTable = (count) => {
  const dispatch = useDispatch();
  const reduxMembers = useSelector((state) => state.members);

  const [members, setMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");

  const [itemsPerPage, setItemsPerPage] = useState(5);

  const [showDeleteMemberModal, setShowDeleteMemberModal] = useState(false);
  const [showEditionMemberModal, setShowEditionMemberModal] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [searchField, setSearchField] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDelete = (member) => {
    setShowDeleteMemberModal(true);
    setSelectedMember(member.id);
  };

  const handleEdit = (member) => {
    setShowEditionMemberModal(true);
    setSelectedMember(member);
  };

  const headers = [
    { name: "", accessor: "profileImg" },
    { name: "Nombre", accessor: "userName" },
    { name: "Role", accessor: "role" },
    { name: "Equipos de trabajo", accessor: "team" },
    { name: "Registro", accessor: "register" },
    { name: "Status", accessor: "status" },
    { name: "Acciones", accessor: "actions" },
  ];
  const headersSearch = [
    { name: "", accessor: "profileImg" },
    { name: "Nombre", accessor: "userName" },
    { name: "Role", accessor: "role" },
    { name: "Equipos de trabajo", accessor: "team" },
    { name: "Registro", accessor: "register" },
    { name: "Status", accessor: "status" },

  ];

  const memberRole = (member) => {
    let role = "";
    if (member.admin) {
      role = "Admin";
    } else if (member.superadmin) {
      role = "Super Admin";
    } else {
      role = "Moderador";
    }
    return role;
  };

  const newMemberList = useMemo(
    () =>
      filteredMembers.map((member) => ({
        id: member.id,
        profileImg: (
          <Image
            src={member.imageUrl ? member.imageUrl : fallback}
            width="32"
            height="32"
            className="rounded-circle imgContainer"
          />
        ),
        userName: member.name ? member.name : "--",
        email: member.email ? member.email : "--",
        role: memberRole(member) ? memberRole(member) : "--",
        register: member.cdate ? member.cdate : "--",
        team: member.teams ? member.teams.join(" / ") : "--",
        status: member.isActive ? (
          <span size="sm" className="activeColor">
            Activo
          </span>
        ) : (
          <span size="sm" className="inactiveColor">
            Inactivo
          </span>
        ),
        actions: (
          <Stack direction="horizontal" gap={2}>
            <span variant="secondary" size="sm" className="text-light">
              <DarkPencilIcon onClick={() => handleEdit(member)} />
            </span>
            <span variant="secondary" size="sm" className="text-light">
              <DarkTrashCanIcon onClick={() => handleDelete(member)} />
            </span>
            {/* <span variant="secondary" size="sm" className="text-light">
              <DarkRoundedMenuIcon />
            </span> */}
          </Stack>
        ),
      })),
    [filteredMembers]
  );

  useEffect(() => {
    // MAIN
    const endOffset = itemOffset + Number(itemsPerPage);
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(newMemberList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(newMemberList.length / Number(itemsPerPage)));
  }, [itemOffset, newMemberList, itemsPerPage]);

  const getMembers = useCallback(async () => {
    setLoading(true);
    const membersList = await getAllMembers();
    setMembers(membersList);
    setFilteredMembers(membersList);
    dispatch(addMembers(membersList));
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);

    if (reduxMembers.members) {
      const { members } = reduxMembers;
      console.log("No new members", reduxMembers);
      setMembers(members);
      setFilteredMembers(members);
      setLoading(false);
    } else {
      getMembers();
    }
  }, [reduxMembers]);

  useEffect(() => {
    if (count !== 0) {
      getMembers();
    }
  }, [count]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % newMemberList.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const sortTableElements = (sortingMethod) => {
    const sortedItems = sortMembersTable(currentItems, sortingMethod);
    setCurrentItems(sortedItems);
  };

  const dropdownList = [
    {
      valuesList: [
        { name: "Más recientes" },
        { name: "Más antiguos" },
        { name: "Orden alfabetico" },
        // { name: "Rol" },
      ],
      label: "",
      filterMethod: sortTableElements,
    },
  ];

  const showActiveMembers = (list) => {
    const members = list.filter((member) => member.isActive === true);
    setFilteredMembers(members);
  };

  const showInactiveMembers = (list) => {
    const members = list.filter((member) => member.isActive === false);
    setFilteredMembers(members);
  };

  const showAllMembers = () => {
    setFilteredMembers(members);
  };

  const options = [
    { text: "Todos", filterMethod: () => showAllMembers(members) },
    { text: "Activos", filterMethod: () => showActiveMembers(members) },
    {
      text: "Inactivos",
      filterMethod: () => showInactiveMembers(members),
    },
  ];

  const rows = searchTable(currentItems, headersSearch, searchField);

  return (
    <>
      <TableFilters
        options={options}
        dropdowns={dropdownList}
        searchRows={setSearchField}
        handleRefresh={() => getMembers()}
      />
      <div className="p-4 bg-light">
        {loading ? (
          <div className="loading-container">
            {/* <Spinner
              className="spinner-style"
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            /> */}

            <CoinLoader size={{ width: 150, height: 150 }} />
          </div>
        ) : (
          <Table className="bg-light mb-0 p-4">
            <TableHeader headers={headers} />
            <TableBodyHover
              rows={rows}
              columns={headers}
              searchField={searchField}
            />
          </Table>
        )}
      </div>

      <div className="bg-light p-4 rounded-bottom">
        <Stack direction="horizontal">
          <ItemsControl setNumItemPerPage={setItemsPerPage} />

          <div className="ms-auto">
            <ReactPaginate
              breakLabel="..."
              nextLabel={<IoIosArrowForward />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel={<IoIosArrowBack />}
              renderOnZeroPageCount={null}
              containerClassName="pagination pagination-modifier"
              pageClassName="page-item"
              previousClassName="previus-button"
              nextClassName="next-button"
              pageLinkClassName="page-link page-link-modifier"
              activeClassName="active"
            />
          </div>
        </Stack>
      </div>
      <CustomModal
        show={showDeleteMemberModal}
        handleClose={setShowDeleteMemberModal}
        modalTitle={() => "Eliminar Colaborador"}
      >
        <MemberDeletion
          onClose={setShowDeleteMemberModal}
          memberId={selectedMember}
          handleRefresh={() => getMembers()}
        />
      </CustomModal>

      <CustomModal
        show={showEditionMemberModal}
        handleClose={setShowEditionMemberModal}
        modalTitle={() => <HeaderButtons />}
      >
        <MemberEdition
          member={selectedMember}
          onClose={setShowEditionMemberModal}
          handleRefresh={() => getMembers()}
        />
      </CustomModal>
    </>
  );
};

export default MembersTable;

const HeaderButtons = ({ type }) => (
  <Stack direction="horizontal" gap={2} className="">
    <Button
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#9A9A9A" }}
    >
      Editar
    </Button>
    <Button
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#AAAAAA" }}
    >
      Colaborador
    </Button>
  </Stack>
);
