import { useCallback, useEffect, useMemo, useState } from "react";

import { Table, Spinner } from "react-bootstrap";
import { Stack } from "react-bootstrap";
import { Button } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

import ReactPaginate from "react-paginate";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

import TableHeader from "../table/table-header";
import TableBody from "../table/table-body";
import ItemsControl from "../items-control/items-control";
import TableFilters from "../table/table-filters";
import CoinLoader from "./../coin-loader/coin-loader";

import {
  getAllCausesByOrganization,
  getAllDonors,
} from "../../firebase/firebase.utils";
import { sortCausesTable } from "../../custom hooks/sort-table/sort-causes-table";
import { searchTable } from "../../custom hooks/search-table/search-table";
import { useDispatch, useSelector } from "react-redux";
import { addCauses, addCoins } from "../../redux/causes/actions/actions";
import { useMediaQuery } from "@mui/material";

const CausesTable = ({
  options,
  dropdownList,
  headers,
  rows,
  loading,
  pageCount,
  setSearchField,
  getCauses,
  searchField,
  setItemsPerPage,
  handlePageClick,
}) => {
  const matchesUp900 = useMediaQuery("(min-width:900px)");

  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const reduxCauses = useSelector((state) => state.causes);

  // const [causes, setCauses] = useState([]);
  // const [coinsByCause, setCoinsByCause] = useState([]);
  // const [filteredCauses, setFilteredCauses] = useState([]);
  // const [itemsPerPage, setItemsPerPage] = useState(5);
  // //const [filteringBy, setfilteringBy] = useState("Name");
  // const [currentItems, setCurrentItems] = useState([]);
  // const [pageCount, setPageCount] = useState(0);
  // const [itemOffset, setItemOffset] = useState(0);

  // const [loading, setLoading] = useState(false);

  // const handleClick = (url) => {
  //   navigate(url);
  // };

  // const headers = [
  //   { name: "Nombre de la causa", accessor: "causeName" },
  //   { name: "Solicitante", accessor: "requestor" },
  //   { name: "Registro", accessor: "register" },
  //   { name: "Coins meta", accessor: "goalCoins" },
  //   { name: "Coins obtenidos", accessor: "gottenCoins" },
  //   { name: "Categoria", accessor: "category" },
  //   { name: "Status", accessor: "status" },
  //   //{ name: "", accessor: "options" },
  // ];

  // const getCauses = useCallback(async () => {
  //   setLoading(true);
  //   //const causesList = await getAllCauses();
  //   const causesList = await getAllCausesByOrganization();
  //   let causesAmount = [];

  //   for (const cause of causesList) {
  //     let total = 0;
  //     const donorsByCause = await getAllDonors(cause.organizationId, cause.id);

  //     for (const donor of donorsByCause) {
  //       total = total + donor.coinsDonated;
  //     }

  //     causesAmount.push(total);
  //   }

  //   setCauses(causesList);
  //   setCoinsByCause(causesAmount);

  //   //setRequestors(requestorsList)
  //   setFilteredCauses(causesList);

  //   dispatch(addCauses(causesList));
  //   dispatch(addCoins(causesAmount));
  // }, [dispatch]);

  // useEffect(() => {
  //   setLoading(true);
  //   if (reduxCauses.causes) {
  //     const { causes, causesCoins } = reduxCauses;
  //     setCauses(causes);
  //     setFilteredCauses(causes);
  //     setCoinsByCause(causesCoins);
  //     setLoading(false);
  //   } else {
  //     getCauses();
  //   }
  // }, [reduxCauses]);

  // useEffect(() => {
  //   if (count !== 0) {
  //     getCauses();
  //   }
  // }, [count]);

  // const newCauses = useMemo(() => {
  //   return filteredCauses.map((cause, index) => {
  //     return {
  //       id: cause.id,
  //       organizationId: cause.organizationId,
  //       causeName: cause.name,
  //       requestor: cause.requestor,
  //       register: cause.cdate,
  //       goalCoins: cause.coinsMeta,
  //       //gottenCoins: cause.coins ? cause.coins : "0",
  //       gottenCoins: coinsByCause[0] ? coinsByCause[index] : "0",
  //       category: cause.category,
  //       status: cause.isActiveCause ? "Activa" : "Cerrada",
  //       options: (
  //         <Button
  //           variant="secondary"
  //           size="sm"
  //           className="text-light"
  //           onClick={() =>
  //             handleClick(
  //               `/cause/${cause.id}/organization/${cause.organizationId}`
  //             )
  //           }
  //         >
  //           Ver
  //         </Button>
  //       ),
  //     };
  //   });
  // }, [filteredCauses]);

  // useEffect(() => {
  //   // Fetch items from another resources.
  //   const endOffset = itemOffset + Number(itemsPerPage);
  //   //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  //   setCurrentItems(newCauses.slice(itemOffset, endOffset));
  //   setPageCount(Math.ceil(newCauses.length / Number(itemsPerPage)));
  // }, [itemOffset, newCauses, itemsPerPage]);

  // // Invoke when user click to request another page.
  // const handlePageClick = (event) => {
  //   const newOffset = (event.selected * itemsPerPage) % newCauses.length;
  //   //console.log(
  //   //  `User requested page number ${event.selected}, which is offset ${newOffset}`
  //   //);
  //   setItemOffset(newOffset);
  // };

  // const sortTableElements = (sortingMethod) => {
  //   const sortedItems = sortCausesTable(currentItems, sortingMethod);
  //   setCurrentItems(sortedItems);
  // };

  // const showActiveCauses = (list) => {
  //   const filterElements = list.filter((cause) => cause.isActiveCause === true);

  //   setFilteredCauses(filterElements);
  // };

  // const showCloseCauses = (list) => {
  //   console.log(filteredCauses);
  //   const filterElements = list.filter(
  //     (cause) => cause.isActiveCause === false
  //   );

  //   setFilteredCauses(filterElements);
  // };

  // const showAllCauses = () => {
  //   setFilteredCauses(causes);
  // };

  // const dropdownList = [
  //   {
  //     valuesList: [
  //       { name: "Más recientes" },
  //       { name: "Más antiguos" },
  //       { name: "Orden alfabetico" },
  //       { name: "Metas + a -" },
  //       { name: "Metas - a +" },
  //     ],
  //     label: "",
  //     filterMethod: sortTableElements,
  //   },
  // ];

  // const options = [
  //   { text: "Todos", filterMethod: () => showAllCauses(causes) },
  //   { text: "Activas", filterMethod: () => showActiveCauses(causes) },
  //   { text: "Cerradas", filterMethod: () => showCloseCauses(causes) },
  // ];

  // const [searchField, setSearchField] = useState("");
  // const rows = searchTable(currentItems, headers, searchField);

  return (
    <>
      <TableFilters
        options={options}
        dropdowns={matchesUp900 ? dropdownList : null}
        searchRows={matchesUp900 ? setSearchField : null}
        handleRefresh={matchesUp900 ? () => getCauses() : null}
      />
      <div className="p-4 bg-light">
        <div className="overflow-auto d-block">
          {loading ? (
            <div className="loading-container">
              <CoinLoader size={{ width: 150, height: 150 }} />
            </div>
          ) : (
            <Table className="w-100 bg-light mb-0 p-4">
              <TableHeader headers={headers} />
              <TableBody
                rows={rows}
                columns={headers}
                searchField={searchField}
                clickGoTo="/cause/"
              />
            </Table>
          )}
        </div>
      </div>
      <div className="bg-light p-4 rounded-bottom">
        <Stack direction="horizontal">
          <ItemsControl setNumItemPerPage={setItemsPerPage} />

          <div className="ms-auto">
            <ReactPaginate
              breakLabel="..."
              nextLabel={<IoIosArrowForward />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel={<IoIosArrowBack />}
              renderOnZeroPageCount={null}
              containerClassName="pagination pagination-modifier"
              pageClassName="page-item"
              previousClassName="previus-button"
              nextClassName="next-button"
              pageLinkClassName="page-link page-link-modifier"
              activeClassName="active"
            />
          </div>
        </Stack>
      </div>
    </>
  );
};

export default CausesTable;
