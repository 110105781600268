import { useState, useRef, useEffect, useCallback } from "react";

import { Stack } from "react-bootstrap";

import BriefCard from "../../components/brief-card/brief-card";

import { Box, Container } from "@mui/material";
import { Typography } from "@mui/material";
import { styles } from "../../styles/styles";

import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getBlogPosts } from "../../redux/blogs/blogs";
import { PostsTable } from "../../components/blogs/PostsTable";
import { MdChevronLeft } from "react-icons/md";
import AdminHistory from "../../components/adminHistory/AdminHistory";
import { getPostAdminHistory } from "../../firebase/firebase.utils";

const PostsPage = () => {
  const { postsByCategory } = useSelector((state) => state.blogs);
  const isFirstUse = useRef(true);
  const [loading, setloading] = useState(false);
  const [postsFromDb, setPostsFromDb] = useState([]);
  const dispatch = useDispatch();
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [blogId, setBlogId] = useState(0);
  const { id } = useParams();

  const getPostsFromDb = useCallback(async () => {
    try {
      await dispatch(getBlogPosts(id));
    } catch (error) {
      console.log(error);
    }
  }, [postsByCategory]);
  useEffect(() => {
    if (isFirstUse.current) {
      setloading(true);
      getPostsFromDb();
      setPostsFromDb(postsByCategory[id]);

      isFirstUse.current = false;
    } else if (postsByCategory[id]?.length > 0) {
      setPostsFromDb(postsByCategory[id]);
      setloading(false);
    } else {
      const timeout = setTimeout(() => {
        setNoDataAvailable(true);
        setloading(false);
      }, 2200);

      return () => clearTimeout(timeout);
    }
  }, [getPostsFromDb, postsByCategory]);

  useEffect(() => {
    if (postsByCategory[id]) {
      const post = postsByCategory[id][0];
      setBlogId(post.blogId);
    }
  }, [postsByCategory]);

  const handleRefresh = () => {
    setloading(true);
    getPostsFromDb();
    setTimeout(() => {
      setloading(false);
    }, 1000);
  };

  return (
    <Container maxWidth={"xl"}>
      <Stack direction="horizontal" gap={2} className="mb-4">
        <Link
          to="/blogs"
          className="w-auto d-block link-dark text-decoration-none me-2"
        >
          <h1 className="h5 mb-0">
            <span className="d-inline-block" style={{ width: "14px" }}>
              <MdChevronLeft size={25} color="#7265e3" fontWeight="bold" />
            </span>
          </h1>
        </Link>

        <Typography variant="h5" sx={styles.title}>
          Posts
        </Typography>
      </Stack>
      <BriefCard />

      <PostsTable
        items={postsFromDb}
        handleRefresh={handleRefresh}
        setItems={setPostsFromDb}
        loading={loading}
        id={id}
        noDataAvailable={noDataAvailable}
      />

      <Box mt={3}>
        <AdminHistory id={blogId} getAdminHistory={getPostAdminHistory} />
      </Box>
    </Container>
  );
};

export default PostsPage;
