import Form from "react-bootstrap/Form";
import { Button, Col, Row, Stack, Spinner } from "react-bootstrap";

import { TagsInput } from "react-tag-input-component";

import { useFormik } from "formik";
import * as Yup from "yup";

import FormInput from "../form-input/form-input";
import { updatePromos } from "../../firebase/firebase.utils";
import { useEffect, useState } from "react";

import "./teams-addition.scss";

const TeamAddition = ({ onClose }) => {
  const [loading, setLoading] = useState(false);

  const [selected, setSelected] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState(false);
  const [icons, setIcons] = useState([
    { code: "#FAA60E", selectedColor: false },
    { code: "#798AAD", selectedColor: false },
    { code: "#9197B3", selectedColor: false },
    { code: "#00C886", selectedColor: false },
    { code: "#DE6972", selectedColor: false },
    { code: "#EEEEEE", selectedColor: false },
  ]);

  const areas = ["Marketing", "Desarrollo", "Contabilidad"];

  const validate = Yup.object({
    teamName: Yup.string().required("requerido"),
    area: Yup.string().oneOf(areas, "No existe"),
    members: Yup.string().email().typeError("Email no valido"),
    icon: Yup.string().oneOf(icons, "No existe"),
  });

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      teamName: "",
      area: "",
      members: "",
      icon: "",
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      // setLoading(true);
      // const newDate = new Date(values.dueDate);
      // const updates = {
      //   name: values.exchangable,
      //   description: values.specification,
      //   restrictions: values.restrictionsAndDetails,
      //   dueDate: newDate,
      //   category: values.category,
      // };
      // updatePromos(sponsorId, promotionId, updates);
      // setTimeout(() => {
      //   setLoading(false);
      //   resetForm();
      //   onClose(false);
      // }, 2000);
    },
  });

  const handleClick = (targetElement) => {
    const cleanList = icons.map((icon) => {
      return { ...icon, selectedColor: false };
    });

    const selectedIconIndex = icons.findIndex(
      ({ code }) => code === targetElement.textContent
    );

    cleanList[selectedIconIndex].selectedColor = true;

    setIcons(cleanList);
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
        onClose(false);
      }}
      noValidate
      className="p-3"
    >
      <Row className="mb-3">
        <Col>
          <FormInput
            label={"Nombre del equipo"}
            onChange={handleChange}
            value={values.teamName}
            type="text"
            name="teamName"
            placeholder={""}
            errorList={errors}
          />
        </Col>
        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: "14px" }} className="mb-3">
              Area
            </Form.Label>
            <Form.Select
              size="sm"
              onChange={handleChange}
              name="area"
              value={values.area}
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
            >
              {areas.map((area) => {
                return (
                  <option value={area} name="area">
                    {area}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: "14px" }} className="mb-3">
              Colaboradores
            </Form.Label>
            <TagsInput
              value={selected}
              style={{ background: "red" }}
              onChange={setSelected}
              name="tags"
              placeHolder="Escriba aqui"
              classNames={{
                input:
                  "border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none",
                tag: "bg-tags",
              }}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Stack direction="horizontal" gap={2}>
            {icons.map((icon) => {
              return (
                <span
                  className={`d-inline-block rounded-1 hover-color ${
                    icon.selectedColor ? "selected-color" : ""
                  }`}
                  onClick={(event) => handleClick(event.target)}
                  style={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: icon.code,
                    color: icon.code,
                    fontSize: "1px",
                  }}
                >
                  {icon.code}
                </span>
              );
            })}
          </Stack>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="d-flex align-items-center">
          <Form.Check
            type={"checkbox"}
            id={`memberState`}
            label={`Activo`}
            className="mb-0"
          />
        </Col>
        <Col className="text-center">
          <Button
            variant="primary"
            className="text-light ms-auto rounded-pill"
            disabled={loading}
          >
            {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {!loading && (
              <span style={{ color: "white !important" }}>Guardar</span>
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default TeamAddition;
