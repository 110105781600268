import { types } from "../types/types";

const INITIAL_STATE = {
  associations: [],
  b2bUsers: [],
  codes: [],
  notifications: [],
};

const b2bReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.associations:
      return {
        ...state,
        associations: action.payload.associations,
      };
    case types.b2bUsers:
      return {
        ...state,
        b2bUsers: action.payload.b2bUsers,
      };
    case types.codes:
      return {
        ...state,
        codes: action.payload.codes,
      };

    case types.isLoadingProducts:
      return {
        ...state,
        isLoadingProducts: action.payload.isLoadingProducts,
      };

    case types.notifications:
      return {
        ...state,
        notifications: action.payload.notifications,
        // displayName: action.payload.displayName,
      };

    default:
      return state;
  }
};

export default b2bReducer;
