import { Button, Col, Row, Stack } from "react-bootstrap";

const UserExchangeList = ({ userExchanges, setRedeem }) => {
  return (
    <>
      {userExchanges.map((exchange) => {
        return (
          <Row className="mt-3">
            <Col xxl="1" lg={0}>
              <small className="d-block rounded-circle bg-primary outer-circle"></small>
            </Col>
            <Col className="p-0">
              <Stack direction="horizontal" className="px-3">
                <small className="text-muted">
                  {exchange.redeemDate
                    ? exchange.redeemDate.toDate().toLocaleDateString("es-CR", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })
                    : "----"}
                </small>
                <small className="fw-bold ms-auto">
                  {exchange.isRedeemed ? "Redimido" : "Disponible"}
                </small>
              </Stack>

              <Stack direction="horizontal" className="px-3">
                <small className="fw-bold">{exchange.exchange.name}</small>
                <Button
                  variant="light"
                  className="ms-auto"
                  onClick={() => setRedeem(exchange)}
                >
                  <small className="text-primary">Detalles</small>
                </Button>
              </Stack>
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default UserExchangeList;
