import SelectAudience from "../select-audience/select-audience";
import SelectPromotion from "../select-promotion/select-promotion";

import { Card, Stack } from "react-bootstrap";

const StepsModal = ({ promos, stepNumber, nextStep, setShowStepModal }) => {
  //const [step, setStep] = useState(0);

  const pageDisplay = () => {
    if (stepNumber === 0) {
      return <SelectPromotion promos={promos} nextStep={nextStep} setShowStepModal={setShowStepModal} />;
    } else if (stepNumber === 1) {
      return <SelectAudience />;
    } //else {
    //return <OtherInfo/>;
    //}
  };

  return <Card className="border-light p-2 w-100">{pageDisplay()}</Card>;
};

export default StepsModal;
