import { db, storageRef } from "../../../firebase/firebase-config";
import { types } from "../types/types";

export const getAllPromoBanners = () => {
  return (dispatch) => {
    const promoBanners = [];
    dispatch(isLoading(true));
    db.collection("banners")
      .doc("exchange")
      .collection("promotionCarousel")
      .get()
      .then(async (itemsFromFs) => {
        itemsFromFs.docs.forEach((item) =>
          promoBanners.push({ id: item.id, ...item.data() })
        );
        dispatch(getPromoBanners(promoBanners));
        dispatch(isLoading(false));
      })

      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
      });
  };
};
export const getAllHomeBanners = () => {
  return (dispatch) => {
    const homeBanners = [];
    dispatch(isLoading(true));
    db.collection("banners")
      .doc("wellness")
      .collection("wellnessBanners")
      .get()
      .then(async (itemsFromFs) => {
        itemsFromFs.docs.forEach((item) =>
          homeBanners.push({ id: item.id, ...item.data() })
        );
        dispatch(getHomeBanners(homeBanners));
        dispatch(isLoading(false));
      })

      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
      });
  };
};
export const getAllCarouselBanners = () => {
  return (dispatch) => {
    const carouselBanners = [];
    dispatch(isLoading(true));
    db.collection("banners")
      .doc("wellness")
      .collection("wellnessCarousel")
      .get()
      .then(async (itemsFromFs) => {
        itemsFromFs.docs.forEach((item) =>
          carouselBanners.push({ id: item.id, ...item.data() })
        );
        dispatch(getCarouselBanners(carouselBanners));
        dispatch(isLoading(false));
      })

      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
      });
  };
};
export const getAllActivityBanners = () => {
  return (dispatch) => {
    const activityBanners = [];
    dispatch(isLoading(true));
    db.collection("banners")
      .doc("activity")
      .collection("news")
      .get()
      .then(async (itemsFromFs) => {
        itemsFromFs.docs.forEach((item) =>
          activityBanners.push({ id: item.id, ...item.data() })
        );
        dispatch(getActivityBanners(activityBanners));
        dispatch(isLoading(false));
      })

      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
      });
  };
};
export const createPromoBanner = async (data) => {
  try {
    const bannerRef = await db
      .collection("banners")
      .doc("wellness")
      .collection("wellnessBanners")
      .add(data);
    //const doc = await db.collection("sponsors").add(sponsorData);

    return bannerRef.id;
  } catch (error) {
    console.log(error);
  }
};
export const createHomeBanner = async (data) => {
  try {
    const bannerRef = await db
      .collection("banners")
      .doc("wellness")
      .collection("wellnessBanners")
      .add(data);
    //const doc = await db.collection("sponsors").add(sponsorData);

    return bannerRef.id;
  } catch (error) {
    console.log(error);
  }
};
export const createHomeCarouselBanner = async (data) => {
  try {
    const bannerRef = await db
      .collection("banners")
      .doc("wellness")
      .collection("wellnessCarousel")
      .add(data);
    //const doc = await db.collection("sponsors").add(sponsorData);

    return bannerRef.id;
  } catch (error) {
    console.log(error);
  }
};

export const createBlog = async (data) => {
  try {
    const blogRef = await db.collection("blogs").add(data);
    //const doc = await db.collection("sponsors").add(sponsorData);

    return blogRef.id;
  } catch (error) {
    console.log(error);
  }
};
export const createActivityBanner = async (data) => {
  try {
    const bannerRef = await db
      .collection("banners")
      .doc("activity")
      .collection("news")
      .add(data);
    //const doc = await db.collection("sponsors").add(sponsorData);

    return bannerRef.id;
  } catch (error) {
    console.log(error);
  }
};

export const createBannerImage = async (imageUpload, id) => {
  const imageName = imageUpload.name;
  try {
    const path = `banners/promoBanners/${id}/${imageName}`;
    const fileUrl = await uploadFile(path, imageUpload);

    const newImg = { imageURL: fileUrl };

    await updatePromoBanner(id, newImg);
  } catch (error) {
    console.log(error);
  }
};
export const createHomeBannerImage = async (imageUpload, id) => {
  const imageName = imageUpload.name;
  try {
    const path = `banners/wellnessBanners/${id}/${imageName}`;
    const fileUrl = await uploadFile(path, imageUpload);

    const newImg = { imgURL: fileUrl };

    await updateHomeBanner(id, newImg);
  } catch (error) {
    console.log(error);
  }
};
export const createHomeCarouselImage = async (imageUpload, id) => {
  const imageName = imageUpload.name;
  try {
    const path = `banners/wellnessCarousel/${id}/${imageName}`;
    const fileUrl = await uploadFile(path, imageUpload);

    const newImg = { imageURL: fileUrl };

    await updateHomeCarouselBanner(id, newImg);
  } catch (error) {
    console.log(error);
  }
};
export const createBlogImage = async (imageUpload, id) => {
  const imageName = imageUpload.name;
  try {
    const path = `blogs/${id}/${imageName}`;
    const fileUrl = await uploadFile(path, imageUpload);

    const newImg = { imageURL: fileUrl };

    await updateBlog(id, newImg);
  } catch (error) {
    console.log(error);
  }
};
export const createActivityBannerImage = async (imageUpload, id) => {
  const imageName = imageUpload.name;
  try {
    const path = `banners/wellnessCarousel/${id}/${imageName}`;
    const fileUrl = await uploadFile(path, imageUpload);

    const newImg = { imgURL: fileUrl };

    await updateActivityBanner(id, newImg);
  } catch (error) {
    console.log(error);
  }
};
export const uploadFile = async (path, file) => {
  if (file === null) return;

  try {
    const upload = await storageRef.ref(path).put(file);
    const fileUrl = await upload.ref.getDownloadURL();

    return fileUrl;
  } catch (error) {
    console.log(file, path);
    console.log("No se puede subir el archivo seleccionado");
  }
};

export const updatePromoBanner = async (id, data) => {
  try {
    db.collection("banners")
      .doc("exchange")
      .collection("promotionCarousel")
      .doc(id)
      .set(data, { merge: true });
    console.log("Documento actualizado:", data);
  } catch (error) {
    console.log(error);
  }
};
export const updateHomeBanner = async (id, data) => {
  try {
    db.collection("banners")
      .doc("wellness")
      .collection("wellnessBanners")
      .doc(id)
      .set(data, { merge: true });
    console.log("Documento actualizado:", data);
  } catch (error) {
    console.log(error);
  }
};
export const updateActivityBanner = async (id, data) => {
  try {
    db.collection("banners")
      .doc("activity")
      .collection("news")
      .doc(id)
      .set(data, { merge: true });
    console.log("Documento actualizado:", data);
  } catch (error) {
    console.log(error);
  }
};
export const updateHomeCarouselBanner = async (id, data) => {
  try {
    db.collection("banners")
      .doc("wellness")
      .collection("wellnessCarousel")
      .doc(id)
      .set(data, { merge: true });
    console.log("Documento actualizado:", data);
  } catch (error) {
    console.log(error);
  }
};
export const updateBlog = async (id, data) => {
  try {
    db.collection("blogs").doc(id).set(data, { merge: true });
    console.log("Documento actualizado:", data);
  } catch (error) {
    console.log(error);
  }
};

export const deleteBanner = async (id) => {
  try {
    await db
      .collection("banners")
      .doc("exchange")
      .collection("promotionCarousel")
      .doc(id)
      .delete();
  } catch (error) {
    console.log(error);
  }
};
export const deleteHomeBanner = async (id) => {
  try {
    await db
      .collection("banners")
      .doc("wellness")
      .collection("wellnessBanners")
      .doc(id)
      .delete();
  } catch (error) {
    console.log(error);
  }
};
export const deleteCarouselBanner = async (id) => {
  try {
    await db
      .collection("banners")
      .doc("wellness")
      .collection("wellnessCarousel")
      .doc(id)
      .delete();
  } catch (error) {
    console.log(error);
  }
};
export const deleteActivityBanner = async (id) => {
  try {
    await db
      .collection("banners")
      .doc("activity")
      .collection("news")
      .doc(id)
      .delete();
  } catch (error) {
    console.log(error);
  }
};

export const getBanners = () => ({
  type: types.banners,
});

export const addBanners = (banners) => ({
  type: types.addBanners,
  payload: banners,
});
export const getPromoBanners = (promoBanners) => ({
  type: types.getPromoBanners,
  payload: promoBanners,
});
export const getHomeBanners = (homeBanners) => ({
  type: types.getHomeBanners,
  payload: homeBanners,
});
export const getCarouselBanners = (carouselBanners) => ({
  type: types.getCarouselBanners,
  payload: carouselBanners,
});
export const getActivityBanners = (activityBanners) => ({
  type: types.getActivityBanners,
  payload: activityBanners,
});

export const isLoading = (isLoading) => ({
  type: types.isLoadingPromoBanners,
  payload: {
    isLoading,
  },
});
