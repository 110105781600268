import { useCallback, useEffect, useMemo, useState } from "react";

import ProductsTable from "../commerce-table/products-table";

import { ReactComponent as DarkPencilIcon } from "./../../assests/pencil.svg";
import { ReactComponent as DarkTrashCanIcon } from "./../../assests/trashcan.svg";
import { ReactComponent as DarkRoundedMenuIcon } from "./../../assests/rounded-hamburger-menu.svg";

import "./commerce-table-tabs.scss";
import { Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addProducts } from "../../redux/products/actions/actions";
import { sortProductsTable } from "../../custom hooks/sort-table/sort-products-table";
import { searchTable } from "../../custom hooks/search-table/search-table";
import Swal from "sweetalert2";
import { deleteProduct, getProducts } from "../../firebase/firebase.utils";
import { Stack } from "react-bootstrap";
import TableFilters from "../table/table-filters";
import { Box } from "@mui/material";

const ProductTableContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxProducts = useSelector((state) => state.products);

  const matchesUp1200 = useMediaQuery("(min-width:1200px)");

  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const headers = [
    { name: "Nombre", accessor: "planName" },
    { name: "Moneda", accessor: "currency" },
    { name: "Precio", accessor: "price" },
    { name: "IVA", accessor: "tax" },
    { name: "Aseguradora", accessor: "insurer" },
    { name: "Total", accessor: "total" },
    { name: "Prorrateo", accessor: "apportionment" },
    { name: "En la app", accessor: "status" },
    { name: "Acciones", accessor: "actions" },
  ];
  const headersSerch = [
    { name: "Nombre", accessor: "planName" },
    { name: "Moneda", accessor: "currency" },
    { name: "Precio", accessor: "price" },
    { name: "IVA", accessor: "tax" },
    { name: "Aseguradora", accessor: "insurer" },
    { name: "Total", accessor: "total" },
    { name: "Prorrateo", accessor: "apportionment" },
    { name: "MO", accessor: "status" },
  ];

  const handleClick = (url) => {
    navigate(url);
  };

  const getPrice = (finalPrice) => {
    const price = finalPrice / 1.13;

    return price.toFixed(2);
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-danger me-2",
      cancelButton: "btn btn-secondary",
    },
    buttonsStyling: false,
  });

  const callDeleteProduct = (id) => {
    swalWithBootstrapButtons
      .fire({
        title: "¿Desea eliminar este producto?",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Eliminar",
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteProduct(id);
          Swal.fire("Producto eliminado", "", "success");
          getAllProducts();
        }
      });
  };

  const newProductsList = useMemo(
    () =>
      filteredProducts.map((product) => {
        //console.log(product);
        return {
          id: product.id,
          planName: product.subTier ? product.subTier : "--",
          currency: product.currency ? product.currency : "--",
          price: product.finalPrice ? getPrice(product.finalPrice) : 0,
          insurer: product.insurer ? product.insurer : "--",
          tax: product.iva ? (
            <span className="text-active">{product.iva + "%"}</span>
          ) : (
            <span className="text-active">13%</span>
          ),
          taxAmount: product.finalPrice
            ? (product.finalPrice - getPrice(product.finalPrice)).toFixed(2)
            : 0,
          total: product.finalPrice ? product.finalPrice : 0,
          apportionment: product.apportionment === "Si" ? "Si" : "No",
          status: product.isActive ? (
            <span className="text-active">Mostrado</span>
          ) : (
            <span className="text-danger">No Mostrado</span>
          ),
          actions: (
            <Stack direction="horizontal" gap={2} key={product.id}>
              <span variant="secondary" size="sm" className="text-light">
                <DarkPencilIcon
                  onClick={() => handleClick(`edit-product/${product.id}`)}
                />
              </span>
              <span variant="secondary" size="sm" className="text-light">
                <DarkTrashCanIcon
                  onClick={() => callDeleteProduct(product.id)}
                />
              </span>
              <span variant="secondary" size="sm" className="text-light">
                <DarkRoundedMenuIcon />
              </span>
            </Stack>
          ),
        };
      }),
    [filteredProducts]
  );

  const [itemsPerPage, setItemsPerPage] = useState(5);
  //const [filteringBy, setfilteringBy] = useState("Name");

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + Number(itemsPerPage);
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(newProductsList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(newProductsList.length / Number(itemsPerPage)));
  }, [itemOffset, newProductsList, itemsPerPage]);

  const getAllProducts = useCallback(async () => {
    setLoading(true);
    const productsList = await getProducts();

    setProducts(productsList);
    setFilteredProducts(products);
    dispatch(addProducts(productsList));
  }, [dispatch, products]);

  useEffect(() => {
    setLoading(true);
    if (reduxProducts.products) {
      const { products } = reduxProducts;
      setProducts(products);
      setFilteredProducts(products);
      setLoading(false);
    } else {
      getAllProducts();
    }
  }, [reduxProducts]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % newProductsList.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const sortTableElements = (sortingMethod) => {
    const sortedItems = sortProductsTable(currentItems, sortingMethod);
    setCurrentItems(sortedItems);
  };

  const dropdownList = [
    {
      valuesList: [
        { name: "IVA + a -" },
        { name: "IVA - a +" },
        { name: "Precio + a -" },
        { name: "Precio - a +" },
      ],
      label: "",
      filterMethod: sortTableElements,
    },
  ];

  const showActiveProducts = (list) => {
    const activeProducts = list.filter((product) => product.isActive);

    setFilteredProducts(activeProducts);
  };

  const showInactiveProducts = (list) => {
    const inactiveProducts = list.filter((product) => !product.isActive);

    setFilteredProducts(inactiveProducts);
  };

  const showAllProducts = (list) => {
    setFilteredProducts(list);
  };

  const options = [
    { text: "Todos", filterMethod: () => showAllProducts(products) },
    { text: "Activo", filterMethod: () => showActiveProducts(products) },
    { text: "Inactivo", filterMethod: () => showInactiveProducts(products) },
  ];

  const [searchField, setSearchField] = useState("");
  const rows = searchTable(currentItems, headersSerch, searchField);

  return (
    <>
      <Typography variant="h5" component="h2" my={3}>
        Productos
      </Typography>

      {matchesUp1200 ? null : (
        <Box mb={-4}>
          <TableFilters
            searchRows={setSearchField}
            dropdowns={dropdownList}
            handleRefresh={() => getAllProducts()}
            showOnLargeScreens={true}
          />
        </Box>
      )}

      <ProductsTable
        setSearchField={setSearchField}
        dropdownList={dropdownList}
        options={options}
        getAllProducts={getAllProducts}
        loading={loading}
        headers={headers}
        rows={rows}
        pageCount={pageCount}
        handlePageClick={handlePageClick}
        setItemsPerPage={setItemsPerPage}
      />
    </>
  );
};

export default ProductTableContainer;
