import Form from "react-bootstrap/Form";
import { Button, Spinner, Stack } from "react-bootstrap";

import { useFormik } from "formik";

import FormInput from "../form-input/form-input";
import { useState } from "react";
import {
  addAdminHistoryEntry,
  addInsurer,
  updateInsurer,
} from "../../firebase/firebase.utils";
import { insurerSchema } from "../../schemas/insurer/insurerSchema";
import { useSelector } from "react-redux";
import { getChangedFields } from "../../utils/getChangeFields";
import { translations } from "./spanishFieldName";

const InsurerEditForm = ({ onClose, handleRefresh, insurer }) => {
  const [loading, setLoading] = useState(false);

  const { user: currentAdmin } = useSelector((state) => state.auth);

  const initialData = {
    insurerName: insurer?.name ?? "",
    description: insurer?.description ?? "",
  };

  const { handleChange, handleSubmit, values, errors } = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: insurerSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);

      const data = {
        name: values.insurerName,
        description: values.description,
      };

      const fieldsChanged = getChangedFields(values, initialData, translations);

      const adminHistory = {
        modificationDate: new Date(),
        admin: {
          id: currentAdmin.uid,
          name: currentAdmin.displayName,
          email: currentAdmin.email,
        },
        modifications: fieldsChanged,
      };

      if (insurer) {
        updateInsurer(data, insurer.id);
        addAdminHistoryEntry(
          adminHistory,
          `insurers/${insurer.id}/adminHistory`
        );
      } else {
        addInsurer(data);
      }

      setTimeout(() => {
        setLoading(false);
        handleRefresh();
        //navigate("/achievements");
        resetForm();
        onClose(false);
      }, 2000);
    },
  });

  return (
    <Form
      className="p-2"
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Form.Group className="mb-3" controlId="formGroupInsurer">
        <Form.Label htmlFor="insurerName">Nombre</Form.Label>
        <FormInput
          type="text"
          id="insurerName"
          name="insurerName"
          className="bg-success border-0 p-2 my-3"
          onChange={handleChange}
          errorList={errors}
          value={values.insurerName}
        />

        <Form.Control.Feedback type="invalid">
          {errors.insurerName}
        </Form.Control.Feedback>
      </Form.Group>

      <FormInput
        as="textarea"
        rows={5}
        cols={25}
        placeholder={"Escribe una descripcion"}
        id="description"
        name="description"
        className="bg-success border-0 p-2 my-3"
        onChange={handleChange}
        errorList={errors}
        value={values.description}
      />

      <Form.Control.Feedback type="invalid">
        {errors.description}
      </Form.Control.Feedback>

      <Stack direction="horizontal">
        <Button
          variant="light"
          className="rounded-pill ms-auto"
          onClick={() => onClose(false)}
        >
          Cancelar
        </Button>
        <Button
          variant="primary"
          size="sm"
          className="rounded-pill text-light px-3"
          type="submit"
          disabled={loading}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {!loading && (
            <span style={{ color: "white !important" }}>Aceptar</span>
          )}
        </Button>
      </Stack>
    </Form>
  );
};

export default InsurerEditForm;
