import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Image } from "react-bootstrap";
import { Stack, Container, Col, Row } from "react-bootstrap";

import CustomModal from "../modal/customModal";
import ExchangeDeletion from "../exchange-deletion/exchange-deletion";
import ExchangeEdit from "../exchange-edit/exchange-edit";
import CreateExchangeModal from "../create-exchange-form/create-exchange-form";

import { BiPencil } from "react-icons/bi";
import { BiTrash } from "react-icons/bi";
import { useEffect, useState } from "react";
import { getAllExchangesRealTime } from "../../firebase/firebase.utils";

import "./sponsor-exchange.scss";
import { useTheme } from "@emotion/react";

const SponsorExchange = ({ id, business }) => {
  const [exchanges, setExchanges] = useState([]);
  const [filteredExchanges, setFilteredExchanges] = useState([]);
  const [selectedExchange, setSelectedExchange] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateExchangeModal, setShowCreateExchangeModal] = useState(false);
  const [selectedButton, setSelectedButton] = useState("Todos");

  const theme = useTheme();

  useEffect(() => {
    const query = getAllExchangesRealTime(id);

    query.onSnapshot((querySnapshot) => {
      const exchangesArr = [];
      querySnapshot.forEach((doc) => {
        exchangesArr.push({ id: doc.id, ...doc.data(), selected: false });
      });
      setExchanges(exchangesArr);
      setFilteredExchanges(exchangesArr);
    });
  }, [id]);

  if (!exchanges && !selectedExchange) {
    return;
  }

  const cleanSelectedProducts = (id) => {
    const cleanArray = filteredExchanges.map((exchange) => ({
      ...exchange,
      selected: false,
    }));

    const index = cleanArray.findIndex((exchange) => exchange.id === id);

    cleanArray[index].selected = !cleanArray[index].selected;

    //setSelectedExchange(cleanArray[index]);
    setFilteredExchanges(cleanArray);
  };

  const handleDelete = () => {
    const itemToDelete = filteredExchanges.find(
      (exchange) => exchange.selected
    );
    if (itemToDelete) {
      setShowDeleteModal(true);
    }

    setSelectedExchange(itemToDelete);
  };

  const handleEdit = () => {
    const itemToUpdate = filteredExchanges.find(
      (exchange) => exchange.selected
    );
    if (itemToUpdate) {
      setShowEditModal(true);
    }
    setSelectedExchange(itemToUpdate);
  };

  const showActiveExchanges = (exchangesList, event) => {
    setSelectedButton(event.target.textContent);
    const onlyActiveExchanges = exchangesList.filter(
      (exchange) => exchange.status === "active"
    );

    setFilteredExchanges(onlyActiveExchanges);
  };

  const showExpiredExchanges = (exchangesList, event) => {
    setSelectedButton(event.target.textContent);
    const onlyExpiredExchanges = exchangesList.filter((exchange) => {
      return new Date() > exchange.dueDate.toDate();
    });

    setFilteredExchanges(onlyExpiredExchanges);
  };

  const showAllExchanges = (event) => {
    setSelectedButton(event.target.textContent);
    setFilteredExchanges(exchanges);
  };

  return (
    <>
      <Card className="border-0 mt-3">
        <Card.Header className="bg-success p-0 pb-2 border-bottom-0">
          <h2 className="h5 fw-bold py-3 px-4">Canjes</h2>
          <Stack direction="horizontal" gap={3} className="py-3 px-4">
            <Button
              variant="light"
              style={{ boxShadow: "0px 7px 6px -3px rgba(0,0,0,0.35)" }}
              className={`rounded-pill border-0 ${
                selectedButton === "Todos"
                  ? "bg-primary text-light"
                  : "bg-light"
              }`}
              size="sm"
              onClick={(event) => showAllExchanges(event)}
            >
              Todos
            </Button>
            <Button
              variant="light"
              style={{ boxShadow: "0px 7px 6px -3px rgba(0,0,0,0.35)" }}
              className={`rounded-pill border-0 ${
                selectedButton === "Activos"
                  ? "bg-primary text-light"
                  : "bg-light"
              }`}
              size="sm"
              onClick={(event) => showActiveExchanges(exchanges, event)}
            >
              Activos
            </Button>
            <Button
              variant="light"
              style={{ boxShadow: "0px 7px 6px -3px rgba(0,0,0,0.35)" }}
              className={`rounded-pill border-0 ${
                selectedButton === "Vencidos"
                  ? "bg-primary text-light"
                  : "bg-light"
              }`}
              size="sm"
              onClick={(event) => showExpiredExchanges(exchanges, event)}
            >
              Vencidos
            </Button>

            <Button
              variant="light"
              style={{ boxShadow: "0px 7px 6px -3px rgba(0,0,0,0.35)" }}
              className="rounded-pill ms-auto"
              // className={`rounded-pill ${
              //   selectedButton === "Todos" ? "bg-primary text-light" : "bg-light"
              // }`}
              size="sm"
              onClick={(event) => setShowCreateExchangeModal(true)}
            >
              Agregar
            </Button>
          </Stack>
        </Card.Header>
        <Card.Body className="p-0">
          <Stack direction="horizontal" gap={2} className="py-3 px-4">
            <p className="text-muted me-auto mb-0">Acciones</p>

            <span
              className="bg-secondary rounded-circle d-flex justify-content-center align-items-center"
              style={{ width: "25px", height: "25px" }}
            >
              <BiTrash
                color={theme.palette.light.main}
                size={15}
                className="hover-effect"
                onClick={() => {
                  handleDelete();
                }}
              />
            </span>

            <span
              className="bg-primary rounded-circle d-flex justify-content-center align-items-center"
              style={{ width: "25px", height: "25px" }}
            >
              <BiPencil
                className="hover-effect"
                onClick={handleEdit}
                color={theme.palette.light.main}
                size={15}
              />
            </span>
          </Stack>

          <Container className="px-4 bg-success rounded-bottom">
            <Row className="py-3">
              <Col>
                <p className="text-muted me-auto fw-bold">Producto</p>
              </Col>
              <Col className="text-end">
                <p className="text-muted fw-bold">Canje</p>
              </Col>
            </Row>
            {filteredExchanges.map((exchange) => {
              return (
                <Row
                  className="pb-3 hover-effect bg-effect"
                  onClick={(e) => {
                    cleanSelectedProducts(exchange.id);
                  }}
                >
                  <Col>
                    <Stack direction="horizontal">
                      <small
                        className={`${
                          exchange.selected ? "bg-primary" : "bg-secondary"
                        } d-inline-block rounded-circle me-3`}
                        style={{ width: "12px", height: "12px" }}
                        id={exchange.id}
                      ></small>
                      <p className="mb-0 fw-bold fs-6">{exchange.name}</p>
                    </Stack>

                    <Stack direction="horizontal">
                      <small
                        className="bg-transparent rounded-circle me-3"
                        style={{ width: "12px", height: "12px" }}
                      ></small>
                      <small className="text-muted">
                        {exchange.dueDate
                          ?.toDate()
                          .toLocaleDateString("es-CR", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          })}
                      </small>
                    </Stack>
                  </Col>
                  <Col className="text-end">
                    <small className="ms-auto">{exchange.coinPrice}</small>
                  </Col>
                </Row>
              );
            })}
          </Container>
        </Card.Body>
      </Card>
      <CustomModal
        show={showDeleteModal}
        handleClose={setShowDeleteModal}
        modalTitle={() => "Eliminar Canje"}
      >
        <ExchangeDeletion
          business={business}
          exchangeId={selectedExchange?.id}
          sponsorId={id}
          onClose={setShowDeleteModal}
          placeholderValues={selectedExchange}
        />
      </CustomModal>

      <CustomModal
        show={showEditModal}
        handleClose={setShowEditModal}
        modalTitle={() => (
          <HeaderButtons buttonText={{ first: "Editar", second: "Canje" }} />
        )}
      >
        <ExchangeEdit
          business={business}
          exchangeId={selectedExchange?.id}
          sponsorId={id}
          onClose={setShowEditModal}
          placeholderValues={selectedExchange}
          buttonText={{ cancel: "Cancelar", continue: "Guardar" }}
        />
      </CustomModal>

      <CustomModal
        show={showCreateExchangeModal}
        handleClose={setShowCreateExchangeModal}
        modalTitle={() => (
          <HeaderButtons buttonText={{ first: "Crear", second: "Canje" }} />
        )}
      >
        <CreateExchangeModal
          onClose={setShowCreateExchangeModal}
          id={id}
          business={business}
        />
      </CustomModal>
    </>
  );
};

const HeaderButtons = ({ buttonText }) => (
  <Stack direction="horizontal" gap={2} className="">
    <Button
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#9A9A9A" }}
    >
      {buttonText.first}
    </Button>
    <Button
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#AAAAAA" }}
    >
      {buttonText.second}
    </Button>
  </Stack>
);

export default SponsorExchange;
