import { useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { Stack } from "react-bootstrap";

import Swal from "sweetalert2";
import CustomModal from "../modal/customModal";
import PromotionEdit from "../promotion-edit/promotion-edit";

//import winePromo from "./../../assests/wine-promo.jpg";
import coinFallback from "./../../assests/bg-full-coin.jpg";

// import { ReactComponent as DarkPencilIcon } from "./../../assests/pencil.svg";
// import { ReactComponent as DarkTrashCanIcon } from "./../../assests/trashcan.svg";
import { TfiPowerOff } from "react-icons/tfi";
import { TfiPencil } from "react-icons/tfi";
import { TfiTrash } from "react-icons/tfi";

import {
  deleteBanner,
  getSponsorByName,
  updateBanner,
} from "../../firebase/firebase.utils";

import "./advertisement-card.scss";

const AdvertisementCard = ({
  size,
  bannerData,
  bannerNumber,
  currentPromo,
}) => {
  const numbers = [];

  const [itemNumber, setItemNumber] = useState(bannerData.itemNumber);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentSponsor, setCurrentSponsor] = useState(null);



  useEffect(() => {
    const getSponsor = async () => {
      const sponsor = await getSponsorByName(currentPromo.businessName);

      setCurrentSponsor(sponsor[0]);
    };

    getSponsor();
  }, [currentPromo.businessName]);

  const handleChange = (event) => {
    event.preventDefault();

    setItemNumber(event.target.value);

    const item = Number(event.target.value);
    updateBanner(bannerData.id, { itemNumber: item });
  };

  for (let index = 1; index < bannerNumber + 1; index++) {
    numbers.push(index);
  }

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-primary me-2",
      cancelButton: "btn btn-secondary",
      denyButton: "btn btn-danger me-2",
    },
    buttonsStyling: false,
  });

  const callUpdateBannerState = () => {
    swalWithBootstrapButtons
      .fire({
        title: "Confirme su elección",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: !bannerData.isActive ? "Activar" : "Desactivar",
        confirmButtonColor: "#FAA60F",
      })
      .then((result) => {
        if (result.isConfirmed) {
          updateBanner(bannerData.id, {
            isActive: !bannerData.isActive,
          });
          Swal.fire("Estado actualizado", "", "success");
        }
      });
  };

  const callDeleteBanner = () => {
    swalWithBootstrapButtons
      .fire({
        title: "Confirme su elección",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Eliminar",
        confirmButtonColor: "#FAA60F",
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteBanner(bannerData.id);
          Swal.fire("Banner eliminado", "", "success");
        }
      });
  };

  if (!currentSponsor) return;

  return (
    <Card
      className={`shadow border-0 ${
        bannerData.isActive ? "" : "advertisement-inactive"
      }`}
      style={size}
    >
      <Card.Header className="bg-light">
        <Stack direction="horizontal">
          <Form.Group className="w-25">
            <Form.Select
              size="sm"
              onChange={handleChange}
              name="number"
              value={itemNumber}
              className="border-0 bg-light rounded-0 shadow-none"
            >
              {numbers.map((num) => {
                return (
                  <option value={num} name="number">
                    {num}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>

          <Stack direction="horizontal" className="ms-auto" gap={2}>
            {/* <DarkPencilIcon />
            <DarkTrashCanIcon /> */}
            <TfiPencil
              className="advertisement-icon"
              onClick={() => setShowEditModal(true)}
            />
            <TfiTrash
              className="advertisement-icon"
              onClick={callDeleteBanner}
            />
            <TfiPowerOff
              className="advertisement-icon"
              onClick={callUpdateBannerState}
            />
          </Stack>
        </Stack>
      </Card.Header>
      <Card.Img
        variant="top"
        src={currentPromo?.imageURL ? currentPromo.imageURL : coinFallback}
        style={{
          borderRadius: 0,
          width: "100%",
          height: "200px",
          objectFit: "cover",
        }}
      />
      <Card.Title className="px-4 py-3 fs-5">
        {currentPromo?.name ? currentPromo.name : "--"}
      </Card.Title>
      <Card.Body className="px-4">
        <small className="fw-bold">
          {currentPromo?.businessName ? currentPromo.businessName : "--"}
        </small>

        <Stack direction="horizontal" gap={3}>
          <div>
            <small className="text-muted d-block">Vistas</small>
            <p className="fs-2 text-primary fw-bold">5.421</p>
          </div>
          <div>
            <small className="text-primary d-block mb-n2">83%</small>{" "}
            <small className="mb-0">de los usuarios</small>
          </div>
        </Stack>

        <Stack direction="horizontal" gap={3}>
          <div>
            <small className="text-muted d-block">Aperturas</small>
            <p className="fs-2 text-primary fw-bold">2.121</p>
          </div>
          <div>
            <small className="text-primary d-block mb-n2">83%</small>{" "}
            <small className="mb-0">de los usuarios</small>
          </div>
        </Stack>

        <small className="text-muted d-block mb-2">Audiencias</small>
        <Button
          size="sm"
          className="border-0 text-light rounded-pill"
          style={{ backgroundColor: "#8A9BC4" }}
        >
          General
        </Button>
      </Card.Body>

      <CustomModal
        show={showEditModal}
        handleClose={setShowEditModal}
        modalTitle={() => (
          <HeaderButtons buttonText={{ first: "Editar", second: "Promo" }} />
        )}
      >
        <PromotionEdit
          selectedPromo={currentPromo}
          business={currentSponsor}
          promotionId={currentPromo?.id}
          sponsorId={currentSponsor?.id}
          onClose={setShowEditModal}
          placeholderValues={currentPromo}
          buttonText={{ cancel: "Cancelar", continue: "Guardar" }}
        />
      </CustomModal>
    </Card>
  );
};

const HeaderButtons = ({ buttonText }) => (
  <Stack direction="horizontal" gap={2} className="">
    <Button
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#9A9A9A" }}
    >
      {buttonText.first}
    </Button>
    <Button
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#AAAAAA" }}
    >
      {buttonText.second}
    </Button>
  </Stack>
);

export default AdvertisementCard;
