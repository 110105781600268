import { useState, useRef, useEffect, useCallback } from "react";

import { Button } from "react-bootstrap";
import { Stack } from "react-bootstrap";

import BriefCard from "../../components/brief-card/brief-card";

import { Container } from "@mui/material";
import { Typography } from "@mui/material";
import { styles } from "../../styles/styles";

import { BlogsTable } from "../../components/blogs/BlogsTable";
import { getBlogs } from "../../redux/blogs/blogs";
import { useDispatch, useSelector } from "react-redux";
import BlogModal from "../../components/blogs/BlogModal";

const BlogsPage = () => {
  const [showSponsorEditionModal, setShowSponsorEditionModal] = useState(false);
  const [count, setCount] = useState(0);
  const { blogs } = useSelector((state) => state.blogs);
  const [blogsFromDb, setBlogsFromDB] = useState([]);
  const dispatch = useDispatch();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const isFirstUse = useRef(true);
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [loading, setloading] = useState(true);
  const handleRefresh = () => {
    setCount((current) => current + 1);
  };

  const getBlogsFromDb = useCallback(async () => {
    try {
      const blogs = await dispatch(getBlogs());
      setBlogsFromDB(blogs);
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  }, [blogs]);
  useEffect(() => {
    if (isFirstUse.current) {
      setloading(true);
      getBlogsFromDb();
      setBlogsFromDB(blogs);

      isFirstUse.current = false;
    } else if (blogs.length > 0) {
      setloading(false);
      setBlogsFromDB(blogs);
    }
  }, [getBlogsFromDb, blogs]);

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };
  const handleOpenEditModal = () => {
    setIsEditModalOpen(true);
  };
  // const handleCopy = () => {
  //   const locationsJSON = JSON.stringify(blogs);

  //   navigator.clipboard
  //     .writeText(locationsJSON)
  //     .then(() => {
  //       console.log("JSON list copied to clipboard");
  //     })
  //     .catch((error) => {
  //       console.log("Error copying JSON list to clipboard:", error);
  //     });
  // };
  return (
    <Container maxWidth={"xl"}>
      <Stack direction="horizontal" gap={2} className="mb-4">
        <Typography variant="h5" sx={styles.title}>
          Crear Categoría de Blogs
        </Typography>
        <Button
          className="ms-auto rounded-pill"
          style={{ color: "white !important" }}
          onClick={handleOpenEditModal}
        >
          Crear blog
        </Button>
      </Stack>
      <BriefCard />
      <BlogModal
        open={isEditModalOpen}
        onClose={handleCloseEditModal}
        handleRefresh={handleRefresh}
        mode={"add"}
      />
      <BlogsTable
        loading={loading}
        setloading={setloading}
        blogsFromDb={blogsFromDb}
        setBlogsFromDB={setBlogsFromDB}
        refresh={getBlogsFromDb}
        noDataAvailable={noDataAvailable}
      />
    </Container>
  );
};

export default BlogsPage;
