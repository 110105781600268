import { useState, useEffect } from "react";

import Form from "react-bootstrap/Form";
import { Button, Stack, Col, Row, Spinner } from "react-bootstrap";

import { useFormik } from "formik";
import * as Yup from "yup";

import FormInput from "../form-input/form-input";

import "./achievement-edit.scss";
import {
  updateAchievement,
  createAchievementImage,
} from "../../firebase/firebase.utils";
import UploadLogo from "../upload-logo/upload-logo";

const categories = [
  { name: "1" },
  { name: "2" },
  { name: "3" },
  { name: "4" },
  { name: "5" },
];

const awards = [
  { name: "1" },
  { name: "2" },
  { name: "3" },
  { name: "4" },
  { name: "5" },
  { name: "6" },
  { name: "7" },
  { name: "8" },
  { name: "9" },
  { name: "10" },
];

const modules = [{ name: "1" }, { name: "2" }, { name: "3" }];

const AchievementEdition = ({ onClose, achievement, handleRefresh }) => {
  const [imageUpload, setImageUpload] = useState(achievement.imageUrl);
  const [disabled, setDisabled] = useState(true);
  const [hasRewardDef, setHasRewardDef] = useState(achievement.hasReward);
  const [achievementStatus, setAchievementStatus] = useState(
    achievement.isActive
  );

  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState(achievement.imageUrl);
  const [fileUrl, setFileUrl] = useState(null);
  const [rewards, setRewards] = useState(null);

  const [active, setActive] = useState(() =>
    achievement.isActive ? true : false
  );
  const [inactive, setInactive] = useState(() =>
    !achievement.isActive ? true : false
  );

  const [withReward, setWithReward] = useState(() =>
    achievement.hasReward ? true : false
  );
  const [withOutReward, setWithOutReward] = useState(() =>
    !achievement.hasReward ? true : false
  );

  useEffect(() => {
    if (achievement.hasReward) {
      setDisabled(false);
      setRewards(null);
    }
  }, [achievement]);

  const handleSelectYes = () => {
    setDisabled(false);
    setRewards(null);
    setHasRewardDef(true);
    setWithReward(true);
    setWithOutReward(false);
  };

  const handleSelectNo = () => {
    setDisabled(true);
    setRewards("0");
    setHasRewardDef(false);
    setWithReward(false);
    setWithOutReward(true);
  };

  const handleActiveYes = () => {
    setAchievementStatus(true);
    setActive(true);
    setInactive(false);
  };

  const handleActiveNo = () => {
    setAchievementStatus(false);
    setActive(false);
    setInactive(true);
  };

  const rewardAmount = (reward) => {
    let finalReward = "";

    if (rewards == null) {
      finalReward = reward;
    } else if (rewards == "0") {
      finalReward = "0";
    }

    return finalReward;
  };

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      achievementName: achievement.name,
      title: achievement.title,
      tag: achievement.tag,
      description: achievement.description,
      hasReward: hasRewardDef,
      reward: rewards,
      imageURL: achievement.imageUrl,
      module: achievement.module,
      status: achievementStatus,
    },
    // validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const achievementData = {
        name: values.achievementName,
        title: values.title,
        tag: values.tag,
        description: values.description,
        hasReward: hasRewardDef,
        reward: rewardAmount(values.reward),
        imageUrl: imageUpload,
        module: values.module,
        isActive: achievementStatus,
      };

      updateAchievement(achievement.id, achievementData);
      if (achievement.imageUrl != imageUpload) {
        createAchievementImage(imageUpload, achievement.id);
      }

      setTimeout(() => {
        setLoading(false);
        handleRefresh();
        resetForm();
        onClose(false);
      }, 2000);
    },
  });
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      noValidate
      className="p-3"
    >
      <Form.Group>
        <FormInput
          label={"Nombre del achievement"}
          onChange={handleChange}
          value={values.achievementName}
          type="text"
          name="achievementName"
          errorList={errors}
        />

        <Form.Control.Feedback type="invalid">
          {errors.achievementName}
        </Form.Control.Feedback>
      </Form.Group>

      <Row>
        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: "14px" }} className="mb-3">
              Award
            </Form.Label>
            <Form.Select
              size="sm"
              onChange={handleChange}
              name="title"
              value={values.title}
              defaultValue={achievement.title}
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
            >
              {awards.map((award) => {
                return (
                  <option value={award.name} name="title">
                    {award.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: "14px" }} className="mb-3">
              Categoria
            </Form.Label>
            <Form.Select
              size="sm"
              onChange={handleChange}
              name="tag"
              value={values.tag}
              defaultValue={achievement.tag}
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
            >
              {categories.map((category) => {
                return (
                  <option value={category.name} name="tag">
                    {category.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Form.Group>
        <Form.Label style={{ fontSize: "14px" }} className="mb-3">
          Descripción
        </Form.Label>
        <FormInput
          as="textarea"
          className="description"
          onChange={handleChange}
          name="description"
          value={values.description}
          errorList={errors}
        />
        <Form.Control.Feedback type="invalid">
          {errors.description}
        </Form.Control.Feedback>
      </Form.Group>

      <Row>
        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: "14px" }} className="mt-3">
              Recompensa
            </Form.Label>

            <Form.Check
              checked={withReward}
              id="0"
              type="radio"
              name="hasReward"
              label="Si"
              size="sm"
              onChange={handleSelectYes}
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
            />
            <Form.Check
              checked={withOutReward}
              id="1"
              type="radio"
              name="hasReward"
              label="No"
              size="sm"
              onChange={handleSelectNo}
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
            />
            <Form.Control.Feedback type="invalid">
              {errors.hasReward}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: "14px" }} className="mt-3">
              Coins
            </Form.Label>
            <FormInput
              disabled={disabled}
              onChange={handleChange}
              value={rewards}
              placeholder={achievement.reward}
              type="number"
              name="reward"
              errorList={errors}
            />
            <Form.Control.Feedback type="invalid">
              {errors.coins}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <UploadLogo
            title={"Logotipo"}
            loadUrl={setFileUrl}
            imageUpload={imageUpload}
            setImageUpload={setImageUpload}
            setImage={setImage}
            image={image}
          />
        </Col>
        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: "14px" }} className="mb-3">
              Módulo
            </Form.Label>
            <Form.Select
              size="sm"
              onChange={handleChange}
              name="module"
              value={values.module}
              defaultValue={achievement.module}
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
            >
              {modules.map((mod) => {
                return (
                  <option value={mod.name} name="module">
                    {mod.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label style={{ fontSize: "14px" }} className="mt-3">
              Status
            </Form.Label>
            <Form.Check
              checked={active}
              id="0"
              type="radio"
              name="isActive"
              label="Activo"
              size="sm"
              onChange={handleActiveYes}
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
            />
            <Form.Check
              checked={inactive}
              id="1"
              type="radio"
              name="isActive"
              label="Inactivo"
              size="sm"
              onChange={handleActiveNo}
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
            />
          </Form.Group>
        </Col>
      </Row>

      <Stack direction="horizontal" className="mt-3">
        <Button
          variant="light"
          size="sm"
          className="rounded-pill ms-auto px-3"
          onClick={() => onClose(false)}
        >
          Cancelar
        </Button>
        <Button
          variant="primary"
          size="sm"
          className="rounded-pill text-light px-3"
          type="submit"
          disabled={loading}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {!loading && (
            <span style={{ color: "white !important" }}>Guardar</span>
          )}
        </Button>
      </Stack>
    </Form>
  );
};

export default AchievementEdition;
