import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { styles } from "../../styles/styles";
import { ASbutton } from "../buttons/ASbutton";
import { MdClose } from "react-icons/md";
import { createSponsor } from "../../firebase/firebase.utils";
import { fsTimestampCreador } from "../../firebase/firebase-config";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSelector } from "react-redux";

const RequestModal = ({ open, onClose, item, theme, handleRefresh }) => {
  const { idToken, userGetToken } = useSelector((state) => state.auth);
  const MySwal = withReactContent(Swal);

  const [loading, setLoading] = useState(false);
  const reloadToken = async () => {
    let freshToken = await userGetToken.getIdToken(true);
    return freshToken;
    //setToken(freshToken);
  };
  const approveSponsor = async () => {
    const sponsorData = {
      typeId: item.typeId ?? "",
      businessId: item.businessId ?? "",
      name: item.name,
      category: [item.category] ?? "",
      country: item.countries ?? "",
      facebook: item.facebook ?? "",
      instagram: item.instagram ?? "",
      tiktok: item.tiktok ?? "",
      whatsApp: item.whatsApp ?? "",
      cdate: new Date(),
      products: item.products ?? "",
      email: item.email ?? "",
      phone: item.phone ?? "",
      isActive: false,
    };

    try {
      const confirmResult = await MySwal.fire({
        title: "Crear sponsor",
        text: "¿Estás seguro de que deseas crear este sponsor?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, crear",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });

      if (confirmResult.isConfirmed) {
        const loadingSwal = MySwal.fire({
          title: "Guardando...",
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            MySwal.showLoading();
          },
        });

        try {
          setLoading(true);

          const id = await createSponsor(sponsorData);

          const token = await reloadToken();

          const url =
            "https://us-central1-allsports-69231.cloudfunctions.net/admins/sponsorAdmin";
          const options = {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ sponsorId: id, email: item.adminEmail }),
          };

          const response = await fetch(url, options);
          const res = await response.json();

          setTimeout(() => {
            setLoading(false);
            handleRefresh();

            onClose(false);
          }, 2000);

          loadingSwal.close();
          MySwal.fire("Sponsor creado!", "", "success");

          return res.ok;
        } catch (error) {
          loadingSwal.close();
          MySwal.fire("Error", "Hubo un error al crear el sponsor", "error");
          throw new Error(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: "100%",
          backgroundImage: "none",
          overflowX: "hidden",
          position: "relative",
        },
      }}
    >
      <DialogTitle>Solicitud de Sponsor</DialogTitle>
      <IconButton
        sx={{ position: "absolute", right: 10, top: 10 }}
        onClick={onClose}
      >
        <MdClose />
      </IconButton>
      <DialogContent>
        <Box sx={[styles.requestCard]}>
          <Grid
            container
            direction="column"
            alignItems="center"
            sx={styles.requestSummaryCard}
          >
            {/* Section 1 */}

            {/* Section Title */}

            {/* List Items */}
            <Grid item container direction="column">
              <Typography
                variant="h7"
                color="textPrimary"
                fontWeight="bold"
                mb={1}
                sx={{ color: theme.palette.primary.main }}
              >
                Información General
              </Typography>
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                mb={1}
              >
                <Typography variant="body1" color="textPrimary" flex="2">
                  Nombre
                </Typography>
                <Typography variant="body1" color="textMid" flex="1">
                  {item.name}
                </Typography>
              </Grid>

              {/* Due Date */}
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                mb={1}
              >
                <Typography variant="body1" color="textPrimary" flex="2">
                  Fecha de solicitud
                </Typography>
                <Typography variant="body1" color="textMid" flex="1.2">
                  {item.cdate ? item.cdate.toDate().toLocaleDateString() : ""}{" "}
                  {item.cdate ? item.cdate.toDate().toLocaleTimeString() : ""}
                </Typography>
              </Grid>
            </Grid>

            {/* Section 2 */}
            <Grid item container direction="column">
              <Grid item container direction="column">
                {/* Sponsor Name */}
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  mb={1}
                >
                  <Typography variant="body1" color="textPrimary" flex="2">
                    Email
                  </Typography>
                  <Typography variant="body1" color="textMid" flex="1">
                    {item.email}
                  </Typography>
                </Grid>

                {/* ... Any additional list items you may have ... */}
              </Grid>
            </Grid>

            {/* Section 3 */}
            <Grid item container direction="column">
              {/* Title Container */}

              {/* List Items */}
              <Grid item container direction="column">
                {/* Motive */}
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  mb={1}
                >
                  <Typography variant="body1" color="textDark" flex="2">
                    Telefono
                  </Typography>
                  <Typography variant="body1" color="textMid" flex="1">
                    {item.phone}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container direction="column">
              {/* Title Container */}

              {/* List Items */}
              <Grid item container direction="column">
                <Typography
                  variant="h7"
                  color="textPrimary"
                  fontWeight="bold"
                  mb={1}
                  sx={{ color: theme.palette.primary.main }}
                >
                  Redes
                </Typography>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  mb={1}
                >
                  <Typography variant="body1" color="textDark" flex="2">
                    Whatsapp
                  </Typography>
                  <Typography variant="body1" color="textMid" flex="1">
                    {item.whatsApp}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  mb={1}
                >
                  <Typography variant="body1" color="textDark" flex="2">
                    Facebook
                  </Typography>
                  <Typography variant="body1" color="textMid" flex="1">
                    {item.facebook}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  mb={1}
                >
                  <Typography variant="body1" color="textDark" flex="2">
                    Instagram
                  </Typography>
                  <Typography variant="body1" color="textMid" flex="1">
                    {item.instagram}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  mb={1}
                >
                  <Typography variant="body1" color="textDark" flex="2">
                    Tiktok
                  </Typography>
                  <Typography variant="body1" color="textMid" flex="1">
                    {item.tiktok}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* Bottom Section */}
            <Grid item container direction="column">
              <Typography
                variant="h7"
                color="textPrimary"
                fontWeight="bold"
                mb={1}
                sx={{ color: theme.palette.primary.main }}
              >
                Notas adicionales
              </Typography>
              <Typography variant="body1" color="textPrimary"></Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={[styles.requestCard]}>
          <Grid
            container
            sx={styles.buttonsContainer}
            justifyContent={"flex-end"}
            direction="row"
          >
            <Grid item>
              <Box ml={2}>
                <Button onClick={onClose}>Rechazar</Button>
                {/* <ASbutton
                  //backgroundColor={theme.palette.primary.secondary}
                  color={"white"}
                  buttonText={"Rechazar"}
                  onClick={onClose}
                /> */}
              </Box>
            </Grid>
            <Grid item>
              <Box ml={2}>
                <ASbutton
                  backgroundColor={theme.palette.primary.main}
                  color={"white"}
                  buttonText={"Aceptar"}
                  onClick={approveSponsor}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default RequestModal;
