import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { styles } from "../../../styles/styles";
import { ASbutton } from "../../buttons/ASbutton";
import { getAssociationProducts } from "../../../redux/b2b/actions/actions";

const B2bPlans = ({
  handleNext,
  handleBack,
  selectedPlan,
  setSelectedPlan,
  productsFromDB,
  noDataAvailable,
  loading,
  isProductDataAvailable,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  console.log(isProductDataAvailable);
  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan.id === selectedPlan ? null : plan);
  };
  const next = () => {
    const dataToAdd = {
      planSelected: selectedPlan,
    };

    handleNext(dataToAdd);
  };

  const renderCard = (plan) => (
    <Grid item xs={12} sm={6} md={4} lg={4} key={plan.codeName}>
      <Paper
        elevation={selectedPlan.id === plan.id ? 5 : 3} // Add elevated effect for selected card
        style={{
          padding: "16px",
          backgroundColor: selectedPlan.id === plan.id ? "#FAFAFB" : "#F6F6F6", // Change background color for selected card
          cursor: "pointer",
          borderRadius: "18px",
          borderWidth: "2px",
          border: selectedPlan.id === plan.id ? "solid" : "none",
          borderColor:
            selectedPlan.id === plan.id ? theme.palette.primary.main : "white", // Change cursor on hover
        }}
        onClick={() => handlePlanSelect(plan)}
      >
        <Typography variant="h7" gutterBottom>
          {plan.subTier}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Código {plan.code}
        </Typography>
      </Paper>
    </Grid>
  );

  const renderGrid = () => {
    if (loading) {
      return (
        <Grid container spacing={2} sx={[styles.planSelectionLoading]}>
          <CircularProgress />
        </Grid>
      );
    }
    if (!isProductDataAvailable) {
      return (
        <Grid container spacing={2} sx={[styles.planSelectionLoading]}>
          <Typography>No Hay productos disponibles</Typography>
        </Grid>
      );
    }

    return (
      <Grid
        container
        spacing={2}
        sx={styles.planSelection}
        justifyContent={
          productsFromDB.length > 2 ? "space-around" : "flex-start"
        }
      >
        {productsFromDB.map((plan) => renderCard(plan))}
      </Grid>
    );
  };

  return (
    <Box sx={styles.plansCard}>
      <Box sx={styles.formTitle} mb={4}>
        <Typography
          variant="h7"
          sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
        >
          Selecciona un plan
        </Typography>
      </Box>
      {renderGrid()}

      <Grid
        container
        sx={styles.buttonsContainer}
        justifyContent={"flex-end"}
        direction="row"
      >
        <Grid item>
          <ASbutton
            onClick={handleBack}
            type="submit"
            backgroundColor={"grey"}
            color={"white"}
            buttonText={"Atrás"}
          />
        </Grid>
        <Grid item>
          <Box ml={2}>
            <ASbutton
              onClick={() => next()}
              backgroundColor={theme.palette.primary.main}
              color={"white"}
              disabled={selectedPlan === ""}
              buttonText={"Siguiente"}
            >
              Submit
            </ASbutton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default B2bPlans;
