import { useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import { Stack } from "react-bootstrap";
import { Button } from "react-bootstrap";

import Swal from "sweetalert2";

import { BiPencil } from "react-icons/bi";
import { BiTrash } from "react-icons/bi";
import { BiPowerOff } from "react-icons/bi";

import darkLeftArrow from "./../../assests/darkLeftArrow.png";
import { TfiPowerOff } from "react-icons/tfi";

import { Link, useParams } from "react-router-dom";
import {
  getCause,
  getOrganization,
  updateCause,
} from "../../firebase/firebase.utils";

import CauseOverview from "../../components/cause-overview/cause-overview";
import CauseRequestor from "../../components/cause-requestor/cause-requestor";
import CauseDonorTeams from "../../components/cause-donor-teams/cause-donor-teams";
import CauseDonorUsers from "../../components/cause-donor-users/cause-donor-users";
import CauseDeletion from "../../components/cause-deletion/cause-deletion";
import CauseEdition from "../../components/cause-edition/cause-edition";
import CustomModal from "./../../components/modal/customModal";
import { Container } from "@mui/material";
import { useTheme } from "@emotion/react";

const Cause = () => {
  const { causeId, organizationId } = useParams();
  const [selectedCause, setSelectedCause] = useState(null);
  const [currentOrganization, setCurrentOrganization] = useState(null);

  const [showCauseDeletionModal, setShowCauseDeletionModal] = useState(false);
  const [showCauseEditionModal, setShowCauseEditionModal] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    const getSingleCause = async () => {
      getCause(causeId, organizationId).onSnapshot((cause) =>
        setSelectedCause(cause.data())
      );
    };

    const getSingleOrganization = async () => {
      getOrganization(organizationId).onSnapshot((organization) => {
        setCurrentOrganization(organization.data());
      });
    };

    getSingleCause();
    getSingleOrganization();
  }, [causeId, organizationId]);

  if (!selectedCause && !currentOrganization) {
    return;
  }

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-primary me-2",
      cancelButton: "btn btn-secondary",
      denyButton: "btn btn-danger me-2",
    },
    buttonsStyling: false,
  });

  const callOmitCause = () => {
    swalWithBootstrapButtons
      .fire({
        title: "Confirme su elección",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: !selectedCause.isActiveCause
          ? "Activar"
          : "Desactivar",
        confirmButtonColor: "#FAA60F",
      })
      .then((result) => {
        if (result.isConfirmed) {
          updateCause(organizationId, causeId, {
            isActiveCause: !selectedCause.isActiveCause,
          });
          Swal.fire("Estado actualizado", "", "success");
        }
      });
  };

  return (
    <Container maxWidth={"xl"}>
      <Stack direction="horizontal" className="w-100 mb-4">
        <Link
          to="/causes"
          className="w-25 d-block link-dark text-decoration-none"
        >
          <h1 className="h5 mb-0">
            <span className="d-inline-block" style={{ width: "14px" }}>
              <img
                src={darkLeftArrow}
                alt="Black arrow icon"
                className="w-100"
              />
            </span>
            Atrás
          </h1>
        </Link>
        <Stack className="ms-auto" direction="horizontal" gap={2}>
          <span
            className={`${
              selectedCause.isActiveCause ? "bg-primary" : ""
            } rounded-circle d-flex justify-content-center align-items-center`}
            style={{ width: "25px", height: "25px" }}
          >
            <BiPowerOff
              className="hover-effect"
              onClick={callOmitCause}
              color={theme.palette.light.main}
              size={15}
            />
          </span>
          <span
            className="bg-secondary rounded-circle d-flex justify-content-center align-items-center"
            style={{ width: "25px", height: "25px" }}
          >
            <BiTrash
              color={theme.palette.light.main}
              size={15}
              className="hover-effect"
              onClick={() => {
                setShowCauseDeletionModal(true);
              }}
            />
          </span>
          <span
            className="bg-primary rounded-circle d-flex justify-content-center align-items-center"
            style={{ width: "25px", height: "25px" }}
          >
            <BiPencil
              className="hover-effect"
              onClick={() => setShowCauseEditionModal(true)}
              color={theme.palette.light.main}
              size={15}
            />
          </span>
        </Stack>
      </Stack>
      <CauseOverview
        selectedCause={selectedCause}
        causeId={causeId}
        organizationId={organizationId}
      />
      <Row>
        <Col xl="4" lg="6">
          <CauseRequestor organizationId={organizationId} />
        </Col>
        <Col lg="6" xl="4">
          <CauseDonorTeams />
        </Col>
        <Col lg="6" xl="4">
          <CauseDonorUsers causeId={causeId} organizationId={organizationId} />
        </Col>
        {/* <Col lg="6" xl="4">
          <CauseHistory />
        </Col> */}
      </Row>
      <CustomModal
        show={showCauseDeletionModal}
        handleClose={setShowCauseDeletionModal}
        modalTitle={() => "Eliminar Causa"}
      >
        <CauseDeletion
          onClose={setShowCauseDeletionModal}
          organizationId={organizationId}
          causeId={causeId}
          placeHolderValues={selectedCause}
        />
      </CustomModal>
      <CustomModal
        show={showCauseEditionModal}
        handleClose={setShowCauseEditionModal}
        modalTitle={() => <HeaderButtons type={"promo"} />}
      >
        <CauseEdition
          onClose={setShowCauseEditionModal}
          organizationId={organizationId}
          causeId={causeId}
          placeHolderValues={selectedCause}
          organizationValues={currentOrganization}
        />
      </CustomModal>
    </Container>
  );
};

export default Cause;

const HeaderButtons = ({ type }) => (
  <Stack direction="horizontal" gap={2} className="">
    <Button
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#9A9A9A" }}
    >
      Editar
    </Button>
    <Button
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#AAAAAA" }}
    >
      Causa
    </Button>
  </Stack>
);
