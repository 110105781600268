import { useEffect, useState } from "react";

import { useProvince } from "../../../hooks/useProvince";
import { useCanton } from "../../../hooks/useCanton";

import { Provinces as provinces } from "../../../utils/provinces";
import { Cantons as cantons } from "../../../utils/cantons";
import { Districts as districts } from "../../../utils/districts";

import { Row, Col, Form as BootstrapForm } from "react-bootstrap";

const countryList = [{ key: 1, name: "CR" }];

const LocationDataSection = ({ formikData }) => {
  const { values, handleChange, setFieldValue } = formikData;

  const [filteredCantons, setFilteredCantons] = useState([]);
  const [filteredDistricts, setFilteredDistricts] = useState([]);

  const cantonItems = useProvince(values, cantons);
  const districtItems = useCanton(values, districts);

  useEffect(() => {
    setFilteredCantons(cantonItems);
  }, [cantonItems]);

  useEffect(() => {
    setFilteredDistricts(districtItems);
  }, [districtItems]);

  return (
    <>
      <Row>
        <Col md={6}>
          <BootstrapForm.Group>
            <BootstrapForm.Label>País</BootstrapForm.Label>

            <BootstrapForm.Select
              size="sm"
              name="country"
              onChange={handleChange}
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
            >
              {countryList.map((countryItem) => (
                <option key={countryItem.key} value={countryItem.name}>
                  {countryItem.name}
                </option>
              ))}
            </BootstrapForm.Select>

            <BootstrapForm.Control.Feedback type="invalid">
              {/* {errors.description} */}
            </BootstrapForm.Control.Feedback>
          </BootstrapForm.Group>
        </Col>
        <Col md={6}>
          <BootstrapForm.Group>
            <BootstrapForm.Label>Provincia</BootstrapForm.Label>

            <BootstrapForm.Select
              size="sm"
              name="province"
              value={values.province}
              onChange={(event) => {
                setFieldValue("canton", "");
                setFieldValue("district", "");
                handleChange(event);
              }}
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
            >
              <option value="">Seleccione una opcion</option>
              {provinces.map((province) => {
                return (
                  <option key={province.label} value={province.label}>
                    {province.label}
                  </option>
                );
              })}
            </BootstrapForm.Select>

            <BootstrapForm.Control.Feedback type="invalid">
              {/* {errors.description} */}
            </BootstrapForm.Control.Feedback>
          </BootstrapForm.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <BootstrapForm.Group>
            <BootstrapForm.Label>Canton</BootstrapForm.Label>

            <BootstrapForm.Select
              size="sm"
              name="canton"
              disabled={!values.province}
              value={values.canton}
              onChange={(event) => {
                setFieldValue("district", "");
                handleChange(event);
              }}
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
            >
              <option value="">Seleccione una opcion</option>
              {filteredCantons.map((canton) => {
                return (
                  <option key={canton.label} value={canton.label}>
                    {canton.label}
                  </option>
                );
              })}
            </BootstrapForm.Select>

            <BootstrapForm.Control.Feedback type="invalid">
              {/* {errors.description} */}
            </BootstrapForm.Control.Feedback>
          </BootstrapForm.Group>
        </Col>
        <Col md={6}>
          <BootstrapForm.Group>
            <BootstrapForm.Label>Distrito</BootstrapForm.Label>

            <BootstrapForm.Select
              size="sm"
              name="district"
              disabled={!values.canton || !values.province}
              value={values.district}
              onChange={handleChange}
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
            >
              <option value="">Seleccione una opcion</option>
              {filteredDistricts.map((district) => {
                return (
                  <option key={district.label} value={district.label}>
                    {district.label}
                  </option>
                );
              })}
            </BootstrapForm.Select>

            <BootstrapForm.Control.Feedback type="invalid">
              {/* {errors.description} */}
            </BootstrapForm.Control.Feedback>
          </BootstrapForm.Group>
        </Col>
      </Row>
    </>
  );
};

export default LocationDataSection;
