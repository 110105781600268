import { Container } from "react-bootstrap";
import "./coin-loader.scss";

const CoinLoader = ({ size, fullScreen }) => {
  return (
    <Container
      className={`d-flex justify-content-center align-items-center ${
        fullScreen && "vh-100"
      }`}
    >
      <div
        className="bg-loader"
        style={{ width: `${size.width}px`, height: `${size.height}px` }}
      ></div>
    </Container>
  );
};

export default CoinLoader;
