export const translations = {
  name: "Nombre",
  category: "Categoria",
  status: "Estado",
  content: "Contenido",
  tags: "Etiquetas",
  postType: "Tipo de post",
  videoURL: "URL del video",
  imageURL: "URL de la imagen(es)",
  imageURLs: "URL de la imagen(es)",
};
