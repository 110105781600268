
import { Container } from "@mui/material";
import CommerceSummary from "../../components/commerce-summary/commerce-summary";
import CommerceTableTabs from "../../components/commerce-table-tabs/commerce-table-tabs";

const Commerce = () => {
  return (
    <Container maxWidth={'xl'} >
      <CommerceSummary />
      <CommerceTableTabs />
    </Container>
  );
};

export default Commerce;
