import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";

import {
  getCausesRequestor,
  getOrganization,
} from "../../firebase/firebase.utils";

const CauseRequestor = ({ organizationId }) => {
  const [organization, setOrganization] = useState(null);

  useEffect(() => {
    // const getSingleOrganization = async () => {
    //   const organization = await getOrganization(organizationId);

    //   setOrganization(organization);
    // };

    const getSingleOrganization = () => {
      getOrganization(organizationId).onSnapshot((organization) =>
        setOrganization(organization.data())
      );
    };

    getSingleOrganization();
  }, [organizationId]);

  if (!organization) {
    return;
  }

  return (
    <Card className="border-light mt-3 mb-n1 rounded-top">
      <Card.Header className="bg-light p-3">
        <h1 className="h5">Solicitante</h1>
      </Card.Header>
      <Card.Body className="p-0">
        <div className="border-bottom px-3 py-2">
          <small className="text-primary d-block">Nombre</small>
          <small className="text-muted d-block mt-2">
            {organization.representative}
          </small>
        </div>

        <div className="border-bottom px-3 py-2">
          <small className="text-primary d-block">
            Medio de contacto preferido
          </small>
          <small className="text-muted d-block mt-2">
            {organization.contactBy}
          </small>
        </div>

        <div className="border-bottom px-3 py-2">
          <small className="text-primary d-block">Correo</small>
          <small className="text-muted d-block mt-2">
            {organization.email}
          </small>
        </div>

        <div className="border-bottom px-3 py-2">
          <small className="text-primary d-block">Telefono</small>
          <small className="text-muted d-block mt-2">
            {organization.phone}
          </small>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CauseRequestor;
