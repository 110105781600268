export const types = {
  // Login
  login: "[Auth] Login",
  loginStart: "[Auth] LoginStart",
  loginUserStart: "[Auth] LoginUserStart",
  loginFailure: "[Auth] LoginFailure",
  logoutError: "[Auth] LogoutError",
  logoutSuccess: "[Auth] LogoutSuccess",
  logoutStart: "[Auth] LogoutStart",
  signInWithPhone: "[Auth] SignIn",
  logout: "[Auth] Logout",
  isLoadingAuth: "[Auth] isLoading",
};
