export const businessInnitialValues = {
  typeId: "Cedula Juridica",
  idNumber: "--",
  name: "--",
  tier: "--",
  email: "--",
  phone: "--",
  country: "--",
  province: "--",
  canton: "--",
  district: "--",
  category: "--",
  products: "--",
  status: true,
};
