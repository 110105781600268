import { ListGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";

const RedeemDetails = ({ redeem }) => {
  const { exchange } = redeem;

  return (
    <Form.Group className="mb-5">
      {/* <Form.Label style={{ fontSize: "15px" }} className="mb-2">
        {exchange.name}
      </Form.Label> */}
      <ListGroup className="mb-3">
        <ListGroup.Item
          as="li"
          disabled
          style={{ fontSize: "16px", border: "none" }}
          className="mb-0"
        >
          <strong>{exchange.name}</strong>
        </ListGroup.Item>

        <ListGroup.Item
          as="li"
          disabled
          style={{ fontSize: "13px", border: "none" }}
          className="mb-0"
        >
          Valor en coins: <strong>{exchange.coinPrice}</strong>
        </ListGroup.Item>

        <ListGroup.Item
          as="li"
          disabled
          style={{ fontSize: "13px", border: "none" }}
        >
          Fue redimido: <strong>{redeem.isRedeemed ? "Si" : "No"}</strong>
        </ListGroup.Item>
        <ListGroup.Item
          as="li"
          disabled
          style={{ fontSize: "13px", border: "none" }}
        >
          Fecha de canje:{" "}
          <strong>
            {redeem.redeemDate.toDate().toLocaleDateString("es-CR", {
              month: "numeric",
              day: "numeric",
              year: "numeric",
            })}
          </strong>
        </ListGroup.Item>

        <ListGroup.Item
          as="li"
          disabled
          style={{ fontSize: "13px", border: "none" }}
        >
          Fecha de vencimiento:{" "}
          <strong>
            {exchange.dueDate.toDate().toLocaleDateString("es-CR", {
              month: "numeric",
              day: "numeric",
              year: "numeric",
            })}
          </strong>
        </ListGroup.Item>
      </ListGroup>
    </Form.Group>
  );
};

export default RedeemDetails;
