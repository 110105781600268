import { useEffect, useState } from "react";

export const useCanton = (values, districts) => {
  const [filteredDistricts, setFilteredDistricts] = useState([]);

  useEffect(() => {
    const selectedCanton = values.canton;

    if (selectedCanton) {
      const filterItems = districts.filter(
        (district) => district.cantonName === selectedCanton
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });
      setFilteredDistricts(filterItems);
    } else {
      setFilteredDistricts([]);
    }
  }, [values.canton]);

  return filteredDistricts;
};
