export const sortUsersTable = (cells, sortingMethod) => {
  let sortedItems = [];

  switch (sortingMethod) {
    case "Más recientes":
      sortedItems = [...cells].sort((firstElement, secondElement) => {
        return secondElement.register.toDate() - firstElement.register.toDate();
      });

      return sortedItems;
    case "Más antiguos":
      sortedItems = [...cells].sort((firstElement, secondElement) => {
        return firstElement.register.toDate() - secondElement.register.toDate();
      });

      return sortedItems;
    case "Orden alfabetico":
      sortedItems = [...cells].sort((firstElement, secondElement) => {
        return firstElement.userName.localeCompare(secondElement.userName);
      });
      return sortedItems;
    case "Coins + a -":
      sortedItems = [...cells].sort((firstElement, secondElement) => {
        return secondElement.coins - firstElement.coins;
      });
      return sortedItems;
    case "Coins - a +":
      sortedItems = [...cells].sort((firstElement, secondElement) => {
        return firstElement.coins - secondElement.coins;
      });
      return sortedItems;
    default:
      return;
  }
};
