export const postInitialValues = {
  name: "",
  category: "",
  status: "",
  content: "",
  tags: [],
  postType: "image", // Default to image post type
  imageURL: "",
  videoURL: "",
  imageURLs: "",
};
