import { Container, useMediaQuery } from "@mui/material";
import SummaryCard from "../../components/summary-card/summary-card";
import { UsersTable } from "../../components/users-table/UsersTable";

import { Stack } from "react-bootstrap";

import { styles } from "../../styles/styles";
import { Typography } from "@mui/material";

const Users = () => {
  const matches600 = useMediaQuery("(min-width:600px)");

  return (
    <Container maxWidth={"xl"}>
      <Stack direction="horizontal" gap={2} className="mb-4">
        <Typography variant="h5" sx={styles.title}>
          Usuarios
        </Typography>
      </Stack>

      <SummaryCard />

      <UsersTable />
    </Container>
  );
};

export default Users;
