import jwtDecode from "jwt-decode";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MUIDataTable from "mui-datatables";
import {
  Box,
  Tab,
  Tabs,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import CustomBodyWithSpinner from "../general/CustomBodyWithSpinner";
import { useNavigate } from "react-router-dom";
import { IoRefreshOutline } from "react-icons/io5";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { getUserByUserRef, removeUser } from "../../redux/b2b/actions/actions";
import { refreshToken } from "../../redux/user/actions/auth";
import { Typography } from "@mui/material";
import { ASbutton } from "../buttons/ASbutton";
import { ViewUserModal } from "./ViewUserModal";

const MySwal = withReactContent(Swal);

export const B2bRequestExclusionsTable = ({
  associationId,
  isDataAvailable,
  pendingUsers,
  handleRefresh,
  loading,
  value,
  handleChange,
}) => {
  const { idToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const matchesUp752 = useMediaQuery("(min-width:752px)");

  const [selectedItem, setSelectedItem] = useState(null);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const handleOpenUserModal = async (item) => {
    setUserModalOpen(true);
    setSelectedItem(item);
  };

  const onClose = () => {
    setUserModalOpen(false);

    setSelectedItem();
  };

  const handleOpenExcludeModal = async (item) => {
    const userData = await getUserByUserRef(item.userRef);
    const updatedData = { ...item, ...userData };

    MySwal.fire({
      text: `¿Deseas remover al usuario ${updatedData.name}  ${" "} ${
        updatedData.lastname
      } ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      cancelButtonColor: "#d33",
      confirmButtonColor: "#3085d6",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const loadingSwal = MySwal.fire({
          title: "Guardando...",
          html: '<div class="swal2-spinner"></div>',
          showConfirmButton: false,
          allowOutsideClick: false,
        });
        try {
          const userId = updatedData.userId;
          const code = updatedData.codeId;
          const type = "exclusion";
          const associationId = updatedData.associationId;
          const data = {
            userId,
            code,
            associationId,
            type,
          };
          if (isValidToken(idToken)) {
            console.log("idtoken");
            const res = await removeUser(data, idToken);
            console.log(idToken);
            if (res.ok) {
              MySwal.fire({
                title: "Usuario removido",
                text: `El usuario ${updatedData.name}  ${" "} ${
                  updatedData.lastname
                } ha sido removido del plan.`,
                icon: "success",
              });
            } else if (res.error === 3) {
              MySwal.fire(
                "Error",
                "Hubo un error al crear el usuario",
                "error"
              );
              loadingSwal.close();
            }
            handleRefresh();
          } else {
            const newToken = await refreshToken();
            const res = await removeUser(data, newToken);

            if (res.ok) {
              loadingSwal.close();
              MySwal.fire({
                title: "Usuario removido",
                text: `El usuario ${updatedData.name}  ${" "} ${
                  updatedData.lastname
                } ha sido removido del plan.`,
                icon: "success",
              });
            }
            handleRefresh();
          }
          loadingSwal.close();
          console.log(data);
        } catch (error) {
          console.log(error);
          loadingSwal.close();
          MySwal.fire("Error", "Hubo un error al crear el usuario", "error");
        }
      }
    });
  };

  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };

  const handleUserSelect = (url) => {
    navigate(`/user/${url}`);
  };

  const BodyComponent = useMemo(
    () => (tableProps) => {
      if (isDataAvailable) {
        return (
          <CustomBodyWithSpinner loading={!isDataAvailable} {...tableProps} />
        );
      }

      return <CustomBodyWithSpinner loading={loading} {...tableProps} />;
    },
    [pendingUsers, loading, isDataAvailable]
  );

  const columns = [
    {
      name: "name",
      label: "Nombre", // Combined column label
      options: {
        display: true,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const name = tableMeta.rowData[0]; // Assuming "name" is the first column
          const lastname = tableMeta.rowData[1]; // Assuming "lastname" is the second column
          return `${name} ${lastname}`;
        },
      },
    },

    {
      name: "lastname",
      label: "Apellido",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        display: true,
        filter: false,
      },
    },

    {
      name: "codeName",
      label: "Código Asociación",
      options: {
        display: true,
        filter: false,
      },
    },
    {
      name: "codeId",
      label: "Código",
      options: {
        display: true,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const associationCodeName = tableMeta.rowData[3]; // Assuming the association code name is at index 6
          const codeId = tableMeta.rowData[4]; // Assuming the codeId is at index 3
          const combinedCodeId = `${associationCodeName}-${codeId}`;
          return combinedCodeId;
        },
      },
    },
    {
      name: "exclusionRequestDate",
      label: "Fecha",
      options: {
        customBodyRender: (value) => {
          if (typeof value === "object" && value.seconds && value.nanoseconds) {
            const date = new Date(
              value.seconds * 1000 + value.nanoseconds / 1000000
            );
            const formattedDate = date.toLocaleDateString();
            return formattedDate;
          } else {
            return "-";
          }
        },

        filter: false,
      },
    },
    // {
    //   name: "status",
    //   label: "Status",
    //   options: {
    //     customBodyRender: (value) => {
    //       return value ? "Pendiente" : " ";
    //     },
    //     filter: false,
    //   },
    // },
    {
      name: "userRef",
      label: "Referencia",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "view",
      label: "Ver",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const data = pendingUsers[dataIndex];
          const handleEdit = () => {
            handleOpenUserModal(data);
          };
          return (
            <ASbutton buttonText={"Ver"} onClick={handleEdit} width={"100%"}>
              <Typography>Excluir</Typography>;
            </ASbutton>
          );
        },
        filter: false,
        sort: false,
      },
    },
    {
      name: "remove",
      label: "Excluir",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const data = pendingUsers[dataIndex];
          const handleEdit = () => {
            handleOpenExcludeModal(data);
          };
          return (
            <ASbutton
              buttonText={"Excluir"}
              onClick={handleEdit}
              width={"100%"}
            >
              <Typography>Excluir</Typography>;
            </ASbutton>
          );
        },
        filter: false,
        sort: false,
      },
    },
  ];
  const CustomToolbar = ({ onRefresh }) => {
    return (
      <IconButton className="refresh-button" onClick={onRefresh}>
        <IoRefreshOutline size={25} />
      </IconButton>
    );
  };
  const CustomTitle = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab
            label="Solicitudes de Exclusión"
            sx={{ fontSize: 12, paddingBottom: 0 }}
          />
          <Tab
            label="Solicitudes de Inclusión"
            sx={{ fontSize: 12, paddingBottom: 0 }}
          />
        </Tabs>
      </Box>
    );
  };

  const options = {
    filter: false,
    responsive: "standard",
    selectableRows: "none",
    rowsPerPage: 15,
    // onTableChange: () => setUsersFromDB(pendingUsers),
    sortOrder: {
      name: "email",
      direction: "asc",
    },
    textLabels: {
      body: {
        noMatch: "No hay datos disponibles",
      },
    },
    sortCompare: (order) => (a, b) => {
      const isAsc = order === "asc";
      // Replace "lastname" with the column name you want to handle
      const column = "lastname";

      // Handle null, undefined, and empty string values
      const aValue =
        a.data[column] !== undefined &&
        a.data[column] !== null &&
        a.data[column].trim() !== ""
          ? a.data[column]
          : "N/A";
      const bValue =
        b.data[column] !== undefined &&
        b.data[column] !== null &&
        b.data[column].trim() !== ""
          ? b.data[column]
          : "N/A";

      // Use default comparison for other cases
      if (isAsc) {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    },
    customToolbar: () => {
      return <CustomToolbar onRefresh={handleRefresh} />;
    },

    // onRowClick: (rowData) => {
    //   const clickedOwnerId = rowData[0];

    //   handleUserSelect(clickedOwnerId);
    // },
  };
  // if (loading) {
  //   return <div>loading</div>;
  // }
  return (
    <Box
      sx={{
        width: "100%",
        marginTop: {
          xs: 0,
          [theme.breakpoints.up(752)]: {
            marginTop: 60,
          },
        },
      }}
    >
      <MUIDataTable
        title={matchesUp752 ? CustomTitle() : ""}
        data={pendingUsers}
        columns={columns}
        options={options}
        components={{ TableBody: BodyComponent }}
      />
      {selectedItem && (
        <ViewUserModal
          code={selectedItem}
          open={userModalOpen}
          onClose={onClose}
          onRefresh={handleRefresh}
          associationId={associationId}
        />
      )}
    </Box>
  );
};
