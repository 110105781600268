import { useState } from "react";

import { EditText } from "react-edit-text";

import { v4 as uuid } from "uuid";
import { FieldArray, useFormikContext } from "formik";

import { Card, Table, Form } from "react-bootstrap";
import { Stack, Button } from "react-bootstrap";

import { BiPencil } from "react-icons/bi";
import { ReactComponent as DarkTrashCanIcon } from "./../../assests/trashcan.svg";

import TableHeader from "../table/table-header";

import "./product-addition.scss";
import { useEffect } from "react";
import { useTheme } from "@emotion/react";

const ProductAddition = ({ productToEdit, id }) => {
  const { setFieldValue, errors, handleChange, values } = useFormikContext();

  const [products, setProducts] = useState([]);

  const theme = useTheme();

  useEffect(() => {
    const typeData = ["Moneda", "Porcentaje"];
    if (id && productToEdit) {
      setProducts(productToEdit);
    } else {
      setProducts([
        {
          id: uuid().slice(0, 8),
          onlyReadable: true,
          variable: "Membresia AllSports",
          type: typeData,
          amount: 2500,
        },
        {
          id: uuid().slice(0, 8),
          onlyReadable: true,
          variable: "Plan de seguros bronce",
          type: typeData,
          amount: 4500,
        },
      ]);
    }
  }, [id, productToEdit]);

  const headers = [
    { name: "Variable", accessor: "variable" },
    { name: "Monto", accessor: "amount" },
    { name: "Acciones", accessor: "actions" },
  ];

  const editVariable = (elementId) => {
    let rowIndex = products.findIndex((product) => product.id === elementId);
    const productsCopy = [...products];

    productsCopy[rowIndex].onlyReadable = !productsCopy[rowIndex].onlyReadable;

    setProducts(productsCopy);
  };

  const deleteVariable = (elementId) => {
    try {
      let rowIndex = products.findIndex((product) => product.id === elementId);
      const productsCopy = products.map((product) => {
        return { ...product, rowAction: "delete" };
      });

      productsCopy.splice(rowIndex, 1);

      setProducts(productsCopy);
    } catch (error) {
      console.log(error);
    }
  };

  // const rows = useMemo(
  //   () =>
  //     products.map((product) => {
  //       return {
  //         id: product.id,
  //         onlyReadable: product.onlyReadable,
  //         variable: product.variable,
  //         type: product.type,
  //         amount: product.amount,
  //       };
  //     }),
  //   [products]
  // );

  const createNewRow = () => {
    const productRow = {
      id: uuid().slice(0, 8),
      onlyReadable: true,
      rowAction: "add",
      variable: "Membresia AllSports",
      type: ["Moneda", "Porcentaje"],
      amount: 2500,
    };

    const row = {
      amount: 2500,
      name: "Membresia AllSports",
    };

    const newRows = [...values.variableRows, productRow];
    setProducts(newRows);

    return row;
  };

  return (
    <Card border="light" className="mt-4">
      <Card.Body>
        <Table
          className="bg-light mb-0 p-4 products-table"
          responsive
          style={{ width: "400px !important" }}
        >
          <FieldArray
            name="variableRows"
            render={(arrayHelpers) => (
              <>
                <TableHeader headers={headers} />
                <tbody className="p-4">
                  {values.variableRows.map((_, index) => {
                    return (
                      // <tr key={rows[index]?.id}>
                      <tr key={values.variableRows[index].id}>
                        <td>
                          <Form.Group>
                            <EditText
                              //readonly={rows[index]?.onlyReadable}
                              readonly={values.variableRows[index].onlyReadable}
                              name={`variableRows[${index}].name`}
                              onChange={handleChange}
                              //defaultValue={row.variable}
                              value={values.variableRows[index].name}
                              className="products-container"
                              inputClassName="bg-info border-0 shadow-none form-control is-invalid products-input"
                              onSave={({ value, previousValue }) => {
                                if (value === "") {
                                  setFieldValue(
                                    `variableRows[${index}].name`,
                                    previousValue
                                  );
                                }
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.variableRows
                                ? errors.variableRows[index]?.name
                                : null}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </td>
                        <td style={{ paddingLeft: "8px" }}>
                          <Form.Group>
                            <EditText
                              type="number"
                              //readonly={rows[index]?.onlyReadable}
                              readonly={values.variableRows[index].onlyReadable}
                              //name={`amounts[${index}]`}
                              name={`variableRows[${index}].amount`.toString()}
                              onChange={handleChange}
                              //value={values.amounts[index]}
                              value={values.variableRows[
                                index
                              ].amount.toString()}
                              //defaultValue={row.amount}
                              className="products-container"
                              inputClassName="bg-info border-0 shadow-none form-control is-invalid products-input"
                              onSave={({ value, previousValue }) => {
                                if (value === "") {
                                  setFieldValue(
                                    `variableRows[${index}].amount`,
                                    previousValue
                                  );
                                }
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.variableRows
                                ? errors.variableRows[index]?.amount
                                : null}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </td>
                        {/* <td>{rows[index].actions}</td> */}
                        <td>
                          <Stack
                            direction="horizontal"
                            style={{ paddingRight: "40px" }}
                          >
                            <span
                              className={`text-light ms-auto products-btn ${
                                //!rows[index]?.onlyReadable
                                !values.variableRows[index].onlyReadable
                                  ? "products-active-edition"
                                  : ""
                              }`}
                            >
                              <BiPencil
                                color={theme.palette.light.main}
                                size={15}
                                onClick={() => {
                                  const modification = {
                                    ...values.variableRows[index],
                                    onlyReadable:
                                      !values.variableRows[index].onlyReadable,
                                  };
                                  arrayHelpers.replace(index, modification);
                                }}
                              />
                            </span>
                            <span className="text-light products-btn">
                              <DarkTrashCanIcon
                                onClick={() => {
                                  deleteVariable(values.variableRows[index].id);
                                  arrayHelpers.remove(index);
                                }}
                              />
                            </span>
                          </Stack>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <div className="bg-light py-3 rounded-bottom">
                  <Button
                    variant="link"
                    className="text-muted text-decoration-none fw-bold"
                    onClick={() => {
                      const newRow = createNewRow();
                      arrayHelpers.push(newRow);
                    }}
                  >
                    + Agregar variable
                  </Button>
                </div>
              </>
            )}
          ></FieldArray>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default ProductAddition;
