import { Button, Col, Row, Stack } from "react-bootstrap";

const HistoryList = ({ adminChanges = [], setHistoryEntry }) => {
  return (
    <>
      {adminChanges.map((historyEntry) => {
        return (
          <Row className="mt-3">
            <Col xxl="1" lg={0} className="d-flex justify-content-center">
              <small className="d-block rounded-circle bg-primary outer-circle"></small>
            </Col>
            <Col className="p-0">
              <Stack direction="horizontal" className="px-3">
                <small className="text-muted">
                  {historyEntry.modificationDate
                    ? historyEntry.modificationDate
                        .toDate()
                        .toLocaleDateString("es-CR", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })
                    : "----"}
                </small>
              </Stack>

              <Stack direction="horizontal" className="px-3">
                <small className="fw-bold">
                  {historyEntry.admin.name
                    ? historyEntry.admin.name
                    : historyEntry.admin.email}
                </small>
                <Button
                  variant="light"
                  className="ms-auto"
                  onClick={() => setHistoryEntry(historyEntry)}
                >
                  <small className="text-primary">Detalles</small>
                </Button>
              </Stack>
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default HistoryList;
