import { useState, useEffect, useMemo } from "react";

import { ReactComponent as DarkPencilIcon } from "./../../assests/pencil.svg";
import { ReactComponent as DarkTrashCanIcon } from "./../../assests/trashcan.svg";
import { ReactComponent as DarkRoundedMenuIcon } from "./../../assests/rounded-hamburger-menu.svg";

import { Table, Button } from "react-bootstrap";
import { Stack } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

import ReactPaginate from "react-paginate";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import TableHeader from "../table/table-header";
//import TableBody from "../table/table-body";
import TableBodyHover from "../table/table-body-hover";
import ItemsControl from "../items-control/items-control";
import TableFilters from "../table/table-filters";
import CustomModal from "../modal/customModal";
import TeamEdition from "../roles-edition/teams-edition";

import {
  getAllUsers,
  getUserPrivateData,
  getUserWallet,
} from "../../firebase/firebase.utils";

import { sortUsersTable } from "../../custom hooks/sort-table/sort-users-table";
import { searchTable } from "../../custom hooks/search-table/search-table";

const TeamsTable = () => {
  const [teams, setTeams] = useState([
    {
      color: "#FAA60F",
      teamName: "Polo Norte",
      members: ["Rodolfo el Reno", "Cometa"],
      cdate: "",
      isActiveTeam: true,
    },
  ]);

  const [showEditionTeamModal, setShowEditionTeamModal] = useState(false);

  const navigate = useNavigate();

  const handleClick = (url) => {
    navigate(url);
  };

  const headers = [
    { name: "", accessor: "color" },
    { name: "Equipo", accessor: "teamName" },
    { name: "Colaboradores", accessor: "members" },
    { name: "Registro", accessor: "register" },
    { name: "Status", accessor: "status" },
    { name: "Acciones", accessor: "actions" },
  ];

  const newTeamList = useMemo(
    () =>
      teams.map((team) => {
        return {
          id: team.id,
          color: (
            <div
              className="rounded-circle"
              style={{
                backgroundColor: `${team.color}`,
                width: "35px",
                height: "35px",
              }}
            ></div>
          ),
          teamName: team.teamName ? team.teamName : "--",
          members: team.members ? team.members.join(",") : "--",
          register: team.cdate ? team.cdate : "--",
          team: team.team ? (
            <span className="text-primary">{team.team}</span>
          ) : (
            "--"
          ),
          status: team.isActiveTeam ? (
            <span size="sm" className="w-100" style={{ color: "#AAAAAA" }}>
              Activo
            </span>
          ) : (
            <span variant="danger" size="sm" className="w-100 text-danger">
              Inactivo
            </span>
          ),
          actions: (
            <Stack direction="horizontal" gap={2}>
              <span variant="secondary" size="sm" className="text-light">
                <DarkPencilIcon onClick={() => setShowEditionTeamModal(true)} />
              </span>
              <span variant="secondary" size="sm" className="text-light">
                <DarkTrashCanIcon />
              </span>
              <span variant="secondary" size="sm" className="text-light">
                <DarkRoundedMenuIcon />
              </span>
            </Stack>
          ),
        };
      }),
    [teams]
  );

  const [itemsPerPage, setItemsPerPage] = useState(5);
  //const [filteringBy, setfilteringBy] = useState("Name");

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + Number(itemsPerPage);
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(newTeamList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(newTeamList.length / Number(itemsPerPage)));
  }, [itemOffset, newTeamList, itemsPerPage]);

  // useEffect(() => {
  //   const getUsers = async () => {
  //     const userList = await getAllUsers();
  //     let wallets = [];
  //     let userPrivate = [];

  //     for (const user of userList) {
  //       const wallet = await getUserWallet(user.id);
  //       const privateData = await getUserPrivateData(user.id);
  //       wallets.push(wallet);
  //       userPrivate.push(privateData);
  //     }

  //     setUsers(userList);
  //     setFilteredUsers(userList);
  //     setUserWallets(wallets);
  //     setUsersPrivateData(userPrivate);
  //   };

  //   getUsers();
  // }, []);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % newTeamList.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const sortTableElements = (sortingMethod) => {
    const sortedItems = sortUsersTable(currentItems, sortingMethod);
    setCurrentItems(sortedItems);
  };

  const dropdownList = [
    {
      valuesList: [
        { name: "Más recientes" },
        { name: "Más antiguos" },
        { name: "Orden alfabetico" },
        { name: "Rol" },
      ],
      label: "",
      filterMethod: sortTableElements,
    },
  ];

  // const showClients = (list) => {
  //   const clients = list.filter((user) => user.isActiveMember);

  //   setFilteredUsers(clients);
  // };

  // const showAllUsers = () => {
  //   setFilteredUsers(users);
  // };

  const options = [
    { text: "Todos", filterMethod: () => console.log("users") },
    { text: "Activos", filterMethod: () => console.log("users") },
    { text: "Inactivos", filterMethod: () => console.log("users") },
  ];

  const [searchField, setSearchField] = useState("");
  const rows = searchTable(currentItems, headers, searchField);

  return (
    <>
      <TableFilters
        searchRows={setSearchField}
        dropdowns={dropdownList}
        options={options}
      />
      <div className="p-4 bg-light">
        <Table
          achievements={true}
          className="bg-light mb-0 p-4"
          responsive
          style={{ width: "400px !important" }}
        >
          <TableHeader headers={headers} />
          <TableBodyHover rows={rows} columns={headers} clickGoTo="/user/" />
        </Table>
      </div>

      <div className="bg-light p-4 rounded-bottom">
        <Stack direction="horizontal">
          <ItemsControl setNumItemPerPage={setItemsPerPage} />

          <div className="ms-auto">
            <ReactPaginate
              breakLabel="..."
              nextLabel={<IoIosArrowForward />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel={<IoIosArrowBack />}
              renderOnZeroPageCount={null}
              containerClassName="pagination pagination-modifier"
              pageClassName="page-item"
              previousClassName="previus-button"
              nextClassName="next-button"
              pageLinkClassName="page-link page-link-modifier"
              activeClassName="active"
            />
          </div>
        </Stack>

        <CustomModal
          show={showEditionTeamModal}
          handleClose={setShowEditionTeamModal}
          modalTitle={() => <HeaderButtons />}
        >
          <TeamEdition teams={teams} onClose={setShowEditionTeamModal} />
        </CustomModal>
      </div>
    </>
  );
};

export default TeamsTable;

const HeaderButtons = ({ type }) => (
  <Stack direction="horizontal" gap={2} className="">
    <Button
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#9A9A9A" }}
    >
      Editar
    </Button>
    <Button
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#AAAAAA" }}
    >
      Equipo
    </Button>
  </Stack>
);
