import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  TextareaAutosize,
  Typography,
  useTheme,
} from "@mui/material";
import { styles } from "../../styles/styles";
import { ASbutton } from "../buttons/ASbutton";

import { TagsInput } from "react-tag-input-component";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { useDispatch, useSelector } from "react-redux";
import UploadImage from "../general/UploadImage";
import { editBlogPost } from "../../redux/blogs/blogs";
import { Close } from "@mui/icons-material";

import "./loader.scss";
import { postInitialValues } from "./initialValues";
import { getChangedFields } from "../../utils/getChangeFields";
import { translations } from "./spanishPostFieldName";
import { addAdminHistoryEntry } from "../../firebase/firebase.utils";

const MySwal = withReactContent(Swal);
export const PostModal = ({ open, onClose, data, onRefresh, mode }) => {
  const theme = useTheme();

  const { user: currentAdmin } = useSelector((state) => state.auth);

  const [tags, setTags] = useState(data?.tags ?? []);
  const [imageUpload, setImageUpload] = useState("");
  const [fileUrl, setFileUrl] = useState(null);
  const [image, setImage] = useState([]);
  const [postId, setPostId] = useState();
  const [loading, setLoading] = useState(true);
  const [initialData, setInitialData] = useState(postInitialValues);

  useEffect(() => {
    if (data) {
      setInitialData({
        name: data?.name ?? "",
        category: data?.category ?? "",
        status: data?.status ?? "",
        content: data?.content ? data.content.join("\n") : "",
        tags: data?.tags ?? [],
        postType: data?.postType ?? "image", // Default to image post type
        videoURL: data?.videoURL ?? "",
        imageURL: data?.imageURL ?? "",
        imageURLs: data?.imageURLs ?? "",
      });
    }

    const copyArr = data?.imageURLs?.slice() || [];
    setImage(copyArr);
    setImageUpload(copyArr);

    if (mode === "edit") {
      setPostId(data.id);
    }
  }, [data]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    onSubmit: async (values) => {
      const blogId = data?.blogId;

      const confirmResult = await MySwal.fire({
        text: ` ¿Estás seguro de que quieres editar este post?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, editar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });

      const paragraphsArray = values.content.split("\n"); // Split content into paragraphs

      if (confirmResult.isConfirmed) {
        Swal.fire({
          title: "Guardando...",
          html: '<span class="loader"></span>',
          showConfirmButton: false,
          allowOutsideClick: false,
        });

        const content = paragraphsArray.filter(Boolean); // Remove empty paragraphs

        const postData = {
          name: values.name ?? "",
          content: content,
          category: values.category ?? "",
          status: values.status ?? "",
          videoURL: values.videoURL.includes("youtube")
            ? values.videoURL + "&"
            : values.videoURL,
          tags: values.tags ?? "",
        };

        const wasImgModified = image.some((img) => img.includes("blob:"));

        const fieldsChanged = getChangedFields(
          values,
          initialData,
          translations
        );

        try {
          if (postId) {
            const urlList = await editBlogPost(
              blogId,
              postId,
              postData,
              imageUpload
            );

            const imgModified = {
              field: "imageURLs",
              fieldEs: "imagen",
              newValue: urlList,
              oldValue: data.imageURLs,
            };

            const adminHistory = {
              modificationDate: new Date(),
              admin: {
                id: currentAdmin.uid,
                name: currentAdmin.displayName,
                email: currentAdmin.email,
              },
              modifications: wasImgModified
                ? [...fieldsChanged, imgModified]
                : fieldsChanged,
              modificationContext: {
                collectionName: "posts",
                docId: postId,
              },
            };

            await addAdminHistoryEntry(
              adminHistory,
              `blogs/${blogId}/adminHistory`
            );
            Swal.close();
            // Show the success Swal
            MySwal.fire({
              title: "Cambios Guardados",
              text: "",
              icon: "success",
            });
            onClose();
            onRefresh();
          }
        } catch (error) {
          console.error(error);
          MySwal.fire("Error", "Hubo un error al editar el post", "error");
          Swal.close();
        } finally {
          // loadingSwal.close();
        }
      }
    },
  });

  if (loading) {
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"md"}
      PaperProps={{
        sx: {
          // backgroundColor: theme.palette.secondary.secondary,
          width: "100%",
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle color={theme.palette.primary.main}></DialogTitle>
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"md"}
      PaperProps={{
        sx: {
          width: "100%",
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle color={theme.palette.primary.main}>
        Post{" "}
        <Box sx={styles.closeButton} onClick={onClose}>
          <Close />
        </Box>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={[styles.plansCard, { mt: 0, mb: 2 }]}>
            <Grid container sx={styles.summaryCard}>
              <Grid
                container
                columnGap={0}
                justifyContent={"space-between"}
                rowGap={4}
                mb={2}
                mt={2}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      select
                      label="Tipo de post"
                      name="postType"
                      value={formik.values.postType}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value="image">Imagen</MenuItem>
                      <MenuItem value="video">Video</MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Nombre"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TagsInput
                    value={formik.values.tags}
                    onChange={(tags) => formik.setFieldValue("tags", tags)}
                    name="tags"
                    placeHolder="Introduzca nuevas etiquetas"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextareaAutosize
                    minRows={3}
                    maxRows={10}
                    aria-label="content"
                    placeholder="Contenido"
                    name="content"
                    value={formik.values.content}
                    onChange={formik.handleChange}
                    style={{
                      width: "100%",
                      resize: "vertical",
                      padding: "10px",
                    }}
                  />
                  {formik.touched.content && formik.errors.content ? (
                    <Typography color="error" variant="body2">
                      {formik.errors.content}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
              {formik.values.postType === "image" ? (
                <Grid
                  item
                  container
                  justifyContent={"center"}
                  xs={12}
                  sm={12}
                  md={12}
                  my={2}
                >
                  <UploadImage
                    loadUrl={setFileUrl}
                    imageUpload={imageUpload}
                    setImageUpload={setImageUpload}
                    setImage={setImage}
                    image={image}
                  />
                </Grid>
              ) : (
                <Grid
                  item
                  container
                  justifyContent={"center"}
                  xs={12}
                  sm={12}
                  md={12}
                  my={2}
                >
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      label="URL VIDEO"
                      name="videoURL"
                      value={formik.values.videoURL}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.videoURL &&
                        Boolean(formik.errors.videoURL)
                      }
                      helperText={
                        formik.touched.videoURL && formik.errors.videoURL
                      }
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} mt={4}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  select
                  label="Estado"
                  name="status"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value="pending">Pendiente</MenuItem>
                  <MenuItem value="rejected">Rechazado</MenuItem>
                  <MenuItem value="accepted">Aceptado</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
          </Box>

          <Grid container mt={4} justifyContent={"flex-end"}>
            <ASbutton
              onClick={formik.handleSubmit}
              backgroundColor={theme.palette.primary.main}
              color={"white"}
              buttonText={"Guardar"}
            />
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>{/* Your actions or buttons here */}</DialogActions>
    </Dialog>
  );
};
