import { useState, useEffect } from "react";

import Form from "react-bootstrap/Form";
import { Button, Stack, Col, Row, Spinner } from "react-bootstrap";
import { TagsInput } from "react-tag-input-component";

import { useFormik } from "formik";
import * as Yup from "yup";

import FormInput from "../form-input/form-input";

import "./cause-edition.scss";
import {
  createCause,
  updateCause,
  updateOrganization,
} from "../../firebase/firebase.utils";
import { fsTimestampCreador } from "../../firebase/firebase-config";

const categoriesData = [
  { name: "Medio ambiente" },
  { name: "Educacion" },
  { name: "Apoyo economico" },
  { name: "Deportes" },
  { name: "Inclusividad" },
];

const sectorsData = [
  { name: "ONG" },
  { name: "Empresa corporatia" },
  { name: "PyME" },
  { name: "Asociacion de desarrollo" },
  { name: "Organizacion comunal" },
  { name: "Gobierno local" },
  { name: "OGN" },
];

const areasData = [
  { name: "Conservacion natural" },
  { name: "Niñez y adolecencia" },
  { name: "Deporte y cultura" },
  { name: "Asistencia social" },
  { name: "Atencion de emergencias" },
  { name: "Poblaciones indigenas" },
  { name: "Inclusividad" },
  { name: "Asistencia educativa" },
  { name: "Desarrollo y vivienda" },
  { name: "Asistencia medica" },
];

const organizationIdTypeData = [{ name: "Cedula Juridica" }, { name: "RUT" }];

const idTypeData = [
  { name: "Cedula Nacional" },
  { name: "Cedula de residencia" },
  { name: "DIMEX" },
  { name: "Pasaporte" },
];

const communicationChannelData = [
  { name: "WhatsApp" },
  { name: "Correo" },
  { name: "Llamada" },
];

const CauseEdition = ({
  onClose,
  organizationValues,
  placeHolderValues,
  causeId,
  organizationId,
}) => {
  const [selected, setSelected] = useState(placeHolderValues.generalTags);
  const [categories, setCategories] = useState(categoriesData);
  const [sectors, setSectors] = useState(sectorsData);
  const [areas, setAreas] = useState(areasData);
  const [organizationIdType, setOrganizationIdType] = useState(
    organizationIdTypeData
  );
  const [idType, setIdType] = useState(idTypeData);
  const [communicationChannel, setCommunicationChannel] = useState(
    communicationChannelData
  );
  const [loading, setLoading] = useState(false);

  const countryList = [{ name: "Costa Rica" }];

  useEffect(() => {
    const sortSelectInput = (data) => {
      const newArr = [...data];
      const elementIndex = data.findIndex(
        (categ) => categ.name === placeHolderValues.category
      );

      const [firstElement] = newArr.splice(elementIndex, 1);

      newArr.unshift(firstElement);
      return newArr;
    };

    const newCategories = sortSelectInput(categories);
    setCategories(newCategories);

    const newSectors = sortSelectInput(sectors);
    setSectors(newSectors);

    const newImpactArea = sortSelectInput(areas);
    setAreas(newImpactArea);

    const newIdOrganization = sortSelectInput(areas);
    setOrganizationIdType(newIdOrganization);

    const newIdType = sortSelectInput(idType);
    setIdType(newIdType);

    const newCommunicationChannel = sortSelectInput(communicationChannel);
    setCommunicationChannel(newCommunicationChannel);
  }, []);

  const validate = Yup.object({
    causeName: Yup.string().required("Requerido"),
    category: Yup.string(),
    city: Yup.string().required("Requerido"),
    description: Yup.string().required("Requerido"),
    goalCoins: Yup.number().required("No es un numero"),
    countries: Yup.string(),
    province: Yup.string().required("Requerido"),
    requestorName: Yup.string().required("Requerido"),
    sectors: Yup.string(),
    areas: Yup.string(),
    organizationIdType: Yup.string(),
    organizationId: Yup.number().required("No es un ID valido"),
    communicationChannel: Yup.string(),
    phone: Yup.number().typeError().required("No es un numero"),
    email: Yup.string().email().typeError().required("No es un email"),
    legalRepresentator: Yup.string().required("Requerido"),
    location: Yup.string().required("Requerido"),
    personalIdType: Yup.string(),
    personalId: Yup.number().required("No es un ID valido"),
  });

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      causeName: placeHolderValues.name ? placeHolderValues.name : "",
      category: categories[0].name,
      city: placeHolderValues.city ? placeHolderValues.city : "",
      goalCoins: placeHolderValues.coinsMeta ? placeHolderValues.coinsMeta : "",
      countries: countryList[0].name,
      location: placeHolderValues.location ? placeHolderValues.location : "",
      province: placeHolderValues.province ? placeHolderValues.province : "",
      requestorName: organizationValues.organizationName
        ? organizationValues.organizationName
        : "",
      sectors: sectors[0].name,
      areas: areas[0].name,
      description: placeHolderValues.description
        ? placeHolderValues.description
        : "",
      organizationIdType: organizationIdType[0].name,
      organizationId: organizationValues.legalId
        ? organizationValues.legalId
        : "",
      communicationChannel: communicationChannel[0].name,
      phone: organizationValues.phone ? organizationValues.phone : "",
      email: organizationValues.email ? organizationValues.email : "",
      legalRepresentator: organizationValues.representative
        ? organizationValues.representative
        : "",
      personalIdType: idType[0].name,
      personalId: organizationValues.personalId
        ? organizationValues.personalId
        : "",
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const tags = selected;

      let creationDate = fsTimestampCreador.fromDate(new Date(Date.now()));

      const causeData = {
        name: values.causeName,
        category: values.category,
        coinsMeta: values.goalCoins,
        description: values.description,
        country: values.countries,
        province: values.province,
        city: values.city,
        location: values.location,
        generalTags: tags,
        cdate: creationDate,
        isActiveCause: true,
      };

      const organizationData = {
        organizationName: values.requestorName,
        sector: values.sectors,
        impactArea: values.areas,
        legalIdType: values.organizationIdType,
        legalId: values.organizationId,
        representative: values.legalRepresentator,
        personalIdType: values.personalIdType,
        personalId: values.personalId,
        contactBy: values.communicationChannel,
        phone: values.phone,
        email: values.email,
        isActiveOrganization: true,
      };

      updateCause(organizationId, causeId, causeData);
      updateOrganization(organizationId, causeId, organizationData);
      setTimeout(() => {
        setLoading(false);
        resetForm();
        onClose(false);
      }, 2000);
    },
  });
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      noValidate
      className="p-3"
    >
      {/* {Object.keys(errors).map((val) => {
        return <p>{val}</p>;
      })} */}
      <Form.Group>
        <FormInput
          label={"Nombre de la causa"}
          onChange={handleChange}
          value={values.causeName}
          type="text"
          name="causeName"
          placeholder={"Salvemos lo arrecifes"}
          errorList={errors}
        />

        <Form.Control.Feedback type="invalid">
          {errors.causeName}
        </Form.Control.Feedback>
      </Form.Group>

      <Row>
        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: "14px" }} className="mb-3">
              Categoria
            </Form.Label>
            <Form.Select
              size="sm"
              onChange={handleChange}
              name="category"
              value={values.category}
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
            >
              {categories.map((categ) => {
                return (
                  <option value={categ.name} name="category">
                    {categ.name}
                  </option>
                );
              })}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.category}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group>
            <FormInput
              label={"Coins meta"}
              onChange={handleChange}
              value={values.goalCoins}
              type="number"
              name="goalCoins"
              placeholder={"250000"}
              errorList={errors}
              style={{ marginTop: "1px" }}
            />

            <Form.Control.Feedback type="invalid">
              {errors.goalCoins}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Form.Group>
        <FormInput
          label={"Detalles de la causa"}
          onChange={handleChange}
          value={values.description}
          type="text"
          name="description"
          placeholder={""}
          errorList={errors}
        />

        <Form.Control.Feedback type="invalid">
          {errors.description}
        </Form.Control.Feedback>
      </Form.Group>

      <Row>
        <Col>
          <Form.Label style={{ fontSize: "14px" }} className="mt-3">
            Pais
          </Form.Label>
          <Form.Select
            size="sm"
            onChange={handleChange}
            name="countries"
            value={values.countries}
            className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
          >
            {countryList.map((country) => {
              return (
                <option value={country.name} name="countries">
                  {country.name}
                </option>
              );
            })}
          </Form.Select>
        </Col>
        <Col style={{ marginTop: "7px" }}>
          <Form.Group>
            <FormInput
              label={"Provincia/Departamento"}
              onChange={handleChange}
              value={values.province}
              type="text"
              name="province"
              placeholder={"San Jose"}
              errorList={errors}
            />

            <Form.Control.Feedback type="invalid">
              {errors.province}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <FormInput
              label={"Ciudad"}
              onChange={handleChange}
              value={values.city}
              type="text"
              name="city"
              placeholder={"San Jose"}
              errorList={errors}
            />

            <Form.Control.Feedback type="invalid">
              {errors.city}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col>
          <FormInput
            label={"Ubicacion"}
            onChange={handleChange}
            value={values.location}
            type="text"
            name="location"
            placeholder={"https://googlemaps"}
            errorList={errors}
          />

          <Form.Control.Feedback type="invalid">
            {errors.location}
          </Form.Control.Feedback>
        </Col>
      </Row>

      <Form.Label style={{ fontSize: "14px" }} className="mt-3">
        Etiquetas
      </Form.Label>
      <TagsInput
        value={selected}
        onChange={setSelected}
        name="tags"
        placeHolder="Introduzca nuevas etiquetas"
        classNames={{
          input:
            "border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none",
          tag: "bg-tags",
        }}
      />

      <h2 className="h5 mt-4">Datos del solicitante</h2>

      <Form.Group>
        <FormInput
          label={"Nombre del solicitante"}
          onChange={handleChange}
          value={values.requestorName}
          type="text"
          name="requestorName"
          placeholder={"Fundacion las tortugas Ninja"}
          errorList={errors}
        />

        <Form.Control.Feedback type="invalid">
          {errors.requestorName}
        </Form.Control.Feedback>
      </Form.Group>
      <Row>
        <Col>
          <Form.Label style={{ fontSize: "14px" }} className="mt-3">
            Sector
          </Form.Label>
          <Form.Select
            size="sm"
            onChange={handleChange}
            name="sectors"
            value={values.sectors}
            className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
          >
            {sectors.map((sector) => {
              return (
                <option value={sector.name} name="sectors">
                  {sector.name}
                </option>
              );
            })}
          </Form.Select>
        </Col>
        <Col>
          <Form.Label style={{ fontSize: "14px" }} className="mt-3">
            Area de impacto
          </Form.Label>
          <Form.Select
            size="sm"
            onChange={handleChange}
            name="areas"
            value={values.areas}
            className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
          >
            {areas.map((area) => {
              return (
                <option value={area.name} name="areas">
                  {area.name}
                </option>
              );
            })}
          </Form.Select>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Label style={{ fontSize: "14px" }} className="mt-3">
            Tipo ID organizacion
          </Form.Label>
          <Form.Select
            size="sm"
            onChange={handleChange}
            name="organizationIdType"
            value={values.organizationIdType}
            className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
          >
            {organizationIdType.map((id) => {
              return (
                <option value={id.name} name="organizationIdType">
                  {id.name}
                </option>
              );
            })}
          </Form.Select>
        </Col>

        <Col>
          <Form.Group>
            <FormInput
              label={"ID organizacion"}
              onChange={handleChange}
              value={values.organizationId}
              type="text"
              name="organizationId"
              placeholder={"2909595"}
              errorList={errors}
            />
            <Form.Control.Feedback type="invalid">
              {errors.organizationId}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group>
            <FormInput
              label={"Representante legal"}
              onChange={handleChange}
              value={values.legalRepresentator}
              type="text"
              name="legalRepresentator"
              placeholder={"Nombre Apellido"}
              errorList={errors}
            />

            <Form.Control.Feedback type="invalid">
              {errors.legalRepresentator}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Label style={{ fontSize: "14px" }} className="mt-3">
            Tipo ID
          </Form.Label>
          <Form.Select
            size="sm"
            onChange={handleChange}
            name="personalIdType"
            value={values.idType}
            className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
          >
            {idType.map((id) => {
              return (
                <option value={id.name} name="personalIdType">
                  {id.name}
                </option>
              );
            })}
          </Form.Select>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group>
            <FormInput
              label={"ID"}
              onChange={handleChange}
              value={values.personalId}
              type="number"
              name="personalId"
              placeholder={"2909595"}
              errorList={errors}
            />

            <Form.Control.Feedback type="invalid">
              {errors.personalId}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Label style={{ fontSize: "14px" }} className="mt-3">
            Medio preferido de contacto
          </Form.Label>
          <Form.Select
            size="sm"
            onChange={handleChange}
            name="communicationChannel"
            value={values.communicationChannel}
            className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
          >
            {communicationChannel.map((channel) => {
              return (
                <option value={channel.name} name="communicationChannel">
                  {channel.name}
                </option>
              );
            })}
          </Form.Select>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group>
            <FormInput
              label={"Telefono"}
              onChange={handleChange}
              value={values.phone}
              type="number"
              name="phone"
              placeholder={"2909595"}
              errorList={errors}
            />

            <Form.Control.Feedback type="invalid">
              {errors.phone}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <FormInput
              label={"Correo"}
              onChange={handleChange}
              value={values.email}
              type="email"
              name="email"
              placeholder={"user@dominio.com"}
              errorList={errors}
            />

            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Stack direction="horizontal" className="mt-3">
        <Form.Check
          type={"checkbox"}
          id={"sponsorNotification"}
          label={"Notificar al sponsor"}
          style={{ fontSize: "14px" }}
        />

        <Button
          variant="light"
          size="sm"
          className="rounded-pill ms-auto px-3"
          onClick={() => onClose(false)}
        >
          Cancelar
        </Button>
        <Button
          variant="primary"
          size="sm"
          className="rounded-pill text-light px-3"
          type="submit"
          disabled={loading}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {!loading && (
            <span style={{ color: "white !important" }}>Guardar</span>
          )}
        </Button>
      </Stack>
    </Form>
  );
};

export default CauseEdition;
