import { useState, useEffect } from "react";

import Form from "react-bootstrap/Form";
import { Button, Stack, Col, Row, Spinner, ListGroup } from "react-bootstrap";

import { useFormik } from "formik";
import * as Yup from "yup";

import FormInput from "../form-input/form-input";
import { fsTimestampCreador } from "../../firebase/firebase-config";

import { createRefund } from "../../firebase/firebase.utils";

const reasons = [
  { name: "Baja de cliente aprobada" },
  { name: "Cobro duplicado" },
  { name: "Monto incorrecto" },
  { name: "Cobro a usuario no cliente" },
];

const currencies = [{ name: "CRC" }, { name: "USD" }];

const UserRefund = ({ onClose, payment, handleRefresh }) => {
  const [disabled, setDisabled] = useState(true);

  const [achievementStatus, setAchievementStatus] = useState("");

  const [loading, setLoading] = useState(false);

  const [amount, setAmount] = useState(0);
  const [type, setType] = useState("");

  // const validate = Yup.object({
  //   password: Yup.string().required("Contraseña vacia"),
  // });

  const handleSelectTotal = () => {
    setDisabled(true);
    setAmount(payment.transactionAmount);
    setType("total");
  };

  const handleSelectPartial = () => {
    setDisabled(false);
    setAmount(null);
    setType("partial");
  };

  const { handleChange, handleSubmit, values, errors, touched, handleBlur } =
    useFormik({
      initialValues: {
        paymentReference: "",
        type: "",
        amount: "",
        currency: "",
        reason: "",
      },
      // validationSchema: validate,

      onSubmit: (values, { resetForm }) => {
        setLoading(true);
        let creationDate = fsTimestampCreador.fromDate(new Date(Date.now()));
        const refundData = {
          cdate: creationDate,
          paymentReference: payment.id,
          type: type,
          amount: values.amount,
          currency: values.currency,
          reason: values.reason,
        };

        //createRefund(payment.id, refundData);

        setTimeout(() => {
          setLoading(false);
          // handleRefresh();
          // resetForm();
          // onClose(false);
        }, 2000);
      },
    });
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      noValidate
      className="p-3"
    >
      <Form.Group className="mb-5">
        <Form.Label style={{ fontSize: "15px" }} className="mb-2">
          Resumen:
        </Form.Label>
        <ListGroup className="mb-3">
          <ListGroup.Item
            as="li"
            disabled
            style={{ fontSize: "13px", border: "none" }}
            className="mb-0"
          >
            Orden: <strong>{payment.transactionId}</strong>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            disabled
            style={{ fontSize: "13px", border: "none" }}
          >
            Producto: <strong>Plan {payment.plan.subTier}</strong>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            disabled
            style={{ fontSize: "13px", border: "none" }}
          >
            Concepto: <strong>Renovación mensual</strong>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            disabled
            style={{ fontSize: "13px", border: "none" }}
          >
            Fecha de la operación:{" "}
            <strong>
              {payment.transactionDate.toDate().toLocaleDateString("es-CR", {
                month: "numeric",
                day: "numeric",
                year: "numeric",
              })}
            </strong>
          </ListGroup.Item>
        </ListGroup>
      </Form.Group>

      <Row className="mb-3">
        <Col>
          <Form.Label style={{ fontSize: "14px", marginLeft: "50%" }}>
            Tipo:
          </Form.Label>
        </Col>
        <Col>
          <Form.Check
            type="radio"
            name="amount"
            label="Total"
            size="sm"
            onChange={handleSelectTotal}
          />
        </Col>
        <Col>
          <Form.Check
            type="radio"
            name="amount"
            label="Parcial"
            size="sm"
            onChange={handleSelectPartial}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label style={{ fontSize: "14px" }} className="mb-0">
            Monto:
          </Form.Label>
          <FormInput
            disabled={disabled}
            onChange={handleChange}
            value={amount}
            type="number"
            name="amount"
            errorList={errors}
          />
          <Form.Control.Feedback type="invalid">
            {errors.amount}
          </Form.Control.Feedback>
        </Col>

        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: "14px" }} className="mb-2">
              Moneda:
            </Form.Label>
            <Form.Select
              disabled={disabled}
              size="sm"
              onChange={handleChange}
              name="currency"
              value={values.currency}
              // defaultValue={payment.title}
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none"
            >
              {currencies.map((currency) => {
                return (
                  <option value={currency.name} name="title">
                    {currency.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Form.Group>
          <Form.Label style={{ fontSize: "14px" }} className="mb-3">
            Motivo del reintegro:
          </Form.Label>
          <Form.Select
            size="sm"
            onChange={handleChange}
            name="reason"
            value={values.reason}
            className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
          >
            {reasons.map((reason) => {
              return (
                <option value={reason.name} name="title">
                  {reason.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row className="mt-3">
        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: "14px" }} className="mt-3">
              Esta operación es permanente. Digita tu <br /> contraseña para
              continuar:
            </Form.Label>

            <Form.Control
              type="password"
              name="password"
              onChange={handleChange}
              value={values.password}
              isInvalid={errors.password && touched.password}
              placeholder="Contraseña"
              className="rounded-1"
              onBlur={handleBlur}
            />

            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-5">
        <Form.Check
          type={"checkbox"}
          id={`creditNote`}
          label={`Crear una nota de crédito`}
        />
      </Row>

      <Stack direction="horizontal" className="mt-3">
        <Button
          variant="light"
          size="sm"
          className="rounded-pill ms-auto px-3"
          onClick={() => onClose(false)}
        >
          Cancelar
        </Button>
        <Button
          variant="primary"
          size="sm"
          className="rounded-pill text-light px-3"
          type="submit"
          disabled={loading}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {!loading && (
            <span style={{ color: "white !important" }}>Reintegrar</span>
          )}
        </Button>
      </Stack>
    </Form>
  );
};

export default UserRefund;
