export const translations = {
  productName: "Nombre del producto",
  category: "Categoria",
  currency: "Moneda",
  ASPCode: "Codigo ASP",
  CABYS: "CABYS",
  apportionment: "Prorrateo",
  insurer: "Aseguradora",
  exchangeRate: "Tipo de cambio",
  iva: "Porcentaje de IVA",
  variableNames: "Variable",
  amounts: "Cantidad",
  variableData: "Variable",
  variableRows: "Variable",
  valuesData: "Variable",
  data: "Data",
  generalTags: "Etiquetas",
  selectedAssociation: "",
};
