import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  getNotifications,
  subscribeToNotifications,
} from "../../../redux/b2b/actions/actions";

const NotificationDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.b2b);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (route) => {
    navigate(route);
    handleClose();
  };

  useEffect(() => {
    console.log(notifications);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "notification-popover" : undefined;

  useEffect(() => {
    // Set up the onSnapshot listener and handle updates
    const unsubscribe = subscribeToNotifications((newNotifications) => {
      console.log("new", newNotifications);
      dispatch(getNotifications(newNotifications));
    });

    // Unsubscribe when the component unmounts
    return () => {
      unsubscribe();
    };
  }, []); // Empty dependency array ensures this effect runs only once on mount

  // const notifications = [

  //   { id: 1, message: "Notification 1" },
  //   { id: 2, message: "Notification 2" },
  //   { id: 3, message: "Notification 3" },
  // ];

  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        color="inherit"
        sx={{ mx: 2 }}
      >
        <Badge badgeContent={notifications.length} color="error">
          <NotificationsIcon sx={{ color: "text.secondary" }} />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List>
          {notifications.map((notification) => (
            <ListItem
              key={notification.id}
              onClick={() => handleNotificationClick(notification.route)}
              sx={{
                cursor: "pointer", // Add cursor pointer style
                "&:hover": {
                  backgroundColor: "#f0f0f0", // Add background color on hover
                },
                minWidth: "125px",
              }}
            >
              <ListItemText
                primary={notification.title}
                sx={{ textAlign: "center" }}
              />
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};

export default NotificationDropdown;
