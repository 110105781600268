import { SET_BLOGS, SET_POSTS, ADD_POST } from "./blogs";

const initialState = {
  blogs: [],
  postsByCategory: {}, // Initialize an empty object to store posts by category
};

export const blogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BLOGS:
      return {
        ...state,
        blogs: action.blogs,
      };
    case SET_POSTS:
      return {
        ...state,
        postsByCategory: {
          ...state.postsByCategory,
          [action.category]: action.posts, // Store posts under category key
        },
      };
    case ADD_POST:
      return {
        ...state,
        postsByBlog: {
          ...state.postsByBlog,
          [action.blogId]: [
            ...(state.postsByBlog[action.blogId] || []),
            action.post,
          ],
        },
      };
    default:
      return state;
  }
};
