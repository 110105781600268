import { types } from "../types/types";

export const getRequests = (requests) => ({
  type: types.requests,
  payload: requests,
});

export const setShowRefundSummary = (showRefundSummary) => ({
  type: types.SHOW_REFUND_SUMMARY,
  payload: showRefundSummary,
});
