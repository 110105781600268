import { Form } from "react-bootstrap";
import { Stack } from "react-bootstrap";

const Filters = ({ label, values, sortingMethod }) => (
  <div className="bg-info rounded px-2">
    <Stack direction="horizontal" gap={2}>
      <Form.Label className="mb-0">{label}</Form.Label>
      <Form.Select
        size="small"
        className="fw-bold border-0"
        onChange={(e) => sortingMethod(e.target.value)}
      >
        {values.map((value) => (
          <option>{value.name}</option>
        ))}
      </Form.Select>
    </Stack>
  </div>
);

export default Filters;
