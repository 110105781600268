import { types } from "../types/types";

export const getCustomers = () => ({
  type: types.customers,
});

export const addCustomers = (customers) => ({
  type: types.addCustomers,
  payload: customers,
});

export const addCustomerWallets = (wallets) => ({
  type: types.addCustomerWallets,
  payload: wallets,
});
