import { useEffect, useState } from "react";

export const useProvince = (values, cantons) => {
  const [filteredCantons, setFilteredCantons] = useState([]);

  useEffect(() => {
    const selectedProvince = values.province;

    if (selectedProvince) {
      const filterItems = cantons.filter(
        (canton) => canton.provinceName === selectedProvince
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });
      setFilteredCantons(filterItems);
    } else {
      setFilteredCantons([]);
    }
  }, [values.province]);

  return filteredCantons;
};
