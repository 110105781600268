import { auth, db } from "../../../firebase/firebase-config";
import { types } from "../types/types";

export const getUserByEmail = async (email) => {
  const hasAccount = await db
    .collection("admins")
    .where("email", "==", email)
    .get();
  return !hasAccount.empty;
};
export const refreshToken = async () => {
  const token = await auth.currentUser.getIdToken(true);

  return token;
};

export const loginUser = (email, password, setFieldError) => {
  return async (dispatch) => {
    dispatch(loginUserStart());
    try {
      const response = await auth.signInWithEmailAndPassword(email, password);
      const idToken = await response.user.getIdToken(true);
      const claims = await response.user.getIdTokenResult();
      console.log(claims);

      if (claims.claims.superadmin || claims.claims.admin || claims.almighty) {
        dispatch(loginUserSuccess(response.user.toJSON(), true, idToken));
      } else {
        dispatch(
          loginUserFailure(
            new Error(
              "No tienes acceso a este administrador. Contacta a sporte para mas información"
            )
          )
        );
        setFieldError(
          "No tienes acceso a este administrador. Contacta a sporte para mas información"
        );
      }
    } catch (error) {
      dispatch(loginUserFailure(error));
      setFieldError("user", error.message);
      console.log(error);
    }
  };
};

export const logoutUser = () => {
  return async (dispatch) => {
    dispatch(logoutUserStart());
    try {
      await auth.signOut();
      dispatch(logoutUserSuccess());
    } catch (error) {
      dispatch(logoutUserFailure(error));
      console.error(error);
    }
  };
};

export const loginUserStart = () => ({
  type: types.loginStart,
  payload: true,
});

export const loginUserSuccess = (user, loggedIn, idToken, userGetToken) => ({
  type: types.login,
  payload: {
    user: user,
    loggedIn: loggedIn,
    idToken: idToken,
    isLoadingAuth: false,
    userGetToken: userGetToken,
  },
});

export const loginUserFailure = (error) => ({
  type: types.loginFailure,
  payload: error,
});

export const logoutUserStart = () => ({
  type: types.logoutStart,
});

export const logoutUserSuccess = (user) => ({
  type: types.logoutSuccess,
});

export const logoutUserFailure = (error) => ({
  type: types.logoutError,
  payload: error,
});

export const signInWithEmail = (user, claims) => ({
  type: types.login,
  payload: {
    user,
    loggedIn: true,
    isAlmighty: claims.almighty,
    isSuperAdmin: claims.superadmin,
    isAdmin: claims.admin,
    isModerator: claims.moderator,
  },
});
export const setIsLoadingAuth = (isLoading) => ({
  type: types.isLoadingAuth,
  payload: {
    isLoadingAuth: isLoading,
  },
});

export const logout = () => ({
  type: types.logout,
  payload: {},
});
