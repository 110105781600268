import { db } from "../../../firebase/firebase-config";
import { types } from "../types/types";

export const getAllUsers = () => {
  return (dispatch) => {
    dispatch(isLoading(true));
    db.collection("users")
      .get()
      .then(async (itemsFromFs) => {
        const userPromises = itemsFromFs.docs.map(async (item) => {
          const userData = item.data();
          const { personalId, phone } = await getPrivateData(item.id);
          const { overallSportCoins, sportCoins } = await getWalletData(
            item.id
          );

          const name = userData.name || "N/A";
          const lastname =
            userData.lastname !== undefined &&
            userData.lastname !== null &&
            userData.lastname.trim() !== ""
              ? userData.lastname
              : "N/A";
          const secondLastname = userData.secondLastname || "N/A";
          const email = userData.email || "N/A";
          const plan = userData.planType?.subTier || "N/A";

          return {
            id: item.id,
            name: name.toString(),
            lastname: lastname.toString(),
            secondLastname: secondLastname.toString(),
            email: email,
            plan: plan.toString() ?? "N/A",
            personalId: personalId ?? "N/A",
            phone: phone ?? "N/A",
            overallSportCoins: overallSportCoins ?? 0,
            sportCoins: sportCoins ?? 0,
            ...userData,
          };
        });

        const usersWithData = await Promise.all(userPromises);

        dispatch(getUsers(usersWithData));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getAllB2bUsers = () => {
  return (dispatch) => {
    dispatch(isLoading(true));
    db.collection("users")
      .get()
      .then(async (itemsFromFs) => {
        const userPromises = itemsFromFs.docs.map(async (item) => {
          const userData = item.data();
          const { personalId, phone } = await getPrivateData(item.id);
          const { isB2b, code } = await getWalletData(item.id);
          const { overallSportCoins, sportCoins } = await getWalletData(
            item.id
          );
          // Check if isB2b is true before including the user
          if (isB2b) {
            const name = userData.name || "N/A";
            const lastname =
              userData.lastname !== undefined &&
              userData.lastname !== null &&
              userData.lastname.trim() !== ""
                ? userData.lastname
                : "N/A";
            const secondLastname = userData.secondLastname || "N/A";
            const email = userData.email || "N/A";
            const plan = userData.planType?.subTier || "N/A";

            return {
              id: item.id,
              name: name.toString(),
              lastname: lastname.toString(),
              secondLastname: secondLastname.toString(),
              email: email,
              plan: plan.toString() ?? "N/A",
              personalId: personalId ?? "N/A",
              phone: phone ?? "N/A",
              code: code ?? "N/A",

              ...userData,
            };
          }

          return null; // Skip users that don't meet the criteria
        });

        const usersWithData = await Promise.all(userPromises);

        // Filter out null values (users that didn't meet the criteria)
        const filteredUsers = usersWithData.filter((user) => user !== null);

        dispatch(getB2bUsers(filteredUsers));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getUsersFromAssociationCodes = (associationId) => {
  return (dispatch) => {
    dispatch(isLoading(true));
    db.collection("associations")
      .doc(associationId)
      .collection("codes")
      .get()
      .then(async (codeSnapshots) => {
        const userPromises = codeSnapshots.docs.map(async (codeDoc) => {
          const userId = codeDoc.data().userId;
          const userSnapshot = await db.collection("users").doc(userId).get();
          const userData = userSnapshot.data();
          const { personalId, phone } = await getPrivateData(userId);
          const { overallSportCoins, sportCoins } = await getWalletData(userId);
          const {
            activationDate,
            duration,
            expirationDate,
            isActivated,
            productId,
          } = codeDoc.data();

          // const productSnapshot = await db
          //   .collection("products")
          //   .doc(productId)
          //   .get();
          // const productData = productSnapshot.data();

          return {
            id: userId,
            name: userData.name || "N/A",
            lastname: userData.lastname || "N/A",
            secondLastname: userData.secondLastname || "N/A",
            email: userData.email || "N/A",
            plan: userData.planType?.subTier || "N/A",
            personalId: personalId || "N/A",
            phone: phone || "N/A",
            activationDate: activationDate || "N/A",
            duration: duration || "N/A",
            expirationDate: expirationDate || "N/A",
            isActivated: isActivated || false,
            productId: productId || "N/A",
            overallSportCoins: overallSportCoins ?? 0,
            sportCoins: sportCoins ?? 0,
            ...userData,
          };
        });

        const usersData = await Promise.all(userPromises);

        dispatch(getUsers(usersData));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getPrivateData = async (userId) => {
  return await db
    .collection("users")
    .doc(userId)
    .collection("private")
    .doc("data")
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.log("Error getting private data:", error);
      return {};
    });
};

export const getWalletData = async (userId) => {
  return await db
    .collection("users")
    .doc(userId)
    .collection("private")
    .doc("wallet")
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.log("Error getting wallet data:", error);
      return {};
    });
};

export const countB2b = async (onCount) => {
  const query = db.collection("associations");
  let count = 0;
  query.onSnapshot((snapshot) => {
    snapshot.docChanges().forEach((doc) => {
      if (doc.type === "added") onCount(++count);
      if (doc.type === "removed") onCount(--count);
    });
  });
};

export const countActiveB2bUsers = async (onCount) => {
  const query = db
    .collection("users")
    .where("isB2bMember", "==", true)
    .where("isActiveMember", "==", true);

  let count = 0;

  const results = query.onSnapshot((snapshot) => {
    snapshot.docChanges().forEach((docChange) => {
      if (docChange.type === "added") {
        count++;
      } else if (docChange.type === "removed") {
        count--;
      }
      onCount(count);
    });
  });
};
export const countAllB2bUsers = async (onCount) => {
  const query = db.collection("users").where("isB2bMember", "==", true);

  let count = 0;

  const results = query.onSnapshot((snapshot) => {
    snapshot.docChanges().forEach((docChange) => {
      if (docChange.type === "added") {
        count++;
      } else if (docChange.type === "removed") {
        count--;
      }
      onCount(count);
    });
  });
};

export const getUsers = (users) => ({
  type: types.getUsers,
  payload: {
    users,
  },
});
export const getB2bUsers = (b2bUsers) => ({
  type: types.getB2BUsers,
  payload: {
    b2bUsers,
  },
});

export const addUsers = (users) => ({
  type: types.addUsers,
  payload: users,
});

export const refreshUsers = (shouldRefresh) => ({
  type: types.refreshUsers,
  payload: shouldRefresh,
});

export const addWallets = (wallets) => ({
  type: types.addWallets,
  payload: wallets,
});

export const addPrivateData = (data) => ({
  type: types.addPrivateData,
  payload: data,
});
export const isLoading = (isLoading) => ({
  type: types.isLoadingUsers,
  payload: {
    isLoading,
  },
});
