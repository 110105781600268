import { types } from "../types/types";

const INITIAL_STATE = {
  members: null,
  teams: null,
};

const rolesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.addMembers:
      return {
        ...state,
        members: action.payload,
      };
    case types.removeMembers:
      return {
        ...state,
        members: null,
      };
    case types.refreshMembers:
      return {
        ...state,
        refresh: action.payload,
      };
    case types.addTeams:
      return {
        ...state,
        teams: action.payload,
      };
    case types.removeTeams:
      return {
        ...state,
        teams: null,
      };
    case types.refreshTeams:
      return {
        ...state,
        refresh: action.payload,
      };
    default:
      return state;
  }
};

export default rolesReducer;
