import jwtDecode from "jwt-decode";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MUIDataTable from "mui-datatables";
import {
  Box,
  Tab,
  Tabs,
  useTheme,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import CustomBodyWithSpinner from "../general/CustomBodyWithSpinner";
import { useNavigate } from "react-router-dom";
import { IoRefreshOutline } from "react-icons/io5";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { MdDelete, MdEdit } from "react-icons/md";
import {
  getAssociationCodes,
  getUserByUserRef,
  removeUser,
} from "../../redux/b2b/actions/actions";
import { refreshToken } from "../../redux/user/actions/auth";
import { Typography } from "@mui/material";
import { ASbutton } from "../buttons/ASbutton";
import { ActivationModal } from "./inclusions/ActivationModal";

const MySwal = withReactContent(Swal);

export const B2bRequestInclusionsTable = ({
  associationId,
  isDataAvailable,
  pendingUsers,
  handleRefresh,
  loading,
  handleChange,
  value,
}) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const theme = useTheme();
  const matchesUp752 = useMediaQuery("(min-width:752px)");

  const handleOpenActivateModal = async (item) => {
    setEditModalOpen(true);

    setSelectedItem(item);
  };

  const BodyComponent = useMemo(
    () => (tableProps) => {
      if (isDataAvailable) {
        return (
          <CustomBodyWithSpinner loading={!isDataAvailable} {...tableProps} />
        );
      }

      return <CustomBodyWithSpinner loading={loading} {...tableProps} />;
    },
    [pendingUsers, loading, isDataAvailable]
  );
  const onClose = () => {
    setEditModalOpen(false);

    setSelectedItem();
  };

  const columns = [
    {
      name: "name",
      label: "Nombre", // Combined column label
      options: {
        display: true,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const name = tableMeta.rowData[0]; // Assuming "name" is the first column
          const lastname = tableMeta.rowData[1]; // Assuming "lastname" is the second column
          return `${name} ${lastname}`;
        },
      },
    },

    {
      name: "lastname",
      label: "Apellido",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        display: true,
        filter: false,
      },
    },

    {
      name: "codeName",
      label: "Código Asociación",
      options: {
        display: true,
        filter: false,
      },
    },
    {
      name: "codeId",
      label: "Código",
      options: {
        display: true,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const associationCodeName = tableMeta.rowData[3]; // Assuming the association code name is at index 6
          const codeId = tableMeta.rowData[4]; // Assuming the codeId is at index 3
          const combinedCodeId = `${associationCodeName}-${codeId}`;
          return combinedCodeId;
        },
      },
    },
    // {
    //   name: "duration",
    //   label: "Duración",
    //   options: {
    //     customBodyRender: (value) => {
    //       return value ? value : "N/A";
    //     },
    //     filter: false,
    //   },
    // },
    {
      name: "activationDate",
      label: "Fecha",
      options: {
        customBodyRender: (value) => {
          if (typeof value === "object" && value.seconds && value.nanoseconds) {
            const date = new Date(
              value.seconds * 1000 + value.nanoseconds / 1000000
            );
            const formattedDate = date.toLocaleDateString();
            return formattedDate;
          } else {
            return "-";
          }
        },

        filter: false,
      },
    },
    {
      name: "userRef",
      label: "Referencia",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "activate",
      label: "Activar",
      options: {
        empty: true,
        customBodyRender: (_, tableMeta) => {
          const { rowIndex, rowData } = tableMeta;
          const selectUser = pendingUsers.find(
            (user) => user.codeId === rowData[4]
          ); // Assuming "codeId" is in the third column (index 2)

          return (
            <ASbutton
              buttonText={"Activar"}
              onClick={() => handleOpenActivateModal(selectUser)}
            ></ASbutton>
          );
        },
        filter: false,
        sort: false,
      },
    },
  ];
  const CustomToolbar = ({ onRefresh }) => {
    return (
      <IconButton className="refresh-button" onClick={onRefresh}>
        <IoRefreshOutline size={25} />
      </IconButton>
    );
  };
  const CustomTitle = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab
            label="Solicitudes de Exclusión"
            sx={{ fontSize: 12, paddingBottom: 0 }}
          />
          <Tab
            label="Solicitudes de Inclusión"
            sx={{ fontSize: 12, paddingBottom: 0 }}
          />
        </Tabs>
      </Box>
    );
  };
  const options = {
    filter: false,
    responsive: "standard",
    selectableRows: "none",
    rowsPerPage: 15,
    sortOrder: {
      name: "email",
      direction: "asc",
    },
    textLabels: {
      body: {
        noMatch: "No hay datos disponibles",
      },
    },
    sortCompare: (order) => (a, b) => {
      const isAsc = order === "asc";
      // Replace "lastname" with the column name you want to handle
      const column = "lastname";

      // Handle null, undefined, and empty string values
      const aValue =
        a.data[column] !== undefined &&
        a.data[column] !== null &&
        a.data[column].trim() !== ""
          ? a.data[column]
          : "N/A";
      const bValue =
        b.data[column] !== undefined &&
        b.data[column] !== null &&
        b.data[column].trim() !== ""
          ? b.data[column]
          : "N/A";

      // Use default comparison for other cases
      if (isAsc) {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    },
    customToolbar: () => {
      return <CustomToolbar onRefresh={handleRefresh} />;
    },

    // onRowClick: (rowData) => {
    //   const clickedOwnerId = rowData[0];

    //   handleUserSelect(clickedOwnerId);
    // },
  };
  // if (loading) {
  //   return <div>loading</div>;
  // }
  return (
    <Box
      sx={{
        width: "100%",
        marginTop: {
          xs: 0,
          [theme.breakpoints.up(752)]: {
            marginTop: 60,
          },
        },
      }}
    >
      {/* <div className="refresh-container">
                <Button className="refresh-button">
                  <IoRefreshOutline size={25} onClick={handleRefresh} />
                </Button>
              </div> */}
      <MUIDataTable
        title={matchesUp752 ? CustomTitle() : ""}
        data={pendingUsers}
        columns={columns}
        options={options}
        components={{ TableBody: BodyComponent }}
      />

      {selectedItem && (
        <ActivationModal
          code={selectedItem}
          open={editModalOpen}
          onClose={onClose}
          onRefresh={handleRefresh}
          associationId={associationId}
        />
      )}
    </Box>
  );
};
