import { useState, useEffect } from "react";

import { Card, Form } from "react-bootstrap";

import { EditText } from "react-edit-text";

import { Formik } from "formik";
import * as Yup from "yup";

import {
  getUserMetaData,
  updateUserMetaData,
} from "../../firebase/firebase.utils";

const UserInterests = ({ id, user: userPublicData, editable }) => {
  const [userMetaData, setUserMetaData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const metaData = await getUserMetaData(userPublicData.id);

      setUserMetaData(metaData);
    };

    getData();
  }, [userPublicData.id]);

  if (!userMetaData) return;

  return (
    <Card className="border-light rounded-bottom" style={{ borderRadius: 0 }}>
      <Card.Body className="p-0">
        <Formik
          //validationSchema={validate}
          enableReinitialize={true}
          initialValues={{
            brands: userMetaData.brands
              ? userMetaData.brands.join(" ")
              : "----",
            food: userMetaData.food ? userMetaData.food.join(" ") : "----",
            supplements: userMetaData.supplements
              ? userMetaData.supplements.join(" ")
              : "----",
            hobbies: userMetaData.hobbies
              ? userMetaData.hobbies.join(" ")
              : "----",
          }}
          onSubmit={(values) => {
            const updatesUserMetaData = {
              brands: values.brands,
              food: values.food,
              supplements: values.supplements,
              hobbies: values.hobbies,
            };

            updateUserMetaData(id, updatesUserMetaData);
          }}
        >
          {({ handleChange, handleSubmit, values, errors, setFieldValue }) => (
            <Form noValidate>
              <h2 className="h5 pt-4 pb-2 px-4 border-top fw-bold">
                Intereses
              </h2>
              <div className="border-bottom border-top py-2 px-4">
                <small className="text-primary d-block">Marcas</small>
                <small>
                  <EditText
                    name="brands"
                    readonly={!editable}
                    style={{
                      outline: 0,
                      width: "270px",
                      height: "50px",
                    }}
                    className="d-flex"
                    inputClassName="bg-info border-0 shadow-none form-control is-invalid"
                    onChange={handleChange}
                    onSave={({ value, previousValue }) => {
                      if (value === "") {
                        setFieldValue("brands", previousValue);
                      } else {
                        handleSubmit();
                      }
                    }}
                    value={values.brands}
                  />
                  {/* {userMetaData.brands.map((brand) => {
              if (brand) {
                return (
                  <span key={Math.random() * (100 - 1) + 1}>{`${brand} `}</span>
                );
              } else {
                return "----";
              }
            })} */}
                </small>
              </div>
              <div className="border-bottom py-2 px-4">
                <small className="text-primary d-block">Comidas</small>
                <small>
                  <EditText
                    name="food"
                    readonly={!editable}
                    style={{
                      outline: 0,
                      width: "270px",
                      height: "50px",
                    }}
                    className="d-flex"
                    inputClassName="bg-info border-0 shadow-none form-control is-invalid"
                    onChange={handleChange}
                    onSave={({ value, previousValue }) => {
                      if (value === "") {
                        setFieldValue("food", previousValue);
                      } else {
                        handleSubmit();
                      }
                    }}
                    value={values.food}
                  />
                  {/* {userMetaData.food.map((foodStuff) => {
                    if (foodStuff) {
                      return (
                        <span
                          key={Math.random() * (100 - 1) + 1}
                        >{`${foodStuff} `}</span>
                      );
                    } else {
                      return "----";
                    }
                  })} */}
                </small>
              </div>
              <div className="border-bottom py-2 px-4">
                <small className="text-primary d-block">Suplementos</small>
                <small>
                  <EditText
                    name="supplements"
                    readonly={!editable}
                    style={{
                      outline: 0,
                      width: "270px",
                      height: "50px",
                    }}
                    className="d-flex"
                    inputClassName="bg-info border-0 shadow-none form-control is-invalid"
                    onChange={handleChange}
                    onSave={({ value, previousValue }) => {
                      if (value === "") {
                        setFieldValue("supplements", previousValue);
                      } else {
                        handleSubmit();
                      }
                    }}
                    value={values.supplements}
                  />
                </small>
              </div>
              <div className="py-2 px-4">
                <small className="text-primary d-block">Hobbies</small>
                <small>
                  <EditText
                    name="hobbies"
                    readonly={!editable}
                    style={{
                      outline: 0,
                      width: "270px",
                      height: "60px",
                    }}
                    className="d-flex"
                    inputClassName="bg-info border-0 shadow-none form-control is-invalid"
                    onChange={handleChange}
                    onSave={({ value, previousValue }) => {
                      if (value === "") {
                        setFieldValue("hobbies", previousValue);
                      } else {
                        handleSubmit();
                      }
                    }}
                    value={values.hobbies}
                  />
                </small>
                {/* {userMetaData.hobbies.map((hobby) => {
                  if (hobby) {
                    return <small>{hobby}</small>;
                  } else {
                    return "----";
                  }
                })} */}
              </div>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default UserInterests;
