import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/firestore";
import {
  createBannerImage,
  updatePromoBanner,
} from "../../redux/banners/actions/actions";
import darkPlus from "./../../assests/darkPlus.png";
import { MdEdit } from "react-icons/md";
// import UploadBannerImage from "./UploadBannerImage";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FormInput from "../form-input/form-input";
import { Close } from "@mui/icons-material";
import { styles } from "../../styles/styles";
import { FormControl } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { Checkbox } from "@mui/material";
import { updateB2b } from "../../redux/b2b/actions/actions";
import { Button, Spinner } from "react-bootstrap";
import UploadImage from "./UploadImage";

const MySwal = withReactContent(Swal);
const EditB2b = ({ open, onClose, data, handleRefresh, id }) => {
  const theme = useTheme();

  const validationSchema = Yup.object().shape({
    businessName: Yup.string().required("Business Name is required"),
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
    // Add validation for other fields as needed
  });

  const [imageUpload, setImageUpload] = useState("");
  const [image, setImage] = useState(data.imageURL);
  const [fileUrl, setFileUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Update formik initial values and the image state when data prop changes
    formik.setValues({
      name: data.name ?? "",
      // email: data.email || "",
      businessName: data.businessName || "",
      codeName: data.codeName ?? "",
      legalId: data.legalId ?? "",
      phone: data.phone ?? "",
      //   imageURL: data.imageURL ?? "",
      location: data.location ?? "",
      address: data.address ?? "",
      latitude: data.location?.latitude ?? "",
      longitude: data.location?.longitude ?? "",
      isActive: data.isActive ?? false,
    });

    // Update the image state with the initial data.imageURL
    setImage(data.imageURL || "");
  }, [data]);

  const formik = useFormik({
    initialValues: {
      name: data.name ?? "",
      // email: data.email || "",
      businessName: data.businessName || "",
      codeName: data.codeName ?? "",
      legalId: data.legalId ?? "",
      phone: data.phone ?? "",
      //   imageURL: data.imageURL ?? "",
      location: data.location ?? "",
      address: data.address ?? "",
      latitude: data.location?.latitude ?? "",
      longitude: data.location?.longitude ?? "",
      isActive: data.isActive ?? false,
    },
    // validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const updatedData = {
        name: values.name,
        codeName: values.codeName,
        legalId: values.legalId,
        phone: values.phone,
        // email: values.email,
        businessName: values.businessName,
        location: new firebase.firestore.GeoPoint(
          parseFloat(values.latitude),
          parseFloat(values.longitude)
        ),
        address: values.address,
        isActive: values.isActive ?? false,
      };

      // Show the confirmation prompt using Swal
      const confirmResult = await MySwal.fire({
        title: "Confirmar edición",
        text: "¿Estás seguro de que deseas editar esta asociación?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, editar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });

      if (confirmResult.isConfirmed) {
        try {
          //   if (imageUpload !== "") {
          //     createBannerImage(imageUpload, data.id);
          //   }
          setLoading(true);
          await updateB2b(id, updatedData);

          console.log(id);

          MySwal.fire("Guardado!", "", "success");
        } catch (error) {
          MySwal.fire(
            "Error",
            "Hubo un error al activar/desactivar el curso",
            "error"
          );
        }

        setTimeout(() => {
          setLoading(false);
          //   setImageUpload("");

          handleRefresh();
          onClose();
        }, 1000);
      }
    },
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          // backgroundColor: theme.palette.secondary.secondary,
          width: "100%",
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle>
        Editar Asociación
        <Box sx={styles.closeButton} onClick={onClose}>
          <Close />
        </Box>
      </DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Nombre Asociación"
                fullWidth
                variant="standard"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Empresa de la asociación"
                fullWidth
                variant="standard"
                name="businessName"
                value={formik.values.businessName}
                onChange={formik.handleChange}
                error={
                  formik.touched.businessName &&
                  Boolean(formik.errors.businessName)
                }
                helperText={
                  formik.touched.businessName && formik.errors.businessName
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Codigo"
                fullWidth
                variant="standard"
                name="codeName"
                disabled
                value={formik.values.codeName}
                onChange={formik.handleChange}
                error={
                  formik.touched.codeName && Boolean(formik.errors.codeName)
                }
                helperText={formik.touched.codeName && formik.errors.codeName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Teléfono"
                fullWidth
                variant="standard"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Cedula Jurídica"
                fullWidth
                variant="standard"
                name="legalId"
                value={formik.values.legalId}
                onChange={formik.handleChange}
                error={formik.touched.legalId && Boolean(formik.errors.legalId)}
                helperText={formik.touched.legalId && formik.errors.legalId}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Dirección"
                fullWidth
                variant="standard"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Latitud"
                fullWidth
                variant="standard"
                name="latitude"
                value={formik.values.latitude}
                onChange={formik.handleChange}
                error={
                  formik.touched.latitude && Boolean(formik.errors.latitude)
                }
                helperText={formik.touched.latitude && formik.errors.latitude}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Longitud"
                fullWidth
                variant="standard"
                name="longitude"
                value={formik.values.longitude}
                onChange={formik.handleChange}
                error={
                  formik.touched.longitude && Boolean(formik.errors.longitude)
                }
                helperText={formik.touched.longitude && formik.errors.longitude}
              />
            </Grid>

            <Grid item xs={6} mt={2}>
              <FormControl
                component="fieldset"
                sx={{
                  marginLeft: "0px !important",
                  flexDirection: "row",
                  marginBottom: "15px",
                }}
              >
                <FormControlLabel
                  sx={{ marginLeft: "0px !important", flexDirection: "row" }}
                  value="isActive"
                  label="Activa"
                  labelPlacement="start"
                  control={
                    <Checkbox
                      name="isActive"
                      checked={formik.values.isActive}
                      onChange={formik.handleChange}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={6} mt={2}>
            <UploadImage
              loadUrl={setFileUrl}
              imageUpload={imageUpload}
              setImageUpload={setImageUpload}
              setImage={setImage}
              image={image}
            />
          </Grid>

          {/* <UploadBannerImage
            title={"Banner"}
            loadUrl={setFileUrl}
            imageUpload={imageUpload}
            setImageUpload={setImageUpload}
            setImage={setImage}
            image={image}
          /> */}

          <DialogActions>
            <Button
              variant="primary"
              size="md"
              className="rounded-pill text-light px-3 ms-auto"
              onClick={formik.handleSubmit}
              disabled={loading}
            >
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {!loading && (
                <span style={{ color: "white !important" }}>Editar</span>
              )}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditB2b;
