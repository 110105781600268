import { useState } from "react";

import CustomModal from "./../../components/modal/customModal";
import MemberAddition from "../../components/roles-addition/member-addition";
import RolesTableTabs from "../../components/roles-table-tabs/roles-table-tabs";

import { Button, Stack, FormLabel } from "react-bootstrap";
import { Container } from "@mui/material";

const Roles = () => {
  return (
    <Container maxWidth={'xl'} >
      <RolesTableTabs />
    </Container>
  );
};

export default Roles;
