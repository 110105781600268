import { Container, Typography } from "@mui/material";
import CommerceSummary from "../../components/commerce-summary/commerce-summary";
import CommerceTableTabs from "../../components/commerce-table-tabs/commerce-table-tabs";
import ProductTableContainer from "../../components/products/ProductsTableContainer";
import InsurersTable from "../../components/commerce-table/insurersTable";
import AdminHistory from "../../components/adminHistory/AdminHistory";
import { getInsurerAdminHistory } from "../../firebase/firebase.utils";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const Products = () => {
  const { insurers } = useSelector((state) => state.insurers);
  const [insurerIdList, setInsurerIdList] = useState([]);

  useEffect(() => {
    if (insurers?.length) {
      const idList = insurers.map((insurer) => insurer.id);

      setInsurerIdList(idList);
    }
  }, [insurers]);

  return (
    <Container maxWidth={"xl"}>
      <CommerceSummary />
      <ProductTableContainer />
      <div className="mt-4">
        <Typography variant="h5" component="h2" my={3}>
          Aseguradoras
        </Typography>
        <InsurersTable />

        <div className="mt-3">
          <AdminHistory
            id={insurerIdList}
            getAdminHistory={getInsurerAdminHistory}
          />
        </div>
      </div>
    </Container>
  );
};

export default Products;
