import { types } from "../types/types";

const INITIAL_STATE = {
  banners: null,
  promoBanners: [],
  carouselBanners: [],
  homeBanners: [],
  activityBanners: [],
};

const bannersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.getPromoBanners:
      return {
        ...state,
        promoBanners: action.payload,
      };
    case types.getHomeBanners:
      return {
        ...state,
        homeBanners: action.payload,
      };
    case types.getCarouselBanners:
      return {
        ...state,
        carouselBanners: action.payload,
      };
    case types.getActivityBanners:
      return {
        ...state,
        activityBanners: action.payload,
      };
    case types.addBanners:
      return {
        ...state,
        banners: action.payload,
      };
    case types.removeBanners:
      return {
        ...state,
        achievements: null,
      };
    case types.isLoadingPromoBanners:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        // displayName: action.payload.displayName,
      };
    default:
      return state;
  }
};

export default bannersReducer;
