import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Stack, Button } from "react-bootstrap";
import { Col, Row, Container } from "react-bootstrap";

import { getUserExchanges } from "./../../firebase/firebase.utils";

import "./user-exchange.scss";
import CustomModal from "../modal/customModal";
import RedeemDetails from "../redeems/redeemDetails";
import UserExchangeList from "./user-exchangeList";

const UserExchange = ({ id }) => {
  const [userExchanges, setUserExchanges] = useState(null);
  const [showRedeemDetails, setShowRedeemDetails] = useState(false);
  const [selectedReedem, setSelectedReedem] = useState(null);

  useEffect(() => {
    const getExchangesFromUser = async () => {
      const exchanges = await getUserExchanges(id);

      setUserExchanges(exchanges);
    };

    getExchangesFromUser();
  }, [id]);

  if (!userExchanges) return;

  const setRedeem = (data) => {
    setSelectedReedem(data);
    setShowRedeemDetails(true);
  };

  return (
    <Card className="border-light">
      <Card.Body className="p-0">
        <h2 className="h5 border-bottom fw-bold py-3 px-4">
          Historial de canjes
        </h2>
        <Container>
          {userExchanges?.length ? (
            <UserExchangeList
              userExchanges={userExchanges}
              setRedeem={setRedeem}
            />
          ) : (
            <h5 className="text-center my-3">No hay historial</h5>
          )}
          {/* {userExchanges.map((exchange) => {
            return (
              <Row className="mt-3">
                <Col xxl="1" lg={0}>
                  <small className="d-block rounded-circle bg-primary outer-circle"></small>
                </Col>
                <Col className="p-0">
                  <Stack direction="horizontal" className="px-3">
                
                    <small className="text-muted">
                      {exchange.redeemDate
                        ? exchange.redeemDate
                            .toDate()
                            .toLocaleDateString("es-CR", {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            })
                        : "----"}
                    </small>
                    <small className="fw-bold ms-auto">
                      {exchange.isRedeemed ? "Redimido" : "Disponible"}
                    </small>
                  </Stack>

                  <Stack direction="horizontal" className="px-3">
                    <small className="fw-bold">{exchange.exchange.name}</small>
                    <Button
                      variant="light"
                      className="ms-auto"
                      onClick={() => setRedeem(exchange)}
                    >
                      <small className="text-primary">Detalles</small>
                    </Button>
                  </Stack>
                </Col>
              </Row>
            );
          })} */}

          <CustomModal
            show={showRedeemDetails}
            handleClose={() => setShowRedeemDetails(false)}
            modalTitle={() => "Detalles"}
          >
            <RedeemDetails redeem={selectedReedem} />
          </CustomModal>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default UserExchange;
