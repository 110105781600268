import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Stack, Container, Col, Row } from "react-bootstrap";

import CustomModal from "../modal/customModal";
import ExchangeDeletion from "../exchange-deletion/exchange-deletion";
import PromotionEdit from "../promotion-edit/promotion-edit";
import CreatePromoModal from "../create-promo-form/create-promo-form";

import { BiPencil } from "react-icons/bi";
import { BiTrash } from "react-icons/bi";

import { useState, useEffect } from "react";

import { getAllPromosRealTime } from "../../firebase/firebase.utils";

import "./sponsor-promo.scss";
import { useTheme } from "@emotion/react";

const SponsorPromo = ({ id, business }) => {
  const [promos, setPromos] = useState([]);
  const [filteredPromos, setFilteredPromos] = useState([]);
  const [selectedPromo, setSelectedPromo] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreatePromoModal, setShowCreatePromoModal] = useState(false);
  const [selectedButton, setSelectedButton] = useState("Todos");

  const theme = useTheme();

  useEffect(() => {
    const query = getAllPromosRealTime(id);

    query.onSnapshot((querySnapshot) => {
      const promosArr = [];
      querySnapshot.forEach((doc) => {
        promosArr.push({ id: doc.id, ...doc.data(), selected: false });
      });
      setPromos(promosArr);
      setFilteredPromos(promosArr);
    });
  }, [id]);

  if (!promos) {
    return;
  }

  const cleanSelectedPromo = (id) => {
    const cleanArray = filteredPromos.map((promo) => ({
      ...promo,
      selected: false,
    }));

    const index = cleanArray.findIndex((promo) => promo.id === id);

    cleanArray[index].selected = !cleanArray[index].selected;

    setFilteredPromos(cleanArray);
  };

  const handleDelete = () => {
    const itemToDelete = filteredPromos.find((promo) => promo.selected);
    if (itemToDelete) {
      setShowDeleteModal(true);
    }

    setSelectedPromo(itemToDelete);
  };

  const handleEdit = () => {
    const itemToUpdate = filteredPromos.find((promo) => promo.selected);
    setSelectedPromo(itemToUpdate);
    if (itemToUpdate) {
      setShowEditModal(true);
    }
  };

  const showActivePromos = (promotionsList, event) => {
    setSelectedButton(event.target.textContent);
    const onlyActivePromos = promotionsList.filter(
      (promo) => promo.status === "active"
    );

    setFilteredPromos(onlyActivePromos);
  };

  const showExpiredPromos = (promotionsList, event) => {
    setSelectedButton(event.target.textContent);
    const onlyExpiredPromos = promotionsList.filter((promo) => {
      return new Date() > promo.dueDate.toDate();
    });

    setFilteredPromos(onlyExpiredPromos);
  };

  const showAllPromos = (event) => {
    setSelectedButton(event.target.textContent);
    setFilteredPromos(promos);
  };

  return (
    <>
      <Card className="border-0 mt-3">
        <Card.Header className="bg-success p-0 pb-2 border-bottom-0">
          <h2 className="h5 fw-bold py-3 px-4 text-primary">Promos</h2>
          <Stack direction="horizontal" gap={3} className="py-3 px-4">
            <Button
              variant="light"
              style={{ boxShadow: "0px 7px 6px -3px rgba(0,0,0,0.35)" }}
              className={`rounded-pill border-0 ${
                selectedButton === "Todos"
                  ? "bg-primary text-light"
                  : "bg-light"
              }`}
              size="sm"
              onClick={(event) => showAllPromos(event)}
            >
              Todos
            </Button>
            <Button
              variant="light"
              style={{ boxShadow: "0px 7px 6px -3px rgba(0,0,0,0.35)" }}
              className={`rounded-pill ${
                selectedButton === "Activos"
                  ? "bg-primary text-light"
                  : "bg-light"
              }`}
              size="sm"
              onClick={(event) => showActivePromos(promos, event)}
            >
              Activos
            </Button>
            <Button
              variant="light"
              style={{ boxShadow: "0px 7px 6px -3px rgba(0,0,0,0.35)" }}
              className={`rounded-pill ${
                selectedButton === "Vencidos"
                  ? "bg-primary text-light"
                  : "bg-light"
              }`}
              size="sm"
              onClick={(event) => showExpiredPromos(promos, event)}
            >
              Vencidos
            </Button>

            <Button
              variant="light"
              style={{ boxShadow: "0px 7px 6px -3px rgba(0,0,0,0.35)" }}
              className="rounded-pill ms-auto"
              size="sm"
              onClick={() => setShowCreatePromoModal(true)}
            >
              Agregar
            </Button>
          </Stack>
        </Card.Header>
        <Card.Body className="p-0">
          <Stack direction="horizontal" gap={2} className="py-3 px-4">
            <p className="text-muted me-auto mb-0">Acciones</p>

            <span
              className="bg-secondary rounded-circle d-flex justify-content-center align-items-center"
              style={{ width: "25px", height: "25px" }}
            >
              <BiTrash
                color={theme.palette.light.main}
                size={15}
                className="hover-effect"
                onClick={handleDelete}
              />
            </span>

            <span
              className="bg-primary rounded-circle d-flex justify-content-center align-items-center"
              style={{ width: "25px", height: "25px" }}
            >
              <BiPencil
                className="hover-effect"
                onClick={handleEdit}
                color={theme.palette.light.main}
                size={15}
              />
            </span>
          </Stack>

          <Container className="px-4 bg-success rounded-bottom">
            <Row className="py-3">
              <Col>
                <p className="text-muted me-auto fw-bold">Producto</p>
              </Col>
            </Row>
            {filteredPromos.map((promo) => {
              return (
                <Row
                  className="pb-3 hover-effect bg-effect"
                  onClick={(e) => {
                    cleanSelectedPromo(promo.id);
                  }}
                >
                  <Col className="pb-3">
                    {/* <p className="text-muted me-auto fw-bold">Producto</p> */}
                    <Stack direction="horizontal">
                      <small
                        className={`${
                          promo.selected ? "bg-primary" : "bg-secondary"
                        } d-inline-block rounded-circle me-3`}
                        style={{ width: "12px", height: "12px" }}
                      ></small>
                      <p className="mb-0 fw-bold fs-6">{promo.name}</p>
                    </Stack>

                    <Stack direction="horizontal">
                      <small
                        className="bg-transparent rounded-circle me-3"
                        style={{ width: "12px", height: "12px" }}
                      ></small>
                      <small className="text-muted">
                        {promo.dueDate?.toDate().toLocaleDateString("es-CR", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </small>
                    </Stack>
                  </Col>
                </Row>
              );
            })}
          </Container>
        </Card.Body>
      </Card>
      <CustomModal
        show={showDeleteModal}
        handleClose={setShowDeleteModal}
        modalTitle={() => "Eliminar Promo"}
      >
        <ExchangeDeletion
          business={business}
          exchangeId={selectedPromo?.id}
          sponsorId={id}
          onClose={setShowDeleteModal}
          promo={true}
        />
      </CustomModal>

      <CustomModal
        show={showEditModal}
        handleClose={setShowEditModal}
        modalTitle={() => (
          <HeaderButtons buttonText={{ first: "Editar", second: "Promo" }} />
        )}
      >
        <PromotionEdit
          selectedPromo={selectedPromo}
          business={business}
          promotionId={selectedPromo?.id}
          sponsorId={id}
          onClose={setShowEditModal}
          placeholderValues={selectedPromo}
          buttonText={{ cancel: "Cancelar", continue: "Guardar" }}
        />
      </CustomModal>

      <CustomModal
        show={showCreatePromoModal}
        handleClose={setShowCreatePromoModal}
        modalTitle={() => (
          <HeaderButtons buttonText={{ first: "Crear", second: "Promo" }} />
        )}
      >
        <CreatePromoModal
          onClose={setShowCreatePromoModal}
          sponsorId={id}
          business={business}
        />
      </CustomModal>
    </>
  );
};

const HeaderButtons = ({ buttonText }) => (
  <Stack direction="horizontal" gap={2} className="">
    <Button
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#9A9A9A" }}
    >
      {buttonText.first}
    </Button>
    <Button
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#AAAAAA" }}
    >
      {buttonText.second}
    </Button>
  </Stack>
);

export default SponsorPromo;
