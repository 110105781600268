import { Grid, Paper, Divider } from "@mui/material";
import { ReactComponent as UserGroup } from "./../../assests/userGroup.svg";
import { ReactComponent as Phone } from "./../../assests/phone.svg";
import { ReactComponent as CheckedUser } from "./../../assests/checkedUser.svg";

import { useEffect, useState } from "react";
import Overview from "../overview/overview";
import { useSelector } from "react-redux";
import {
  MdPerson2,
  MdBusiness,
  MdLocalOffer,
  MdShoppingBasket,
  MdDiscount,
} from "react-icons/md";

import {
  countActiveExchanges,
  countSponsors,
  getAllSponsors,
} from "../../firebase/firebase.utils";

const BriefCard = () => {
  const [sponsorsNumber, setSponsorsNumber] = useState(0);
  const [activeExchangesNum, setActiveExchangesNum] = useState(0);

  useEffect(() => {
    const count = async () => {
      // const sponsors = await getAllSponsors();

      // for (const sponsor of sponsors) {
      //   await countActiveExchanges(setActiveExchangesNum, sponsor.id);
      // }

      await countSponsors(setSponsorsNumber);
    };

    count();
  }, []);

  const totalSponsors = {
    title: "Total de sponsors",
    totalNumber: sponsorsNumber,
    statistics: "16%",
    color: true,
    reference: "este mes",
  };

  const activeExchange = {
    title: "Canjes activos",
    totalNumber: activeExchangesNum,
    statistics: "10%",
    color: true,
    reference: "este mes",
  };

  const activePromo = {
    title: "Promos activas",
    totalNumber: 189,
    statistics: "5%",
    color: true,
    reference: "este mes",
  };

  const exchangedCoins = {
    title: "Coins canjeados",
    totalNumber: 152080,
    statistics: "10%",
    color: true,
    reference: "este mes",
  };

  const bestExchange = {
    title: "Mejor canje",
    totalNumber: "Cafe del dia",
    statistics: "Startbucks",
    color: false,
    reference: "9000 coins",
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Overview
            data={totalSponsors}
            icon={
              <MdBusiness
                style={{ width: "25px", height: "25px", color: "white" }}
              />
            }
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Overview
            data={activeExchange}
            icon={
              <MdPerson2
                style={{ width: "25px", height: "25px", color: "white" }}
              />
            }
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Overview
            data={activePromo}
            icon={
              <MdDiscount
                style={{ width: "25px", height: "25px", color: "white" }}
              />
            }
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Overview
            data={exchangedCoins}
            icon={
              <MdShoppingBasket
                style={{ width: "25px", height: "25px", color: "white" }}
              />
            }
          />
        </Grid>
        {/* <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Overview data={bestExchange} number={251} />
        </Grid> */}
      </Grid>
    </Paper>
  );
};

export default BriefCard;
