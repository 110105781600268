export const sortAchievementsTable = (cells, sortingMethod) => {
  let sortedItems = [];

  switch (sortingMethod) {
    case "Más recientes":
      sortedItems = [...cells].sort((firstElement, secondElement) => {
        return secondElement.register.toDate() - firstElement.register.toDate();
      });

      return sortedItems;
    case "Más antiguos":
      sortedItems = [...cells].sort((firstElement, secondElement) => {
        return firstElement.register.toDate() - secondElement.register.toDate();
      });

      return sortedItems;
    case "Orden alfabetico":
      sortedItems = [...cells].sort((firstElement, secondElement) => {
        return firstElement.categorie.localeCompare(secondElement.categorie);
      });
    default:
      return;
  }
};
