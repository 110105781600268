import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import {
  createBannerImage,
  createHomeBannerImage,
  createHomeCarouselImage,
  updateHomeBanner,
  updateHomeCarouselBanner,
  updatePromoBanner,
} from "../../redux/banners/actions/actions";
import darkPlus from "./../../assests/darkPlus.png";
import { MdEdit } from "react-icons/md";
import UploadBannerImage from "./UploadBannerImage";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FormInput from "../form-input/form-input";
import { Close } from "@mui/icons-material";
import { styles } from "../../styles/styles";
import { FormControl } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { Checkbox } from "@mui/material";

const MySwal = withReactContent(Swal);
const EditHomeBanner = ({ open, onClose, data, handleRefresh, activity }) => {
  const theme = useTheme();

  const validationSchema = Yup.object().shape({
    businessName: Yup.string().required("Business Name is required"),
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
    // Add validation for other fields as needed
  });

  const [imageUpload, setImageUpload] = useState("");
  const [image, setImage] = useState(data.imageURL);
  const [fileUrl, setFileUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isRoute, setIsRoute] = useState(false);

  const routes = [
    { id: 1, value: "Sponsor", name: "Sponsor" },
    { id: 1, value: "Commerce", name: "Comercio" },
    { id: 1, value: "Exchange", name: "Canjes" },
  ];

  const formik = useFormik({
    initialValues: {
      link: data.link || "",
      isRoute: data.isRoute ?? false,
      isLarge: data.isLarge ?? "",
      imgURL: data.imgURL ?? "",
      isWebView: data.isWebView ?? false,
      route: data.route ?? "",
      screen: data.screen ?? "",
      sponsorId: data.sponsorId ?? "",
      orderNumber: data.orderNumber ?? 0,
      isWebOnly: data.isWebOnly ?? false,
    },
    // validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (values.isRoute) {
        values.isWebView = false;
      } else {
        values.isWebView = true;
      }
      if (values.route === "Sponsor") {
        values.screen = "SponsorScreen";
      } else {
        values.screen = "";
        values.sponsorId = "";
      }
      const updatedData = {
        link: values.link,
        isRoute: values.isRoute,
        isLarge: values.isLarge,
        isWebView: values.isWebView,
        route: values.route,
        screen: values.screen,
        sponsorId: values.sponsorId,
        imgURL: values.imgURL,
        orderNumber: values.orderNumber,
        isWebOnly: values.isWebOnly,
      };

      // Show the confirmation prompt using Swal
      const confirmResult = await MySwal.fire({
        title: "Confirmar edición",
        text: "¿Estás seguro de que deseas editar este banner?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, editar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });

      if (confirmResult.isConfirmed) {
        setLoading(true);
        const loadingSwal = MySwal.fire({
          title: "Guardando...",

          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            MySwal.showLoading();
          },
        });
        try {
          if (activity) {
            if (imageUpload !== "") {
              createHomeCarouselImage(imageUpload, data.id);
            }

            await updateHomeCarouselBanner(data.id, updatedData);
          } else {
            if (imageUpload !== "") {
              createHomeBannerImage(imageUpload, data.id);
            }

            await updateHomeBanner(data.id, updatedData);
          }

          console.log(updatedData);
          // console.log(values);
          loadingSwal.close();
          MySwal.fire("Guardado!", "", "success");
        } catch (error) {
          MySwal.fire("Error", "Hubo un error al editar el banner", "error");
        }

        onClose();
        setTimeout(() => {
          setLoading(false);
          setImageUpload("");

          handleRefresh();
          onClose();
          loadingSwal.close();
        }, 1000);
      }
    },
  });
  useEffect(() => {
    // Update formik initial values and the image state when data prop changes
    formik.setValues({
      link: data.link || "",
      isRoute: data.isRoute ?? false,
      isLarge: data.isLarge ?? "",
      imgURL: data.imgURL ?? "",
      isWebView: data.isWebView ?? false,
      route: data.route ?? "",
      screen: data.screen ?? "",
      sponsorId: data.sponsorId ?? "",
      orderNumber: data.orderNumber ?? 0,
      isWebOnly: data.isWebOnly ?? false,
    });

    // Update the image state with the initial data.imageURL
    setImage(data.imgURL || "");
  }, [data]);

  //   useEffect(() => {
  //     if (formik.values.isRoute === true) {
  //       setIsRoute(true);
  //     } else {
  //       setIsRoute(false);
  //     }
  //     console.log(isRoute);
  //   }, [formik.values]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          // backgroundColor: theme.palette.secondary.secondary,
          width: "100%",
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle>
        Editar Banner de Home
        <Box sx={styles.closeButton} onClick={onClose}>
          <Close />
        </Box>
      </DialogTitle>
      <DialogContent>
        <form>
          <Box mb={3} mt={3}>
            <Typography>General</Typography>
          </Box>
          <Grid container>
            <Grid item md={12}>
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="link"
                name="link"
                label="Enlace"
                value={formik.values.link}
                onChange={formik.handleChange}
                error={formik.touched.link && Boolean(formik.errors.link)}
                helperText={formik.touched.link && formik.errors.link}
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item md={5}>
              <FormControl
                sx={{ my: 2, width: "100%" }}
                variant="standard"
                margin="dense"
              >
                <InputLabel htmlFor="isLarge">Tamaño</InputLabel>
                <Select
                  native
                  value={formik.values.isLarge}
                  onChange={formik.handleChange}
                  inputProps={{
                    name: "isLarge",
                    id: "isLarge",
                  }}
                >
                  <option value={true}>Grande</option>
                  <option value={false}>Normal</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={5}>
              <TextField
                variant="standard"
                fullWidth
                type="number"
                id="item"
                name="orderNumber"
                label="Numero Orden"
                value={formik.values.orderNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.orderNumber &&
                  Boolean(formik.errors.orderNumber)
                }
                helperText={
                  formik.touched.orderNumber && formik.errors.orderNumber
                }
              />
            </Grid>
          </Grid>
          <FormControl
            component="fieldset"
            sx={{
              marginLeft: "0px !important",
              flexDirection: "row",
              marginBottom: "15px",
            }}
          >
            <FormControlLabel
              sx={{ marginLeft: "0px !important", flexDirection: "row" }}
              value="isWebOnly"
              label="Solo Web"
              labelPlacement="start"
              control={
                <Checkbox
                  name="isWebOnly"
                  checked={formik.values.isWebOnly}
                  onChange={formik.handleChange}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
            />
          </FormControl>
          <Box mb={3} mt={3}>
            <Typography>App</Typography>
          </Box>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item md={5}>
              <FormControl sx={{ my: 2, width: "100%" }}>
                <InputLabel htmlFor="isRoute">Contiene ruta</InputLabel>
                <Select
                  native
                  value={formik.values.isRoute}
                  onChange={(e) => {
                    const newValue = e.target.value === "true"; // Convert to boolean
                    formik.setFieldValue("isRoute", newValue);
                  }}
                  inputProps={{
                    name: "isRoute",
                    id: "isRoute",
                  }}
                >
                  <option value={true}>Si</option>
                  <option value={false}>No</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={5}>
              {formik.values.isRoute && (
                <FormControl sx={{ my: 2, width: "100%" }}>
                  <InputLabel htmlFor="route">Ruta app</InputLabel>
                  <Select
                    native
                    value={formik.values.route}
                    onChange={formik.handleChange}
                    inputProps={{
                      name: "route",
                      id: "route",
                    }}
                  >
                    {routes.map((option) => (
                      <option value={option.value}> {option.name}</option>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            mb={4}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item md={12}>
              {formik.values.route === "Sponsor" &&
                formik.values.isRoute === true && (
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="sponsorId"
                    name="sponsorId"
                    label="Id Sponsor"
                    value={formik.values.sponsorId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.sponsorId &&
                      Boolean(formik.errors.sponsorId)
                    }
                    helperText={
                      formik.touched.sponsorId && formik.errors.sponsorId
                    }
                  />
                )}
            </Grid>
          </Grid>

          <UploadBannerImage
            title={"Banner"}
            loadUrl={setFileUrl}
            imageUpload={imageUpload}
            setImageUpload={setImageUpload}
            setImage={setImage}
            image={image}
          />

          <DialogActions>
            <Button variant="contained" color="error" onClick={onClose}>
              Cancelar
            </Button>
            <Button
              onClick={formik.handleSubmit}
              variant="contained"
              disabled={loading}
            >
              {loading ? "Guardando..." : "Guardar"}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditHomeBanner;
