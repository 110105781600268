import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";

import CustomModal from "../modal/customModal";
import HistoryEntryDetails from "./HistoryEntryDetails";

import "./adminHistory.scss";
import HistoryList from "./HistoryList";

const AdminHistory = ({ id, getAdminHistory }) => {
  const [adminChanges, setAdminChanges] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);

  useEffect(() => {
    const getAdminHistoryFromItem = async () => {
      const history = await getAdminHistory(id);

      setAdminChanges(history);
    };

    if (id) {
      getAdminHistoryFromItem();
    }
  }, [id]);

  // if (!adminChanges?.length) return;

  const setHistoryEntry = (data) => {
    setSelectedEntry(data);
    setShowDetails(true);
  };

  return (
    <Card className="border-light">
      <Card.Body className="p-0">
        <h2 className="h5 border-bottom fw-bold py-3 px-4">
          Historial de admin
        </h2>
        <div className="p-3">
          {adminChanges?.length ? (
            <HistoryList
              setHistoryEntry={setHistoryEntry}
              adminChanges={adminChanges}
            />
          ) : (
            <h5 className="text-center my-3">No hay historial</h5>
          )}

          <CustomModal
            show={showDetails}
            handleClose={() => setShowDetails(false)}
            modalTitle={() => "Detalles"}
          >
            <HistoryEntryDetails historyEntry={selectedEntry} />
          </CustomModal>
        </div>
      </Card.Body>
    </Card>
  );
};

export default AdminHistory;
