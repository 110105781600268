import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Accordion, Card, Form, Button, Col, Row } from "react-bootstrap";
import {
  addAdminHistoryEntry,
  deleteBranch,
  editBranches,
} from "../../firebase/firebase.utils";
import firebase from "firebase/compat/app";
import "firebase/firestore";
import FormInput from "../form-input/form-input";
import { Provinces as provinces } from "../../utils/provinces";
import { Cantons as cantons } from "../../utils/cantons";
import { Districts as districts } from "../../utils/districts";
import { countryList } from "../../utils/sponsorData";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSelector } from "react-redux";
import { branchInitialValues } from "./editBranchInitialValues";
import { getChangedFields } from "../../utils/getChangeFields";
import { translations } from "./spanishBranchFieldName";

const MySwal = withReactContent(Swal);

const EditBranch = ({ data, sponsorId, index, getSponsorBranches }) => {
  const [expanded, setExpanded] = useState(false);
  const [filteredCantons, setFilteredCantons] = useState([]);
  const [filteredDistricts, setFilteredDistricts] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [initialData, setInitialData] = useState(branchInitialValues);

  const { user: currentAdmin } = useSelector((state) => state.auth);

  const validationSchema = Yup.object().shape({
    city: Yup.string().required("Campo Requerido"),
    location: Yup.string().required("Campo Requerido"),
    province: Yup.string().required("Campo Requerido"),
    canton: Yup.string().required("Campo Requerido"),
    district: Yup.string().required("Campo Requerido"),
    latitude: Yup.number()
      .required("Campo Requerido")
      .typeError("Debe ser un numero"),
    longitude: Yup.number()
      .required("Campo Requerido")
      .typeError("Debe ser un numero"),
  });

  // useEffect(() => {
  //   // Update formik initial values and the image state when data prop changes
  //   formik.setValues({
  //     city: data.city ?? "",
  //     location: data.location ?? "",
  //     province: data.province ?? "San José",
  //     district: data.district ?? "",
  //     countries: data.countries ?? "CR",
  //     latitude: data.latitude ?? "",
  //     longitude: data.longitude ?? "",
  //     canton: data.canton ?? "",
  //     district: data.district ?? "",
  //   });
  // }, []);

  useEffect(() => {
    if (data) {
      setInitialData({
        city: data.city ?? "",
        location: data.location ?? "",
        province: data.province ?? "San José",
        district: data.district ?? "",
        countries: data.countries ?? "CR",
        latitude: data.latitude ?? "",
        longitude: data.longitude ?? "",
        canton: data.canton ?? "",
        district: data.district ?? "",
      });
    }
  }, [data]);

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const branchData = {
        city: values.city,
        location: values.location,
        province: values.province,
        canton: values.canton,
        district: values.district,
        geopoint: new firebase.firestore.GeoPoint(
          parseFloat(values.latitude),
          parseFloat(values.longitude)
        ),
        latitude: values.latitude ?? "",
        longitude: values.longitude ?? "",
      };

      const fieldsChanged = getChangedFields(values, initialData, translations);

      const adminHistory = {
        modificationDate: new Date(),
        admin: {
          id: currentAdmin.uid,
          name: currentAdmin.displayName,
          email: currentAdmin.email,
        },
        modifications: fieldsChanged,
        modificationContext: {
          collectionName: "branches",
          docId: data.id,
        },
      };

      const confirmResult = await MySwal.fire({
        title: "Crear sponsor",
        text: "¿Estás seguro de que deseas crear este sponsor?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, crear",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });

      if (confirmResult.isConfirmed) {
        const loadingSwal = MySwal.fire({
          title: "Guardando...",

          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            MySwal.showLoading();
          },
        });

        try {
          await editBranches(sponsorId, branchData, data.id);
          addAdminHistoryEntry(
            adminHistory,
            `sponsors/${sponsorId}/adminHistory`
          );
          setHasUnsavedChanges(false);
          loadingSwal.close();
          MySwal.fire("Sucursal editada!", "", "success");
        } catch (error) {
          loadingSwal.close();
          MySwal.fire("Error", "Hubo un error al intentar guardar", "error");
          console.log(error);
        }
      }
    },
  });

  console.log(formik.values);

  useEffect(() => {
    const selectedProvince = formik.values.province;

    if (selectedProvince) {
      const filterItems = cantons.filter(
        (canton) => canton.provinceName === selectedProvince
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });

      setFilteredCantons(filterItems);
    }
  }, [formik.values.province, formik.values.canton, formik.values.district]);

  useEffect(() => {
    const selectedCanton = formik.values.canton;

    if (selectedCanton) {
      const filterItems = districts.filter(
        (district) => district.cantonName === selectedCanton
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });

      setFilteredDistricts(filterItems);
    }
  }, [formik.values.canton, formik.values.district]);

  const handleRemove = () => {
    MySwal.fire({
      title: "¿Estás seguro?",
      text: "¿Deseas eliminar esta sucursal?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          deleteBranch(sponsorId, data.id);
          getSponsorBranches();
          MySwal.fire("Sucursal eliminada!", "", "success");
        } catch (error) {
          MySwal.fire("Error", "Hubo un error al eliminar", "error");
          console.log(error);
        }
      }
    });
  };

  return (
    <>
      <Form
        onSubmit={(e) => {
          formik.handleSubmit();
        }}
        noValidate
      >
        <Accordion className="mb-4">
          <Accordion.Item eventKey="0">
            <Accordion.Header>{`Sucursal ${index + 1}`}</Accordion.Header>
            <Accordion.Body>
              <Row className="mt-2">
                <Col>
                  <Form.Group>
                    <Form.Label style={{ fontSize: "14px" }} className="mb-3">
                      Pais
                    </Form.Label>
                    <Form.Select
                      size="sm"
                      onChange={formik.handleChange}
                      name={`countries`}
                      value={formik.values.countries}
                      className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                    >
                      {countryList.map((country) => (
                        <option value={country.name} key={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Provincia</Form.Label>
                    <Form.Control
                      as="select"
                      name={`province`}
                      value={formik.values.province}
                      onChange={(e) => {
                        // Set the 'province' value
                        formik.setFieldValue("province", e.target.value);

                        // You can also set the 'canton' and 'district' values here
                        formik.setFieldValue("canton", "");
                        formik.setFieldValue("district", "");
                      }}
                    >
                      {provinces.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Cantón</Form.Label>
                    <Form.Control
                      as="select"
                      name={`canton`}
                      value={formik.values.canton}
                      onChange={(event) => {
                        formik.setFieldValue("district", "");
                        formik.handleChange(event);
                      }}
                      isInvalid={
                        !!formik.errors.canton && formik.touched.canton
                      }
                    >
                      <option value="">Seleccione una opcion</option>
                      {filteredCantons.map((option) => (
                        <option key={option.label} value={option.label}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>

                    <Form.Control.Feedback type="invalid">
                      {formik.errors.canton}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Distrito</Form.Label>
                    <Form.Control
                      as="select"
                      name={`district`}
                      value={formik.values.district}
                      onChange={formik.handleChange}
                      isInvalid={
                        !!formik.errors.district && formik.touched.district
                      }
                    >
                      <option value="">Seleccione una opcion</option>
                      {filteredDistricts.map((option) => (
                        <option key={option.label} value={option.label}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>

                    <Form.Control.Feedback type="invalid">
                      {formik.errors.district}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <FormInput
                      label={"Ciudad"}
                      onChange={formik.handleChange}
                      value={formik.values.city}
                      type="text"
                      name={`city`}
                      placeholder={"San Jose"}
                      errorList={formik.errors}
                      touched={formik.touched}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.city}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <FormInput
                      label={"Ubicación"}
                      onChange={formik.handleChange}
                      value={formik.values.location}
                      type="text"
                      name={`location`}
                      placeholder={"https://googlemaps"}
                      errorList={formik.errors}
                      touched={formik.touched}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.location}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <FormInput
                      label={"Latitud"}
                      onChange={formik.handleChange}
                      value={formik.values.latitude}
                      type="number"
                      name={`latitude`}
                      placeholder={"9.00"}
                      errorList={formik.errors}
                      touched={formik.touched}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.latitude}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <FormInput
                      label={"Longitud"}
                      onChange={formik.handleChange}
                      value={formik.values.longitude}
                      type="number"
                      name={`longitude`}
                      placeholder={"-84.00"}
                      errorList={formik.errors}
                      touched={formik.touched}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.longitude}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <div className="text-center mt-2">
                <Button
                  type="submit"
                  className="btn btn-primary rounded-pill me-4"
                  onClick={formik.handleSubmit}
                >
                  Guardar
                </Button>
                <Button
                  className="btn btn-danger rounded-pill "
                  onClick={handleRemove}
                >
                  Eliminar Sucursal
                </Button>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {hasUnsavedChanges && ( // Display unsaved changes indicator if changes are detected
          <div variant="warning">hay cambios sin guardar.</div>
        )}
      </Form>
    </>
  );
};

export default EditBranch;
