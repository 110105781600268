export const translations = {
  typeId: "Tipo identificacion",
  idNumber: "Número de identificacion",
  name: "Nombre",
  level: "Nivel",
  email: "Email",
  phone: "Teléfono",
  country: "País",
  province: "Provincia",
  canton: "Cantón",
  district: "Distrito",
  category: "Categoría",
  products: "Productos",
  status: "Estado",
};
