import { Button, Stack, FormLabel } from "react-bootstrap";
import SummaryCauses from "../../components/summary-causes/summary-causes";
import AchievementsTable from "../../components/achievements-table/achievements-table";
import CustomModal from "../../components/modal/customModal";
import AchievementAddition from "../../components/achievement-addition/achievement-addition";
import { useState } from "react";
import { Container } from "@mui/material";

const Achievements = () => {
  const [count, setCount] = useState(0);

  const [showAchievementAdditionModal, setShowAchievementAdditionModal] =
    useState(false);

  const handleRefresh = () => {
    setCount((current) => current + 1);
  };
  return (
    <Container maxWidth={'xl'} >
      <Stack direction="horizontal" gap={2} className="mb-4">
        <h1 className="h5 mb-0">Achievements</h1>

        <Button
          className="ms-auto"
          variant="primary ms-auto"
          onClick={() => {
            setShowAchievementAdditionModal(true);
          }}
        >
          Agregar
        </Button>
      </Stack>
      <SummaryCauses />
      <AchievementsTable count={count} />
      <CustomModal
        show={showAchievementAdditionModal}
        handleClose={setShowAchievementAdditionModal}
        modalTitle={() => <HeaderButtons type={"promo"} />}
      >
        <AchievementAddition
          handleRefresh={handleRefresh}
          onClose={setShowAchievementAdditionModal}
        />
      </CustomModal>
    </Container>
  );
};

const HeaderButtons = ({ type }) => (
  <Stack direction="horizontal" gap={2} className="">
    <FormLabel
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#9A9A9A", fontSize: "15px" }}
    >
      Registro
    </FormLabel>
    <FormLabel
      size="sm"
      className="text-light border-0 px-4 rounded-pill "
      style={{ background: "#AAAAAA", fontSize: "15px" }}
    >
      Achievement
    </FormLabel>
  </Stack>
);

export default Achievements;
