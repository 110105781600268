import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";

import {
  deactivateSponsor,
  deleteSponsor,
  getSponsor,
  updateSponsor,
} from "../../firebase/firebase.utils";

import darkLeftArrow from "./../../assests/darkLeftArrow.png";

import { Col, Row } from "react-bootstrap";
import { Stack, Button } from "react-bootstrap";

import Swal from "sweetalert2";
import { Box, Switch, Tab, Tabs, Typography } from "@mui/material";

import {
  getAssociation,
  getAssociationCodes,
  getAssociationProducts,
  updateB2b,
} from "../../redux/b2b/actions/actions";
import B2bOverview from "../../components/b2b/B2bOverview";
import { Container, Grid } from "@mui/material";

import { B2bUsersTable } from "../../components/b2b/B2bUsersTable";
import { AddB2bForm } from "../../components/b2b/AddB2BForm";
import { styles } from "../../styles/styles";
import { B2bExclusionsTable } from "../../components/b2b/B2bExclusionsTable";
import { B2bDeactivatedTable } from "../../components/b2b/B2bDeactivatedTable";

const B2bProfile = () => {
  const { id } = useParams();
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [showSocialMediaEdition, setShowSocialMediaEdition] = useState(false);
  const [productsFromDB, setProductsFromDB] = useState([]);
  const [loading, setloading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const isFirstUse = useRef(true);
  const isFirstUse2 = useRef(true);
  const [isProductDataAvailable, setIsProductDataAvaialable] = useState(true);
  const [isDataAvailable, setDataAvailable] = useState(true);
  const [productsLoading, setProductsLoading] = useState(true);
  const [users, setUsers] = useState({
    all: [],
    pending: [],
    deactivated: [],
    active: [],
  });
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getUsersFromFB = useCallback(async () => {
    try {
      const codes = await getAssociationCodes(id);
      const filteredUsers = codes.filter(
        (u) => u.status === "exclusion request"
      );
      const active = codes.filter((u) => u.status === "active");
      const deactivated = codes.filter((u) => u.status === "deactivated");
      setUsers({
        all: codes,
        pending: filteredUsers,
        deactivated: deactivated,
        active: active,
      });
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  useEffect(() => {
    if (isFirstUse.current) {
      setloading(true);
      getUsersFromFB();
      isFirstUse.current = false;
    } else if (users.all.length > 0) {
      setloading(false); // Set loading to false if users are available
    } else {
      const timeout = setTimeout(() => {
        setDataAvailable(false);
        setloading(false); // Set loading to false if no data is available
      }, 2000);

      return () => clearTimeout(timeout);
    }

    // No need to set loading to false here
  }, [id, users]);

  useEffect(() => {
    const getAssociationFromFB = async () => {
      getAssociation(id).onSnapshot((business) =>
        setSelectedBusiness(business.data())
      );
    };

    getAssociationFromFB();
  }, [id]);

  const getProductsFromFB = useCallback(async () => {
    try {
      const products = await getAssociationProducts(id);
      setProductsFromDB(products);
      console.log(products);
    } catch (error) {
      console.log(error);
    }
    console.log(productsFromDB);
  }, [productsFromDB]);

  useEffect(() => {
    if (isFirstUse2.current) {
      setProductsLoading(true);
      getProductsFromFB();
      isFirstUse2.current = false;
    } else if (productsFromDB.length > 0) {
      setProductsLoading(false);
      setIsProductDataAvaialable(true);
    } else {
      const timeout = setTimeout(() => {
        setIsProductDataAvaialable(false);
        setProductsLoading(false); // Set loading to false if no data is available
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [getProductsFromFB, productsFromDB]);

  const handleRefresh = () => {
    setDataAvailable(false);
    setIsRefreshing(true);
    setloading(true);
    getUsersFromFB();
    setTimeout(() => {
      setIsRefreshing(false);
      setloading(false);
    }, 1000);
  };
  const TabPanel = ({ children, value, index }) => {
    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
      >
        {value === index && (
          <Grid item md={12} sm={12} xs={12}>
            {children}
          </Grid>
        )}
      </Box>
    );
  };
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-primary me-2",
      cancelButton: "btn btn-secondary",
      denyButton: "btn btn-danger me-2",
    },
    buttonsStyling: false,
  });

  const callOmitSponsor = () => {
    swalWithBootstrapButtons
      .fire({
        title: "Confirme su elección",

        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Desactivar",
        confirmButtonColor: "#FAA60F",
      })
      .then((result) => {
        if (result.isConfirmed) {
          // deactivateSponsor(id);
          updateB2b(id, { isActive: false });
          Swal.fire("Asociación desactivada", "", "success");
        }
      });
  };

  const callIncludeSponsor = () => {
    Swal.fire({
      title: "Confirme su elección",

      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Activar",
      confirmButtonColor: "#FAA60F",
    }).then((result) => {
      if (result.isConfirmed) {
        updateB2b(id, { isActive: true });
        Swal.fire("Asociación activada", "", "success");
      }
    });
  };

  if (!selectedBusiness) {
    // Early return with loading message or spinner
    return <p>Loading...</p>;
  }
  return (
    <Container maxWidth="xl">
      <Stack direction="horizontal" className="w-100 mb-4">
        <Link to="/b2b" className="w-25 d-block link-dark text-decoration-none">
          <h1 className="h5 mb-0">
            <span className="d-inline-block" style={{ width: "14px" }}>
              <img
                src={darkLeftArrow}
                alt="Black arrow icon"
                className="w-100"
              />
            </span>
            Atrás
          </h1>
        </Link>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "auto",
            alignItems: "center",
          }}
        >
          <Typography variant="body1" color="textSecondary" mr={2}>
            Estado: {selectedBusiness.isActive ? "Activado" : "Desactivado"}
          </Typography>
          <Switch
            checked={selectedBusiness.isActive}
            onChange={() => {
              if (selectedBusiness.isActive) {
                callOmitSponsor();
              } else {
                callIncludeSponsor();
              }
            }}
            color="primary"
            inputProps={{ "aria-label": "toggle sponsor status" }}
          />
        </Box>
      </Stack>
      <B2bOverview
        business={selectedBusiness}
        id={id}
        openModal={setShowSocialMediaEdition}
        productsFromDB={productsFromDB}
        loading={productsLoading}
        noDataAvailable={isProductDataAvailable}
      />
      <Grid container item spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <B2bUsersTable
            activeUsers={users.active}
            handleRefresh={handleRefresh}
            isDataAvailable={isDataAvailable}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid item>
        {/* <Tabs value={value} onChange={handleChange} sx={{ mt: 4 }}>
          <Tab label="Exclusiones" />
          <Tab label="Inclusiones" />
        </Tabs> */}
        <TabPanel value={value} index={0}>
          <B2bExclusionsTable
            pendingUsers={users.pending}
            handleRefresh={handleRefresh}
            isDataAvailable={isDataAvailable}
            associationId={id}
            value={value}
            handleChange={handleChange}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <B2bDeactivatedTable
            deactivatedUsers={users.deactivated}
            handleRefresh={handleRefresh}
            isDataAvailable={isDataAvailable}
            associationId={id}
            loading={loading}
            value={value}
            handleChange={handleChange}
          />
        </TabPanel>

        <Grid item md={12} sm={12} xs={12}>
          <AddB2bForm
            id={id}
            productsFromDB={productsFromDB}
            loading={loading}
            noDataAvailable={isDataAvailable}
            isProductDataAvailable={isProductDataAvailable}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default B2bProfile;
