import { types } from "../types/types";

const INITIAL_STATE = {
  causes: null,
  causesCoins: [],
};

const causesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.addCauses:
      return {
        ...state,
        causes: action.payload,
      };
    case types.removeCauses:
      return {
        ...state,
        causes: null,
      };
    case types.addCoins:
      return {
        ...state,
        causesCoins: action.payload,
      };
    case types.refreshCauses:
      return {
        ...state,
        refresh: action.payload,
      };
    default:
      return state;
  }
};

export default causesReducer;
