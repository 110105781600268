import magnifyingGlass from "./../../assests/magnifyingGlass.png";

import { Stack } from "react-bootstrap";

const Searchbar = ({ onSearch, barWidth, containerClass = "ms-auto" }) => {
  return (
    <div className={containerClass}>
      <Stack direction="horizontal" gap={2}>
        <div style={{ position: "relative" }} className={containerClass}>
          <img
            src={magnifyingGlass}
            alt="magnifying glass"
            style={{
              position: "absolute",
              top: "8px",
              left: "10px",
              width: 20,
            }}
          />
          <input
            type="text"
            className="form-control border-0"
            style={{
              height: 35,
              width: barWidth ? barWidth : "100%",
              textIndent: 33,
            }}
            placeholder="Buscar"
            onChange={(e) => onSearch(e.target.value)}
          />
        </div>
      </Stack>
    </div>
  );
};

export default Searchbar;
