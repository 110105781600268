export const sortCustomersTable = (cells, sortingMethod) => {
  console.log(cells);
  let sortedItems = [];
  switch (sortingMethod) {
    case "Donaciones + a -":
      sortedItems = [...cells].sort((firstElement, secondElement) => {
        return secondElement.donated - firstElement.donated;
      });
      return sortedItems;

    case "Registro recientes":
      sortedItems = [...cells].sort((firstElement, secondElement) => {
        return secondElement.cdate.toDate() - firstElement.cdate.toDate();
      });

      return sortedItems;

    case "Compras recientes":
      sortedItems = [...cells].sort((firstElement, secondElement) => {
        return (
          secondElement.memberSince.toDate() - firstElement.memberSince.toDate()
        );
      });

      return sortedItems;

    default:
      return;
  }
};
