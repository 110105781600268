import {
  Container,
  Row,
  Col,
  Button,
  Form as BootstrapForm,
  Stack,
} from "react-bootstrap";
import FormInput from "../../form-input/form-input";

import LocationDataSection from "./locationDataSection";

const EditUserDataForm = ({ onClose, formikData }) => {
  const {
    values,
    handleChange,
    errors,
    setFieldValue,
    handleSubmit,
    handleBlur,
    touched,
  } = formikData;

  return (
    <>
      <Container
        as={"form"}
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
      >
        <Row>
          <Col md={12}>
            <BootstrapForm.Group>
              <BootstrapForm.Label>Nombre de usuario</BootstrapForm.Label>

              <FormInput
                type="text"
                name="userName"
                className="form-control"
                value={values.userName}
                as={BootstrapForm.Control}
                onChange={handleChange}
                errorList={errors}
              />

              <BootstrapForm.Control.Feedback type="invalid">
                {/* {errors.description} */}
              </BootstrapForm.Control.Feedback>
            </BootstrapForm.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <BootstrapForm.Group>
              <BootstrapForm.Label>Número cédula</BootstrapForm.Label>

              <FormInput
                type="text"
                name="idNumber"
                className="form-control"
                value={values.idNumber}
                as={BootstrapForm.Control}
                onChange={handleChange}
                errorList={errors}
              />

              <BootstrapForm.Control.Feedback type="invalid">
                {/* {errors.description} */}
              </BootstrapForm.Control.Feedback>
            </BootstrapForm.Group>
          </Col>
          <Col md={6}>
            <BootstrapForm.Group>
              <BootstrapForm.Label>Nacimiento</BootstrapForm.Label>

              <FormInput
                type="date"
                name="birthday"
                className="form-control"
                value={values.birthday}
                as={BootstrapForm.Control}
                onChange={handleChange}
                errorList={errors}
              />

              <BootstrapForm.Control.Feedback type="invalid">
                {/* {errors.description} */}
              </BootstrapForm.Control.Feedback>
            </BootstrapForm.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <BootstrapForm.Group>
              <BootstrapForm.Label>Email</BootstrapForm.Label>

              <FormInput
                type="email"
                name="email"
                className="form-control"
                disabled
                value={values.email}
                as={BootstrapForm.Control}
                onChange={handleChange}
                errorList={errors}
              />

              <BootstrapForm.Control.Feedback type="invalid">
                {/* {errors.description} */}
              </BootstrapForm.Control.Feedback>
            </BootstrapForm.Group>
          </Col>
          <Col md={6}>
            <BootstrapForm.Group>
              <BootstrapForm.Label>Teléfono</BootstrapForm.Label>

              <FormInput
                type="text"
                name="phone"
                className="form-control"
                value={values.phone}
                as={BootstrapForm.Control}
                onChange={handleChange}
                errorList={errors}
              />

              <BootstrapForm.Control.Feedback type="invalid">
                {/* {errors.description} */}
              </BootstrapForm.Control.Feedback>
            </BootstrapForm.Group>
          </Col>
        </Row>

        <LocationDataSection
          formikData={{
            values,
            handleChange,
            setFieldValue,
          }}
        />

        <Row>
          <Col md={12}>
            <BootstrapForm.Group>
              <BootstrapForm.Label>Deporte favorito</BootstrapForm.Label>

              <FormInput
                name="favoriteSport"
                className="form-control"
                value={values.favoriteSport}
                component={BootstrapForm.Control}
                onChange={handleChange}
                errorList={errors}
              />

              <BootstrapForm.Control.Feedback type="invalid">
                {/* {errors.description} */}
              </BootstrapForm.Control.Feedback>
            </BootstrapForm.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <BootstrapForm.Group>
              <BootstrapForm.Label>Otros deportes</BootstrapForm.Label>

              <FormInput
                name="sportsOfInterest"
                className="form-control"
                value={values.sportsOfInterest}
                component={BootstrapForm.Control}
                onChange={handleChange}
                errorList={errors}
                multiple
              />

              <BootstrapForm.Control.Feedback type="invalid">
                {/* {errors.description} */}
              </BootstrapForm.Control.Feedback>
            </BootstrapForm.Group>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <BootstrapForm.Group>
              <BootstrapForm.Label>Observaciones</BootstrapForm.Label>

              <FormInput
                name="observations"
                className="form-control"
                value={values.observations}
                component={BootstrapForm.Control}
                onChange={handleChange}
                onBlur={handleBlur}
                errorList={errors}
                touched={touched}
                as="textarea"
              />

              <BootstrapForm.Control.Feedback type="invalid">
                {errors.observations}
              </BootstrapForm.Control.Feedback>
            </BootstrapForm.Group>
          </Col>
        </Row>

        <Stack direction="horizontal" gap={2} className="mt-2">
          <Button variant="light" className="ms-auto" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Finalizar
          </Button>
        </Stack>
      </Container>
    </>
  );
};

export default EditUserDataForm;
