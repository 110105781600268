import { Button } from "react-bootstrap";
import { Stack, Container } from "react-bootstrap";

import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

import AdvertisementCard from "../advertisement-card/advertisement-card";
import SelectPromotion from "../select-promotion/select-promotion";
import CustomModal from "../modal/customModal";
import StepsModal from "../modal/steps-modal";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import "./publicity.scss";
import { useCallback, useEffect, useState } from "react";
import { getAllBanners, getAllPromos, getDisplayNumber, getSponsorPromos } from "../../firebase/firebase.utils";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addBanners } from "../../redux/banners/actions/actions";
import { getAllActivePromos } from './../../firebase/firebase.utils';
import CoinLoader from './../coin-loader/coin-loader';

const Publicity = () => {
  const [promos, setPromos] = useState([]);
  //const [showSelectPromos, setShowSelectPromos] = useState(false);
  const [showStepModal, setShowStepModal] = useState(false);
  const [banners, setBanners] = useState([]);
  const [stepNumber, setStepNumber] = useState(0);
const [displayNumber, setDisplayNumber] = useState(3)
const [loading, setLoading] = useState(false)
  const titles = ["Seleccionar promoción", "Seleccionar audiencia"];

  const dispatch = useDispatch();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1400 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1400, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 50,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
useEffect(() => {
  setLoading(true)
  getNumber();
  setLoading(false)
}, [])

const getNumber = async () => {


const getDisplayN = await getDisplayNumber();
const displayN =getDisplayN.data().carouselDisplayBanners;
setDisplayNumber(displayN)


}


// useEffect(() => {

//   getActiveP()

// }, [])
// const getActiveP = useCallback(async () => {

//   const promotionsFromDb = await getSponsorPromos();
// console.log(promotionsFromDb)


// }, [ ]);




  useEffect(() => {
    try {
      setLoading(true)
      const getBanners = async () => {
        getAllBanners().onSnapshot((querySnapshot) => {
          const bannerData = [];
          querySnapshot.forEach((doc) => {
            bannerData.push({ id: doc.id, ...doc.data() });
          });
  
          setBanners(bannerData);
          dispatch(addBanners(bannerData));
        });
      };
      const getPromotions = () => {
        getAllPromos().onSnapshot((querySnapshot) => {
          let promotions = [];
  
          querySnapshot.forEach((doc) => {
            promotions.push({ ...doc.data(), id: doc.id, selected: false });
          });
  
  
          setPromos(promotions);
        });
  
        // data.docs.forEach((doc) => {
        //   promotions.push({ ...doc.data(), id: doc.id,  });
        // });
  
        //setPromos(promotions);
      };
      
          getPromotions();
          getBanners();
          setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)

    }
  }, []);

  useEffect(() => {
    if (!showStepModal) {
      setStepNumber(0);
    }
  }, [showStepModal]);

  const handleRightClick = () => {
    const arrow = document.getElementsByClassName(
      "react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
    );
    arrow[0]?.click();
  };

  const handleLeftClick = () => {
    const arrow = document.getElementsByClassName(
      "react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
    );
    arrow[0]?.click();
  };

  return (
    <Container className="px-4 pb-3">
      {/* <Stack
        direction="horizontal"
        gap={2}
        style={{ marginTop: "25px", paddingTop: "30px" }}
      >
        <Button
          style={{ backgroundColor: "#8A9BC4" }}
          size="sm"
          className="rounded-pill text-light border-0"
        >
          Novedades
        </Button>
        <Button
          style={{ backgroundColor: "#AFAFAF" }}
          size="sm"
          className="rounded-pill text-light border-0"
          onClick={() => navigation("/commerce/audiences/")}
        >
          Audiencias
        </Button>
      </Stack> */}
      <Stack direction="horizontal" className="my-4">
        <h6 className="fw-bold">Banner de novedades</h6>

        <Button className="ms-auto" onClick={() => setShowStepModal(true)}>
          Agregar anuncio
        </Button>
      </Stack>
      {loading ? (
          <div className="loading-container">
            {/* <Spinner
              className="spinner-style"
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            /> */}
            <CoinLoader size={{ width: 150, height: 150 }} />
          </div>
        ) : (
      <Carousel
        responsive={responsive}
        containerClass="carousel-holder"
        sliderClass={window.screen.width > 1400 ? "carousel-track" : ""}
        itemClass="carousel-content"
        renderButtonGroupOutside={true}
      >
        {banners.map((banner) => {
   
          const currentPromo = promos.filter(
            (promo) => promo.id === banner.promoRef 
          );
          return (
            <>
              <AdvertisementCard
                bannerData={banner}
                bannerNumber={banners.length}
                currentPromo={currentPromo[0]}
                size={{ width: "100%" }}
              />
            </>
          );
        })}
      </Carousel>) }

      <Stack direction="horizontal" gap={2}>
        <span
          onClick={handleLeftClick}
          className="carousel-button shadow-sm mt-3 d-inline-block ms-auto"
        >
          <IoIosArrowBack />
        </span>
        <span
          onClick={handleRightClick}
          className="carousel-button shadow-sm mt-3 d-inline-block"
        >
          <IoIosArrowForward />
        </span>
      </Stack>

      <CustomModal
        show={showStepModal}
        handleClose={setShowStepModal}
        modalTitle={() => titles[stepNumber]}
        currentSize="lg"
      >
        <StepsModal
          promos={promos}
          stepNumber={stepNumber}
          nextStep={setStepNumber}
          setShowStepModal={setShowStepModal}
        />
        {/* <SelectPromotion promos={promos} /> */}
      </CustomModal>
    </Container>
  );
};

export default Publicity;
