import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { MdArrowOutward, MdQrCode, MdQuestionMark } from "react-icons/md";

import { styles } from "../../styles/styles";
import PlanModal from "../general/PlanModal";

const plans = [
  { subTier: "Plan A", codeName: "Code A" },
  { subTier: "Plan B", codeName: "Code B" },
  { subTier: "Plan C", codeName: "Code C" },
  // Add more plans as needed
];

const B2bPlansOverview = ({ productsFromDB, loading, noDataAvailable }) => {
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPlan, setModalPlan] = useState(null);

  const renderCard = (plan) => (
    <Grid item xs={12} sm={6} md={4} lg={4} key={plan.id}>
      <Paper
        sx={{
          width: "225px",
          padding: "16px",
          backgroundColor: "#F6F6F6", // Change background color for selected card
          cursor: "pointer",
          borderRadius: "18px",
          position: "relative",
          borderWidth: "2px",
          border: "solid",
          borderColor: "white", // Change cursor on hover
        }}
      >
        <Typography variant="h6" gutterBottom>
          {plan.subTier}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Código {plan.code}
        </Typography>
        <IconButton
          variant="outlined"
          onClick={() => {
            setModalPlan(plan);
            setIsModalOpen(true);
          }}
          sx={{
            position: "absolute",
            right: -5,
            top: -5,
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <MdQuestionMark color="white" size={12} />
        </IconButton>
      </Paper>
    </Grid>
  );
  const renderGrid = () => {
    if (loading) {
      return (
        <Grid container spacing={2} sx={[styles.planSelectionLoading]}>
          <CircularProgress />
        </Grid>
      );
    }
    if (!noDataAvailable) {
      return (
        <Grid container spacing={2} sx={[styles.planSelectionLoading]}>
          <Typography>No Hay productos disponibles</Typography>
        </Grid>
      );
    }

    return (
      <Grid container spacing={2} sx={styles.planSelection}>
        {productsFromDB.map((plan) => renderCard(plan))}
      </Grid>
    );
  };

  return (
    <>
      {renderGrid()}
      <PlanModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        plan={modalPlan}
      />
    </>
  );
};

export default B2bPlansOverview;
