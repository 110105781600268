import Form from "react-bootstrap/Form";
import { Button, Stack, Spinner } from "react-bootstrap";
import { Image } from "react-bootstrap";

import { useFormik } from "formik";
import * as Yup from "yup";

import editStartbucks from "./../../assests/editStartbucks.jpg";

import FormInput from "../form-input/form-input";
import {
  addAdminHistoryEntry,
  updateExchange,
} from "../../firebase/firebase.utils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getChangedFields } from "../../utils/getChangeFields";
import { exchangeInitialValues } from "./exchangeInitialValues";
import { translations } from "./spanishFieldName";

const ExchangeEdit = ({
  business = { logo: editStartbucks },
  exchangeId,
  sponsorId,
  onClose,
  placeholderValues,
  buttonText,
}) => {
  const [loading, setLoading] = useState(false);
  const [initialData, setInitialData] = useState(exchangeInitialValues);

  const { user: currentAdmin } = useSelector((state) => state.auth);

  useEffect(() => {
    if (placeholderValues) {
      setInitialData({
        exchangable: placeholderValues.name,
        specification: placeholderValues.description,
        restrictionsAndDetails: placeholderValues.restrictions,
        value: placeholderValues.coinPrice,
        dueDate: placeholderValues.dueDate.toDate().toISOString().slice(0, 10),
      });
    }
  }, [placeholderValues]);

  const validate = Yup.object({
    exchangable: Yup.string().required("requerido"),
    specification: Yup.string().required("requerido"),
    restrictionsAndDetails: Yup.string().required("requerido"),
    value: Yup.number()
      .required("No es un numero")
      .typeError("No es un numero")
      .integer("No es un numero"),
    dueDate: Yup.string().required("requerido"),
  });

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: initialData,
    validationSchema: validate,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const [year, month, day] = values.dueDate.split("-").map(Number);
      const newDate = new Date(year, month - 1, day); // Months are 0-indexed in JavaScript

      const [initialDateYear, initialDateMonth, initialDateDay] =
        initialData.dueDate.split("-").map(Number);

      const initialDate = new Date(
        initialDateYear,
        initialDateMonth - 1,
        initialDateDay
      ); // Months are 0-indexed in JavaScript

      const updates = {
        name: values.exchangable,
        description: values.specification,
        restrictions: values.restrictionsAndDetails,
        dueDate: newDate,
        coinPrice: values.value,
      };

      //dueDate coming from values is a string, let's turn it to a Date
      const fieldsChanged = getChangedFields(
        { ...values, dueDate: newDate },
        { ...initialData, dueDate: initialDate },
        translations
      );

      const adminHistory = {
        modificationDate: new Date(),
        admin: {
          id: currentAdmin.uid,
          name: currentAdmin.displayName,
          email: currentAdmin.email,
        },
        modifications: fieldsChanged,
        modificationContext: {
          collectionName: "exchanges",
          docId: exchangeId,
        },
      };

      updateExchange(sponsorId, exchangeId, updates);
      addAdminHistoryEntry(adminHistory, `sponsors/${sponsorId}/adminHistory`);

      setTimeout(() => {
        setLoading(false);
        resetForm();
        onClose(false);
      }, 2000);
    },
  });

  const updateExchangeStatus = (newStatus) => {
    const adminHistory = {
      modificationDate: new Date(),
      admin: {
        id: currentAdmin.uid,
        name: currentAdmin.displayName,
        email: currentAdmin.email,
      },
      modifications: [
        { field: "status", newValue: newStatus, oldValue: "pending" },
      ],
      modificationContext: {
        collectionName: "exchanges",
        docId: exchangeId,
      },
    };

    updateExchange(sponsorId, exchangeId, {
      status: newStatus,
    });

    addAdminHistoryEntry(adminHistory, `sponsors/${sponsorId}/adminHistory`);
  };

  if (!exchangeId) return;

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      noValidate
      className="p-3"
    >
      <Stack direction="horizontal" className="mb-3">
        <Image
          src={business.logo}
          roundedCircle
          className="me-2"
          width="80"
          height="80"
          onError={
            "https://i.picsum.photos/id/1012/3973/2639.jpg?hmac=s2eybz51lnKy2ZHkE2wsgc6S81fVD1W2NKYOSh8bzDc"
          }
        />

        <div>
          <h3 className="mb-0 fw-bol">{business.name}</h3>
          <p className="mb-0">
            {business.category.map((categ) => `${categ} `)}
          </p>
        </div>
      </Stack>

      <FormInput
        label={"Articulo/Servicio/Producto canjeable:"}
        onChange={handleChange}
        value={values.exchangable}
        type="text"
        name="exchangable"
        // placeholder={placeholderValues.name}
        errorList={errors}
      />

      <FormInput
        label={"Especificaciones del producto"}
        onChange={handleChange}
        value={values.specification}
        type="text"
        name="specification"
        // placeholder={placeholderValues.description}
        errorList={errors}
      />

      <FormInput
        label={"Detalles y restricciones del canje"}
        as="textarea"
        onChange={handleChange}
        value={values.restrictionsAndDetails}
        type="text"
        name="restrictionsAndDetails"
        // placeholder={placeholderValues.restrictions}
        errorList={errors}
      />

      <Stack direction="horizontal" gap={3}>
        <div>
          <Form.Group>
            <FormInput
              label={"Valor en AllSport coins"}
              onChange={handleChange}
              value={values.value}
              name="value"
              type="number"
              // placeholder={placeholderValues.coinPrice}
              errorList={errors}
            />

            <Form.Control.Feedback type="invalid">
              {errors.value}
            </Form.Control.Feedback>
          </Form.Group>

          <FormInput
            label={"Válido hasta"}
            onChange={handleChange}
            value={values.dueDate}
            type="date"
            name="dueDate"
            // placeholder={placeholderValues.dueDate}
            errorList={errors}
          />
        </div>

        {/* <Image
          src={editStartbucks}
          rounded
          style={{ width: "220px" }}
          className="mt-3"
        ></Image> */}
      </Stack>

      <Stack direction="horizontal" className="mt-3">
        <Form.Check
          type={"checkbox"}
          id={"sponsorNotification"}
          label={"Notificar al sponsor"}
          style={{ fontSize: "14px" }}
        />

        <Button
          variant="light"
          size="sm"
          className="rounded-pill ms-auto px-3"
          onClick={() => {
            if (buttonText.cancel === "Rechazar") {
              updateExchangeStatus("rejected");
            }

            onClose(false);
          }}
        >
          {buttonText.cancel}
        </Button>
        <Button
          variant="primary"
          size="sm"
          className="rounded-pill text-light px-3"
          onClick={() => {
            if (buttonText.cancel === "Rechazar") {
              updateExchangeStatus("active");
              onClose(false);
            } else {
              handleSubmit();
            }
          }}
          disabled={loading}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {!loading && (
            <span style={{ color: "white !important" }}>
              {buttonText.continue}
            </span>
          )}
        </Button>
      </Stack>
    </Form>
  );
};

export default ExchangeEdit;
