import { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import MembersTable from "../roles-table/members-table";
import TeamsTable from "../roles-table/teams-table";
import CustomModal from "./../../components/modal/customModal";
import MemberAddition from "../../components/roles-addition/member-addition";
import TeamAddition from "../../components/roles-addition/team-addition";

import "./roles-table-tabs.scss";
import { Container, Button, Stack, FormLabel } from "react-bootstrap";

const RolesTableTabs = () => {
  const [showMemberAdditionModal, setShowMemberAdditionModal] = useState(false);
  const [showTeamAdditionModal, setShowTeamAdditionModal] = useState(false);
  const [tabMembersClass, setTabMembersClass] = useState("selected-tab");
  const [tabTeamsClass, setTabTeamsClass] = useState("inactive-tab");
  const [showMemberAdditionalButton, setShowMemberAdditionalButton] =
    useState(false);
  const [showTeamAdditionalButton, setShowTeamAdditionalButton] =
    useState(true);
  const [count, setCount] = useState(0);

  const handleRefresh = () => {
    setCount((current) => current + 1);
  };

  const modifyTab = (tabName) => {
    if (tabName === "members") {
      setTabMembersClass("selected-tab");
      setTabTeamsClass("inactive-tab");
      setShowMemberAdditionalButton(false);
      setShowTeamAdditionalButton(true);
    } else {
      setTabTeamsClass("selected-tab");
      setTabMembersClass("inactive-tab");
      setShowTeamAdditionalButton(false);
      setShowMemberAdditionalButton(true);
    }
  };

  return (
    <Container className="p-3 w-100 ps-4">
      <Stack direction="horizontal" gap={2} className="mb-4">
        <Button
          className="ms-auto"
          variant="primary ms-auto"
          onClick={() => {
            setShowMemberAdditionModal(true);
          }}
          hidden={showMemberAdditionalButton}
        >
          Agregar Colaborador
        </Button>

        <Button
          className="ms-auto"
          variant="primary ms-auto"
          onClick={() => {
            setShowTeamAdditionModal(true);
          }}
          hidden={showTeamAdditionalButton}
        >
          Agregar Equipo
        </Button>
      </Stack>

      <Tabs
        defaultActiveKey={"members"}
        onSelect={(tabName) => modifyTab(tabName)}
        className="table-tabs"
      >
        <Tab
          tabClassName={tabMembersClass}
          title="Colaboradores"
          eventKey={"members"}
          className="bg-light"
        >
          <MembersTable count={count} />
        </Tab>
        <Tab
          tabClassName={tabTeamsClass}
          title="Equipos"
          eventKey={"teams"}
          className="bg-light"
        >
          <TeamsTable count={count} />
        </Tab>
      </Tabs>
      <CustomModal
        show={showMemberAdditionModal}
        handleClose={setShowMemberAdditionModal}
        modalTitle={() => <HeaderButtonsOne type={"promo"} />}
      >
        <MemberAddition
          onClose={setShowMemberAdditionModal}
          handleRefresh={handleRefresh}
        />
      </CustomModal>

      <CustomModal
        show={showTeamAdditionModal}
        handleClose={setShowTeamAdditionModal}
        modalTitle={() => <HeaderButtonsTwo type={"promo"} />}
      >
        <TeamAddition
          onClose={setShowTeamAdditionModal}
          handleRefresh={handleRefresh}
        />
      </CustomModal>
    </Container>
  );
};

const HeaderButtonsOne = ({ type }) => (
  <Stack direction="horizontal" gap={2} className="">
    <FormLabel
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#9A9A9A", fontSize: "15px" }}
    >
      Registro
    </FormLabel>
    <FormLabel
      size="sm"
      className="text-light border-0 px-4 rounded-pill "
      style={{ background: "#AAAAAA", fontSize: "15px" }}
    >
      Colaborador
    </FormLabel>
  </Stack>
);

const HeaderButtonsTwo = ({ type }) => (
  <Stack direction="horizontal" gap={2} className="">
    <FormLabel
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#9A9A9A", fontSize: "15px" }}
    >
      Registro
    </FormLabel>
    <FormLabel
      size="sm"
      className="text-light border-0 px-4 rounded-pill "
      style={{ background: "#AAAAAA", fontSize: "15px" }}
    >
      Equipo
    </FormLabel>
  </Stack>
);

export default RolesTableTabs;
