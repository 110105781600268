import { Card } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { Stack, Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import darkPlus from "./../../assests/darkPlus.png";
import { ReactComponent as WhatsApp } from "./../../assests/whatsapp.svg";
import { ReactComponent as Instagram } from "./../../assests/instagram.svg";
import { ReactComponent as Facebook } from "./../../assests/facebook.svg";
import { ReactComponent as Tiktok } from "./../../assests/tiktok.svg";

import { EditText } from "react-edit-text";

import { businessInnitialValues } from "./businessInnitialValues";

import "./business-overview.scss";

import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";

import { useFormik } from "formik";
import * as Yup from "yup";

import {
  getAllExchanges,
  getAllPromosById,
  getBranches,
  getRedeemHistory,
  updateBranch,
  updateSponsor,
  uploadFile,
  addAdminHistoryEntry,
} from "../../firebase/firebase.utils";
import { getChangedFields } from "../../utils/getChangeFields";
import { fsTimestampCreador } from "../../firebase/firebase-config";
import BranchListModal from "./EditBranches";
import { useTheme } from "@emotion/react";

import CustomModal from "../modal/customModal";
import EditSponsorDataForm from "./editSponsorDataForm";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { translations } from "./spanishFieldName";

const MySwal = withReactContent(Swal);

const tierValues = ["Basic", "Premium", "Elite"];

const BusinessOverview = ({ id, business, openModal }) => {
  const [branches, setBranches] = useState([]);
  const [editable, setEditable] = useState(false);
  const [exchangesNumber, setExchangesNumber] = useState([]);
  const [promosNumber, setPromosNumber] = useState([]);
  const [imageUploadSponsor, setImageUploadSponsor] = useState(null);
  const [totalRedeemedCoins, setTotalRedeemedCoins] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [initialData, setInitialData] = useState(businessInnitialValues);
  const [showEditModal, setShowEditModal] = useState(false);

  const { user: currentAdmin } = useSelector((state) => state.auth);

  const [showWhatsApp, setShowWhatsApp] = useState(false);
  const targetWhatsApp = useRef(null);

  const [showInstagram, setShowInstagram] = useState(false);
  const targetInstagram = useRef(null);

  const [showFacebook, setShowFacebook] = useState(false);
  const targetFacebook = useRef(null);

  const [showTiktok, setShowTiktok] = useState(false);
  const targetTiktok = useRef(null);

  const theme = useTheme();

  useEffect(() => {
    const getSponsorBranches = async () => {
      const data = await getBranches(id);
      const exchanges = await getAllExchanges(id);
      const promos = await getAllPromosById(id);
      const redeems = await getRedeemHistory(id);

      const activeExchanges = exchanges.filter(
        (exchange) => exchange.status === "active"
      );

      const activePromos = promos.filter((promo) => promo.status === "active");

      const totalRedeemedCoins = redeems.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.exchange.coinPrice;
      }, 0);

      setTotalRedeemedCoins(totalRedeemedCoins);

      let numberExchanges = {
        active: 0,
        total: 0,
      };

      let numberPromos = {
        active: 0,
        total: 0,
      };

      numberExchanges.active = activeExchanges.length;
      numberExchanges.total = exchanges.length;

      numberPromos.active = activePromos.length;
      numberPromos.total = promos.length;

      setExchangesNumber(numberExchanges);
      setPromosNumber(numberPromos);

      setBranches(data);
    };

    getSponsorBranches();
  }, [id]);

  useEffect(() => {
    const uploadSponsorImage = async () => {
      const path = `sponsor/${id}/logo`;
      const fileUrl = await uploadFile(path, imageUploadSponsor);

      const updatesSponsor = { logo: fileUrl };

      updateSponsor(id, updatesSponsor);
    };

    if (imageUploadSponsor === null) {
      return;
    } else {
      uploadSponsorImage();
    }
  }, [id, imageUploadSponsor]);

  useEffect(() => {
    if (business) {
      setInitialData({
        typeId: "Cedula Juridica",
        idNumber: business.businessId ? business.businessId : "--",
        name: business.name ? business.name : "--",
        tier: business.tier ? business.tier : "",
        email: business.email ? business.email : "--",
        phone: business.phone ? business.phone : "--",
        country: business.country ? business.country : "--",
        province: branches[0]?.province ? branches[0].province : "--",
        canton: branches[0]?.canton ? branches[0].canton : "--",
        district: branches[0]?.district ? branches[0].district : "--",
        category: business.category ? business.category.join("/") : "--",
        products:
          typeof business.products === "object"
            ? business.products.join(" ")
            : "--",
        status: business.isActive,
      });
    }
  }, [business, branches]);

  const validate = Yup.object({
    country: Yup.string(),
    province: Yup.string(),
    canton: Yup.string(),
    district: Yup.string(),
    tier: Yup.string()
      .required("Campo requerido")
      .oneOf(tierValues, "Valores válidos: Basic, Premium, Elite"),
  });

  const openInNewTab = (url) => {
    const cleanUrl = url.replace("https://", "");
    const currentUrl = "https://".concat(cleanUrl);
    window.open(currentUrl, "_blank", "noopener,noreferrer");
  };

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    setFieldValue,
    handleBlur,
    touched,
    handleReset,
    submitCount,
  } = useFormik({
    initialValues: initialData,
    validationSchema: validate,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      const confirmResult = await MySwal.fire({
        title: "Confirmar edición",
        text: "¿Estás seguro de que deseas editar el sponsor?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, continuar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });

      if (confirmResult.isConfirmed) {
        //setLoading(true);
        const loadingSwal = MySwal.fire({
          title: "Guardando...",
          //html: '<div class="swal2-spinner"></div>',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            MySwal.showLoading();
          },
        });
        try {
          let inputDate = new Date(values.cdate);
          inputDate.setDate(inputDate.getDate() + 1);

          let formatDate = fsTimestampCreador.fromDate(inputDate);

          if (isNaN(formatDate.nanoseconds)) {
            formatDate = business.cdate;
          }

          const fieldsChanged = getChangedFields(
            values,
            initialData,
            translations
          );

          const updatesSponsor = {
            typeId: values.typeId,
            businessId: values.idNumber,
            name: values.name,
            tier: values.tier,
            email: values.email,
            phone: values.phone,
            country: values.country,
            category: values.category.split("/"),
            products: values.products,
            isActive: values.status,
          };

          const updatesBranches = {
            province: values.province,
            canton: values.canton,
            district: values.district,
          };

          const updatesAdminHistory = {
            modificationDate: new Date(),
            admin: {
              id: currentAdmin.uid,
              name: currentAdmin.displayName,
              email: currentAdmin.email,
            },
            modifications: fieldsChanged,
          };

          await updateSponsor(id, updatesSponsor);
          await updateBranch(id, updatesBranches, branches[0].id);
          await addAdminHistoryEntry(
            updatesAdminHistory,
            `sponsors/${id}/adminHistory`
          );

          setTimeout(() => {
            //setTrigger((prevValue) => !prevValue);
            setShowEditModal(false);
          }, 2000);

          loadingSwal.close();
          MySwal.fire("Guardado!", "", "success");
        } catch (error) {
          console.log(error);
          MySwal.fire("Error", "Hubo un error al editar", "error");
        }

        setShowEditModal(false);
        resetForm();
        setTimeout(() => {
          //setTrigger((prevValue) => !prevValue);
          setShowEditModal(false);
          loadingSwal.close();
        }, 1000);
      }
    },
  });

  if (!branches[0]) {
    return;
  }

  return (
    <Card body border="light" className="position-relative">
      <Form noValidate>
        <Row>
          <Col
            lg={12}
            xxl={6}
            className="d-flex align-items-center justify-content-center"
          >
            <Stack direction="horizontal" gap={3}>
              <div>
                <div className="position-relative sponsor-container">
                  <Image
                    src={business.logo}
                    roundedCircle
                    width="150px"
                    height="150px"
                  />
                  <label htmlFor="file-input">
                    <Image
                      src={darkPlus}
                      width="15"
                      height="15"
                      style={{ top: "85%" }}
                      className="position-absolute end-0 plus-sign"
                    />
                  </label>
                  <input
                    type="file"
                    name="sponsor"
                    id="file-input"
                    className="d-none"
                    onChange={(event) => {
                      setImageUploadSponsor(event.target.files[0]);
                    }}
                  />
                </div>

                <Stack direction="vertical" gap={2} className="mt-2">
                  <Button
                    size="sm"
                    sx={{ display: "block", mx: "auto" }}
                    onClick={() => setShowEditModal(true)}
                  >
                    Editar
                  </Button>

                  <Button
                    size="sm"
                    variant="secondary"
                    className="text-light"
                    onClick={() => setShowModal(true)}
                  >
                    Editar Sucursales
                  </Button>
                </Stack>
              </div>
              <Stack
                gap={2}
                direction="vertical"
                className="justify-content-center"
              >
                <small className="text-muted">
                  <Stack direction="horizontal">
                    <span>Usuario desde</span>
                    <EditText
                      type="date"
                      formatDisplayText={(val) => {
                        if (val === "--" || !val) return "--";
                        if (val.toDate) {
                          return val.toDate().toLocaleDateString("es-CR", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          });
                        } else {
                          let inputDate = new Date(val);
                          inputDate.setDate(inputDate.getDate() + 1);
                          return inputDate.toLocaleDateString("es-CR", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          });
                        }
                      }}
                      name="cdate"
                      className="d-flex ms-2"
                      readonly={!editable}
                      style={{
                        outline: 0,
                        width: "210px",
                        height: "30px",
                        alignItems: "center",
                      }}
                      inputClassName="bg-info border-0 shadow-none form-control is-invalid"
                      onChange={handleChange}
                      onSave={handleSubmit}
                      value={values.cdate}
                      defaultValue={business.cdate ? business.cdate : "--"}
                    />
                  </Stack>
                </small>

                <Stack direction="horizontal" gap={3}>
                  <h2 className="h5 mb-0">
                    <Form.Group>
                      <EditText
                        name="name"
                        readonly={!editable}
                        style={{
                          outline: 0,
                          width: "200px",
                          height: "30px",
                          alignItems: "center",
                        }}
                        defaultValue={business?.name ?? "----"}
                        className="d-flex"
                        inputClassName="bg-info border-0 shadow-none form-control is-invalid"
                        onChange={handleChange}
                        onSave={({ value, previousValue }) => {
                          if (value === "") {
                            setFieldValue("name", previousValue);
                          } else {
                            handleSubmit();
                          }
                        }}
                        value={values.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </h2>
                </Stack>

                <div className="mb-0">
                  <EditText
                    name="category"
                    className="mb-0"
                    readonly={!editable}
                    onChange={handleChange}
                    onSave={({ value, previousValue }) => {
                      if (value === "") {
                        setFieldValue("category", previousValue);
                      } else {
                        handleSubmit();
                      }
                    }}
                    style={{
                      outline: 0,
                      width: "210px",
                      height: "30px",
                      alignItems: "center",
                    }}
                    inputClassName="bg-info border-0 w-50 shadow-none is-valid"
                    value={values.category}
                  />
                  <Form.Control.Feedback isValid className="text-secondary">
                    Escriba una categoria. Para más de una categoria use "/"
                  </Form.Control.Feedback>
                </div>
              </Stack>
            </Stack>
          </Col>
          <Col className="bl-over-1400 border-end mt-4">
            <Row>
              <Col className="d-flex flex-column align-items-center">
                <small className="text-muted">Coins canjeados</small>
                <h2 className="h4 fw-bold">
                  {totalRedeemedCoins ? totalRedeemedCoins : "--"}
                </h2>
                <small className="text-muted">Tasa de canje</small>
                <div
                  className="circular-container-sponsor circular-container mt-3"
                  style={{ width: "180px" }}
                >
                  <CircularProgressbar
                    value={90}
                    text={`${90}%`}
                    strokeWidth={12}
                    styles={buildStyles({
                      pathColor: theme.palette.accent.main,

                      trailColor: "#F0F4FF",
                      textColor: "#000",
                    })}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col className="mt-4 ms-1">
            <Row>
              <Col className="d-flex flex-column align-items-center">
                <div>
                  <small className="text-muted">Canjes activos</small>
                  <p className="fw-bold fs-4">
                    <span className="text-primary">
                      {exchangesNumber.active}
                    </span>
                    /{exchangesNumber.total}
                  </p>
                  <small className="text-muted">Promociones</small>
                  <p className="fw-bold fs-4">
                    <span className="text-primary">{promosNumber.active}</span>/
                    {promosNumber.total}
                  </p>
                  <small className="text-muted">Nivel</small>
                  <p className="fw-bold fs-4">Insider</p>
                  <small className="text-muted">Redes sociales</small>
                  <Stack direction="horizontal" gap={2}>
                    {business.whatsApp ? (
                      <span
                        href={business.whatsApp}
                        target="_blank"
                        rel="noreferrer"
                        className="cursor"
                        onClick={() => openInNewTab(business.whatsApp)}
                      >
                        <WhatsApp />
                      </span>
                    ) : (
                      <>
                        <span
                          className="cursor"
                          ref={targetWhatsApp}
                          onClick={() => setShowWhatsApp(!showWhatsApp)}
                        >
                          <WhatsApp />
                        </span>
                        <Overlay
                          target={targetWhatsApp.current}
                          show={showWhatsApp}
                          placement="top"
                        >
                          {(props) => (
                            <Tooltip id="overlay-example" {...props}>
                              No existe
                            </Tooltip>
                          )}
                        </Overlay>
                      </>
                    )}

                    {business.instagram ? (
                      <span
                        href={business.instagram}
                        target="_blank"
                        rel="noreferrer"
                        className="cursor"
                        onClick={() => openInNewTab(business.instagram)}
                      >
                        <Instagram />
                      </span>
                    ) : (
                      <>
                        <span
                          className="cursor"
                          ref={targetInstagram}
                          onClick={() => setShowInstagram(!showInstagram)}
                        >
                          <Instagram />
                        </span>
                        <Overlay
                          target={targetInstagram.current}
                          show={showInstagram}
                          placement="top"
                        >
                          {(props) => (
                            <Tooltip id="overlay-example" {...props}>
                              No existe
                            </Tooltip>
                          )}
                        </Overlay>
                      </>
                    )}

                    {business.facebook ? (
                      <span
                        href={business.facebook}
                        className="cursor"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => openInNewTab(business.facebook)}
                      >
                        <Facebook />
                      </span>
                    ) : (
                      <>
                        <span
                          className="cursor"
                          ref={targetFacebook}
                          onClick={() => setShowFacebook(!showFacebook)}
                        >
                          <Facebook />
                        </span>
                        <Overlay
                          target={targetFacebook.current}
                          show={showFacebook}
                          placement="top"
                        >
                          {(props) => (
                            <Tooltip id="overlay-example" {...props}>
                              No existe
                            </Tooltip>
                          )}
                        </Overlay>
                      </>
                    )}

                    {business.tiktok ? (
                      <span
                        href={business.tiktok}
                        target="_blank"
                        rel="noreferrer"
                        className="cursor"
                        onClick={() => openInNewTab(business.tiktok)}
                      >
                        <Tiktok />
                      </span>
                    ) : (
                      <>
                        <span
                          className="cursor"
                          ref={targetTiktok}
                          onClick={() => setShowTiktok(!showTiktok)}
                        >
                          <Tiktok />
                        </span>
                        <Overlay
                          target={targetTiktok.current}
                          show={showTiktok}
                          placement="top"
                        >
                          {(props) => (
                            <Tooltip id="overlay-example" {...props}>
                              No existe
                            </Tooltip>
                          )}
                        </Overlay>
                      </>
                    )}
                  </Stack>
                  <Button
                    size="sm"
                    variant="secondary"
                    className="mt-2 text-light"
                    onClick={() => openModal(true)}
                  >
                    Editar redes
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      {showModal && (
        <BranchListModal
          setBranches={setBranches}
          branches={branches}
          sponsorId={id}
          open={showModal}
          onClose={() => setShowModal(false)}
        />
      )}

      <CustomModal
        show={showEditModal}
        handleClose={setShowEditModal}
        modalTitle={() => "Editar"}
      >
        <EditSponsorDataForm
          onClose={() => {
            handleReset();
            setShowEditModal(false);
          }}
          formikData={{
            values,
            handleChange,
            errors,
            handleSubmit,
            handleBlur,
            touched,
            submitCount,
          }}
          handleRefresh={() => {}}
        />
      </CustomModal>
    </Card>
  );
};

export default BusinessOverview;
