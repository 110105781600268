import { Box, Container, Tab, Tabs } from "@mui/material";
import { Button, Stack } from "react-bootstrap";
import CustomModal from "../../components/modal/customModal";
import { useDispatch, useSelector } from "react-redux";
import { B2bTable, B2bUsersTable } from "../../components/b2b/B2bTable";
import B2bCard from "../../components/b2b/B2bCard";
import { useCallback, useEffect, useRef, useState } from "react";
import CreateB2b from "../../components/b2b/CreateB2b";
import { Typography } from "@mui/material";
import { styles } from "../../styles/styles";
import {
  getAllAssociationCodes,
  getAllAssociations,
} from "../../redux/b2b/actions/actions";
import { B2bRequestExclusionsTable } from "../../components/b2b/B2bRequestExclusionsTable";
import { Grid } from "@mui/material";
import { B2bRequestInclusionsTable } from "../../components/b2b/B2bRequestInclusionsTable";
import NotificationDropdown from "../../components/b2b/notifications/NotificationDropdown";
import { ASbutton } from "../../components/buttons/ASbutton";
import { useTheme } from "@emotion/react";

const B2bUsers = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const { associations, codes } = useSelector((state) => state.b2b);
  const isFirstUse = useRef(true);
  const [loading, setloading] = useState(true);
  const [value, setValue] = useState(0);

  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isDataAvailable, setDataAvailable] = useState(true);
  const [codesFromFB, setCodesFromFB] = useState({
    all: [],
    pending: [],
    exclusions: [],
    active: [],
  });
  const getUsersFromFB = useCallback(() => {
    try {
      dispatch(getAllAssociations());
    } catch (error) {
      console.log(error);
    }
  }, [associations]);

  const getCodesFromFB = useCallback(async () => {
    try {
      dispatch(getAllAssociationCodes());

      const pendingCodes = codes.filter((u) => u.status === "pending request");
      const exclusionCodes = codes.filter(
        (u) => u.status === "exclusion request"
      );
      const activeCodes = codes.filter((u) => u.status === "active");
      // const deactivated = codes.filter((u) => u.status === "deactivated");
      setCodesFromFB({
        all: codes,
        active: activeCodes,
        pending: pendingCodes,
        // deactivated: deactivated,
        exclusions: exclusionCodes,
      });
    } catch (error) {
      console.log(error);
    }
  }, [codes]);
  useEffect(() => {
    if (isFirstUse.current) {
      setloading(true);
      getCodesFromFB();
      isFirstUse.current = false;
    } else if (codes.length > 0) {
      const pendingCodes = codes.filter((u) => u.status === "pending request");
      const exclusionCodes = codes.filter(
        (u) => u.status === "exclusion request"
      );
      const activeCodes = codes.filter((u) => u.status === "active");
      // const deactivated = codes.filter((u) => u.status === "deactivated");
      setCodesFromFB({
        all: codes,
        active: activeCodes,
        pending: pendingCodes,
        // deactivated: deactivated,
        exclusions: exclusionCodes,
      });

      setloading(false); // Set loading to false if users are available
    } else {
      const timeout = setTimeout(() => {
        setDataAvailable(false);
        setloading(false); // Set loading to false if no data is available
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [codes]);

  const handleRefresh = () => {
    setDataAvailable(false);

    setloading(true);
    getCodesFromFB();
  };
  const TabPanel = ({ children, value, index }) => {
    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
      >
        {value === index && (
          <Grid container item>
            {children}
          </Grid>
        )}
      </Box>
    );
  };

  return (
    <Container maxWidth={"xl"}>
      <Stack direction="vertical" gap={2} className="mb-4 flex-sm-row">
        <Stack direction="horizontal">
          <Typography variant="h5" sx={styles.title}>
            Asociaciones
          </Typography>
          {/* <button onClick={handleCopy}>Copy JSON</button> */}

          <NotificationDropdown />
        </Stack>
        <Box className="ms-sm-auto rounded-pill">
          <ASbutton
            buttonText="Crear Asociación"
            onClick={() => setOpenModal(true)}
            style={{ color: "white !important" }}
          />
        </Box>
      </Stack>

      <B2bCard
        all={codesFromFB.all}
        exclusions={codesFromFB.exclusions}
        pending={codesFromFB.pending}
        active={codesFromFB.active}
      />

      <B2bTable associations={associations} />

      <Tabs
        value={value}
        onChange={handleChange}
        centered
        sx={{
          mt: 6,
          display: {
            xs: "block",
            [theme.breakpoints.up(752)]: {
              display: "none",
            },
          },
          mb: -2,
          zIndex: 110,
          position: "relative",
          background: "#fff",
          border: "3px solid #ECECEE",
          borderBottom: 0,
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
        }}
      >
        <Tab label="Exclusiones" />
        <Tab label="Inclusiones" />
      </Tabs>

      <TabPanel value={value} index={0}>
        <B2bRequestExclusionsTable
          pendingUsers={codesFromFB.exclusions}
          handleRefresh={handleRefresh}
          isDataAvailable={isDataAvailable}
          loading={loading}
          value={value}
          handleChange={handleChange}
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <B2bRequestInclusionsTable
          pendingUsers={codesFromFB.pending}
          handleRefresh={handleRefresh}
          isDataAvailable={isDataAvailable}
          loading={loading}
          value={value}
          handleChange={handleChange}
        />
      </TabPanel>
      <CustomModal
        show={openModal}
        handleClose={setOpenModal}
        modalTitle={() => <HeaderButtons type={"promo"} />}
      >
        <CreateB2b handleRefresh={getUsersFromFB} onClose={setOpenModal} />
      </CustomModal>
    </Container>
  );
};

export default B2bUsers;

const HeaderButtons = ({ type }) => (
  <Stack direction="horizontal" gap={2} className="">
    <Button
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#9A9A9A" }}
    >
      Agregar
    </Button>
    <Button
      size="sm"
      className="text-light border-0 px-4 rounded-pill"
      style={{ background: "#AAAAAA" }}
    >
      B2b
    </Button>
  </Stack>
);
