import Form from "react-bootstrap/Form";
import { Button, Stack, Spinner } from "react-bootstrap";

import { useFormik } from "formik";
import * as Yup from "yup";

import FormInput from "../form-input/form-input";
import { deleteMember } from "../../firebase/firebase.utils";
import { useState } from "react";

import { useSelector } from "react-redux";

const MemberDeletion = ({ onClose, memberId, handleRefresh }) => {
  const [loading, setLoading] = useState(false);

  const { userGetToken } = useSelector((state) => state.auth);

  const reloadToken = async () => {
    let freshToken = await userGetToken.getIdToken(true);
    return freshToken;
  };

  const reasons = [
    { name: "Usuario sin uso" },
    { name: "Salida del equipo" },
    { name: "Usuario duplicado" },
    { name: "Reemplazo de usuario" },
  ];

  // const validate = Yup.object({
  //   password: Yup.string().required("Contraseña vacia"),
  //   reasons: Yup.string(),
  // });

  const { handleChange, handleSubmit, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        password: "",
      },
      // validationSchema: validate,
      onSubmit: async (values, { resetForm }) => {
        setLoading(true);
        const token = await reloadToken();

        const url =
          "https://us-central1-allsports-69231.cloudfunctions.net/admins/rootAdmin";
        const options = {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            //id: memberId,
            token: token,
          }),
        };

        try {
          const response = await fetch(url, options);
          const res = await response.json();

          setTimeout(() => {
            setLoading(false);
            // handleRefresh();
            // resetForm();
            // onClose(false);
          }, 2000);

          return res.ok;
        } catch (error) {
          console.log(error);
          throw new Error(error);
        }
      },
    });

  return (
    <Form
      className="p-3"
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Form.Group>
        <Form.Label className="mb-3">Motivo de la eliminación</Form.Label>
        <Form.Select
          size="md"
          onChange={handleChange}
          name="reasons"
          value={values.award}
          className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
        >
          {reasons.map((reason) => {
            return (
              <option value={reason.name} name="reasons">
                {reason.name}
              </option>
            );
          })}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors.reasons}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Check
        type={"checkbox"}
        id={`userNotification`}
        label={`Notificar al usuario`}
        className="my-3"
      />

      <p>
        La eliminación es permanente. Para proceder debes escribir tu contraseña
      </p>

      <Form.Control
        type="password"
        name="password"
        onChange={handleChange}
        value={values.password}
        isInvalid={errors.password && touched.password}
        placeholder="Contraseña"
        className="rounded-1"
        onBlur={handleBlur}
      />

      <Form.Control.Feedback type="invalid">
        {errors.password}
      </Form.Control.Feedback>

      <Stack direction="horizontal" className="mt-3">
        <Button
          variant="light"
          className="rounded-pill ms-auto"
          onClick={() => onClose(false)}
        >
          Cancelar
        </Button>
        <Button
          variant="primary"
          className="rounded-pill"
          type="submit"
          disabled={loading}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {!loading && (
            <span style={{ color: "white !important" }}>Sí, eliminar...</span>
          )}
        </Button>
      </Stack>
    </Form>
  );
};

export default MemberDeletion;
