import { Card } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { Stack } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import GoogleMapReact from "google-map-react";
import "react-circular-progressbar/dist/styles.css";

import gear from "./../../assests/gear.png";
import darkPlus from "./../../assests/darkPlus.png";

import { EditText } from "react-edit-text";

import { useEffect, useState, useRef } from "react";

import { Formik } from "formik";
import * as Yup from "yup";

import { updateSponsor, uploadFile } from "../../firebase/firebase.utils";
import { fsTimestampCreador } from "../../firebase/firebase-config";
import {
  Avatar,
  Box,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import B2bPlansOverview from "./B2bPlansOverview";
import { updateB2b } from "../../redux/b2b/actions/actions";
import { MdAdd, MdEdit, MdSettings } from "react-icons/md";
import EditB2b from "./EditB2b";

const B2bOverview = ({
  id,
  business,
  openModal,
  productsFromDB,
  noDataAvailable,
  loading,
}) => {
  const defaultProps = {
    center: {
      lat: business.location?.latitude ?? 10.99835602,
      lng: business.location?.longitude ?? 77.01502627,
    },
    zoom: 11,
  };
  const [editable, setEditable] = useState(false);
  const [imageUpload, setImageUpload] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [active, setActive] = useState(() =>
    business.isActive ? true : false
  );
  const [inactive, setInactive] = useState(() =>
    !business.isActive ? true : false
  );
  const handleOpenEditModal = () => {
    setIsEditModalOpen(true);
  };
  const handleCloseEditModal = () => {
    setSelectedData([]);
    setIsEditModalOpen(false);
  };
  useEffect(() => {
    const uploadB2bImage = async () => {
      const imageName = imageUpload.name;
      const path = `associations/${id}/${imageName}`;
      const fileUrl = await uploadFile(path, imageUpload);

      const newImg = { imageURL: fileUrl };
      await updateB2b(id, newImg);
    };

    if (imageUpload === null) {
      return;
    } else {
      uploadB2bImage();
    }
  }, [id, imageUpload]);

  const validate = Yup.object({
    //typeId: Yup.string().required("Tipo de ID es requerido"),
    //idNumber: Yup.string().required("No es un ID valido"),
    //businessName: Yup.string().required("Nombre del sponsor requerido"),
    //email: Yup.string().email("Email formato invalido"),
    //phone: Yup.number().typeError("Telefono invalido"),
  });
  const handleRefresh = () => {};
  const openInNewTab = (url) => {
    const cleanUrl = url.replace("https://", "");
    const currentUrl = "https://".concat(cleanUrl);
    window.open(currentUrl, "_blank", "noopener,noreferrer");
  };

  const handleActiveYes = () => {
    //setBusinessStatus(true);
    setActive(true);
    setInactive(false);
  };

  const handleActiveNo = () => {
    //setBusinessStatus(false);
    setActive(false);
    setInactive(true);
  };

  return (
    <div className="position-relative">
      <Card body border="light" className="position-relative">
        <Formik
          validationSchema={validate}
          initialValues={{}}
          onSubmit={(values) => {
            let inputDate = new Date(values.cdate);
            inputDate.setDate(inputDate.getDate() + 1);

            let formatDate = fsTimestampCreador.fromDate(inputDate);

            if (isNaN(formatDate.nanoseconds)) {
              formatDate = business.cdate;
            }
          }}
        >
          {({ handleChange, handleSubmit, values, errors, setFieldValue }) => (
            <Grid container columnGap={0} justifyContent={"space-between"}>
              <Grid item xl={4} md={6} sm={12} xs={12} mt={2}>
                <Form noValidate>
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",

                      width: "85px",
                    }}
                  >
                    <Image
                      src={business.imageURL}
                      alt="Sponsor"
                      style={{ borderRadius: "50%", position: "relative" }}
                      width={70}
                      height={70}
                    />
                    <label
                      htmlFor="file-input"
                      style={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                      }}
                    >
                      <MdAdd size={24} />
                    </label>

                    <input
                      type="file"
                      name="sponsor"
                      id="file-input"
                      className="d-none"
                      onChange={(event) => {
                        setImageUpload(event.target.files[0]);
                      }}
                    />

                    <Box
                      sx={{
                        ml: 4,
                        display: "flex",
                        position: "relative",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <Box
                        sx={{
                          width: "40%",
                          display: "flex",
                          flexDirection: "row",
                          position: "relative",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography sx={{ fontSize: "1rem !important" }}>
                          {business.name}
                        </Typography>

                        <IconButton
                          sx={{ right: 0, top: -10 }}
                          onClick={() => handleOpenEditModal()}
                        >
                          <MdSettings />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                  <Grid
                    item
                    md={12}
                    lg={10}
                    sm={12}
                    xs={12}
                    xl={10}
                    style={{
                      height: "240px",
                      width: "100%",
                      borderRadius: "18px",
                      overflow: "hidden",
                      margin: "20px auto 30px 0 ",
                    }}
                  >
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
                      }}
                      defaultCenter={defaultProps.center}
                      defaultZoom={defaultProps.zoom}
                      yesIWantToUseGoogleMapApiInternals
                      onGoogleApiLoaded={({ map, maps }) => {
                        // Place a marker on the map
                        const marker = new maps.Marker({
                          position: {
                            lat: defaultProps.center.lat,
                            lng: defaultProps.center.lng,
                          },
                          map,
                          title: "My Location",
                        });
                      }}
                    ></GoogleMapReact>
                  </Grid>
                </Form>
              </Grid>

              <Grid item xl={8} md={6} sm={12} xs={12}>
                <B2bPlansOverview
                  associationId={id}
                  productsFromDB={productsFromDB}
                  noDataAvailable={noDataAvailable}
                  loading={loading}
                />
              </Grid>
            </Grid>
          )}
        </Formik>
      </Card>
      <EditB2b
        open={isEditModalOpen}
        onClose={handleCloseEditModal}
        data={business}
        id={id}
        handleRefresh={handleRefresh}
      />
    </div>
  );
};

export default B2bOverview;
