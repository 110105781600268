import { useState } from "react";

import Form from "react-bootstrap/Form";
import { Button, Stack, Col, Row, Spinner } from "react-bootstrap";

import { useFormik } from "formik";
import * as Yup from "yup";

import FormInput from "../form-input/form-input";
import fallback from "./../../assests/fallback.jpg";

import "./achievement-addition.scss";
import {
  createAchievement,
  createAchievementImage,
} from "../../firebase/firebase.utils";
import { fsTimestampCreador } from "../../firebase/firebase-config";
import UploadLogo from "../upload-logo/upload-logo";

const AchievementAddition = ({ onClose, handleRefresh }) => {
  const [imageUpload, setImageUpload] = useState(fallback);
  const [disabled, setDisabled] = useState(true);
  const [hasRewardDef, setHasRewardDef] = useState(false);

  const [image, setImage] = useState("");
  const [coins, setCoins] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSelectYes = () => {
    setDisabled(false);
    setCoins(null);
    setHasRewardDef(true);
  };

  const handleSelectNo = () => {
    setDisabled(true);
    setCoins(0);
    setHasRewardDef(false);
  };

  const categories = [
    { name: "1" },
    { name: "2" },
    { name: "3" },
    { name: "4" },
    { name: "5" },
  ];

  const awards = [
    { name: "1" },
    { name: "2" },
    { name: "3" },
    { name: "4" },
    { name: "5" },
    { name: "6" },
    { name: "7" },
    { name: "8" },
    { name: "9" },
    { name: "10" },
  ];

  const modules = [{ name: "1" }, { name: "2" }, { name: "3" }];

  const validate = Yup.object({
    achievementName: Yup.string().required("Requerido"),
    description: Yup.string()
      .required("Requerido")
      .max(225, "No puede tener mas de 225 caracteres"),
    categories: Yup.string(),
    awards: Yup.string(),
    modules: Yup.string(),
    reward: Yup.number()
      .typeError("Solo puede ingresar valores numericos")
      .max(10000, "No debe superar los 10000 coins"),
  });

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      achievementName: "",
      title: awards[0].name,
      tag: categories[0].name,
      description: "",
      hasReward: hasRewardDef,
      reward: 0,
      imageURL: "",
      module: modules[0].name,
    },
    validationSchema: validate,

    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      let creationDate = fsTimestampCreador.fromDate(new Date(Date.now()));
      const achievementData = {
        cdate: creationDate,
        name: values.achievementName,
        title: values.title,
        tag: [values.tag],
        description: values.description,
        hasReward: hasRewardDef,
        reward: values.reward,
        imageUrl: "",
        module: values.module,
        isActive: true,
      };
      const id = await createAchievement(achievementData);
      createAchievementImage(imageUpload, id);

      setTimeout(() => {
        setLoading(false);
        handleRefresh();
        resetForm();
        onClose(false);
      }, 2000);
    },
  });
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      noValidate
      className="p-3"
    >
      <Form.Group>
        <FormInput
          label={"Nombre del achievement"}
          onChange={handleChange}
          value={values.achievementName}
          type="text"
          name="achievementName"
          errorList={errors}
        />

        <Form.Control.Feedback type="invalid">
          {errors.achievementName}
        </Form.Control.Feedback>
      </Form.Group>

      <Row>
        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: "14px" }} className="mb-3">
              Award
            </Form.Label>
            <Form.Select
              size="sm"
              onChange={handleChange}
              name="title"
              value={values.title}
              required
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
            >
              {awards.map((award) => {
                return (
                  <option value={award.name} name="title">
                    {award.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: "14px" }} className="mb-3">
              Categoria
            </Form.Label>
            <Form.Select
              size="sm"
              onChange={handleChange}
              name="tag"
              value={values.tag}
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
            >
              {categories.map((category) => {
                return (
                  <option value={category.name} name="tag">
                    {category.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Form.Group>
        <Form.Label style={{ fontSize: "14px" }} className="mb-3">
          Descripción
        </Form.Label>
        <FormInput
          as="textarea"
          className="description"
          onChange={handleChange}
          name="description"
          value={values.description}
          errorList={errors}
        />
        <Form.Control.Feedback type="invalid">
          {errors.description}
        </Form.Control.Feedback>
      </Form.Group>

      <Row>
        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: "14px" }} className="mt-3">
              Recompensa
            </Form.Label>

            <Form.Check
              id="0"
              type="radio"
              name="hasReward"
              label="Si"
              size="sm"
              onChange={handleSelectYes}
              value="true"
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
            />
            <Form.Check
              id="1"
              type="radio"
              name="hasReward"
              label="No"
              size="sm"
              onChange={handleSelectNo}
              value="false"
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
            />
            <Form.Control.Feedback type="invalid">
              {errors.hasReward}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: "14px" }} className="mt-3">
              Coins
            </Form.Label>
            <FormInput
              disabled={disabled}
              onChange={handleChange}
              value={coins}
              type="number"
              name="reward"
              errorList={errors}
            />
            <Form.Control.Feedback type="invalid">
              {errors.reward}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <UploadLogo
            title={"Logotipo"}
            imageUpload={imageUpload}
            setImageUpload={setImageUpload}
            setImage={setImage}
            image={image}
          />
        </Col>
        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: "14px" }} className="mb-3">
              Módulo
            </Form.Label>
            <Form.Select
              size="sm"
              onChange={handleChange}
              name="module"
              value={values.module}
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
            >
              {modules.map((modul) => {
                return (
                  <option value={modul.name} name="module">
                    {modul.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Stack direction="horizontal" className="mt-3">
        <Button
          variant="light"
          size="sm"
          className="rounded-pill ms-auto px-3"
          onClick={() => onClose(false)}
        >
          Cancelar
        </Button>
        <Button
          variant="primary"
          size="sm"
          className="rounded-pill text-light px-3"
          type="submit"
          disabled={loading}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {!loading && <span style={{ color: "white !important" }}>Crear</span>}
        </Button>
      </Stack>
    </Form>
  );
};

export default AchievementAddition;
