import Form from "react-bootstrap/Form";

const FormInput = ({ label, errorList, ...otherProps }) => {
  let inputError = "";
  let { name, touched } = otherProps;

  if (errorList[name]) {
    inputError = errorList[name];
  }

  /*if (errorList.email) {
    inputError = errorList.email;
  } else if (errorList.password) {
    inputError = errorList.password;
  }*/

  return (
    <>
      <Form.Label style={{ fontSize: "14px" }}>{label}</Form.Label>
      <Form.Control
        {...otherProps}
        autoComplete="off"
        isInvalid={touched ? !!inputError && touched[name] : !!inputError}
        className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
      />
      {/*inputError.toLocaleLowerCase().includes("email") ? (
        <Form.Control.Feedback type="invalid">
          {errorList.email}
        </Form.Control.Feedback>
      ) : (
        <Form.Control.Feedback type="invalid">
          {errorList.password}
        </Form.Control.Feedback>
      )*/}
    </>
  );
};

export default FormInput;
