import jwtDecode from "jwt-decode";

import React, { useState } from "react";
import { Box, Container, Tab, Tabs } from "@mui/material";
import { styles } from "../../styles/styles";

import dayjs from "dayjs";
import B2bPlans from "./inclusions/B2bPlans";
import { B2BForm } from "./inclusions/B2BForm";
import { B2bSummary } from "./inclusions/B2bSummary";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSelector } from "react-redux";
import { setSale, uploadFiles } from "../../redux/b2b/actions/actions";
import { refreshToken } from "../../redux/user/actions/auth";
import B2bDocumentation from "./inclusions/B2bDocumentation";
import { InclusionModal } from "./inclusions/InclusionModal";

const MySwal = withReactContent(Swal);

export const AddB2bForm = ({
  associationId,
  productsFromDB,
  loading,
  noDataAvailable,
  isProductDataAvailable,
}) => {
  const { idToken } = useSelector((state) => state.auth);

  const [activeStep, setActiveStep] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [imageUpload, setImageUpload] = useState("");
  const [image, setImage] = useState("");
  const [fileUrl, setFileUrl] = useState(null);
  const [files, setFiles] = useState([]);
  const [apportionmentPrice, setApportionmentPrice] = useState(0);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [code, setCode] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userId, setUserId] = useState();
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const [data, setData] = useState({
    name: "",
    lastname: "",
    lastname2: "",
    phone: "",
    email: "",
    birthday: dayjs("2005-01-01"),
    civilState: "",
    gender: "",
    nationality: "",
    personalId: "",
    address: "",
    province: "",
    canton: "",
    district: "",
    zipCode: "",
    billNumber: "",
    price: 0,
    planDuration: "",
    apportionmentPrice: 0,
  });

  const handleRegister = async () => {
    const userData = {
      name: data.name,
      lastname: data.lastname,
      secondLastname: data.secondLastname ?? "",
      email: data.email ?? "",
      birthday: data.birthday ?? "",
      civilState: data.civilState ?? "",
      gender: data.gender ?? "",
      idType: data.nationality ?? "",
      address: data.address ?? "",
      province: data.province ?? "",
      canton: data.canton ?? "",
      district: data.district ?? "",
      zipCode: data.zipCode ?? "",
      device: data.device ?? "",
      personalId: data.personalId ?? "",
      phone: data.phone ?? "",
    };

    const finalData = {
      user: userData,
      plan: selectedPlan,

      planDuration: data.planDuration,
      finalPrice: apportionmentPrice,
      billNumber: data.billNumber,
      associationId: associationId,
    };

    console.log("FINAL DATA", finalData);

    // const productData = {
    //   planSelected: selectedPlan ?? "",
    //   price: data.price ?? 0,
    //   billNumber: data.billNumber ?? "",
    //   apportionmentPrice: apportionmentPrice ?? 0,
    // };
    const confirmResult = await MySwal.fire({
      title: "Confirmar ",
      text: "¿Estás seguro de que quieres agregar este usuario?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, agregar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    });
    if (confirmResult.isConfirmed) {
      const loadingSwal = MySwal.fire({
        title: "Guardando...",
        html: '<div class="swal2-spinner"></div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        // didOpen: () => {
        //   MySwal.showLoading();
        // },
      });
      try {
        if (isValidToken(idToken)) {
          const res = await setSale(finalData, idToken);
          if (res.ok) {
            setQrCodeUrl(res.codeInfo.qrUrl);
            setCode(res.codeInfo.code);

            const id = res.userId;

            if (files) {
              await uploadFiles(id, associationId, files);
            }
            setUserId(id);

            MySwal.fire({
              title: `Usuario ${userData.name} creado con el correo ${userData.email}`,
              icon: "success",
              showCancelButton: true,
              confirmButtonText: "OK",
              cancelButtonText: "Cancelar",
            }).then((result) => {
              if (result.isConfirmed) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              }
            });
          }
        } else {
          const newToken = refreshToken();
          const res = await setSale(finalData, newToken);
          if (res.ok) {
            setQrCodeUrl(res.codeInfo.qrUrl);
            setCode(res.codeInfo.code);
            const id = res.userId;
            setUserId(id);

            if (files) {
              await uploadFiles(id, associationId, files);
            }

            MySwal.fire({
              title: `Usuario ${userData.name} creado con el correo ${userData.email}`,
              icon: "success",
              showCancelButton: true,
              confirmButtonText: "OK",
              cancelButtonText: "Cancelar",
            }).then((result) => {
              if (result.isConfirmed) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              }
            });
          }
        }

        loadingSwal.close();
      } catch (error) {
        console.log(error);
        loadingSwal.close();

        MySwal.fire("Error", "Hubo un error al crear el usuario", "error");
      }
      loadingSwal.close();
    }
  };

  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };
  const handleTabChange = (event, newValue) => {
    setActiveStep(newValue);
  };

  const steps = [
    "Información del Asociado",
    "Tipo de Plan",
    "Documentaciòn",
    "Resumen del Plan",
  ];

  const handleNext = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));

    // console.log("Data after handleNext:", { ...data, ...newData });

    if (activeStep === 3) {
      handleRegister(newData);

      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={styles.inclusionCard}>
      <Tabs
        value={activeStep}
        onChange={handleTabChange}
        indicatorColor="primary"
        sx={{ marginTop: 1, marginLeft: 1 }}
      >
        {steps.map((label, index) => (
          <Tab key={index} label={label} disabled={index > activeStep} />
        ))}
      </Tabs>

      <Box>
        {activeStep === 0 && (
          <B2BForm
            handleNext={handleNext}
            handleBack={handleBack}
            data={data}
            activeStep={activeStep}
          />
        )}

        {activeStep === 1 && (
          <B2bPlans
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
            associationId={associationId}
            productsFromDB={productsFromDB}
            loading={loading}
            noDataAvailable={noDataAvailable}
            isProductDataAvailable={isProductDataAvailable}
          />
        )}
        {activeStep === 2 && (
          <B2bDocumentation
            handleNext={handleNext}
            handleBack={handleBack}
            files={files}
            setSelectedPlan={setSelectedPlan}
            setFiles={setFiles}
          />
        )}
        {activeStep === 3 && (
          <B2bSummary
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            selectedPlan={selectedPlan}
            apportionmentPrice={apportionmentPrice}
            setApportionmentPrice={setApportionmentPrice}
            data={data}
            userId={userId}
            code={code}
            qrCodeUrl={qrCodeUrl}
            associationId={associationId}
            handleOpenDialog={handleOpenDialog}
            dialogOpen={dialogOpen}
            handleCloseDialog={handleCloseDialog}
          />
        )}
        {/* {activeStep == 3 && (
            <B2bSummary
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
            />
          )} */}
      </Box>

      <InclusionModal
        open={dialogOpen}
        onClose={handleCloseDialog}
        name={data.name}
        lastname={data.lastname}
        qrCodeUrl={qrCodeUrl}
        code={code}
      />
    </Box>
  );
};
