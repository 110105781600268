import { types } from "../types/types";

const initialState = {
  requests: 0,
  showRefundSummary:false,
};

export const requestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.requests:
      return {
        ...state,
        requests: action.payload,
      };
    case types.SHOW_REFUND_SUMMARY:
      return {
        ...state,
        showRefundSummary: action.payload,
      };
    default:
      return state;
  }
};
