import { Card, Stack } from 'react-bootstrap';
import { Table, Image, Button } from 'react-bootstrap';

import TableHeader from '../table/table-header';
import SelectPromotionFilters from './select-promotion-filters';
import TableBodyHover from '../table/table-body-hover';
import { useState, useMemo } from 'react';
import { useEffect } from 'react';

import SelectAudience from '../select-audience/select-audience';
import CustomModal from '../modal/customModal';

import ReactPaginate from 'react-paginate';
import { IoIosArrowBack } from 'react-icons/io';
import { IoIosArrowForward } from 'react-icons/io';
import ItemsControl from '../items-control/items-control';
import Swal from 'sweetalert2';
import { searchTable } from './../../custom hooks/search-table/search-table';

import './select-promotion';
import { sortSelectPromoTable } from '../../custom hooks/sort-table/sort-select-promotion-table';
import { createBanner } from '../../firebase/firebase.utils';
import { fsTimestampCreador } from '../../firebase/firebase-config';
import { useSelector } from 'react-redux';
import { getDisplayNumber } from './../../firebase/firebase.utils';

const SelectPromotion = ({ promos, nextStep ,setShowStepModal}) => {
  const [promotions, setPromotions] = useState([]);
  const [searchField, setSearchField] = useState('');
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [filteredPromos, setFilteredPromos] = useState([]);

  const [showSelectAudience, setShowSelectAudience] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [selectedPromo, setSelectedPromo] = useState(null);
  const [displayNumber, setDisplayNumber] = useState(3);
  const { banners } = useSelector((state) => state.banners);
  const [activeBanners, setActiveBanners] = useState();
  const headers = [
    // { name: "", accessor: "selection" },
    { name: 'Sponsor', accessor: 'businessName' },
    { name: 'Promoción', accessor: 'promotion' },
    { name: 'Categoría', accessor: 'category' },
    { name: 'Vencimiento', accessor: 'dueDate' },
  ];
  useEffect(() => {
    getNumber();
  }, []);

  const getNumber = async () => {
    const getDisplayN = await getDisplayNumber();
    const displayNumberFromFb = getDisplayN.data().carouselDisplayItems;
    setDisplayNumber(displayNumberFromFb);
    setActiveBanners(banners.length);
    // const bannersActive =  banners.filter(b=> b.isActive === true)
    // const bannersInactive =  banners.filter(b=> b.isActive === false)

    // const activeNum =  bannersActive.length;
    // const inactiveNum = bannersInactive.length;
    // const newNumber = displayNumberFromFb - inactiveNum;
    // setActiveBanners(newNumber)
    // console.log(newNumber)
  };
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-danger me-2',
    },
    buttonsStyling: false,
  });
  const swalWithBootstrapButtons2 = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-primary me-2',
    },
    buttonsStyling: false,
  });

  const showAlert = () => {
    swalWithBootstrapButtons.fire({
      title: 'No se pueden agregar mas de 3 banners al carrusel',

      confirmButtonText: 'Volver',
    });
    setShowStepModal(false);
  };
  const showAlert2 = () => {
    swalWithBootstrapButtons.fire({
      title: 'Banner creado correctamente',

      confirmButtonText: 'Volver',
    });
    setShowStepModal(false);
  };
  const newPromotionsList = useMemo(
    () =>
      promotions.filter(a=> a.status === 'active').map((promo) => ({
        id: promo.id,
        businessName: promo.businessName ? (
          <Stack direction="horizontal" gap={2}>
            <small
              className={`${
                promo.selected ? 'bg-primary' : 'bg-info'
              } d-inline-block rounded-circle me-3`}
              style={{ width: '15px', height: '15px' }}
              id={promo.id}
            ></small>
            <Image
              src={promo.imageURL}
              width="50px"
              height="50px"
              roundedCircle
            />
            <span>{promo.businessName}</span>{' '}
          </Stack>
        ) : (
          '--'
        ),
        promotion: promo.name ? promo.name : '--',
        category: promo.category ? promo.category : '--',
        dueDate: promo.dueDate ? promo.dueDate : '--',
      })),
    [promotions]
  );

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + Number(itemsPerPage);
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(newPromotionsList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(newPromotionsList.length / Number(itemsPerPage)));
  }, [itemOffset, newPromotionsList, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % newPromotionsList.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const sortTableElements = (sortingMethod) => {
    const sortedItems = sortSelectPromoTable(promotions, sortingMethod);

    //const sortedPromos = [];

    // sortedItems.forEach((promoItem) => {
    //   const foundPromo = promotions.find((promo) => promo.id === promoItem.id);
    //   if (foundPromo) sortedPromos.push(foundPromo);
    // });

    //setCurrentItems(sortedItems);
    setPromotions(sortedItems);
  };

  const dropdownList = [
    {
      valuesList: [
        { name: 'Más recientes' },
        { name: 'Más antiguos' },
        { name: 'Orden alfabetico' },
      ],
      label: '',
      filterMethod: sortTableElements,
    },
  ];

  //const options = [{ text: "" }];

  useEffect(() => {
    setPromotions(promos);
    setFilteredPromos(promos);
  }, [promos]);

  useEffect(() => {
    const checkSelectedPromo = () => {
      const selectedPromo = promotions.find((promo) => promo.selected);

      setSelectedPromo(selectedPromo);
      setDisableBtn(!selectedPromo);
    };

    checkSelectedPromo();
  }, [promotions]);

  const cleanSelectedPromo = (id) => {
    const cleanArray = promotions.map((promo) => ({
      ...promo,
      selected: false,
    }));

    const index = cleanArray.findIndex((promo) => promo.id === id);

    cleanArray[index].selected = !cleanArray[index].selected;

    setPromotions(cleanArray);
  };

  const createNewBanner = () => {
    const todayTimestamp = fsTimestampCreador.fromDate(new Date(Date.now()));
    const nextItemNumber = banners.length + 1;
    const bannerData = {
      cdate: todayTimestamp,
      isActive: true,
      itemNumber: nextItemNumber,
      promoRef: selectedPromo.id,
    };
    if (activeBanners >= displayNumber) {
      showAlert();
    } else {
      showAlert2();
      createBanner(bannerData);
    }
 
  };

  const rows = searchTable(currentItems, headers, searchField);

  return (
    // <Card className="border-light mx-auto p-2 mt-n4">
    <>
      <SelectPromotionFilters
        //options={options}
        dropdowns={dropdownList}
        searchRows={setSearchField}
        barWidth="100%"
        //handleRefresh={() => getMembers()}
      />
      <Table className="bg-light mb-0 p-4">
        <TableHeader headers={headers} />
        <TableBodyHover
          rows={rows}
          columns={headers}
          searchField={searchField}
          clickMethod={cleanSelectedPromo}
          fixedColumnHeight={50}
        />
      </Table>

      <Stack direction="horizontal" className="mt-4">
        <ReactPaginate
          breakLabel="..."
          nextLabel={<IoIosArrowForward />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={<IoIosArrowBack />}
          renderOnZeroPageCount={null}
          containerClassName="pagination pagination-modifier"
          pageClassName="page-item"
          previousClassName="previus-button"
          nextClassName="next-button"
          pageLinkClassName="page-link page-link-modifier"
          activeClassName="active"
        />

        <Button
          variant="info"
          className="fw-bold ms-auto rounded-pills"
          disabled={disableBtn}
          //onClick={() => nextStep((prevState) => prevState + 1)}
      onClick={createNewBanner}
        >
          Crear
          {/* Siguiente <IoIosArrowForward /> */}
        </Button>
      </Stack>

      <CustomModal
        show={showSelectAudience}
        handleClose={setShowSelectAudience}
        modalTitle={() => 'Seleccionar Audiencia'}
        currentSize="lg"
        animated={true}
      >
        <SelectAudience />
      </CustomModal>
      {/* </Card> */}
    </>
  );
};

export default SelectPromotion;
