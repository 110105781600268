export const types = {
  banners: "[GET] Banners",
  getPromoBanners: "[GET] PromoBanners",
  getHomeBanners: "[GET] HomeBanners",
  getActivityBanners: "[GET] ActivityBanners",
  getCarouselBanners: "[GET] CarouselBanners",
  addBanners: "[POST] Banners",
  removeBanners: "[DELETE] Banners",
  isLoadingPromoBanners: "[Banners] isLoadingPromoBanners",
};
