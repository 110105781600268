export const userInitialData = {
  typeId: "Cedula",
  idNumber: "----",

  userName: "----",
  birthday: "----",
  cdate: "----",
  email: "----",
  phone: "----",
  country: "",
  province: "",
  totalCoins: 0,
  canton: "",
  district: "",
  favoriteSport: "----",
  sportsOfInterest: "----",

  multiplier: "3,7",
  activityTime: "40min",
  status: true,
};
