import jwtDecode from "jwt-decode";

import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField"; // Import FilePond image preview styles

import { MdNotificationImportant } from "react-icons/md";
import { isMobile } from "react-device-detect";

import { useFormik } from "formik";
import * as Yup from "yup";

import QRCode from "react-qr-code";

import { useParams } from "react-router-dom";

import { useSelector } from "react-redux";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { Close } from "@mui/icons-material";
import { activateUser } from "../../../redux/b2b/actions/actions";
import { refreshToken } from "../../../redux/user/actions/auth";
import { styles } from "../../../styles/styles";
import { ASbutton } from "../../buttons/ASbutton";

const MySwal = withReactContent(Swal);
export const ActivationModal = ({ open, onClose, code, onRefresh }) => {
  const theme = useTheme();
  const { idToken } = useSelector((state) => state.auth);

  const [apportionmentPrice, setApportionmentPrice] = useState(0);
  const [priceNoIVA, setPriceNoIva] = useState(0);
  const [priceToCharge, setPriceToCharge] = useState(0);

  const [loading, setloading] = useState(true);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
  });

  const [selectedPlan, setSelectedPlan] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (code.plan) {
      setSelectedPlan(code.plan);
      setloading(false);
    }
    console.log(code.codeId);
  }, [code]);

  useEffect(() => {
    const overallDate = new Date();
    const year = overallDate.getFullYear();
    const month = overallDate.getMonth() + 1;
    const date = overallDate.getDate();

    //Days in the month
    const apportionmentDays = new Date(year, month, 0).getDate();
    //Days left in the month
    const apportionment = apportionmentDays - date + 1;
    //SET Price without IVA
    setPriceNoIva(selectedPlan.finalPrice - selectedPlan.finalPrice * 0.13);

    setApportionmentPrice((priceNoIVA / apportionmentDays) * apportionment);

    setPriceToCharge(
      (apportionmentPrice + apportionmentPrice * 0.13).toFixed(2)
    );
  }, [apportionmentPrice, selectedPlan, priceNoIVA]);

  const formik = useFormik({
    initialValues: {
      email: code?.email,
      price: selectedPlan?.price ?? "0",
      acceptTerms: false,
      billNumber: 0,
      apportionmentPrice: 0,
    },
    enableReinitialize: true,

    // validationSchema: validationSchema,
    onSubmit: async (values) => {
      const confirmResult = await Swal.fire({
        title: "Confirmar",
        text: "¿Estás seguro de que quieres activar este usuario?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, agregar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });

      if (confirmResult.isConfirmed) {
        const loadingSwal = Swal.fire({
          title: "Guardando...",

          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          const type = "acceptance";
          const billNumber = values.billNumber ?? 0;
          const userId = code.userRef;
          const codeId = code.codeId;
          const associationId = code.associationId;

          const requestData = {
            userId,
            code: codeId,
            associationId,
            type,
          };
          console.log("requestData", requestData);
          if (code) {
            if (isValidToken(idToken)) {
              const res = await activateUser(requestData, idToken);

              if (res.ok) {
                loadingSwal.close();
                MySwal.fire(
                  "Usuario Activado",
                  "El usuario ha sido activado",
                  "success"
                );

                onClose();
                onRefresh();
              } else {
                loadingSwal.close();

                MySwal.fire(
                  "Error",
                  "Hubo un error al crear el usuario",
                  "error"
                );
              }
            } else {
              const newToken = await refreshToken();
              const res = await activateUser(requestData, newToken);

              if (res.ok) {
                loadingSwal.close();
                MySwal.fire(
                  "Usuario Activado",
                  "El usuario ha sido activado",
                  "success"
                );

                onClose();
                onRefresh();
              } else {
                loadingSwal.close();

                MySwal.fire(
                  "Error",
                  "Hubo un error al crear el usuario",
                  "error"
                );
              }
            }
          }
        } catch (error) {
          console.log(error);
          loadingSwal.close();

          MySwal.fire("Error", "Hubo un error al crear el usuario", "error");
        }
      }
    },
  });

  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };

  const renderList = () => {
    if (!selectedPlan) {
      <Box sx={styles.formContainer}>
        <Box sx={[styles.plansCard, { mt: 0, mb: 2 }]}>No hay datos</Box>
      </Box>;
    }

    return selectedPlan.data.map((data, index) => (
      <Grid container item md={12} key={index}>
        <Grid item md={8}>
          <Typography
            textAlign={"left"}
            variant="body1"
            sx={{ fontSize: "0.8rem !important" }}
            key={data.id}
          >
            {data.description}
          </Typography>
        </Grid>
        <Grid item md={4}>
          <Typography variant="body2" key={data.id} textAlign={"right"}>
            {data.value}
          </Typography>
        </Grid>
      </Grid>
    ));
  };

  if (loading) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            // backgroundColor: theme.palette.secondary.secondary,
            width: "100%",
            backgroundImage: "none",
            overflowX: "hidden",
          },
        }}
      >
        <DialogTitle sx={{ color: theme.palette.primary.main }}>
          Información del Plan
          <Box sx={styles.closeButton} onClick={onClose}>
            <Close />
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",

            flexDirection: "column",
          }}
        >
          <Container maxWidth="xs" id="acceptanceContainer">
            <CircularProgress />
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  if (!code) {
    return (
      <Box sx={styles.formContainer}>
        <Box sx={[styles.plansCard, { mt: 0, mb: 2 }]}>No hay datos</Box>
      </Box>
    );
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"sm"}
      PaperProps={{
        sx: {
          // backgroundColor: theme.palette.secondary.secondary,
          width: "100%",
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle sx={{ color: theme.palette.primary.main }}>
        Solicitud de activación
        <Box sx={styles.closeButton} onClick={onClose}>
          <Close />
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",

          flexDirection: "column",
        }}
      >
        <Box sx={[styles.plansCard, { mt: 2, mb: 2 }]}>
          <Grid container columnGap={2} justifyContent={"flex-start"} mb={2}>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  label="Nombre"
                  variant="outlined"
                  value={code?.name}
                  InputProps={{
                    readOnly: true,
                    pointerEvents: "none",
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container columnGap={0} justifyContent={"flex-start"} mb={4}>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  label="Correo Electrónico"
                  variant="outlined"
                  value={code?.email}
                  InputProps={{
                    readOnly: true,
                    pointerEvents: "none",
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={styles.formTitle} mb={4}>
            <Typography
              variant="h7"
              sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
            >
              Resumen
            </Typography>
          </Box>
          <Grid container columnGap={2} justifyContent={"space-between"}>
            <Grid item md={12} container mb={3} sx={styles.summaryCard}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",

                  flexDirection: "column",
                }}
              >
                <Typography variant="h6">{selectedPlan?.subTier}</Typography>
                <Typography variant="body1">
                  Código: {selectedPlan?.code}
                </Typography>

                <Grid
                  container
                  sx={{
                    margin: "0 auto",
                    mt: 2,

                    pt: 1,
                    pb: 1,
                  }}
                >
                  <Box mb={1}>
                    <Typography variant="body1">Detalles del Plan</Typography>
                  </Box>
                  {renderList()}
                </Grid>
              </Box>
            </Grid>
            <Grid item md={12} container mb={3} sx={styles.summaryCard}>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Precio"
                    variant="standard"
                    value={selectedPlan?.finalPrice}
                    InputProps={{
                      readOnly: true,
                      pointerEvents: "none",
                    }}
                  />
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  label="Prorateo"
                  variant="standard"
                  value={450}
                  disabled
                />
              </FormControl>
            </Grid> */}
              <Grid item xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Precio a pagar"
                    variant="standard"
                    value={priceToCharge}
                    id="ApportionmentPrice"
                    InputProps={{
                      readOnly: true,
                    }}
                    name="ApportionmentPrice"
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={
                      {
                        // Your styles for the bottom text
                      }
                    }
                  >
                    El pago de tu plan {selectedPlan.subTier} fue ajustando a la
                    fecha {new Date().getDate()}/{new Date().getMonth() + 1}/
                    {new Date().getFullYear()} . Al activar este plan autorizas
                    su recurrencia mensual el primer día del mes en curso por un
                    total de ₡{selectedPlan.finalPrice} I.V.A.I. mensuales.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {/* @ND ROW */}

          <Grid
            container
            spacing={2}
            mb={3}
            mt={2}
            pb={6}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  label="Comprobante de Pago"
                  variant="outlined"
                  value={formik.values.billNumber}
                  id="billNumber"
                  name="billNumber"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.billNumber &&
                    Boolean(formik.errors.billNumber)
                  }
                  helperText={
                    formik.touched.billNumber && formik.errors.billNumber
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box ml={2}>
          <ASbutton
            onClick={formik.handleSubmit}
            backgroundColor={theme.palette.primary.main}
            color={"white"}
            //   disabled={code.statusCode !== 3}
            buttonText={"Finalizar"}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};
