import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import { Col, Row, Button, Spinner, Stack } from "react-bootstrap";
import { TagsInput } from "react-tag-input-component";
import FormInput from "../form-input/form-input";
import UploadLogo from "../upload-logo/upload-logo";
import InputGroup from "react-bootstrap/InputGroup";

import {
  createMemberImageEdit,
  updateMember,
} from "../../firebase/firebase.utils";

const roles = [
  { name: "Super Admin", value: "superadmin" },
  { name: "Admin", value: "admin" },
  { name: "Moderador", value: "moderator" },
];

const typeIdMember = [
  { name: "Pasaporte", value: "pasaporte" },
  { name: "Cédula", value: "cedula" },
];

const countryList = [
  { name: "Costa Rica", value: "costaRica" },
  { name: "Nicaragüa", value: "nicaragua" },
];

const MemberEdition = ({ onClose, member, handleRefresh }) => {
  const [teams, setTeams] = useState([]);
  const [imageUpload, setImageUpload] = useState(member.imageUrl);
  const [image, setImage] = useState(member.imageUrl);
  const [fileUrl, setFileUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [memberCountry, setMemberCountry] = useState(member.country);
  const [memberTeamsDb, setMemberTeamsDb] = useState(member.teams);
  const [memberStatus, setMemberStatus] = useState(member.isActive);

  const [active, setActive] = useState(() => (member.isActive ? true : false));
  const [inactive, setInactive] = useState(() =>
    !member.isActive ? true : false
  );

  const { userGetToken } = useSelector((state) => state.auth);

  const reloadToken = async () => {
    let freshToken = await userGetToken.getIdToken(true);
    return freshToken;
  };

  const memberRole = (member) => {
    let roleValue = "";
    if (member.admin) {
      roleValue = "admin";
    } else if (member.superadmin) {
      roleValue = "superadmin";
    } else {
      roleValue = "moderator";
    }
    return roleValue;
  };

  // const [memberIdType, setMemberIdType] = useState(member.typeId);

  const handleActiveYes = () => {
    setMemberStatus(true);
    setActive(true);
    setInactive(false);
  };

  const handleActiveNo = () => {
    setMemberStatus(false);
    setActive(false);
    setInactive(true);
  };

  const validate = Yup.object({
    memberName: Yup.string().required("Requerido"),
    memberLastName: Yup.string().required("Requerido"),
    memberEmail: Yup.string()
      .email("Formato no válido")
      .typeError("Formato no válido")
      .required("Requerido"),
    roles: Yup.string(),
    typeIdMember: Yup.string(),
    idMember: Yup.number()
      .typeError("Solo puede ingresar valores numericos")
      .required("No es un ID válido"),
    countries: Yup.string(),
    province: Yup.string().required("Requerido"),
    city: Yup.string().required("Requerido"),
    location: Yup.string().required("Requerido"),
    memberPhoneNumber: Yup.number()
      .typeError("Solo puede ingresar valores numericos")
      .required("No es un número válido"),
  });

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      memberName: member.name,
      memberLastName: member.lastName,
      memberEmail: member.email,
      memberPhoneNumber: member.phoneNumber,
      role: memberRole(member),
      typeId: member.typeId,
      idMember: member.memberId,
      country: member.country,
      city: member.city,
      province: member.province,
      location: member.location,
      teams: memberTeamsDb,
      imageUrl: member.imageUrl,
      isActive: member.isActive,
    },
    validationSchema: validate,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);

      let newImageUrl = member.imageUrl ? member.imageUrl : "";

      const memberData = {
        name: values.memberName,
        lastName: values.memberLastName,
        typeId: values.typeId,
        memberId: values.idMember,
        phoneNumber: values.memberPhoneNumber,
        country: values.country,
        city: values.city,
        province: values.province,
        location: values.location,
        teams: memberTeamsDb,
        imageUrl: member.imageUrl ? member.imageUrl : "",
        isActive: memberStatus,
      };
      if (member.imageUrl !== imageUpload) {
        newImageUrl = await createMemberImageEdit(imageUpload, member.id);
      }

      const newMemberData = { ...memberData, imageUrl: newImageUrl };

      const token = await reloadToken();
      console.log(member.imageUrl);
      const url =
        "https://us-central1-allsports-69231.cloudfunctions.net/admins/rootAdmin";
      const options = {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: values.memberEmail,
          role: values.role,
          data: newMemberData,
        }),
      };

      try {
        const response = await fetch(url, options);
        const res = await response.json();

        console.log(res);

        setTimeout(() => {
          setLoading(false);
          handleRefresh();
          // resetForm();
          onClose(false);
        }, 2000);
        return res.ok;
      } catch (error) {
        console.log(error);
        throw new Error(error);
      }
    },
  });
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      noValidate
      className="p-3"
    >
      <Row>
        <Col>
          <Form.Group>
            <FormInput
              label={"Nombre"}
              onChange={handleChange}
              value={values.memberName}
              type="text"
              name="memberName"
              errorList={errors}
            />

            <Form.Control.Feedback type="invalid">
              {errors.memberName}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <FormInput
              label={"Apellido"}
              onChange={handleChange}
              value={values.memberLastName}
              type="text"
              name="memberLastName"
              errorList={errors}
            />

            <Form.Control.Feedback type="invalid">
              {errors.memberLastName}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group>
            <FormInput
              label={"Correo"}
              onChange={handleChange}
              value={values.memberEmail}
              type="text"
              name="memberEmail"
              placeholder={""}
              errorList={errors}
            />

            <Form.Control.Feedback type="invalid">
              {errors.adminEmail}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: "14px", marginBottom: "15px" }}>
              Roles
            </Form.Label>
            <Form.Select
              size="sm"
              onChange={handleChange}
              name="role"
              value={values.role}
              defaultValue={memberRole(member)}
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-3"
            >
              {roles.map((role) => {
                return (
                  <option value={role.value} name="role">
                    {role.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: "14px" }}>Equipos</Form.Label>
            <TagsInput
              value={memberTeamsDb}
              onChange={setMemberTeamsDb}
              name="tags"
              classNames={{
                input:
                  "border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none",
                tag: "bg-tags",
              }}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: "14px" }} className="mb-3">
              Pais
            </Form.Label>
            <Form.Select
              size="sm"
              onChange={handleChange}
              name="country"
              value={values.country}
              defaultValue={member.country}
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
            >
              {countryList.map((country) => {
                return (
                  <option value={country.value} name="country">
                    {country.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <FormInput
              label={"Provincia/Departamento"}
              onChange={handleChange}
              value={values.province}
              type="text"
              name="province"
              errorList={errors}
            />

            <Form.Control.Feedback type="invalid">
              {errors.province}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group>
            <FormInput
              label={"Ciudad"}
              onChange={handleChange}
              value={values.city}
              type="text"
              name="city"
              placeholder={"San Jose"}
              errorList={errors}
            />

            <Form.Control.Feedback type="invalid">
              {errors.city}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <FormInput
              label={"Ubicacion"}
              onChange={handleChange}
              value={values.location}
              type="text"
              name="location"
              placeholder={"https://googlemaps"}
              errorList={errors}
            />

            <Form.Control.Feedback type="invalid">
              {errors.location}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="mt-3">
            <Form.Group>
              <InputGroup className="position-relative">
                <Form.Group>
                  <FormInput
                    label={"Teléfono"}
                    onChange={handleChange}
                    value={values.memberPhoneNumber}
                    type="text"
                    name="memberPhoneNumber"
                    errorList={errors}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.memberPhoneNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </InputGroup>

              <InputGroup className="position-relative">
                <Form.Group>
                  <Form.Label style={{ fontSize: "14px" }} className="mb-3">
                    Tipo de ID
                  </Form.Label>
                  <Form.Select
                    size="sm"
                    onChange={handleChange}
                    name="typeId"
                    value={values.typeId}
                    defaultValue={member.typeId}
                    className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                  >
                    {typeIdMember.map((typeId) => {
                      return (
                        <option value={typeId.value} name="typeId">
                          {typeId.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </InputGroup>

              <InputGroup className="position-relative">
                <Form.Group>
                  <FormInput
                    label={"Número ID"}
                    onChange={handleChange}
                    value={values.idMember}
                    type="text"
                    name="idMember"
                    errorList={errors}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.idMember}
                  </Form.Control.Feedback>
                </Form.Group>
              </InputGroup>
            </Form.Group>
          </div>
        </Col>
        <Col className="image-container">
          <UploadLogo
            title={"Imagen"}
            loadUrl={setFileUrl}
            imageUpload={imageUpload}
            setImageUpload={setImageUpload}
            setImage={setImage}
            image={image}
          />
          <Form.Group>
            <Form.Label style={{ fontSize: "14px" }} className="mt-3">
              Status
            </Form.Label>
            <Form.Check
              checked={active}
              id="0"
              type="radio"
              name="isActive"
              label="Activo"
              size="sm"
              onChange={handleActiveYes}
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
            />
            <Form.Check
              checked={inactive}
              id="1"
              type="radio"
              name="isActive"
              label="Inactivo"
              size="sm"
              onChange={handleActiveNo}
              className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
            />
          </Form.Group>
        </Col>
      </Row>

      <Stack direction="horizontal" className="mt-3">
        <Button
          variant="light"
          size="sm"
          className="rounded-pill ms-auto px-3"
          onClick={() => onClose(false)}
        >
          Cancelar
        </Button>
        <Button
          variant="primary"
          size="md"
          className="rounded-pill text-light px-3"
          type="submit"
          disabled={loading}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {!loading && (
            <span style={{ color: "white !important" }}>Guardar</span>
          )}
        </Button>
      </Stack>
    </Form>
  );
};

export default MemberEdition;
