import React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { FaRocket } from "react-icons/fa";

export const ASbutton = ({
  buttonText,
  backgroundColor,
  color,
  outlined,
  borderColor,
  width,
  height,
  fontSize,
  weight,
  icon,
  onClick,
  disabled,
  padR,
  padL,
}) => {
  const theme = useTheme();
  const buttonBaseStyles = {
    display: "flex",
    flexDirection: "row",
    width: width,
    height: height,
    backgroundColor: backgroundColor ?? null,
    borderRadius: "2em",
    pr: padR ? padR : 2,
    pl: padL ? padL : 2,

    [theme.breakpoints.down("sm")]: {
      height: "40px",
      fontSize: "12px",
    },
  };

  const buttonOutlinedStyles = {
    ...buttonBaseStyles,
    borderColor: borderColor,

    alignContent: "center",
    justifyContent: "center",

    mr: 1,
  };

  return (
    <Button
      size="small"
      variant={outlined ? "outlined" : "contained"}
      sx={{
        ...(outlined ? buttonOutlinedStyles : buttonBaseStyles),
        "&:hover": {
          backgroundColor: outlined ? backgroundColor : "#625EA7",
          borderColor: outlined ? borderColor : null,
          cursor: "pointer",
        },
        "&:active": {
          backgroundColor: outlined ? backgroundColor : "#666666",
          borderColor: outlined ? borderColor : null,
        },
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <Box>
        {icon}
        <Typography
          variant="p"
          fontSize={fontSize}
          sx={{
            color: color,
            fontWeight: `${weight} !important`,
            ml: icon ? 1 : 0,
            textTransform: "capitalize",
          }}
        >
          {buttonText}
        </Typography>
      </Box>
    </Button>
  );
};
