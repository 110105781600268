import { db, storageRef } from "../../firebase/firebase-config";

export const SET_BLOGS = "SET_BLOGS";
export const SET_POSTS = "SET_POSTS";
export const ADD_POST = "ADD_POST";

export const getBlogs = () => {
  return async (dispatch) => {
    try {
      const blogsRef = db.collection("blogs");
      const blogsSnap = await blogsRef.get();

      const blogs = [];

      // Iterate through each blog document
      for (const doc of blogsSnap.docs) {
        const blogData = doc.data();
        const blogId = doc.id;

        // Get all posts for this blog
        const postsRef = await db
          .collection("blogs")
          .doc(blogId)
          .collection("posts")
          .get();
        const allPosts = postsRef.docs.map((doc) => doc.data());

        // Filter posts by status
        const pendingPosts = allPosts.filter(
          (post) => post.status === "pending"
        );
        const acceptedPosts = allPosts.filter(
          (post) => post.status === "accepted"
        );
        const rejectedPosts = allPosts.filter(
          (post) => post.status === "rejected"
        );

        const totalPostsCount = allPosts.length;
        const pendingPostsCount = pendingPosts.length;
        const acceptedPostsCount = acceptedPosts.length;
        const rejectedPostsCount = rejectedPosts.length;

        // Push the blog data along with the total, pending, accepted, and rejected posts counts
        blogs.push({
          id: blogId,
          totalPostsCount: totalPostsCount,
          pendingPostsCount: pendingPostsCount,
          acceptedPostsCount: acceptedPostsCount,
          rejectedPostsCount: rejectedPostsCount,
          ...blogData,
        });
      }

      // Dispatch the action with all blogs data
      dispatch({
        type: SET_BLOGS,
        blogs: blogs,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getBlogPosts = (category) => {
  return async (dispatch) => {
    try {
      // Query blogs collection to find the document with the specified category name
      const blogQuerySnapshot = await db
        .collection("blogs")
        .where("name", "==", category)
        .get();
      console.log(category);
      if (!blogQuerySnapshot.empty) {
        // Access the first document found (assuming category names are unique)
        const blogDocSnapshot = blogQuerySnapshot.docs[0];
        console.log(blogDocSnapshot);
        // Access the "posts" subcollection of the document
        const postsQuerySnapshot = await blogDocSnapshot.ref
          .collection("posts")
          .get();

        // Process fetched posts
        const items = [];
        postsQuerySnapshot.forEach((doc) => {
          const data = doc.data();
          items.push({
            id: doc.id,
            blogId: blogDocSnapshot.id,
            ...data,
          });
        });
        console.log(items);
        // Dispatch action to set posts in Redux state
        dispatch({
          type: SET_POSTS,
          category: category,
          posts: items,
        });
      } else {
        console.log("No blogs found with the category:", category);
      }
    } catch (error) {
      console.error("Error fetching blog posts:", error);
    }
  };
};

export const getCategories = async () => {
  try {
    // Fetch categories from Firestore collection
    const snapshot = await db
      .collection("utils")
      .doc("blog")
      .collection("categories")
      .get();

    // Extract categories from snapshot
    const categories = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return categories;
  } catch (error) {
    console.error(error);
    return []; // Return an empty array in case of error
  }
};

//POSTS ADD EDIT

export const addBlogPost = (blogId, postData) => {
  return async (dispatch) => {
    try {
      // Add current date to postData
      postData = {
        ...postData,
        cdate: new Date(),
      };

      const docRef = await db
        .collection("blogs")
        .doc(blogId)
        .collection("posts")
        .add(postData);
      const postId = docRef.id;

      dispatch({
        type: ADD_POST,
        blogId: blogId,
        post: {
          id: postId,
          ...postData,
        },
      });

      return postId;
    } catch (error) {
      console.error(error);
    }
  };
};
export const editBlogPost = async (
  blogId,
  postId,
  updatedData,
  imageUpload
) => {
  try {
    let newData = updatedData;
    let urlList;

    if (imageUpload.length > 0) {
      urlList = await createPostImages(imageUpload, blogId, postId);
    } else {
      const newImg = { imageURLs: [] };
      newData = { ...updatedData, ...newImg };
      // await db
      //   .collection("blogs")
      //   .doc(blogId)
      //   .collection("posts")
      //   .doc(postId)
      //   .update(newData);
    }

    await db
      .collection("blogs")
      .doc(blogId)
      .collection("posts")
      .doc(postId)
      .update(newData);

    return urlList;
  } catch (error) {
    console.error(error);
  }
};

export const uploadFile = async (path, file) => {
  if (!(file instanceof File)) return;
  console.log(path, file);
  try {
    const upload = await storageRef.ref(path).put(file);
    const fileUrl = await upload.ref.getDownloadURL();

    return fileUrl;
  } catch (error) {
    console.log(file, path);
    console.log("No se puede subir el archivo seleccionado");
  }
};
export const createPostImage = async (imageUpload, blogId, id) => {
  const imageName = imageUpload.name;

  try {
    const path = `blogs/${blogId}/${id}/${imageName}`;
    const fileUrl = await uploadFile(path, imageUpload);
    console.log(imageUpload, blogId, id);
    if (fileUrl) {
      const newImg = { imageURL: fileUrl };
      await db
        .collection("blogs")
        .doc(blogId)
        .collection("posts")
        .doc(id)
        .set(newImg, { merge: true });
    }
  } catch (error) {
    console.log(error);
  }
};

export const createPostImages = async (imageUploads, blogId, postId) => {
  try {
    /*When editing, imageUploads possible value is a string url
    coming from Firebase.
    uploadFile function doesn't handle strings only File objects
    */
    let imageURLs = imageUploads;

    if (typeof imageUploads[0] !== "string") {
      imageURLs = []; // Array to store imageURLs

      const uploadPromises = imageUploads.map(async (imageUpload, index) => {
        console.log("Processing image:", imageUpload.name);
        const imageName = imageUpload.name;
        const path = `blogs/${blogId}/${postId}/postImage`;
        const fileUrl = await uploadFile(path, imageUpload);

        if (fileUrl) {
          imageURLs.push(fileUrl); // Add imageURL to the array
        }
      });

      await Promise.all(uploadPromises);
    }

    // Store the array of imageURLs in Firestore
    await db
      .collection("blogs")
      .doc(blogId)
      .collection("posts")
      .doc(postId)
      .set({ imageURLs }, { merge: true }); // Merge with existing data if any

    return imageURLs;
  } catch (error) {
    console.error(error);
  }
};
