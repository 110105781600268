import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { useState } from "react";
import UploadBannerImage from "./UploadBannerImage";
import {
  createBannerImage,
  createPromoBanner,
} from "../../redux/banners/actions/actions";
import { Spinner } from "react-bootstrap";
import FormInput from "../form-input/form-input";
import { styles } from "../../styles/styles";
import { Close } from "@mui/icons-material";
import { FormControl } from "@mui/material";

const AddPromoBanner = ({ open, onClose, handleRefresh }) => {
  const validationSchema = Yup.object().shape({
    businessName: Yup.string().required("Business Name is required"),
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
    // Add validation for other fields as needed
  });
  const [imageUpload, setImageUpload] = useState("");
  const [image, setImage] = useState("");
  const [fileUrl, setFileUrl] = useState(null);

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      businessName: "",
      name: "",
      description: "",
      restrictions: "",
      imageURL: "",
      dueDate: new Date().toISOString().slice(0, 10),
      isActive: false,
      itemNumber: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const newDate = new Date(values.dueDate);

      const data = {
        businessName: values.businessName,
        name: values.name,
        description: values.description,
        restrictions: values.restrictions,
        imageURL: values.imageURL,
        dueDate: newDate,
        itemNumber: values.itemNumber,
        isActive: values.isActive,
        cdate: new Date(),
      };

      try {
        const id = await createPromoBanner(data);
        createBannerImage(imageUpload, id);

        setTimeout(() => {
          setLoading(false);
          resetForm();
          handleRefresh();
          onClose();
        }, 2000);
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          // backgroundColor: theme.palette.secondary.secondary,
          width: "100%",

          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle>
        Agregar Banner de Publicidad
        <Box sx={styles.closeButton} onClick={onClose}>
          <Close />
        </Box>
      </DialogTitle>
      <DialogContent>
        <form>
          <TextField
            margin="dense"
            variant="standard"
            fullWidth
            id="businessName"
            name="businessName"
            label="Nombre Negocio"
            value={formik.values.businessName}
            onChange={formik.handleChange}
            error={
              formik.touched.businessName && Boolean(formik.errors.businessName)
            }
            helperText={
              formik.touched.businessName && formik.errors.businessName
            }
          />

          <TextField
            margin="dense"
            variant="standard"
            fullWidth
            id="name"
            name="name"
            label="Nombre"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            margin="dense"
            variant="standard"
            fullWidth
            id="description"
            name="description"
            label="Descripción"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />
          <TextField
            margin="dense"
            variant="standard"
            fullWidth
            id="restrictions"
            name="restrictions"
            label="Restricciones"
            value={formik.values.restrictions}
            onChange={formik.handleChange}
            error={
              formik.touched.restrictions && Boolean(formik.errors.restrictions)
            }
            helperText={
              formik.touched.restrictions && formik.errors.restrictions
            }
          />
          <TextField
            margin="dense"
            variant="standard"
            fullWidth
            type="number"
            id="item"
            name="itemNumber"
            label="Numero"
            value={formik.values.itemNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.itemNumber && Boolean(formik.errors.itemNumber)
            }
            helperText={formik.touched.itemNumber && formik.errors.itemNumber}
          />
          <FormInput
            label={"Válido hasta"}
            onChange={formik.handleChange}
            value={formik.values.dueDate}
            type="date"
            name="dueDate"
            placeholder={formik.values.dueDate}
            errorList={formik.errors} // Pass the formik.errors as the errorList prop if you are using it in FormInput
          />
          <FormControl
            component="fieldset"
            sx={{
              marginLeft: "0px !important",
              flexDirection: "row",
              marginBottom: "15px",
            }}
          >
            <FormControlLabel
              sx={{ marginLeft: "0px !important", flexDirection: "row" }}
              value="isActive"
              label="Activo"
              labelPlacement="start"
              control={
                <Checkbox
                  name="isActive"
                  checked={formik.values.isActive}
                  onChange={formik.handleChange}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
            />
          </FormControl>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "375px",
              marginRight: "auto",
              marginLeft: "auto",
            }}
            mt={2}
            mb={2}
          >
            <UploadBannerImage
              title={"Banner"}
              loadUrl={setFileUrl}
              imageUpload={imageUpload}
              setImageUpload={setImageUpload}
              setImage={setImage}
              image={image}
            />
          </Box>

          <DialogActions>
            <Button variant="contained" color="error" onClick={onClose}>
              Cancelar
            </Button>
            <Button
              onClick={formik.handleSubmit}
              variant="contained"
              disabled={loading}
            >
              {loading ? "Creando..." : "Crear"}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddPromoBanner;
