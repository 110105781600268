import { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";

import { Stack, Button } from "react-bootstrap";

import Swal from "sweetalert2";

import UserOverview from "../../components/user-overview/user-overview";
import UserPurchases from "../../components/user-purchases/user-purchases";
import UserProfile from "../../components/user-profile/user-profile";
import UserInterests from "../../components/user-interests/user-interests";
import UserExchange from "../../components/user-exchange/user-exchange";
import AdminHistory from "../../components/adminHistory/AdminHistory";

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import {
  getUserAdminHistory,
  getUserRealTime,
  updateUserData,
} from "../../firebase/firebase.utils";

import { useSelector } from "react-redux";
import { refreshToken } from "../../redux/user/actions/auth";

import { Container } from "@mui/material";

import darkLeftArrow from "./../../assests/darkLeftArrow.png";

const User = () => {
  const { id } = useParams();
  const [selectedUser, setSelectedUser] = useState(null);
  const [editable, setEditable] = useState(false);
  const { userGetToken } = useSelector((state) => state.auth);
  const [reason, setReason] = useState("");

  useEffect(() => {
    const user = getUserRealTime(id).onSnapshot((user) =>
      setSelectedUser({ ...user.data(), id: user.id })
    );
    return user;
  }, [id]);

  if (!selectedUser) {
    return;
  }

  const deleteUser = async (userId, reason) => {
    const url = `https://us-central1-allsports-69231.cloudfunctions.net/admins/users/${userId}?reason=${reason}`;
    const token = await refreshToken();
    console.log(userId);
    const options = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    try {
      // db.collection('users').doc(userId).delete();
      const response = await fetch(url, options);
      const res = await response.json();

      return res.ok;
    } catch (error) {
      console.log(error);
    }
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-primary me-2",
      cancelButton: "btn btn-secondary",
      denyButton: "btn btn-danger me-2",
    },
    buttonsStyling: false,
  });

  const callOmitUser = async () => {
    swalWithBootstrapButtons
      .fire({
        title: "Confirme su elección",
        showDenyButton: true,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Desactivar",
        confirmButtonColor: "#FAA60F",
        denyButtonText: "Deshablitar",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          updateUserData(id, { isActiveMember: false });
          Swal.fire("Usuario desactivado", "", "success");
        }
        if (result.isDenied) {
          const { value } = await Swal.fire({
            input: "textarea",
            inputLabel: "Message",
            inputPlaceholder: "Mensaje de deshabilitación...",
            inputAttributes: {
              "aria-label": "Mensaje de deshabilitación",
            },
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value) {
                return "Mensaje requerido";
              }
            },
          });

          if (value) {
            swalWithBootstrapButtons
              .fire({
                title: "¿Esta seguro que desea deshabilitar a este usuario?",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonText: "Deshablitar",
                confirmButtonColor: "#FAA60F",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  deleteUser(id, value);
                  Swal.fire("Usuario deshabilitado", "", "success");
                }
              });
          } else {
            Swal.fire("Mensaje requerido para deshabilitar usuarios");
          }
        }
      });
  };

  const callIncludeUser = () => {
    Swal.fire({
      title: "Confirme su elección",
      showDenyButton: true,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Activar",
      confirmButtonColor: "#EF7817",
      denyButtonText: "Deshabilitar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        updateUserData(id, { isActiveMember: true });
        Swal.fire("Usuario activado", "", "success");
      } else if (result.isDenied) {
        const { value } = await Swal.fire({
          input: "textarea",
          inputLabel: "Message",
          inputPlaceholder: "Mensaje de deshabilitación...",
          inputAttributes: {
            "aria-label": "Mensaje de deshabilitación",
          },
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) {
              return "Mensaje requerido";
            }
          },
        });

        if (value) {
          swalWithBootstrapButtons
            .fire({
              title: "¿Esta seguro que desea deshabilitar a este usuario?",
              showCancelButton: true,
              cancelButtonText: "Cancelar",
              confirmButtonText: "Deshablitar",
              confirmButtonColor: "#FAA60F",
            })
            .then((result) => {
              if (result.isConfirmed) {
                deleteUser(id, value);
                Swal.fire("Usuario deshabilitado", "", "success");
              }
            });
        } else {
          Swal.fire("Mensaje requerido para deshabilitar usuarios");
        }
      }
    });
  };

  return (
    <Container maxWidth={"xl"}>
      <Stack direction="horizontal" gap={2} className="w-100 mb-4">
        <Link
          to="/user"
          className="w-25 d-block link-dark text-decoration-none"
        >
          <h1 className="h5 mb-0">
            <span className="d-inline-block" style={{ width: "14px" }}>
              <img
                src={darkLeftArrow}
                alt="Black arrow icon"
                className="w-100"
              />
            </span>
            Atrás
          </h1>
        </Link>
        {selectedUser.isActiveMember ? (
          <Button
            variant="link"
            className="text-dark ms-auto"
            onClick={callOmitUser}
          >
            Deshabilitar/Desactivar
          </Button>
        ) : (
          <Button
            variant="link"
            className="text-dark ms-auto"
            onClick={callIncludeUser}
          >
            Deshabilitar/Activar
          </Button>
        )}
      </Stack>
      <UserOverview
        user={selectedUser}
        id={id}
        editable={editable}
        activeEdition={setEditable}
      />
      <div style={{ marginTop: "30px" }}>
        <ResponsiveMasonry columnsCountBreakPoints={{ 800: 1, 900: 2 }}>
          <Masonry gutter="30px">
            <UserPurchases user={selectedUser} />
            <div style={{ display: selectedUser.infoCompleted ? "" : "none" }}>
              <UserProfile
                user={selectedUser}
                id={id}
                editable={editable}
                activeEdition={setEditable}
              />
              <UserInterests user={selectedUser} id={id} editable={editable} />
            </div>
            <UserExchange user={selectedUser} id={id} />
            <AdminHistory id={id} getAdminHistory={getUserAdminHistory} />
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </Container>
  );
};

export default User;
