import { Grid, Paper, Divider } from "@mui/material";
import { ReactComponent as UserGroup } from "./../../assests/userGroup.svg";
import { ReactComponent as Phone } from "./../../assests/phone.svg";
import { ReactComponent as CheckedUser } from "./../../assests/checkedUser.svg";
import { countUsers } from "../../firebase/firebase.utils";
import { useEffect, useState } from "react";
import Overview from "../overview/overview";
import { useSelector } from "react-redux";
import { MdPerson, MdOutlineNumbers, MdBusiness } from "react-icons/md";
import {
  countActiveB2bUsers,
  countAllB2bUsers,
  countB2b,
} from "../../redux/users/actions/actions";

const B2bCard = ({ exclusions, pending, all, active }) => {
  const [b2bNumber, setB2bNumber] = useState(0);
  const [activeUsersNum, setActiveUsersNum] = useState(0);
  const [usersNum, setUsersNum] = useState(0);
  useEffect(() => {
    let unsubscribeB2b;
    let unsubscribeActiveUsers;
    let unsubscribeAllUsers;

    const count = async () => {
      unsubscribeB2b = await countB2b(setB2bNumber);
      // unsubscribeActiveUsers = await countActiveB2bUsers(setActiveUsersNum);
      // unsubscribeAllUsers = await countAllB2bUsers(setUsersNum);
    };

    count();

    return () => {
      if (unsubscribeB2b) {
        unsubscribeB2b();
      }
      // if (unsubscribeActiveUsers) {
      //   unsubscribeActiveUsers();
      // }
      // if (unsubscribeAllUsers) {
      //   unsubscribeAllUsers();
      // }
    };
  }, []);
  // Get the current date
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1
  const currentYear = currentDate.getFullYear();

  // Filter users with activationDate in the current month and year
  const usersInCurrentMonth = all.filter((user) => {
    if (user.activationDate) {
      const activationDate = user.activationDate.toDate(); // Assuming activationDate is a Firestore Timestamp
      const activationMonth = activationDate.getMonth() + 1;
      const activationYear = activationDate.getFullYear();
      return activationMonth === currentMonth && activationYear === currentYear;
    }
    return false;
  });
  const exclusionsCount = exclusions?.length;
  const pendingCount = pending?.length;
  const activeCount = active?.length;
  const allCount = all?.length;

  const usersCount = usersInCurrentMonth.length;
  const usersPercentage = (usersCount / allCount) * 100;

  // Calculate the percentage
  const excPercentage = (exclusionsCount / allCount) * 100;
  const pendPercentage = (pendingCount / allCount) * 100;
  const actPercentage = (activeCount / allCount) * 100;

  const totalUsers = {
    title: "Total asociaciones",
    totalNumber: b2bNumber ?? 0,
    statistics: "16%",
    reference: "este mes",
    color: true,
  };

  const totalCustomers = {
    title: "Total Usuarios ",
    totalNumber: allCount ?? 0,
    statistics: `${usersPercentage.toFixed(2)}%`,
    reference: "este mes",
    color: true,
  };

  const connectedUsers = {
    title: "Usuarios Activos",
    totalNumber: activeCount ?? 0,
    statistics: `${actPercentage.toFixed(2)}%`,
    reference: "/ total",
    color: true,
  };
  const exclusionRequests = {
    title: "Solicitudes exclusión",
    totalNumber: exclusionsCount ?? 0,
    statistics: `${excPercentage.toFixed(2)}%`,
    reference: "/ total",
    color: true,
  };
  const pendingRequests = {
    title: "Solicitudes activación",
    totalNumber: pendingCount ?? 0,
    statistics: `${pendPercentage.toFixed(2)}%`,
    reference: "/ total",
    color: true,
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Grid
        container
        // spacing={2}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Overview
            data={totalUsers}
            icon={
              <MdBusiness
                style={{
                  width: "25px",
                  height: "25px",

                  color: "white",
                }}
              />
            }
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Overview
            data={totalCustomers}
            icon={
              <MdOutlineNumbers
                style={{
                  width: "25px",
                  height: "25px",

                  color: "white",
                }}
              />
            }
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Overview
            data={connectedUsers}
            icon={
              <MdPerson
                style={{
                  width: "25px",
                  height: "25px",

                  color: "white",
                }}
              />
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Overview
            data={exclusionRequests}
            icon={
              <MdPerson
                style={{
                  width: "25px",
                  height: "25px",

                  color: "white",
                }}
              />
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Overview
            data={pendingRequests}
            icon={
              <MdPerson
                style={{
                  width: "25px",
                  height: "25px",

                  color: "white",
                }}
              />
            }
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={2}
          sx={{
            display: { xs: "flex", lg: "none" },
            justifyContent: "center",
            alignItems: "center",
          }}
        ></Grid>
      </Grid>
    </Paper>
  );
};

export default B2bCard;
