import jwtDecode from "jwt-decode";

import { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";

import { useFormik } from "formik";
import * as Yup from "yup";

import Form from "react-bootstrap/Form";
import { Button, Col, Row, Spinner } from "react-bootstrap";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";

import { db, fsTimestampCreador } from "../../firebase/firebase-config";
import {
  addB2btoFb,
  checkCodeName,
  createAssociationImage,
  createB2b,
} from "../../redux/b2b/actions/actions";
import { refreshToken } from "../../redux/user/actions/auth";
import UploadImage from "./UploadImage";

const MySwal = withReactContent(Swal);

const CreateB2b = ({ onClose, handleRefresh }) => {
  const [loading, setLoading] = useState(false);

  const { idToken, userGetToken } = useSelector((state) => state.auth);

  const reloadToken = async () => {
    let freshToken = await userGetToken.getIdToken(true);
    return freshToken;
    //setToken(freshToken);
  };
  //   console.log(idToken);

  const [imageUpload, setImageUpload] = useState("");
  const [image, setImage] = useState("");
  const [fileUrl, setFileUrl] = useState(null);

  const validate = Yup.object({
    name: Yup.string().required("Requerido"),
    businessName: Yup.string().required("Requerido"),
    codeName: Yup.string()
      .required("Requerido")
      .matches(/^[a-zA-Z]+$/, "Debe contener solo letras")
      .min(3, "Debe tener al menos 3 caracteres")
      .max(4, "No debe tener más de 4 caracteres"),
    legalId: Yup.string().required("Requerido"),
    latitude: Yup.number().required("Requerido"),
    longitude: Yup.number().required("Requerido"),
    address: Yup.string().required("Requerido"),
    phone: Yup.string(),
    email: Yup.string()
      .email("Formato no valido")
      .typeError("Formato no valido")
      .required("Requerido"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      codeName: "",
      legalId: "",
      phone: "",
      businessName: "",
      location: "",
      address: "",
      latitude: "",
      longitude: "",
      isActive: false,
    },
    validationSchema: validate,
    onSubmit: async (values, { resetForm }) => {
      let creationDate = fsTimestampCreador.fromDate(new Date(Date.now()));

      const data = {
        name: values.name,
        cdate: creationDate,
        codeName: values.codeName,
        legalId: values.legalId,
        phone: values.phone,
        email: values.email,
        businessName: values.businessName,
        location: new firebase.firestore.GeoPoint(
          parseFloat(values.latitude),
          parseFloat(values.longitude)
        ),
        address: values.address,
        isActive: values.isActive ?? false,
      };
      console.log(values);
      const confirmResult = await MySwal.fire({
        title: "Confirmar",
        text: "¿Estás seguro de que deseas agregar esta asociación?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, crear",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });
      if (confirmResult.isConfirmed) {
        const loadingSwal = MySwal.fire({
          title: "Guardando...",
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            MySwal.showLoading();
          },
        });
        try {
          const codeNameAlreadyExists = await checkCodeName(values.codeName);
          if (codeNameAlreadyExists) {
            MySwal.fire({
              title: "Código Existente",
              text: "El código de asociación ya existe. Por favor, elija otro.",
              icon: "error",
            });
            return; // Exit early if the codeName exists
          } else {
            const id = await addB2btoFb(data);
            if (imageUpload !== "") {
              createAssociationImage(imageUpload, id);
            }

            const adminDataToSet = {
              associationId: id,
              email: values.email,
              cdate: creationDate,
              associationRef: `/associations/${id}`,
            };

            if (isValidToken(idToken)) {
              const res = await createB2b(adminDataToSet, idToken);
              if (res.ok) {
                MySwal.fire(`Asociación Creada`, "", "success");
                loadingSwal.close();
                handleRefresh();
                onClose();
              } else {
                MySwal.fire(
                  "Error",
                  `Hubo un error al crear la asociación`,
                  "error"
                );
              }
            } else {
              const newToken = await refreshToken();
              const res = await createB2b(adminDataToSet, newToken);
              if (res.ok) {
                MySwal.fire(`Asociación Creada`, "", "success");
                loadingSwal.close();
                handleRefresh();
                onClose();
              } else {
                MySwal.fire(
                  "Error",
                  `Hubo un error al crear la asociación`,
                  "error"
                );
              }
            }
          }
        } catch (error) {
          console.log(error);

          MySwal.fire("Error", `${error}`, "error");
          loadingSwal.close();
          handleRefresh();
          onClose();
        }
      }
    },
  });
  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };
  return (
    <form className="p-3">
      {/* {Object.keys(errors).map((val) => {
        return <p>{val}</p>;
      })} */}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Nombre Asociación"
            fullWidth
            variant="standard"
            name="name"
            autoComplete="false"
            auto
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Correo Electrónico"
            fullWidth
            variant="standard"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Empresa de la asociación"
            fullWidth
            variant="standard"
            name="businessName"
            value={formik.values.businessName}
            onChange={formik.handleChange}
            error={
              formik.touched.businessName && Boolean(formik.errors.businessName)
            }
            helperText={
              formik.touched.businessName && formik.errors.businessName
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Código"
            fullWidth
            variant="standard"
            name="codeName"
            placeholder="ABC"
            value={formik.values.codeName}
            onChange={(event) => {
              formik.handleChange(event);
              const uppercaseValue = event.target.value.toUpperCase();
              formik.setFieldValue("codeName", uppercaseValue);
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.codeName && Boolean(formik.errors.codeName)}
            helperText={formik.touched.codeName && formik.errors.codeName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Teléfono"
            fullWidth
            variant="standard"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Cedula Jurídica"
            fullWidth
            variant="standard"
            name="legalId"
            value={formik.values.legalId}
            onChange={formik.handleChange}
            error={formik.touched.legalId && Boolean(formik.errors.legalId)}
            helperText={formik.touched.legalId && formik.errors.legalId}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Dirección"
            fullWidth
            variant="standard"
            name="address"
            value={formik.values.address}
            onChange={formik.handleChange}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Latitud"
            fullWidth
            variant="standard"
            name="latitude"
            value={formik.values.latitude}
            onChange={formik.handleChange}
            error={formik.touched.latitude && Boolean(formik.errors.latitude)}
            helperText={formik.touched.latitude && formik.errors.latitude}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Longitud"
            fullWidth
            variant="standard"
            name="longitude"
            value={formik.values.longitude}
            onChange={formik.handleChange}
            error={formik.touched.longitude && Boolean(formik.errors.longitude)}
            helperText={formik.touched.longitude && formik.errors.longitude}
          />
        </Grid>
        <Grid item xs={6} mt={2}>
          <FormControl
            component="fieldset"
            sx={{
              marginLeft: "0px !important",
              flexDirection: "row",
              marginBottom: "15px",
            }}
          >
            <FormControlLabel
              sx={{ marginLeft: "0px !important", flexDirection: "row" }}
              value="isActive"
              label="Activa"
              labelPlacement="start"
              control={
                <Checkbox
                  name="isActive"
                  checked={formik.values.isActive}
                  onChange={formik.handleChange}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} mt={2}>
          <UploadImage
            loadUrl={setFileUrl}
            imageUpload={imageUpload}
            setImageUpload={setImageUpload}
            setImage={setImage}
            image={image}
          />
        </Grid>

        <Grid
          item
          xs={12}
          mt={2}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="primary"
            size="md"
            className="rounded-pill text-light px-3 ms-auto"
            onClick={formik.handleSubmit}
            disabled={loading}
          >
            {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {!loading && (
              <span style={{ color: "white !important" }}>Crear</span>
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateB2b;
