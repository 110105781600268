import Form from "react-bootstrap/Form";
import { Button, Stack, Col, Row, Spinner } from "react-bootstrap";

import { useFormik } from "formik";
import * as Yup from "yup";

import { createExchangeSchema } from "./../../schemas/create-exchange/create-exchange";

import FormInput from "../form-input/form-input";

import { fsTimestampCreador } from "../../firebase/firebase-config";
import { createExchange } from "../../firebase/firebase.utils";
import { useState } from "react";

const CreateExchangeModal = ({ onClose, id, business }) => {
  const categories = [
    "Bar/Vida Nocturna",
    "Restaurante/Cafetería",
    "Tienda deportiva",
    "Outfit",
    "Servicios de salud",
    "Recreación al aire libre",
    "Instalación deportiva",
    "Entretenimiento Indoor",
    "Hospedaje",
    "Supermercado/Abarrotes",
    "Tienda de tecnología",
    "Servicios educativos",
    "Estetica/Spa/Cuidado personal",
    "Productos especializados",
    "Eventos deportivos",
    "Otro",
  ];

  const [loading, setLoading] = useState();

  const { handleChange, handleSubmit, handleBlur, touched, values, errors } =
    useFormik({
      initialValues: {
        exchangeable: "",
        categories: categories[0],
        sportCoinsValue: "",
        specification: "",
        restrictions: "",
        dueDate: "",
      },
      validationSchema: createExchangeSchema,
      onSubmit: (values, { resetForm }) => {
        setLoading(true);
        //const tags = selected;

        let creationDate = fsTimestampCreador.fromDate(new Date(Date.now()));
        let dueDate = fsTimestampCreador.fromDate(
          new Date(values.dueDate + "T00:00:00")
        );

        const exchangeData = {
          cdate: creationDate,
          businessName: business.name,
          name: values.exchangeable,
          categories: values.categories,
          coinPrice: values.sportCoinsValue,
          description: values.specification,
          restrictions: values.restrictions,
          dueDate: dueDate,
          status: "active",
        };

        createExchange(id, exchangeData);

        setTimeout(() => {
          setLoading(false);
          //handleRefresh();
          resetForm();
          onClose(false);
        }, 2000);
      },
    });
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      noValidate
      className="p-3"
    >
      <Form.Group>
        <FormInput
          label={"Nombre del Artículo/Servicios/Producto canjeable:"}
          onChange={handleChange}
          value={values.exchangeable}
          type="text"
          name="exchangeable"
          errorList={errors}
          onBlur={handleBlur}
          touched={touched}
        />

        <Form.Control.Feedback type="invalid">
          {errors.exchangeable}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label style={{ fontSize: "14px" }} className="mt-3">
          Categorias
        </Form.Label>
        <Form.Select
          size="sm"
          onChange={handleChange}
          name="categories"
          value={values.categories}
          className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
        >
          {categories.map((category) => {
            return (
              <option value={category} name="categories">
                {category}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>

      <Form.Group>
        <FormInput
          label={"Valor en Sport Coins"}
          onChange={handleChange}
          value={values.sportCoinsValue}
          type="number"
          name="sportCoinsValue"
          errorList={errors}
          style={{ marginTop: "1px" }}
          onBlur={handleBlur}
          touched={touched}
        />

        <Form.Control.Feedback type="invalid">
          {errors.sportCoinsValue}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <FormInput
          label={"Especificaciones del producto:"}
          as="textarea"
          onChange={handleChange}
          value={values.specification}
          type="text"
          name="specification"
          errorList={errors}
          onBlur={handleBlur}
          touched={touched}
        />

        <Form.Control.Feedback type="invalid">
          {errors.specification}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <FormInput
          label={"Restricciones del canje:"}
          as="textarea"
          onChange={handleChange}
          value={values.restrictions}
          type="text"
          name="restrictions"
          errorList={errors}
          onBlur={handleBlur}
          touched={touched}
        />

        <Form.Control.Feedback type="invalid">
          {errors.restrictions}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <FormInput
          label={"Valido hasta"}
          onChange={handleChange}
          value={values.dueDate}
          type="date"
          name="dueDate"
          errorList={errors}
        />

        <Form.Control.Feedback type="invalid">
          {errors.dueDate}
        </Form.Control.Feedback>
      </Form.Group>

      <Stack direction="horizontal" className="mt-2">
        <Button
          variant="light"
          className="rounded-pill ms-auto"
          size="small"
          onClick={() => onClose(false)}
        >
          Cancelar
        </Button>
        <Button className="rounded-pill" size="small" type="submit">
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {!loading && <span style={{ color: "white !important" }}>Crear</span>}
        </Button>
      </Stack>
    </Form>
  );
};

export default CreateExchangeModal;
