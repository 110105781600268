import jwtDecode from "jwt-decode";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField"; // Import FilePond image preview styles

import { useFormik } from "formik";
import * as Yup from "yup";

import { useSelector } from "react-redux";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { Close } from "@mui/icons-material";
import {
  activateUser,
  getUserByUserRef,
} from "../../redux/b2b/actions/actions";
import { refreshToken } from "../../redux/user/actions/auth";
import { styles } from "../../styles/styles";
import { ASbutton } from "../buttons/ASbutton";
import { DatePicker } from "@mui/x-date-pickers";

const MySwal = withReactContent(Swal);
export const ViewUserModal = ({ open, onClose, code, onRefresh }) => {
  const theme = useTheme();
  const { idToken } = useSelector((state) => state.auth);
  const [parsedDate, setParsedDate] = useState(dayjs("2005-01-01"));
  const [userInfo, setUserInfo] = useState([]);

  const [loading, setloading] = useState(true);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
  });

  const getInfo = useCallback(async () => {
    try {
      const userData = await getUserByUserRef(code.userRef);
      setUserInfo(userData);
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  }, [code]);

  useEffect(() => {
    console.log(userInfo);
    getInfo();
  }, [getInfo]);

  const formik = useFormik({
    initialValues: {
      email: userInfo?.email ?? "",
      name: userInfo?.name ?? "",
      lastname: userInfo?.lastname ?? "",
      secondLastname: userInfo?.secondLastname ?? "",
      phone: userInfo?.phone ?? "",
      birthday: parsedDate,
      personalId: userInfo?.personalId ?? "",
    },
    enableReinitialize: true,

    // validationSchema: validationSchema,
  });

  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };

  if (loading) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            // backgroundColor: theme.palette.secondary.secondary,
            width: "100%",
            backgroundImage: "none",
            overflowX: "hidden",
          },
        }}
      >
        <DialogTitle sx={{ color: theme.palette.primary.main }}>
          Información del Plan
          <Box sx={styles.closeButton} onClick={onClose}>
            <Close />
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",

            flexDirection: "column",
          }}
        >
          <Container maxWidth="xs" id="acceptanceContainer">
            <CircularProgress />
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  if (!code) {
    return (
      <Box sx={styles.formContainer}>
        <Box sx={[styles.plansCard, { mt: 0, mb: 2 }]}>No hay datos</Box>
      </Box>
    );
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"sm"}
      PaperProps={{
        sx: {
          width: "100%",
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle sx={{ color: theme.palette.primary.main }}>
        {userInfo?.name} {userInfo?.lastname} {userInfo?.secondLastname}
        <Box sx={styles.closeButton} onClick={onClose}>
          <Close />
        </Box>
      </DialogTitle>
      <DialogContent>
        <form>
          <Box sx={[styles.plansCard, { mt: 0, mb: 2, minHeight: 0 }]}>
            <Grid container sx={styles.summaryCard}>
              <Grid
                container
                columnGap={0}
                justifyContent={"space-between"}
                mb={4}
                mt={2}
              >
                <Grid item xs={12} sm={12} md={3.7}>
                  <FormControl fullWidth>
                    <TextField
                      label="Nombre"
                      variant="outlined"
                      name="name"
                      value={formik.values.name}
                      InputProps={{
                        readOnly: true,
                        pointerEvents: "none",
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={3.7}>
                  <FormControl fullWidth>
                    <TextField
                      label="Apellido"
                      name="lastname"
                      variant="outlined"
                      disabled={userInfo.isActiveMember}
                      value={formik.values.lastname}
                      InputProps={{
                        readOnly: true,
                        pointerEvents: "none",
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={3.7}>
                  <FormControl fullWidth>
                    <TextField
                      label="Segundo Apellido"
                      variant="outlined"
                      name="secondLastname"
                      disabled={userInfo.isActiveMember}
                      value={formik.values.secondLastname}
                      InputProps={{
                        readOnly: true,
                        pointerEvents: "none",
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              {/* ROW 2 */}

              <Grid
                container
                columnGap={0}
                justifyContent={"space-between"}
                mb={4}
              >
                <Grid item xs={12} sm={12} md={3.7}>
                  <FormControl fullWidth>
                    <TextField
                      label="Correo Electrónico"
                      variant="outlined"
                      disabled
                      name="email"
                      value={code?.email}
                      InputProps={{
                        readOnly: true,
                        pointerEvents: "none",
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={2.5}>
                  <FormControl fullWidth>
                    <TextField
                      label="Telêfono"
                      variant="outlined"
                      name="phone"
                      value={formik.values.phone}
                      InputProps={{
                        readOnly: true,
                        pointerEvents: "none",
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4.85}>
                  <FormControl fullWidth>
                    <TextField
                      label="Identificación"
                      variant="outlined"
                      name="personalId"
                      disabled={userInfo.isActiveMember}
                      value={formik.values.personalId}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.personalId &&
                        Boolean(formik.errors.personalId)
                      }
                      helperText={
                        formik.touched.personalId && formik.errors.personalId
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              {/* ROW 3 */}

              <Grid
                container
                columnGap={0}
                justifyContent={"space-between"}
                mb={2}
              >
                <Grid item xs={12} sm={12} md={3.7}>
                  <FormControl fullWidth>
                    <DatePicker
                      label="Fecha de Nacimiento"
                      id="birthday"
                      name="birthday"
                      value={parsedDate}
                      disabled={userInfo.isActiveMember}
                      onChange={(newValue) => {
                        formik.setFieldValue("birthday", newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={
                            formik.touched.birthday &&
                            Boolean(formik.errors.birthday)
                          }
                          helperText={
                            formik.touched.birthday && formik.errors.birthday
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Box ml={2}>
          <ASbutton
            onClick={onClose}
            backgroundColor={theme.palette.primary.main}
            color={"white"}
            //   disabled={code.statusCode !== 3}
            buttonText={"Volver"}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};
