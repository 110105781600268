import Form from "react-bootstrap/Form";
import { Button, Stack, Spinner, Image } from "react-bootstrap";

import { useFormik } from "formik";

import { createPromoSchema } from "../../schemas/create-promotion/create-promotion";

import FormInput from "../form-input/form-input";
import UploadLogo from "../upload-logo/upload-logo";

import { fsTimestampCreador } from "../../firebase/firebase-config";
import {
  createPromotion,
  createPromotionImage,
} from "../../firebase/firebase.utils";
import { useState } from "react";

const CreatePromoModal = ({ onClose, sponsorId, business }) => {
  const categories = [
    "Bar/Vida Nocturna",
    "Restaurante/Cafetería",
    "Tienda deportiva",
    "Outfit",
    "Servicios de salud",
    "Recreación al aire libre",
    "Instalación deportiva",
    "Entretenimiento Indoor",
    "Hospedaje",
    "Supermercado/Abarrotes",
    "Tienda de tecnología",
    "Servicios educativos",
    "Estetica/Spa/Cuidado personal",
    "Productos especializados",
    "Eventos deportivos",
    "Otro",
  ];

  const [loading, setLoading] = useState();
  const [promoImageUpload, setPromoImageUpload] = useState(null);
  const [promoImage, setPromoImage] = useState("");
  const [promofileUrl, setPromoFileUrl] = useState(null);

  const { handleChange, handleSubmit, handleBlur, touched, values, errors } =
    useFormik({
      initialValues: {
        promoName: "",
        categories: categories[0],
        promoSummary: "",
        description: "",
        dueDate: "",
        imageURL: "",
      },
      validationSchema: createPromoSchema,
      onSubmit: async (values, { resetForm }) => {
        setLoading(true);

        let dueDate = fsTimestampCreador.fromDate(
          new Date(values.dueDate + "T00:00:00")
        );

        const promotionData = {
          cdate: new Date(),
          businessName: business.name,
          name: values.promoName,
          category: values.categories,
          restrictions: values.description,
          description: values.promoSummary,
          dueDate: dueDate,
          status: "pending",
          imageURL: "",
        };

        const idPromotion = await createPromotion(sponsorId, promotionData);
        createPromotionImage(promoImageUpload, sponsorId, idPromotion);

        setTimeout(() => {
          setLoading(false);
          //handleRefresh();
          resetForm();
          onClose(false);
        }, 2000);
      },
    });
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      noValidate
      className="p-3"
    >
      <Form.Group>
        <FormInput
          label={"Nombre de la promoción:"}
          onChange={handleChange}
          value={values.promoName}
          type="text"
          name="promoName"
          errorList={errors}
          onBlur={handleBlur}
          touched={touched}
        />

        <Form.Control.Feedback type="invalid">
          {errors.promoName}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label style={{ fontSize: "14px" }} className="mt-3">
          Categorias
        </Form.Label>
        <Form.Select
          size="sm"
          onChange={handleChange}
          name="categories"
          value={values.categories}
          className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
        >
          {categories.map((category) => {
            return (
              <option value={category} name="categories">
                {category}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>

      <Form.Group>
        <FormInput
          label={"Resumen de la promoción"}
          as="textarea"
          onChange={handleChange}
          value={values.promoSummary}
          type="text"
          name="promoSummary"
          errorList={errors}
          style={{ marginTop: "1px" }}
          onBlur={handleBlur}
          touched={touched}
        />

        <Form.Control.Feedback type="invalid">
          {errors.promoSummary}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <FormInput
          as="textarea"
          rows="4"
          cols="50"
          label={"Detalles y restricciones de la promoción:"}
          onChange={handleChange}
          value={values.description}
          name="description"
          errorList={errors}
          onBlur={handleBlur}
          touched={touched}
        />

        <Form.Control.Feedback type="invalid">
          {errors.description}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <FormInput
          label={"Valido hasta"}
          onChange={handleChange}
          value={values.dueDate}
          type="date"
          name="dueDate"
          errorList={errors}
        />

        <Form.Control.Feedback type="invalid">
          {errors.dueDate}
        </Form.Control.Feedback>
      </Form.Group>

      <div className="w-50 mx-auto">
        <UploadLogo
          title={"Imagen de la promoción"}
          imageUpload={promoImageUpload}
          setImageUpload={setPromoImageUpload}
          setImage={setPromoImage}
          image={promoImage}
          inputId={"file-input-promo"}
        />
      </div>

      <Stack direction="horizontal" className="mt-2">
        <Button
          variant="light"
          className="rounded-pill ms-auto"
          size="small"
          onClick={() => onClose(false)}
        >
          Cancelar
        </Button>
        <Button className="rounded-pill" size="small" type="submit">
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {!loading && <span style={{ color: "white !important" }}>Crear</span>}
        </Button>
      </Stack>
    </Form>
  );
};

export default CreatePromoModal;
