import { types } from "../types/types";

const INITIAL_STATE = {
  users: [],
  b2bUsers: [],
  refresh: true,
  isLoading: false,
};

const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.getUsers:
      return {
        ...state,
        users: action.payload.users,
        // displayName: action.payload.displayName,
      };
    case types.getB2BUsers:
      return {
        ...state,
        b2bUsers: action.payload.b2bUsers,
        // displayName: action.payload.displayName,
      };
    case types.addUsers:
      return {
        ...state,
        users: action.payload,
      };

    case types.addWallets:
      return {
        ...state,
        wallets: action.payload,
      };

    case types.addPrivateData:
      return {
        ...state,
        privateData: action.payload,
      };
    case types.removeUsers:
      return {
        ...state,
        users: null,
      };

    case types.refreshUsers:
      return {
        ...state,
        refresh: action.payload,
      };

    case types.isLoadingUsers:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        // displayName: action.payload.displayName,
      };

    default:
      return state;
  }
};

export default usersReducer;
